import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HorariosCalendarioService from "../../Services/ConfigCalendar/HorariosCalendarioService";
import toast from "react-hot-toast";
import { message } from "../../Utils/functions";

const SeguimientoServicioUbicacionHook = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const refFormik = useRef();
    const navigate = useNavigate();
    const initial = {
        buscar: ''
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState("");
    const [servicioInfo, setServicioInfo] = useState({});


    const columns = [
        { field: 'nombre', headerName: 'Ubicacion', width: '340' },
        { field: 'domingoUI', headerName: 'Domingo', width: '100' },
        { field: 'lunesUI', headerName: 'Lunes', width: '100' },
        { field: 'martesUI', headerName: 'Martes', width: '100' },
        { field: 'miercolesUI', headerName: 'Miércoles', width: '100' },
        { field: 'juevesUI', headerName: 'Jueves', width: '100' },
        { field: 'viernesUI', headerName: 'Viernes', width: '100' },
        { field: 'sabadoUI', headerName: 'Sábado', width: '100' },
    ];

    useEffect(() => {
        Promise.all([getServicioInfo(), getData()])
        .then(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        getData();
    }, [buscar]);

    const getServicioInfo = async () => {
        await HorariosCalendarioService.getServicio(id)
        .then(resp => {
            setServicioInfo(resp.data);
        })
        .catch(err => {
            toast.error(message('¡Error al consultar el servicio!'));
        })
        .finally(() => {
            setLoading(false);
        });
    }


    const getData = async () => {
        setLoading(true);
        let params = {
            buscar,
            calendarioTipoId: id,
            page,
            rows
        }
        await HorariosCalendarioService.getSeguimientoUbicaciones(params)
        .then(resp => {
            const items = resp.data.data.map(item => {
                return {
                    ...item,
                    domingoUI: item.domingo,
                    lunesUI: item.lunes,
                    martesUI: item.martes,
                    miercolesUI: item.miercoles,
                    juevesUI: item.jueves,
                    viernesUI: item.viernes,
                    sabadoUI: item.sabado,
                }
            });
            setData(items);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleEdit = (item) => {
        navigate(`/admin/servicios-datamovil/${id}/${item.ubicacionId}`);
    }

    return {
        refFormik,
        navigate,
        initialValue,
        page,
        rows,
        totalPages,
        totalRows,
        data,
        loading,
        setLoading,
        setBuscar,
        columns,
        handleEdit,
        servicioInfo,
        modalMessage,
        setModalMessage,
        getData,
        getServicioInfo
    };
}

export default SeguimientoServicioUbicacionHook;