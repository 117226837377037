import { Form, useLocation, useNavigate, useParams } from "react-router-dom";
import DataTable from "../../Components/datatable/DataTable";
import SeguimientoServicioUbicacionHook from "../../Hooks/ConfigCalendar/SeguimientoServicioUbicacionHook";
import { TextField } from "../../Components/TextField";
import { Formik } from "formik";
import Modal from "../../Components/Modal/Modal";
import Spinner from "../../Components/Loadig";
import React, { Fragment } from "react";
import { Toaster } from "react-hot-toast";
import TipoServicioModal from "./TipoServicioModal";

const DetalleServicio = React.forwardRef((props, ref) => {
    const { state } = useLocation();
    const { id } = useParams();

    const {
        refFormik,
        navigate,
        initialValue,
        page,
        rows,
        totalPages,
        totalRows,
        data,
        loading,
        setLoading,
        setBuscar,
        columns,
        handleEdit,
        servicioInfo,
        modalMessage,
        setModalMessage,
        getData,
        getServicioInfo
    } = SeguimientoServicioUbicacionHook();

    const styleFakeInput = {
        width: '100%',
        height: '40px',
        backgroundColor: '#F5F5F5',
        lineHeight: '30px',
        padding: '5px 10px',
        borderRadios: '3px'
    };

    return (
        <Fragment ref={ref}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                ref={refFormik}
            // validationSchema={validate}
            >{
                    ({ isValid, values, setFieldValue }) => (
                        <>
                            <Form>
                                <Toaster
                                    position="top-right"
                                    toastOptions={{
                                        success: {
                                            style: {
                                                background: '#47a066',
                                                color: '#FFFF',
                                                borderLeft: '10px solid #2f7246'
                                            },
                                        },
                                        error: {
                                            style: {
                                                background: '#d53f3f',
                                                color: '#FFFF',
                                                borderLeft: '10px solid #ac241a'
                                            },
                                        },
                                    }}
                                />
                                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                                <Modal isOpen={modalMessage.isOpen} color='#fff' width={460}>
                                    {
                                        (modalMessage.isOpen && modalMessage.type === 1) &&
                                        <TipoServicioModal
                                            modalMessage={modalMessage}
                                            setModalMessage={setModalMessage}
                                            edit={true}
                                            getData={getServicioInfo}
                                            servicio={servicioInfo}
                                        />
                                    }
                                </Modal>
                                <section className='headerTab'>
                                    <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                                        <div>
                                            <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                                            Regresar a listado de servicios
                                        </div>
                                    </header>
                                </section>
                                <section className='mx-4 my-4 d-flex flex-column'>
                                    <div className="col-12 mt-2">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>{servicioInfo.tipo}</strong>
                                            </div>
                                            <div className="col-auto">
                                                <button className='btn' onClick={(e) => {
                                                    navigate(`/admin/servicios-datamovil/${id}/0`, { state: servicioInfo })
                                                }}>
                                                    Agregar ubicación
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-3 mt-2">
                                                <label htmlFor="" className="text-sm">Duración de servicio</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3 mt-2" style={{ display: 'block' }}>
                                                <div style={styleFakeInput} className="d-flex justify-content-between">
                                                    <span>{servicioInfo.duracionAtencion}</span>
                                                    <button className="btn-link-blue" onClick={(e) => {
                                                        e.preventDefault();
                                                        setModalMessage({ isOpen: true, type: 1 })
                                                    }}>
                                                        <i className="ri ri-pencil-fill"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>Ubicaciones del servicio</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-3 inner-addon right-addon  mt-2 mr-1">
                                                <i className="glyphicon fas fa-search"></i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Buscar"
                                                    id="buscar"
                                                    name="buscar"
                                                    onChange={
                                                        (e) => {
                                                            setFieldValue("buscar", e.target.value);
                                                            setBuscar(e.target.value);
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-12'>
                                            <DataTable
                                                column={columns}
                                                data={data}
                                                currentPage={page - 1}
                                                paginate={true}
                                                pageCount={totalRows / rows}
                                                editable={true}
                                                handleEdit={handleEdit}
                                            // handlePageClick={handlePageClick}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </Form>
                        </>
                    )
                }
            </Formik>
        </Fragment>
    );
})

export default DetalleServicio;