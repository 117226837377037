import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    generalId: "",
    vin: "",
    numSolicitud: 0,
    nombreMarca: "",
    nombreModelo: "",
    nombreYear: "",
    nombreVersion: "",
    color: "",
    kilometraje: "",
    procedenciaAuto: "",
    fechaEntrega: "",
    numMotor: "",
    nombre: "",
    claves: 0,
    numContrato: "",
    numPlaca: ""
}

export const ContratoCardSlice = createSlice({
    name: 'ContratoCardSlice',
    initialState,
    reducers: {
        setDataContrato: (state, action) => {
            return {
                ...state,
                generalId: action.payload.generalId,
                vin: action.payload.vin,
                numSolicitud: action.payload.numSolicitud,
                nombreMarca: action.payload.nombreMarca,
                nombreModelo: action.payload.nombreModelo,
                nombreYear: action.payload.nombreYear,
                nombreVersion: action.payload.nombreVersion,
                color: action.payload.color,
                kilometraje: action.payload.kilometraje,
                procedenciaAuto: action.payload.procedenciaAuto,
                fechaEntrega: action.payload.fechaEntrega,
                numMotor: action.payload.numMotor,
                nombre: action.payload.nombre,
                claves: action.payload.claves,
                numContrato: action.payload.numContrato,
                numPlaca: action.payload.numPlaca
            }
        },
        clearDataContrato: (state) => {
            state = {};
            return {
                ...state,
                generalId: "",
                vin: "",
                numSolicitud: 0,
                nombreMarca: "",
                nombreModelo: "",
                nombreYear: "",
                nombreVersion: "",
                color: "",
                kilometraje: "",
                procedenciaAuto: "",
                fechaEntrega: "",
                numMotor: "",
                nombre: "",
                claves: 0,
                numContrato: "",
                numPlaca: ""
            }
        }
    }
})

export const selectContratoCard = (state) => {
    return state.ContratoCardSlice;
};

export const { setDataContrato, clearDataContrato } = ContratoCardSlice.actions
export default ContratoCardSlice.reducer;