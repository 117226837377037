import { useEffect, useRef, useState } from 'react'
import AutorizarDictamenService from '../../Services/Seguros/AutorizarDictamenService';
import SeguroSinCotizarService from '../../Services/Seguros/seguroSinCotizarService';
import SeguroPolizaService from '../../Services/Seguros/SeguroPolizaService';
import UnidadesRecuperadasService from '../../Services/Cartera/UnidadesRecuperadas';
import GeneracionExpedientesService from '../../Services/Cartera/GeneracionExpedientesService';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { handleUnchekedColumns, IsNullOrEmpty, message,FormatFecha } from '../../Utils/functions';

const useGeneracionExpedientes = (toast) => {
    const selectRef = useRef({
        selectNivelCobranza: "",
        selectCobrador: "",
        // selectTipoPersona: "",
        // selectTipoDoc: "",
    });
    const refbtnDownload = useRef();
    const columns = [
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'periodo', headerName: 'Periodo', width: 100 },
        { field: 'tipoPersona', headerName: 'Tipo de persona', width: 150 },
        { field: 'nombrePersona', headerName: 'Nombre de persona' },
        { field: 'montoVencido', headerName: 'Monto vencido', width: 150 },
        { field: 'ultimaInteraccion', headerName: 'Última interacción', width: 200 },
        { field: 'fechaUltimaInteraccion', headerName: 'Fecha de última interacción', width: 200 },
        { field: 'diasVencidos', headerName: 'Días vencidos', width: 130 },
        { field: 'direccion', headerName: 'Dirección' }

    ];

    const [data, setData] = useState([]);
    const [currenPage, setCurrenPage] = useState(1);
    const [pageCount, setPageCount] = useState();
    const [str, setStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [pageRows, setpageRows] = useState(10);
    const [openModal, setOpenModal] = useState(false)
    const [itemSelected, setItemSelected] = useState();
    //Filtros Data
    const [TipoPersona, setTipoPersona] = useState([]);
    const [tipoDocumento, setTipoDocumento] = useState([]);
    const [dataFull, setDataFull] = useState();
    const [nivelCobranza, setNivelCobranza] = useState([]);
    const [cobrador, setCobrador] = useState([]);
    //
    //GenerarPdfModal
    const [generarModal, setGenerarModal] = useState(false);

    //ValueFiltros
    const [nivelCobradorValue, setNivelCobradorValue] = useState("");
    const [nombreCobradorValue, setNombreCobradorValue] = useState("");
    const [nombreCobrador, setNombreCobrador] = useState("");
    const [telefonoCobrador, setTelefonoCobrador] = useState("")
    const [tipoPersonaValue, setTipoPersonaValue] = useState("");
    const [tipoDocumentoValue, setTipoDocumentoValue] = useState("");
    const [IdsDescargar, setIdsDescargar] = useState([]);
    const [stateTP, setstateTP] = useState();
    const [stateTD, setstateTD] = useState();
    // useEffect(() => first  //     handleGetAll(currenPage);
    //     setCurrenPage(1);
    // }, [str, pageRows])


    useEffect(() => {
        handleGetAllSelects();
    }, [])

    const handleGetAll = async (page) => {
        if (IsNullOrEmpty(nivelCobradorValue)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar el nivel de cobranza.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        } else if (IsNullOrEmpty(nombreCobradorValue)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar un cobrador.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        else if (IsNullOrEmpty(tipoPersonaValue)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar un tipo de persona.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setLoading(true);
        await GeneracionExpedientesService.GetAllGeneracionExpedientes(page, pageRows, nivelCobradorValue, nombreCobradorValue, tipoPersonaValue)
            .then(res => {
                setData(res.data.data);
                setPageCount(res.data.totalPages);
                setLoading(false);
                handleUnchekedColumns();
                setIdsDescargar([])
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllSelects = async () => {
        setLoading(true)
        const [] = await Promise.all([
            HandleTipoPersona(),
            handleGetTipoCompra(),
            handleGetAllUsersNotInCobranza()
        ]).finally(() => {
            setLoading(false)
        });
    }
    const HandleTipoPersona = async () => {
        await UnidadesRecuperadasService.getAllTipoPersona().then(res => {
            let NewData = [];
            // NewData.push({
            //     value: "",
            //     label: "Todos"
            // })
            res.data?.responseData?.map(item => {
                NewData.push({
                    value: item.id,
                    label: item.nombre
                });
            });
            setTipoPersona(NewData);
        })
    }
    const handleGetTipoCompra = async () => {
        await GeneracionExpedientesService.GetAllDocumentoVisita()
            .then(res => {
                let NewData = [];
                // NewData.push({
                //     value: "",
                //     label: "Todos"
                // })
                res.data.responseData?.map(item => {
                    NewData.push({
                        value: item.id,
                        label: item.nombreDocumento
                    })
                });
                setTipoDocumento(NewData);
            }).catch(e => {
            })
    }
    const handleGetAllUsersNotInCobranza = async () => {
        await CobranzaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                let NewData = [];
                
                if (res.data.callSucceded) {
                    res.data.responseData?.map(item => {
                        item?.contratos?.map(contrato => {
                            let found = NewData.find(item => item.value === contrato.nivel_cobranza);
                            if (!found && contrato.nivel_cobranza !== "1") {
                                NewData.push({
                                    value: contrato.nivel_cobranza, label: `Nivel ${contrato.nivel_cobranza}`
                                });
                            }
                        })
                    })
                    setNivelCobranza(NewData);
                    setDataFull(res.data.responseData);
                }
            })
            .catch(e => {
                console.log(`ERROR -- ${e}`)
            })
    }

    const handleGetCobradorById = async (value) => {
        let newData = [];
        dataFull?.map(item => {
            if (item.nivel_cobrador == value) {
                if (item.user_detail.status) {
                    newData.push({
                        value: item.user_detail.userId, 
                        label: item.user_detail.names,
                        phone: item.numTelefono
                    });
                }
            }
            setCobrador(newData);
        })
    }
    const handlePageClick = (event) => {
        setCurrenPage(event.selected + 1);
        handleGetAll(event.selected + 1);
    };
    const handlechekedItem = (cheked, value) => {
        if (cheked) {
            setIdsDescargar(current => [...current, {
                sol_id: value.sol_Id,
                contrato: value.contrato,
                vin: value.vin
            }]);
        } else {
            setIdsDescargar(IdsDescargar.filter(item => item.vin !== value.vin));
        }
    }
    const downloadPDF = async () => {
        setLoadingModal(true);
        refbtnDownload.current.disabled = true;
        if (IdsDescargar.length > 0 && !IsNullOrEmpty(tipoDocumentoValue)) {
            await GeneracionExpedientesService.GenerarPlantillaExpediente({
                contratos: IdsDescargar,
                tipodocumentos: tipoDocumentoValue,
                comentarios: '',
                numero_telefono: telefonoCobrador,
                nombre_cobrador: nombreCobrador,
            })
                .then(res => {
                    var FileSaver = require('file-saver');
                    let today = FormatFecha(new Date())
                    // FileSaver.saveAs(res.data, `Expediente_${nombreCobrador}_${new Date(Date.now()).toLocaleString().split(',')[0]}.pdf`);
                    FileSaver.saveAs(res.data, `Expediente_${nombreCobrador}_${today}.pdf`);
                    setLoadingModal(false);
                    refbtnDownload.current.disabled = false;
                }).catch(e => {
                    setLoadingModal(false);
                    refbtnDownload.current.disabled = false;
                    toast.custom(<div className='warnin-toast'>{message("Error.!", "Algo ocurrio al intentar generar el PDF.")}</div>);
                })
        } else {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar al menos un registro y un tipo de cocumento.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            setLoadingModal(false);
            refbtnDownload.current.disabled = false;
        }
    }
    const limpiarFiltro = () => {
        Object.values(selectRef.current).forEach(select => {
            if (select) {
                select?.setValue(""); // Limpiar el valor solo si clearValue está disponible
            }
        });
        setstateTD();
        setTipoDocumentoValue("");
        setstateTP();
        setTipoPersonaValue("");
        setIdsDescargar([]);
        handleUnchekedColumns();
        setData([]);
    }

    const handleGenerarExpedienteModal = () => {
        if (IsNullOrEmpty(nivelCobradorValue)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar el nivel de cobranza.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        } else if (IsNullOrEmpty(nombreCobradorValue)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar un cobrador.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        else if (IsNullOrEmpty(tipoPersonaValue)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar un tipo de persona.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        if (!(IdsDescargar.length > 0)) {
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "Es necesario seleccionar un contrato.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setGenerarModal(true)
    }

    return {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        loadingModal,
        setpageRows,
        setOpenModal,
        openModal,
        handleGetAll,
        setItemSelected,
        itemSelected,
        selectRef,
        TipoPersona,
        tipoDocumento,
        nivelCobranza,
        cobrador,
        handleGetCobradorById,
        setNivelCobradorValue,
        setNombreCobradorValue,
        setNombreCobrador,
        setTelefonoCobrador,
        setTipoDocumentoValue,
        setTipoPersonaValue,
        stateTD, stateTP, setstateTD, setstateTP,
        handlechekedItem,
        downloadPDF,
        setIdsDescargar,
        limpiarFiltro,
        refbtnDownload,
        generarModal,
        setGenerarModal,
        handleGenerarExpedienteModal
    }
}

export default useGeneracionExpedientes