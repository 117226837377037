import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha, formatearMontoMx } from '../../Utils/functions'
const SeguimientoVisita2Hook = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)
  const [data, setData] = useState([])

  const userId = sessionStorage.getItem('userId').trim()
  const nivel_cobranza = "3"

  const columns = [
    { field: 'contrato_id', headerName: 'Contrato' },
    { field: 'periodo', headerName: 'Periodo' },
    { field: 'dias_vencido', headerName: 'Días Vencidos' },
    { field: 'saldo_credito', headerName: 'Monto Insoluto' },
    { field: 'monto_adeudo', headerName: 'Monto adeudo' },
    { field: 'fecha_ultimo_pago', headerName: 'Fecha últ. pago' },
    { field: 'fecha_consulta_clave', headerName: 'Fecha últ. clave' },
    { field: 'dias_sin_consultar_clave', headerName: 'Días sin consultar' },
    { field: 'llamadas', headerName: 'Llamadas' },
    { field: 'fecha_ultima_act', headerName: 'Últ. Actualización' },
  ];

  const inicioObjeto = {
    value: "",
    label: "Seleccione"
  };

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [currentData, setCurrentData] = useState(data)
  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  //Select
  const [ejecutivoData, setEjecutivoData] = useState([])
  const [ejecutivo, setEjecutivo] = useState("")


  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data.filter(item => {
      const nameMatches = name === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(name.toLowerCase()));

      return (
        nameMatches &&
        (ejecutivo === "" || item.userid.toLowerCase() === ejecutivo.toLowerCase())
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, pageRows, data, ejecutivo])

  const handleDetails = (item) => {
    //Movimiento a Detalles del Job
    navigate(`/cobranza/seguimiento-telefonico/detalles`, {
      state: item
    })
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1)
  };

  useEffect(() => {
    setLoading(true);

    handleGetAllData()

    setLoading(false);
  }, [])

  const handleGetAllData = async () => {
    let contracts = await handleGetSeguimientoContrato()

    for (const newItem of contracts) {
      let interaccion = await AddInteracciones(newItem)
      let actualización = await AddUltimaActualizacion(newItem)
      newItem.llamadas = interaccion

      if (actualización !== undefined && actualización !== "" && actualización !== null) {
        const fechaUltimaAct = (actualización.fecha_ultima_act === "1990-01-01T00:00:00" || actualización.fecha_ultima_act === "1900-01-01T00:00:00") ? "-" : FormatFecha(actualización.fecha_ultima_act)
        const validation = actualización.validacion ? "Exito" : "Fallido"
        newItem.fecha_ultima_act = fechaUltimaAct
        newItem.validation = validation
      }
    }

    setData(contracts)
  }

  const handleGetSeguimientoContrato = async () => {
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    //CONSEGUIR TODOS LOS CONTRATOS DE TODOS LOS USUARIOS, con el userId.
    const contractData = await CobranzaService.getAllEjecutivosWithFullDetail(data)
      .then(res => {
        let AllContratos = res.data.responseData.map((user) => {
          let datosFormateados = user?.contratos?.map((item) => {
            const fechaPago = (item.fecha_ultimo_pago === "1990-01-01T00:00:00" || item.fecha_ultimo_pago === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultimo_pago)
            const vigenciaClave = (item.vigencia_clave === "1990-01-01T00:00:00" || item.vigencia_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.vigencia_clave)
            const fechaClave = (item.fecha_consulta_clave === "1990-01-01T00:00:00" || item.fecha_consulta_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_consulta_clave)
            const fechaUltimoCalculo = (item.fecha_ultimo_calculo === "1990-01-01T00:00:00" || item.fecha_consulta_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_consulta_clave)
            const fechaUltimaClave = (item.fecha_ultima_clave === "1990-01-01T00:00:00" || item.fecha_ultima_clave === "1900-01-01T00:00:00") ? "-" : FormatFecha(item.fecha_ultima_clave)
            const saldoCredito = formatearMontoMx(item.saldo_credito);
            const montoAdeudo = formatearMontoMx(item.monto_adeudo);
            const diasformateado = item.dias_vencido + ' días';
            const diasformateado2 = item.dias_sin_consultar_clave + ' días'
            const pagoPeriodo = formatearMontoMx(item.pago_periodo);

            return {
              ...item,
              fecha_ultimo_pago: fechaPago,
              fecha_consulta_clave: fechaClave,
              monto_adeudo: montoAdeudo,
              saldo_credito: saldoCredito,
              dias_vencido: diasformateado,
              dias_sin_consultar_clave: diasformateado2,
              fecha_ultima_clave: fechaUltimaClave,
              pago_periodo: pagoPeriodo,
              fecha_ultimo_calculo: fechaUltimoCalculo,
              vigencia_clave: vigenciaClave
            }
          })

          return datosFormateados
        })

        //Quitar los nulos, y mapear todo en un arreglo. 
        const allContratosFormat = AllContratos.flatMap(item => Array.isArray(item) ? item : [])
          .filter(item => item !== null);

        let cobradoresFormat = res.data.responseData.map((item) => {
          if (item.nivel_cobrador === "2") {
            return {
              value: item.user_detail.userId,
              label: item.user_detail.names,
            }
          }

          return null
        })

        cobradoresFormat = cobradoresFormat.filter(item => item !== null);
        cobradoresFormat.unshift(inicioObjeto)
        setEjecutivoData(cobradoresFormat)
        let nivelCobranza = allContratosFormat?.filter(item => (item.nivel_cobranza === nivel_cobranza) && (item.cartera_vencida === 1))
        return nivelCobranza
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })

    return contractData
  }

  const AddInteracciones = async (item) => {
    let data = {
      field: "contrato",
      value: item.contrato_id
    }

    const response = await CobranzaService.getRowsIteractions(data)
    return response.data.responseData;
  };

  const AddUltimaActualizacion = async (item) => {
    let data = {
      contrato: item.contrato_id
    }
    const response = await CobranzaService.getValidacionUltimaAct(data)
    return response.data;
  };

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    handleDetails,
    pageCount,
    currentPage,
    handlePageClick,
    ejecutivoData,
    setEjecutivo
  }
}
export default SeguimientoVisita2Hook