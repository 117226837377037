import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import ClavesDemoService from '../../../Services/Inventario/ClavesDemoService';
import { useParams } from 'react-router-dom';
import AgregarClaveDemoModal from '../../../Components/Modal/AgregarClaveDemoModal';
import SuccessModal from '../../../Components/Modal/SuccessModal';
import ErrorModal from '../../../Components/Modal/ErrorModal';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { Section, SubModule } from '../../../Auth/Authorization';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';

const InvClavesDemo = () => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    
    const initial = {}

    const [initialValue, setInitialValue] = useState(initial)
    const { id, generalid, valuacionid } = useParams()
    const [claves, setClaves] = useState([])

    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    useEffect(() => {
        getClaves();
    }, []);

    const getClaves = async () => {
        try {
            setLoading(true);
            const resp = await ClavesDemoService.GetAllClavesDemo();
            console.log(resp);
            const items = resp.data.map((item) => ({
                clave: item.clavedemo, // Cambiado a 'clavedemo' en lugar de 'claveDemo'
                fechavencimiento: item.fechavencimiento // Cambiado a 'fechavencimiento' en lugar de 'fechaVencimiento'
            }));
            const resOrdenado = items.sort((a, b) => new Date(a.vigencia) - new Date(b.vigencia)); // Ajustar a 'vigencia'
            setClaves(resOrdenado);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };



    const getDateFormat = (fecha, msj) => {
        let d = new Date(fecha + "Z");
        if (fecha !== undefined) {
            return msj + d.toLocaleString();
        }
    }

    const formatDate = (date) => {
        var d = new Date(date + "Z");
        if (date !== undefined) {
            const day = d.getDate();
            const month = d.getMonth() + 1;
            const year = d.getFullYear();
            return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
        }
    }

    const handleActualizar = () => {
        console.log();
    }


    const validate = {}

    const containerStyle = {
        width: '100%',
        height: '37px',
        background: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'left', // Corregido a 'flex-start' para alinear a la izquierda
        alignItems: 'center',
        padding: '8px', // Cambiado el valor 'box' a '8px'
        borderRadius: '4px',
    };

    const cveReq = {
        background: '#E8EAF6',
        color: '#1A3D7E',
        width: '50px',
        height: '50px',
        borderRadius: '4px',
        padding: '8px',
        margin: '5px 2px'
    }

    return (
        <>
            <Modal isOpen={modalMessage.isOpen} color='#fff' width={450}>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 5 &&
                    <AgregarClaveDemoModal
                        modalMessage={modalMessage}
                        getClaves={getClaves}
                        setModalMessage={setModalMessage}
                        claves={claves}
                    />
                }
            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={{}}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <div className='justify-content-start p-2 align-items-center' style={{ margin: '8px' }}>
                            <ValidatePermission isActive={ValidatePermissionById(4, Section.ConsultaClavesDemo, SubModule.ClavesDemo)?.Ver}>
                                <div className='row mt-3 justify-content-between'>
                                    <div className="col-auto" style={{ "marginTop": "5px" }}>
                                        <h5><strong>Consulta de Claves Demo</strong></h5>
                                    </div>
                                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ConsultaClavesDemo, SubModule.ClavesDemo)?.Agregar}>
                                        <div className="col-auto ml-auto" style={{ "marginBottom": "5px" }}>
                                            <button className='btn btn-sm' onClick={(event) => {
                                                setModalMessage({ isOpen: true, title: 'Ingresar nueva clave', type: 5 })

                                            }}>
                                                Ingresar clave
                                            </button>
                                        </div>
                                    </ValidatePermission>
                                </div>
                                <div className="row mt-3">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación se muestra el tipo de solicitud, la clave y su vigencia del vehículo previamente seleccionado.
                                    </small>
                                </div>
                                <div className="row mt-2 mb-2" style={{ margin: '16px' }}>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Clave</th>
                                                <th>Vigencia</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {claves.map((clave, idx) => (
                                                <tr key={`claves_${idx}`} style={{ backgroundColor: idx % 2 === 0 ? '#F5F5F5' : '#FFFFFF' }}>
                                                    <td>{idx + 1}</td>
                                                    <td>
                                                        {clave.clave !== null && clave.clave.split('').map((item, i) => (
                                                            <span style={cveReq} key={`item_${i}`}>
                                                                {item}
                                                            </span>
                                                        ))}
                                                    </td>
                                                    <td>{formatDate(clave.fechavencimiento)}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </ValidatePermission>
                        </div>
                    </div>
                )}
            </Formik>
            {
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
        </>
    );
}

export default InvClavesDemo