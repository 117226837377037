import { Formik } from "formik";
import DSSinAsignarHook from "../../../Hooks/DatamovilSystems/Asignaciones/DSSinAsignarHook";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import DataTable from "../../../Components/datatable/DataTable";
import ProgressBarHalfCircle from "../../../Components/ProgressBarHalfCircle/ProgressBarHalfCircle";
import HalfCircleChart from "../../../Components/HalfCircleChart/HalfCircleChart";
import BarraEstadistica from "./BarraEstadistica";
import Select from 'react-select';
import { TextField } from "../../../Components/TextField";
import RowPages from "../../../Components/RowPages";

const SeguimientoSinAsignar = () => {

    const {
        refFormik,
        selectRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setProceso,
        setTipoSolicitud,
        setTipoUbicacion,
        procesos,
        tipoSolicitudes,
        tipoUbicaciones,
        getListado,
        totalRows,
        totalPages,
        page,
        handlePageClick,
        setRows
    } = DSSinAsignarHook();

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <section className="mt-2 mx-2">
                        <div className='pb-4'>
                            <section className='mx-4 my-4'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Servicios Datamovil</strong>
                                        </div>
                                    </div>
                                </div>
                                <BarraEstadistica />
                                <div className="row mt-4">
                                    <div className="col-12 col-md-2 mr-1 inner-addon right-addon">
                                        <i className="glyphicon fas fa-search"></i>
                                        <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                            setFieldValue("buscar", event.target.value);
                                            setBuscar(event.target.value)
                                        }} />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Select ref={selectRef} options={procesos} className='col-12' onChange={(e) => {
                                            setFieldValue("estatus", e.value);
                                            setProceso(e.value);                                            
                                        }} placeholder="Proceso" />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Select ref={selectRef} options={tipoSolicitudes} className='col-12' onChange={(e) => {
                                            setFieldValue("estatus", e.value);
                                            setTipoSolicitud(e.value);                                            
                                        }} placeholder="Tipo de solicitud" />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Select ref={selectRef} options={tipoUbicaciones} className='col-12' onChange={(e) => {
                                            setFieldValue("estatus", e.value);
                                            setTipoUbicacion(e.value);                                            
                                        }} placeholder="Tipo de ubicación" />
                                    </div>
                                </div>
                                <RowPages
                                    setpageRows={setRows}
                                />
                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            pageCount={totalPages}
                                            handlePageClick={handlePageClick}
                                            currentPage={page - 1}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoSinAsignar;