import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { TextAreaField } from '../../../Components/TextAreaField';
import { message, handleParseDate } from '../../../Utils/functions';

const GenerarExpedienteModal = ({ isOpen, setIsOpen, downloadPDF, loading }) => {
    // const refbtn = useRef();
    let initial = {
        comentario: '',
        numero_telefono: '',
    }
    const [initialValue, setInitialValue] = useState(initial);

    // const validationSchema = Yup.object({
    //     comentario: Yup.string().test(
    //         'min-length-if-not-empty',
    //         'El comentario debe tener al menos 20 caracteres',
    //         function (value) {
    //             // Si el valor no está vacío, debe tener al menos 20 caracteres
    //             return !value || (value && value.length >= 20);
    //         }),
    //     numero_telefono: Yup.string()
    //         .matches(/^\d{10}$/, "El número debe tener exactamente 10 dígitos"),
    // })

    const handleGenerar = async () => {
        downloadPDF()
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
                handleGenerar(values);
            }}
        >
            {({ isValid, values, setFieldValue }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={isOpen} color='#fff'>
                        <div className='d-flex flex-column  my-2 mx-4'>
                            <span className='fs-5 mb-2'><strong>¿Estás seguro de generar el PDF?</strong></span>
                        </div>
                        <section className='my-2 mx-4' style={{ width: '28rem' }}>
                            <Form>
                                <span>Se generará un paquete con todos los documentos solicitados a imprimir</span>
                                {/* <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <label className='text'>Número de Teléfono:</label>
                                    <Field type="text" name="numero_telefono" placeholder="Escribir"
                                        className='programacionJobsCarteraModalSelect' />
                                    <ErrorMessage name="numero_telefono" component="div" className="error" />
                                </div>

                                <div className="col-lg-12 col-12" style={{ display: 'flex', flexFlow: 'column' }}>
                                    <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentario" type="text" holder="Escribe" />
                                </div> */}

                                <section className='my-3 d-flex justify-content-end'>
                                    <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                    <button id="AT_btnGuardar" disabled={!isValid} className='btn mx-2' type="submit">Confirmar</button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                    {
                        loading &&
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    }
                </>
            )
            }
        </Formik>
    )
}

export default GenerarExpedienteModal