import { Formik, Form, Field } from "formik";
import React, { useRef } from "react";
import * as Yup from "yup";
import Modal from "../../../../Components/Modal/Modal";
import { TextField } from "../../../../Components/TextField";
import { formatearMontoMx } from "../../../../Utils/functions";

const CostoGruaModal = ({ isOpen, item, setIsOpen, handleGetAll }) => {
  const refbtn = useRef();
  let initial = {
    concluido: item?.concluido ?? false,
    total: item?.total ?? '',
    iva: item?.iva ?? 0,
    subtotal: item?.subtotal ?? '',
    aplicaIva: (item?.iva) ? true : false
  }

  const validate = Yup.object({
    iva: Yup.number()
      .when("aplicaIva", {
        is: true,
        then: Yup.number().test({
          name: 'is-Iva',
          skipAbsent: true,
          test(value, ctx) {
            // if (value <= 0)
            //   return ctx.createError({ message: 'Ingrese un valor mayor a 0.' })
            if (value >= 100)
              return ctx.createError({ message: 'ingrese un valor entre 0 y 99' })
            return true
          }
        }),
      }),
    subtotal: Yup.number()
      .required("El campo es requerido.")
      .positive("Ingrese un valor mayor a cero."),
    total: Yup.number()
      .required("El campo es requerido.")
  });

  const handleClickConfirmar = async (values) => {
    setIsOpen(false);
    handleGetAll(values);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initial}
      validationSchema={validate}
      onSubmit={(values) => {
        handleClickConfirmar(values);
      }}
    >
      {({ isValid, values, setFieldValue }) => (
        <>
          <Modal isOpen={isOpen} color="#fff">
            <section className='d-flex justify-content-between mx-4'>
              <span className=''><strong>Costo Recolección grua</strong></span>
            </section>
            <div>
              <span className='mx-4' style={{ fontSize: '13px' }}>A continuación captura el costo de la recolección de grua.</span> <br />
            </div>
            <section className='my-4 mx-4' style={{ width: '25rem' }}>
              <Form>
                <div className="mb-3 row">
                  <section className='row col-12'>
                    <span className='col-6'>Valor</span>
                    <span className='col-6'>Total</span>
                  </section>
                  <div className="separator"></div>
                  <section className='row col-12 py-2'>
                    <span className='col-6'>Costo grua</span>
                    <span className='col-6'>
                      <div className="col-12">
                        <TextField icon={"bi bi-currency-dollar"} label="Subtotal" name="subtotal" type="text" holder="Escribe" isMoney={true} onChange={(event) => {
                          if (event.target.value < 0 || isNaN(event.target.value)) {
                            setFieldValue("subtotal", 0);
                            setFieldValue("total", 0);
                          }
                          else {
                            setFieldValue("subtotal", event.target.value)
                            if (event.target.value === "" || isNaN(event.target.value)) {
                              setFieldValue("total", 0);
                            }
                            else {
                              let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                              setFieldValue("total", Number(total.toFixed(2)));
                            }
                          }
                        }} />
                      </div>
                    </span>
                  </section>
                  <section className='row col-12 py-2' style={{ backgroundColor: '#E0E0E0', borderRadius: '3px' }}>
                    <span className='col-6'>Total</span>
                    <span className='col-6'>
                      <TextField disabled name="total" type="text" holder="Escribe" value={formatearMontoMx(values.total)} />
                    </span>
                  </section>
                  {
                    values.aplicaIva &&
                    <section className='row col-12 py-2'>
                      <span className='col-6'>IVA (%)</span>
                      <span className='col-6'>
                        <div className="col-12">
                          <TextField name="iva" type="number" min="0" max="99" decimalScale={0} isMoney={true} holder="Escribe" onChange={(event) => {
                            if (event.target.value === "" || event.target.value < 0 || isNaN(event.target.value)) {
                              setFieldValue("iva", 0);
                              // setFieldValue("total", 0);
                            }
                            else {
                              setFieldValue("iva", event.target.value);
                              if (isNaN(values.subtotal) || values.subtotal === "") {
                                setFieldValue("total", 0);
                              }
                              else {
                                let total = (parseFloat(values.subtotal) * parseInt(event.target.value) / 100) + parseFloat(values.subtotal);
                                setFieldValue("total", Number(total.toFixed(2)));
                              }
                            }
                          }} />
                        </div>
                      </span>
                    </section>
                  }
                </div>
                <div className='mt-2'>
                  <div className="col-12 col-lg-6">
                    <Field className="form-check-input" disabled={initial?.concluido} type="checkbox" name="aplicaIva" onClick={(event) => {
                      if (!values.aplicaIva) {
                        setFieldValue("iva", values.iva);
                        let total = (parseFloat(values.subtotal) * parseInt(values.iva) / 100) + parseFloat(values.subtotal);
                        setFieldValue("total", total);
                      } else {
                        setFieldValue("iva", 0);
                        setFieldValue("total", values.subtotal);
                      }
                      setFieldValue("aplicaIva", event.target.value);
                    }} />
                    <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Aplica. IVA</label>
                  </div>
                </div>
                <section className='my-3 d-flex justify-content-end'>
                  <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                  <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>Confirmar</button>
                </section>
              </Form>
            </section>
          </Modal>
        </>
      )}
    </Formik>
  );
};

export default CostoGruaModal;
