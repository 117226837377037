
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import Llamada from "../../Services/Cartera/Llamadas";
import { FormatFecha, message, formatearMontoMx, handleParseDate, FormatPhoneNumber } from "../../Utils/functions";
import dataDocsServices from "../../Services/DataDocs/dataDocsServices";
import Usuario from "../../Services/Users/Usuarios";
import AuthLogin from "../../Utils/AuthLogin";
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { selectContratoCard, clearDataContrato } from '../../Redux/Slice/Contrato/ContratoCardSlice';

const SeguimientoTelefonicoDetalleHook = (state) => {
    const auth = new AuthLogin();
    const politicaAval = Boolean(state.politica_aval) || false
    let url = auth.UrlDataDocs();
    const contratoInfo = useSelector((state) => selectContratoCard(state));

    const columnsPromesas = [
        { field: 'folio_promesa', headerName: 'Folio Promesa' },
        { field: 'cobrador_nombre', headerName: 'Cobrador' },
        { field: 'periodo', headerName: 'Periodo' },
        { field: 'motivo_promesa_nombre', headerName: 'Motivo Promesa' },
        { field: 'fecha_creacion', headerName: 'Fecha Registro Promesa' },
        { field: 'montoVencidoFormat', headerName: 'Monto Vencido' },
        { field: 'montoPromesaFormat', headerName: 'Monto Promesa' },
        { field: 'montoRecuperadoFormat', headerName: 'Monto Recuperado' },
        { field: 'fecha_compromiso', headerName: 'Fecha Compromiso' },
        { field: 'promesa_status', headerName: 'Estatus' },
        { field: 'dias_de_promesa', headerName: 'Vigencia' },
    ];

    const columnsSeguimientoLog = [
        { field: 'fecha', headerName: 'Fecha' },
        { field: 'cobrador', headerName: 'Cobrador' },
        { field: 'contacto', headerName: 'Contacto' },
        { field: 'nombre', headerName: 'Nombre' },
        { field: 'valor', headerName: 'Valor' },
        { field: 'operacion_tipo_nombre', headerName: 'Operacion' },
        { field: 'operacion_resultado_id_nombre', headerName: 'Resultado' },
        { field: 'aplica_visita_nombre', headerName: 'Aplica visita' },
        { field: 'motivo_visita_nombre', headerName: 'Motivo visita' },
        { field: 'comentariosUI', headerName: 'Comentarios' },
        { field: 'acciones', headerName: 'Ver evidencia' },
    ];

    const columnsInformacionContacto = [
        { field: 'relacion_solicitante', headerName: 'Relación con solicitante' },
        { field: 'nombre_ref', headerName: 'Nombre' },
        { field: 'apellido_paterno_ref', headerName: 'Apellido' },
        { field: 'domicilio_ref', headerName: 'Domicilio Principal' },
        // { field: 'interacciones', headerName: 'Interacciones' },
        // { field: 'operacion_resultado_id_nombre', headerName: 'Última Interacción' }
    ];

    const columnsSubInformacionContacto = [
        { field: 'tipo_contacto', headerName: 'Tipo de Contacto' },
        { field: 'subtipo_contacto', headerName: 'Subtipo' },
        { field: 'dato', headerName: 'Valor' },
        { field: 'fecha', headerName: 'Ultima interaccion' },
        { field: 'interacciones', headerName: 'Interacciones' },
        { field: 'validacion', headerName: 'Exitoso' },
        { field: 'estatus', headerName: 'Estatus' },
        { field: 'comentariosUI', headerName: 'Comentarios' },
    ]

    const navigate = useNavigate();

    const [currentDataPromesas, setCurrentDataPromesas] = useState([])
    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([])
    const [currentDataSeguimientoLog, setCurrentDataSeguimientoLog] = useState([])
    // Datos contrato
    const [userDetail, setUserDetail] = useState([{}])
    const [promesasRotas, setPromesasRotas] = useState(0)
    const [disableInteractions, setDisableInteractions] = useState(politicaAval)
    const [isOpenModalReferencias, setisOpenModalReferencias] = useState(false)
    const [isOpenModalAgregarContacto, setisOpenModalAgregarContacto] = useState(false)
    const [isOpenModalPromesaPagoDelete, setisOpenModalPromesaPagoDelete] = useState(false)
    const [isOpenModalPromesaPagoData, setisOpenModalPromesaPagoData] = useState(false)
    const [promesaPagoData, setPromesaPagoData] = useState(false)
    const [isOpenModalDireccionContactos, setIsOpenModalDireccionContactos] = useState(false)
    const [infoContactoData, setInfoContactoData] = useState()
    const [isOpenModalRegistroLlamada, setIsOpenModalRegistroLlamada] = useState(false)

    //Modal
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [eliminarDatoModal, setEliminarDatoModal] = useState(false)

    //Catalogos
    const [motivosVisita, setMotivosVisita] = useState([{}])
    const [tipoResultado, setTipoResulttado] = useState([{}])
    const [relacionSolicitante, setRelacionSolicitante] = useState([{}])
    const [dataEstado, setDataEstado] = useState([]);
    const [tipoContacto, setTipoContacto] = useState([]);
    const [subtipoContacto, setSubtipoContacto] = useState([]);
    const [motivoPromesa, setMotivoPromesa] = useState([])
    const [motivoIngresoCartera, setMotivoIngresoCartera] = useState([])
    const [tipoOperacionResultado, setTipoOperacionResultado] = useState([])
    const [tipoOperacion, setTipoOperacion] = useState([])
    const [promesasStatus, setPromesasStatus] = useState([])
    const [motivoEliminacion, setMotivoEliminacion] = useState([])

    //Tabla anidada
    const [directorioValue, setDirectorioValue] = useState("Cliente")

    const [infoContactos, setInfoContactos] = useState([{}])
    const [infoContactosFiltered, setInfoContactosFiltered] = useState([{}])
    const [infoAvaliable, setInfoAvaliable] = useState([{
        cont_apoderado: 0,
        cont_referencia: 0,
        cont_coacreditado: 0,
        cont_aval: 0
    }])

    //sUBIR DOCUMENTOS
    const [isOpenDocs, setIsOpenDocs] = useState(false)
    const [fileName, setFileName] = useState("")
    const [SelectedDocs, setSelectedDocs] = useState([]);


    const urlRegreso = state?.urlRegreso;

    const handleReturnPage = () => {
        navigate(urlRegreso || `/cobranza/seguimiento-telefonico/`)
    }

    const handleModalRef = (value) => {
        setisOpenModalReferencias(true)
    }

    const handleModalAdd = (value) => {
        setInfoContactoData(value)
        setisOpenModalAgregarContacto(true)
    }

    const handlePromesasPago = (item) => {
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoData(true)
    }

    const handlePromesasPagoDelete = (item) => {
        const today = new Date()
        const fechaPromesa = handleParseDate(item.fecha_compromiso);

        if (fechaPromesa < today) return null
        if (item.promesa_status_nombre === "Rota" || item.promesa_status_nombre === "Cumplida") return null
        if (item.monto_recuperado !== 0) return null
        setPromesaPagoData(item)
        setisOpenModalPromesaPagoDelete(true)
    }

    const handleDireccionesModal = (item) => {
        setInfoContactoData(item)
        setIsOpenModalDireccionContactos(true)
    }

    const handleRegistroLlamada = (item) => {
        if (promesasRotas >= 3) return null
        if (item.status === false) return null
        setInfoContactoData(item)
        setIsOpenModalRegistroLlamada(true)
    }

    const handleChange = async (value, item) => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Mes ajustado
        const year = today.getFullYear();
        let nuevafecha = `${day}-${month}-${year}`;

        if (value === false) {
            setInfoContactoData(item)
            setEliminarDatoModal(true)
        } else {
            setLoading(true)
            try {
                const data = {
                    "sol_id": state.sol_id,
                    "contrato": state.contrato_id,
                    "vin": state.vin,
                    "idcontacto_acendes": item.idcontacto_acendes,
                    "idpersona_acendes": item?.value?.idreferencia,
                    "tipo_contacto": item.tipo_contacto,
                    "subtipo_contacto": item.subtipo_contacto,
                    "tipo_persona": item.value?.tipo,
                    "curp": item.value?.curp_ref,
                    "motivo_eliminacion": "",
                    "comentario": "",
                    "fecha_operacion": nuevafecha,
                    "activo": true
                }

                await CobranzaService.eliminaDatoContacto(data)
                await HandleGetInformacionContactos()
                setLoading(false)
            } catch (error) {
                setLoading(false)
                if (error.response !== undefined)
                    toast.error(message("Error.!", error.response.data.errors?.error));
                else
                    toast.error(message("Error.!", error.message));
            }
        }

    }

    useEffect(() => {
        GetAllData()
    }, [])

    const GetAllData = async () => {
        setLoading(true)

        //Tabla anidada
        await HandleGetInformacionContactos()
        //Catalogos
        await HandleGetEstadoMx();
        await HandleGetSubtiposContacto()
        await HandleGetTipoContacto()
        await HandleGetAllRelacionSolicitante()
        await HandleMotivoPromesa()
        await HandleMotivoIngresoCartera()
        await HandleTipoOperaciones()
        await HandleTipoOperacionesResultados()
        await HandlePromesasStatus()
        await HandleMotivoEliminacion()

        //Servicios
        await handleGetSeguimientoContrato()
        await handleGetPromesasdePago()
        await handleContactosSeguimientoLog()

        setLoading(false)
    }


    const handleSetStateTab = (value) => {
        setDirectorioValue(value)
    }

    useEffect(() => {
        const result = infoContactos?.filter(item =>
            item?.tipo?.toLowerCase().includes(directorioValue.toLowerCase())
        );
        setInfoContactosFiltered(result)
    }, [directorioValue, infoContactos])

    const HandleGetAllRelacionSolicitante = async (id) => {
        await CobranzaService.getAllRelacionSolicitante()
            .then(res => {
                let relaciones = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        relaciones.push({
                            value: item.id,
                            label: item.nombre
                        })
                    }
                });
                setRelacionSolicitante(relaciones)
            })
    }

    const HandleGetEstadoMx = async () => {
        await Llamada.GetEstadoMx().then(res => {
            let Estados = []
            res.data.data?.map(item => {
                Estados.push({
                    value: item.edoMexicoId,
                    label: item.estado
                });
            });
            setDataEstado(Estados);
        })
    }

    const HandleGetTipoContacto = async () => {
        await CobranzaService.getAllTipoContacto()
            .then(res => {
                let tipos = []
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        tipos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setTipoContacto(tipos);
            })
    }

    const HandleGetSubtiposContacto = async () => {
        await CobranzaService.getAllSubTipoContacto()
            .then(res => {
                let subtipo = []
                res.data?.map(item => {
                    if (item.activo) {
                        subtipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setSubtipoContacto(subtipo);
            })
    }

    const HandleMotivoPromesa = async () => {
        await CobranzaService.getAllMotivosPromesa()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoPromesa(motivo);
            })
    }

    const HandleTipoOperaciones = async () => {

    }

    const HandleTipoOperacionesResultados = async () => {
        await CobranzaService.getAllOperacionResultado()
            .then(res => {
                let operacion = []
                res.data?.map(item => {
                    operacion.push({
                        value: item.resultado_id,
                        label: item.resultado
                    });
                });
                setTipoOperacionResultado(operacion);
            })
    }

    const HandleMotivoIngresoCartera = async () => {
        await CobranzaService.getAllMotivoIngresoCartera()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoIngresoCartera(motivo);
            })
    }


    const HandlePromesasStatus = async () => {
        await CobranzaService.getAllEstatusPromesa()
            .then(res => {
                let promesa = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        promesa.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setPromesasStatus(promesa);
            })
    }

    const HandleMotivoEliminacion = async () => {
        await CobranzaService.getAllMotivoEliminacion()
            .then(res => {
                let motivo = []
                res.data?.responseData?.map(item => {
                    if (item.activo) {
                        motivo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                setMotivoEliminacion(motivo);
            })
    }

    const handleGetSeguimientoContrato = async () => {

        setCurrentDataSeguimiento(state)
        await Usuario.getUsuarioById(state.userid)
            .then(res => {
                const user = res.data.data
                user.names = res.data.data.nombreUsuario
                setUserDetail(user)
        }).catch(e => {
            setLoading(false);
            if (e.response !== undefined)
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
            else
                setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
        })
    }

    const handleContactosSeguimientoLog = async () => {
        // setLoading(true);


        let AllMotivos = await CobranzaService.getAllMotivoVisita()
            .then(res => {
                let newValueMotivos = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueMotivos.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueMotivos
            })



        let AllTipoResultados = await CobranzaService.getAllTipoResultado()
            .then(res => {
                let newValueTipo = [{
                    value: "",
                    label: null
                }]
                res.data.responseData?.map(item => {
                    if (item.activo) {
                        newValueTipo.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return newValueTipo
            })

        let AllTipoOperaciones = await CobranzaService.getAllTipoOperacion()
            .then(res => {
                let operacion = []
                res.data?.map(item => {
                    if (item.activo) {
                        operacion.push({
                            value: item.id,
                            label: item.nombre
                        });
                    }
                });
                return operacion
            })

        setTipoOperacion(AllTipoOperaciones);

        const data = {
            "value": state.contrato_id
        }
        await CobranzaService.getAllSeguimientoContactosLogs(data)
            .then(res => {
                const update = res.data.responseData?.map(item => {
                    const fechaFormat = FormatFecha(item.fecha)
                    const aplicaVisitaFormat = item.aplica_visita ? "Si" : "No"

                    const motivoVisitaFormat = AllMotivos?.find(motivo => motivo?.value?.toLowerCase() === item?.motivo_visita_id?.toLowerCase())
                    const resultadoFormat = AllTipoResultados?.find(resultado => resultado?.value?.toLowerCase() === item?.operacion_resultado_id?.toLowerCase() || null)
                    const operacionFormat = AllTipoOperaciones?.find(operacion => operacion?.value?.toLowerCase() === item?.operacion_id?.toLowerCase() || null)
                    let formatValue = item.valor
                    if (item.contacto === "Celular" || item.contacto === "Télefono fijo") {
                        formatValue = FormatPhoneNumber(item.valor)
                    }

                    return {
                        ...item,
                        fecha: fechaFormat,
                        aplica_visita_nombre: aplicaVisitaFormat,
                        motivo_visita_nombre: motivoVisitaFormat?.label,
                        operacion_resultado_id_nombre: resultadoFormat?.label,
                        operacion_tipo_nombre: operacionFormat?.label,
                        comentariosUI: item.comentario_resultado,
                        valor: formatValue
                    }
                })

                setMotivosVisita(AllMotivos)
                setTipoResulttado(AllTipoResultados)
                setCurrentDataSeguimientoLog(update)
            })
    }

    const handleGetPromesasdePago = async () => {
        const dataQuery = {
            contrato_id: state.contrato_id,
            periodo: state.periodo
        }
        await CobranzaService.getAllPromesa_de_PagoxPeriodo(dataQuery)
            .then(res => {
                let promesasRotas = 0
                const today = new Date()
                const ayer  = new Date()
                ayer.setDate(ayer.getDate() - 1)
                const update = res.data.map(item => {

                    let colorFondo
                    let colorTexto

                    switch (item.promesa_status_nombre) {
                        case "Cumplida":
                            colorFondo = "#D8F2CD"
                            colorTexto = "#47A066"
                            break;
                        case "Rota":
                            colorFondo = "#F5CECE"
                            colorTexto = "#CE2222"
                            break;
                        case "Vigente":
                            const fechaPromesa = new Date(item.fecha_compromiso);

                            if (fechaPromesa < today) {
                                item.promesa_status_nombre = "Rota";
                                colorFondo = "#F5CECE";
                                colorTexto = "#CE2222";
                            } else {
                                item.promesa_status_nombre = "Vigente";
                                colorFondo = "#FAE7BC";
                                colorTexto = "#B46B16";
                            }
                            break;
                        default:
                            break;
                    }

                    const fechaPromesa = FormatFecha(item.fecha_promesa)
                    const montoVencidoFormat = formatearMontoMx(item.monto_vencido)
                    const montoPromesaFormat = formatearMontoMx(item.monto_promesa)
                    const montoRecuperadoFormat = formatearMontoMx(item.monto_recuperado)
                    const fechaCreacionFormat = FormatFecha(item.fecha_creacion)
                    const fechaCompromisoFormat = FormatFecha(item.fecha_compromiso)
                    const vigenciaFormat = FormatFecha(item.vigencia)

                    //Dias Vencidos
                    const nuevaFecha = new Date(item.fecha_promesa)
                    const diference = today - nuevaFecha
                    const daysDifference = Math.floor(diference / (1000 * 60 * 60 * 24));
                    let dias_vencidos
                    if (daysDifference > 0) {
                        dias_vencidos = daysDifference
                    } else {
                        dias_vencidos = "-"
                    }
                    
                    //dias de Vigencia
                    let vigenciaDias = 0
                    if (item.vigencia && item.promesa_status_nombre === "Vigente") {
                        const diferenciaDias = new Date(item.vigencia) - (ayer)
                        vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24)
                    }


                    let ultimaActualizacion
                    if (item.fecha_ultima_actualizacion) {
                        ultimaActualizacion = FormatFecha(item.fecha_ultima_actualizacion)
                    } else {
                        ultimaActualizacion = "-"
                    }


                    return {
                        ...item,
                        promesa_status: item.promesa_status_nombre,
                        colorFondo: colorFondo,
                        colorTexto: colorTexto,
                        fecha_promesa: fechaPromesa,
                        montoVencidoFormat: montoVencidoFormat,
                        montoPromesaFormat: montoPromesaFormat,
                        montoRecuperadoFormat: montoRecuperadoFormat,
                        ultimaActualizacion: ultimaActualizacion,
                        dias_vencidos: dias_vencidos,
                        fecha_creacion: fechaCreacionFormat,
                        vigencia: vigenciaFormat,
                        fecha_compromiso: fechaCompromisoFormat,
                        dias_de_promesa: vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0,
                    }
                })

                const promesasUpdate = update.filter(promesa => promesa.contrato_id === state.contrato_id) || [{}]
                if (promesasUpdate) {
                    promesasUpdate?.forEach((promesa) => {
                        if (promesa.promesa_status_nombre === 'Rota') {
                            promesasRotas++
                        } else if (
                            promesa.promesa_status_nombre === "Vigente" &&
                            promesa.fecha_promesa < today
                        ) {
                            promesasRotas++

                        }
                    })
                }

                if (promesasRotas >= 3) {
                    actualizaVisitabyContrato(state.contrato_id)
                }

                setPromesasRotas(promesasRotas)
                setCurrentDataPromesas(promesasUpdate)

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }


    const AddInteracciones = async (item) => {
        let data = {
            field: "id_referencia_ascendes",
            value: item.idreferencia
        }
        try {
            const response = await CobranzaService.getRowsIteractions(data)
            return response.data.responseData; // Devuelve los datos de la respuesta
        } catch (e) {
            setLoading(false);
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };

    const actualizaVisitabyContrato = async (contratoId) => {
        let data = {
            contrato: contratoId
        }
        try {
            const response = await CobranzaService.actualizaVisitabyContrato(data)
            return response.data; // Devuelve los datos de la respuesta
        } catch (e) {
            setLoading(false);
            if (e.response !== undefined)
                toast.error(message("Error.!", e.response.data.errors?.error));
            else
                toast.error(message("Error.!", e.message));
        }
    };

    const GetInformacionContactos = async () => {
        const data = {
            sol_id: state.sol_id,
            contrato: state.contrato_id,
            vin: state.vin
        }

        let result = await CobranzaService.getInformacionContactos(data)
            .then(res => {
                setInfoAvaliable({
                    cont_apoderado: res.data.responseData?.cont_apoderado,
                    cont_referencia: res.data.responseData?.cont_referencia,
                    cont_coacreditado: res.data.responseData?.cont_coacreditado,
                    cont_aval: res.data.responseData?.cont_aval
                })

                let datosFormateados = res.data.responseData.datos_referencia.map((item) => {
                    if (item) {
                        let domicilio = `${item.calle_ref} ${item.no_ext_ref} ${item.colonia_ref}`
                        return {
                            ...item,
                            domicilio_ref: domicilio,
                            filter: false
                        }
                    }
                })
                return datosFormateados
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })

        return result
    }

    const HandleGetInformacionContactos = async () => {
        const data = await GetInformacionContactos()
        const promesasRotasField =
        {
            FIELD: "ID",
            VALUE: "LLAMADAS_CONSECUTIVAS_NIVEL_1"
        }

        const responseLlamadasConsecutivas = await CobranzaService.getAllParametrosByField(promesasRotasField)
        const maxIterations = Number(responseLlamadasConsecutivas.data.responseData[0].valorpublico)

        const updateResults = await Promise.all(
            data.map(async (item) => {
                const updateItem = await AddInteracciones(item)
                if ((updateItem >= maxIterations) && (item.tipo === "Cliente")) {
                    if (disableInteractions) {
                        setDirectorioValue("Aval")
                    }
                }
                item.interacciones = updateItem

                return item
            })
        )
        setInfoContactos(updateResults)
    }


    const handleGetTokenDatadocs = async () => {
        await dataDocsServices.UpdateTokenDataDc()
            .then(res => {
                auth.handleAuthSesionDataDocs(res.data.data);
            }).catch(e => { })
    }

    const handleViewdocs = async (item) => {
        setLoading(true);
        setFileName(item.nombreDocumento);
        await handleGetTokenDatadocs();
        await axios.post(`${url}Documento/GetFileById/${item.idFolder}/${item.documentoId}/false`, {}, {
            headers: {
                'Authorization': 'Bearer ' + auth.tokenDataDocs(),
            },
            responseType: "blob"
        })
            .then(res => {

                var file = new File([res.data], res.data.name, { type: res.data.type });
                setSelectedDocs([file])
                setLoading(false);
                setIsOpenDocs(true)
            }).catch(e => {

                setLoading(false);
                if (e.response !== undefined)
                    toast.error(message("Error.!", e.response.data.errors?.error));
                else
                    toast.error(message("Error.!", e.message));
            })
    }

    const handleUploadFile = async (value, subido) => {
        if (subido) {
            await handleViewdocs(value)
        } else {
            navigate(`/cobranza/seguimiento-telefonico/datadocs/fCobranza/eTelefono/${value.contrato}/${value.id}`, {
                state: { ...value, registroId: value.id, vin: contratoInfo.vin, nombreMarca: contratoInfo.nombreMarca, nombreModelo: contratoInfo.nombreModelo, nombreVersion: contratoInfo.nombreVersion, nombreYear: contratoInfo.nombreYear }
            });
        }
    }

    return {
        handleReturnPage,
        columnsPromesas,
        columnsSeguimientoLog,
        columnsInformacionContacto,
        columnsSubInformacionContacto,

        currentDataPromesas,
        currentDataSeguimiento,
        currentDataSeguimientoLog,
        userDetail,
        promesasRotas,
        disableInteractions,

        loading,
        setLoading,
        eliminarDatoModal,
        setEliminarDatoModal,

        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,
        setDisableInteractions,

        handleModalRef,
        handleModalAdd,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleDireccionesModal,
        handleRegistroLlamada,
        handleChange,

        //Catalogos
        motivosVisita,
        tipoResultado,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,
        infoAvaliable,
        infoContactoData,
        motivoPromesa,
        motivoIngresoCartera,
        tipoOperacion,
        // tipoOperacionResultado,
        promesasStatus,
        motivoEliminacion,

        directorioValue,
        setDirectorioValue,
        handleSetStateTab,
        setInfoContactos,

        //Actualizar Tabla
        handleGetPromesasdePago,
        HandleGetInformacionContactos,
        handleContactosSeguimientoLog,

        //Subido
        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,
        setCurrentDataSeguimiento
    }
}
export default SeguimientoTelefonicoDetalleHook