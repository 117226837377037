import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import toast from "react-hot-toast";
import { FormatFecha, getHourFormat, message } from "../../../../Utils/functions";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";

const BoletinarAgregar = () => {
   const navigate = useNavigate();
   const [estatusConcluido, setEstatusConcluido] = useState(false);
   const [fechaInicio, setFechaInicio] = useState("")
   const [fechaFin, setFechaFin] = useState("")
   const [loading, setLoading] = useState(false);
   const [dataMotivos, setDataMotivos] = useState([])
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, message: '' });

   let initial = {
      motivo: "",
      motivo_id: "",
      comentarios: "",
      reg_concluido: false
   }

   const [initialValue, setInitialValue] = useState(initial); 
   const validate = Yup.object({
      motivo: Yup.string()
         .required("El campo es requerido."),
      comentarios: Yup.string()
         .required("El campo es requerido.")
         .min(20, "El comentario debe contener al menos 20 caracteres")
   });

   useEffect(()=>{
      let diaActual = new Date()
      let hour = getHourFormat(`${String(diaActual.getHours()).padStart(2, '0')}:${String(diaActual.getMinutes()).padStart(2, '0')}`)
      setFechaInicio(FormatFecha(diaActual) + ' ' + hour)
      setFechaFin(FormatFecha(diaActual) + ' ' + hour)
      handleGetAllMotivos()
   },[])

   const handleGetAllMotivos = async () => {
      setLoading(true);
      await CobranzaService.GetAllMotivoBoletinado()
         .then(res => {
            let items = [];
            res?.data?.responseData?.filter(x=>x.status == true).forEach(item => {
               items.push({ value: item?.id, label: item?.motivo
               })
            });
            setDataMotivos(items);
         })
         .catch(err => {
            console.error(err);
         });
      setLoading(false)
   };

   const handleGuardar = async (values) =>{
      setLoading(true);
      const id = uuidv4();
      const data = {
         "siniestroClienteBoletinarId": id,
         "siniestroId": datosConsulta?.ruta_item_id,
         "siniestroClienteBoletinarStatusId": 0,
         "siniestroClienteBoletinarMotivoId": values.motivo_id.toString(),
         "comentariosEjecutivo": values.comentarios,
         "comentariosSupervisor": "",
         "clienteBoletinado": true,
         "concluido": true,
         "autorizado": true,
         "rechazado": false,
         "contrato": datosConsulta?.contrato,
         "siniestroClienteBoletinarMotivo": values.motivo,
         "sol_id": datosConsulta?.persona.value,
         "vin": datosConsulta?.vin,
         "proceso": "",
         "nombreCliente": datosConsulta?.nombe_cliente,
         "procesoId":"8",
         "tipo_Persona": datosConsulta?.persona?.item?.tipo
      }

      console.log(data)

      await CobranzaService.CreateBoletinarCliente(data)
      .then(res => {
         // if(res.data.data.mensaje === "Siniestro cuenta con un boletinado"){
         if(res.data.data.ban != 1){
            setLoading(false)
            setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: res.data.data.mensaje })
         }
         else{
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            console.log(res)
            setLoading(false)
            navigate(-1)
         }
      }).catch(e => {
         setLoading(false)
         if (e.response !== undefined)
            toast.error(message("¡Error!", e.response.data.message));
         else
            toast.error(message("¡Error!", e.message));
      })
   }

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <Modal isOpen={modalMessage.isOpen} color='#fff'>
            <div>
               <section className='d-flex justify-content-end'>
                  <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }} 
                  onClick={() => { 
                     setModalMessage({ ...modalMessage, isOpen: false }) 
                     navigate(-1)
                  }}><i className="ri-close-fill"></i></button>
               </section>
               <div className='d-flex flex-column justify-content-center align-items-center'>
                  <i className="ri-error-warning-fill" style={{ fontSize: '70px', color: 'yellow' }}></i>
                  <span id="AT_TxtModalMenssage" className='fs-6'><strong>{modalMessage.message}</strong></span>
               </div>
            </div>
         </Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-11 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                 <h2 className="fw-bold ">Boletinar</h2>
                              </div>
                           </div>
                           <div className="row gap-2">
                              <div className="col col-12">
                                 <div className="row mt-4">
                                    <div className="col col-sm-4 col-xl-3">
                                       <div className="row justify-content-start">
                                          <div className="col col-12">
                                             <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.item.tipo}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col col-sm-5 col-xl-4">
                                       <div className="row justify-content-start">
                                          <div className="col col-12">
                                             <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-10 mx-3 wrapper-vehiculo " style={{ background: "#FFF", height: "auto" }}>
                  <Formik
                     enableReinitialize={true}
                     validationSchema={validate}
                     initialValues={initialValue}
                     onSubmit={(values) => {
                        handleGuardar(values);
                  }}>
                  {({ isValid, values, setFieldValue, errors }) => (
                     <>
                        <Form>
                           <div className="px-4">
                              <div className="row mt-2 px-4">
                                 <div className="col-sm-5 col-xl-2 mt-3">
                                    <p >Detalle de Boletinado</p>
                                 </div>
                                 <div className="col-sm-7 col-xl-6 mt-3">
                                    <div className="row justify-content-end">
                                       <div className="col-auto">
                                          <button className='btn col-12' type='submit' disabled={!(isValid)} >Solicitar</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-sm-3 col-xl-3 mt-3 px-4">
                                 <SelectField  label="Motivo boletinado" name="motivo" items={dataMotivos} onChange={(e) => {
                                    setFieldValue("motivo", e.value === "" ? "" : e.label);
                                    setFieldValue("motivo_id", e.value === "" ? "" : e.value);
                                 }} />
                              </div>
                              <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                 <div className="col-6 mb-3">
                                    <TextAreaField label="Comentarios del boletinado:" className="col-sm-12 col-xl-8 mt-2 form-control" rows="4" name="comentarios" type="text" holder="Escribe" />
                                 </div>
                              </div>
                           </div>
                        </Form>
                     </>)}
                  </Formik>
               </div>
            </div>
         </div>
      </>
   );
}

export default BoletinarAgregar;