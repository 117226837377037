import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../Components/Loadig';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';


const ModalCrearManual = ({ isOpen, setIsOpen }) => {
    const refbtn = useRef();
    const { id } = useParams();
    const navigate = useNavigate();

    let initial = {
        tipoServicioId: id,
        ban: 0,
        message: "",
        servicioId: null,
        plantilla: null,
        paso: null,
        vin: "",
        tipoServicio: "",
        contrato: "",
        marca: "",
        modelo: "",
        anio: "",
        generalId: ""

    };
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState([]);
    const [bandera, setBandera] = useState(0);

    const tipoServicios = [
        { value: "1", label: 'Revisión Datamovil' },
        { value: "2", label: 'Programación en demo' },
        { value: "3", label: 'Desinstalación Datamovil' },
        { value: "4", label: 'Reinstalación Datamovil' },
    ];

    useEffect(() => {

    }, [])

    const validate = Yup.object({

    });

    const buscarVIN = (values) => {
        //bandera
        //0 sin busqueda 1 buscado

        if (bandera === 0) {
            setLoading(true)
            DSystemsServicios.getConsultaVINServicio(values.vin, initialValue.tipoServicioId)
                .then(res => {
                    let resData = res.data
                    //resData.vin = resData
                    resData.tipoServicioId = id
                    setInitialValue(resData)
                    setBandera(1)

                    //ban = 0 no hay nada
                    //ban = 1 validar servicio id
                })
                .catch(res => {

                })
                .finally(() => {
                    setLoading(false)
                })
        }

        if (bandera === 1) {


            // if (initialValue.ban === 0 && initialValue.servicioId === null) {
            //     navigate(`/datamovil/prealta/${initialValue.tipoServicioId}/${values.vin}`);
            // }

            if (initialValue.ban === 1 && initialValue.tipoServicio !== null) {
                navigate(`/datamovil/${initialValue.plantilla}/${initialValue.tipoServicioId}/${initialValue.servicioId}/${initialValue.paso}`, {
                    state: {
                        tipoProceso: 6,
                        tipoServicioId: parseInt(initialValue.tipoServicioId),
                        generalId: initialValue.generalId,
                        vin: initialValue.vin,
                        paso: initialValue.paso,

                    }
                });
            }

            if (initialValue.ban === 1 && initialValue.tipoServicio === null) {
                //Crear a servicio y luego reireccionar
                setLoading(true)

                let jsonDate = {
                    "generalId": initialValue.generalId,
                    "tipoServicioId": initialValue.tipoServicioId,
                    "procesoId": 19,
                    "tipoSolicitudId": "F11EFA9A-B083-4983-A9C7-7F135F857204",
                    "subprocesoId": initialValue.tipoServicioId
                }


                console.log(jsonDate)
                DSystemsServicios.postServicio(jsonDate)
                    .then(res => {
                        let _plantilla = 6;
                        navigate(`/datamovil/${_plantilla}/${parseInt(initialValue.tipoServicioId)}/${res.data.servicioId}/1`, {
                            state: {
                                tipoProceso: _plantilla,
                                tipoServicioId: parseInt(initialValue.tipoServicioId),
                                generalId: initialValue.generalId,
                                vin: initialValue.vin,
                                paso: 1,

                            }
                        });

                    })
                    .catch(error => {

                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }

        }
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    buscarVIN(values);
                }}
            >
                {({ isValid, setFieldValue, values }) => (
                    <Modal isOpen={isOpen} color='#fff' style={{ width: '60px' }}>
                        <section className='d-flex justify-content-between mx-4'>
                            <section className='py-2 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className=''><strong>Buscador de VIN / CONTRATO</strong></span>
                                </div>
                                <span className='mt-2 fs-6'>A continuación ingresa el VIN / CONTRATO de la unidad que deseas dar seguimiento a su desinstalación:</span>
                            </section>
                        </section>
                        <section className='my-4 mx-4'>
                            <Form>

                                {
                                    bandera === 0 &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <SelectField id="tipoServicioId"
                                                    label="Tipo de servicio"
                                                    disabled={true}
                                                    name="tipoServicioId"
                                                    items={tipoServicios}
                                                    onChange={(event) => {
                                                        setFieldValue("tipoServicioId", event.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className='mb-3 row'>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <TextField
                                                    label="VIN"
                                                    name="vin"
                                                    type="text"
                                                    holder="Escribe VIN / Contrato"
                                                // onChange={(event) => {
                                                //     setFieldValue("subtotal", event.target.value)
                                                // }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    (bandera === 1 && initialValue.ban === 1) &&
                                    <>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <b>
                                                    VIN ingresado : {initialValue.vin}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                {initialValue.tipoServicio === null
                                                    ? "¿Deseas iniciar una solicitud de servicio ?"
                                                    : `Continuar con solicitud de servicio: ${initialValue.tipoServicio}`}
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <b>
                                                    Contrato: {initialValue.contrato} | Marca: {initialValue.marca}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <b>
                                                    Modelo: {initialValue.modelo} | Año: {initialValue.anio}
                                                </b>
                                            </div>
                                        </div>

                                    </>
                                }

                                {
                                    (bandera === 1 && initialValue.ban === 0) &&
                                    <>
                                        <div className="mb-5 row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                No se encontro el VIN / CONTRATO ingresado
                                            </div>
                                        </div>
                                    </>
                                }

                                <section className='my-3'>
                                    <button
                                        id="AT_btnCancelar"
                                        className='btn btn-outline'
                                        type='reset'
                                        onClick={() => { setIsOpen(false) }}
                                    >
                                        Cancelar
                                    </button>
                                    {
                                        (initialValue.ban === 1 || bandera === 0) &&
                                        <button
                                            className='btn mx-2'
                                            type="submit"
                                            disabled={!(isValid)}>
                                            Continuar
                                        </button>
                                    }

                                </section>
                            </Form>
                        </section>
                    </Modal>
                )
                }
            </Formik >
        </>
    )
}

export default ModalCrearManual