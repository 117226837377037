import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useRevisionDatamovil from '../../../../Hooks/DatamovilSystems/RevisionPasos/useRevisionDatamovil';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { getDateFormat } from '../../../../Utils/functions';

const CardRevisionDatamovil = ({ handleConcluido, setRequiereCosto }) => {
    const { refFormik } = useRef();
    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object().shape({
        alteracionDatamovil: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        datamovilAlterado: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        equipoCompleto: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        numeroServicio: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        formatoHojaId: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        funciona: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        requiereReparacion: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        aplicaCobro: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        observaciones: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        ejecutivoName: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        gps: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        teclado: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        comentariosAlteracion: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            }),
        bloqueador: Yup.string()
            .nullable()
            .when('concluido', {
                is: true,
                then: Yup.string().nullable().required("Campo requerido")
            })
    });



    const {
        initialValue,
        loading,
        setLoading,
        submitForm,
        opcionesBool,
        formatoHoja
    } = useRevisionDatamovil({ handleConcluido, setRequiereCosto })

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseCardRevision"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseCardRevision"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span> <strong>Revisión Datamovil</strong></h6>
                            </div>
                            <div className="col-11">
                                <small>{initialValue.concluido ? " Concluido" : " En Proceso"}</small>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseCardRevision">
                            <div className="divider"></div>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitForm(values)}
                            >
                                {({ setFieldValue }) => (
                                    <Form id="revisionDatamovilForm">

                                        <div className="row mt-2 mb-2">
                                            <div className='col-4'>
                                                <h6><strong>Detalle de ubicacion</strong></h6>
                                            </div>
                                            <div className="col-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.concluido}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="¿Alteración de instalación de Datamovil?"
                                                    name="alteracionDatamovil"
                                                    disabled={initialValue.concluido}
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("alteracionDatamovil", event.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <TextAreaField
                                                    label="Comentarios de alteración"
                                                    disabled={initialValue?.concluido}
                                                    className="form-control col-12"
                                                    rows="3"
                                                    name="comentariosAlteracion"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => {
                                                        setFieldValue("comentariosAlteracion", event.target.value);
                                                    }} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="¿Datamovil alterado?"
                                                    name="datamovilAlterado"
                                                    disabled={initialValue.concluido}
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("datamovilAlterado", event.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="¿Equipo completo?"
                                                    name="equipoCompleto"
                                                    disabled={initialValue.concluido}
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("equipoCompleto", event.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Número de servicio"
                                                    disabled={initialValue?.concluido}
                                                    name="numeroServicio"
                                                    id="numeroServicio"
                                                    type="text"
                                                    holder=''
                                                    onChange={(event) => setFieldValue("numeroServicio", event.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="Formato de hoja"
                                                    name="formatoHojaId"
                                                    disabled={initialValue.concluido}
                                                    items={formatoHoja}
                                                    onChange={(event) => setFieldValue("formatoHojaId", event.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="¿El equipo requiere reparaciones?"
                                                    name="requiereReparacion"
                                                    disabled={initialValue.concluido}
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("requiereReparacion", event.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="¿Funciona?"
                                                    name="funciona"
                                                    disabled={initialValue.concluido}
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("funciona", event.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <h6><strong>Componentes</strong></h6>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Teclado"
                                                    name="teclado"
                                                    type="text"
                                                    disabled={initialValue.concluido}
                                                    holder=""
                                                    onChange={(event) => setFieldValue("teclado", event.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="Bloqueador"
                                                    disabled={initialValue.concluido}
                                                    name="bloqueador"
                                                    type="text"
                                                    holder=''
                                                    onChange={(event) => setFieldValue("bloqueador", event.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <TextField
                                                    label="GPS"
                                                    name="gps"
                                                    type="text"
                                                    holder=''
                                                    disabled={initialValue.concluido}
                                                    onChange={(event) => setFieldValue("gps", event.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3">
                                                <SelectField
                                                    label="¿Aplica un cobro?"
                                                    name="aplicaCobro"
                                                    disabled={initialValue.concluido}
                                                    items={opcionesBool}
                                                    onChange={(event) => setFieldValue("aplicaCobro", event.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <TextAreaField
                                                    label="Observaciones"
                                                    disabled={initialValue?.concluido}
                                                    className="form-control col-12"
                                                    rows="3"
                                                    name="observaciones"
                                                    type="text"
                                                    holder="Escribe"
                                                    onChange={(event) => {
                                                        setFieldValue("observaciones", event.target.value);
                                                    }} />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <TextField
                                                    label="Ejecutivo"
                                                    name="ejecutivoName"
                                                    type="text"
                                                    holder=''
                                                    onChange={(event) => setFieldValue("ejecutivoName", event.target.value)}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-3 d-flex align-items-center">
                                                <Field type="checkbox"
                                                    disabled={initialValue.concluido}
                                                    name="concluido"
                                                    className="form-check-input me-2"
                                                    onClick={(event) => {
                                                        setFieldValue("concluido", event.target.value);
                                                    }}
                                                />
                                                <label className="form-check-label">Servicio concluido</label>
                                            </div>
                                        </div>

                                        <div className="text-end">
                                            <small className="text-muted">
                                                {initialValue?.fechaAlta
                                                    ? `Inicio de proceso ${initialValue.fechaAlta}`
                                                    : ""}
                                                {" | "}
                                                {initialValue?.fechaConcluido
                                                    ? `Fin de proceso ${initialValue.fechaConcluido}`
                                                    : ""}
                                            </small>

                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardRevisionDatamovil;

