import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { SelectField } from "../../../Components/SelectField";
import { TextAreaField } from "../../../Components/TextAreaField";
import * as Yup from 'yup';
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSystemsServicios from "../../../Services/DatamovilSystems/ServiciosDatamovil";
import { message } from "../../../Utils/functions";

const AutorizacionCitaModal = ({ modalMessage, setModalMessage, getListado, toast }) => {
    const refFormik = useRef();
    console.log("modal meesage", modalMessage)
    const initial = {
        citaId: modalMessage?.citaId ?? "",
        autorizado: "",
        motivoRechazoId: "",
        motivoRechazo: "",
        comentarios: "",
        contrato: modalMessage?.contrato ?? "",
        tipoServicio: modalMessage?.tipoServicio ?? "",
        nombreMarca: modalMessage?.nombreMarca ?? "",
        nombreModelo: modalMessage?.nombreModelo ?? "",
        nombreYear: modalMessage?.nombreYear ?? "",
        vin: modalMessage?.vin ?? "",
    };
    const [initialValue, setInitialValue] = useState(initial);
    const resultados = [
        { value: 2, label: 'Aprobar' },
        { value: 3, label: 'Rechazar' }
    ];
    const validate = Yup.object({
        autorizado: Yup.number()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+(?: [a-zA-Z0-9-ZÀ-ÿ\.\,\u00f1\u00d1]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ\.\,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .required("El campo es requerido."),
        motivoRechazoId: Yup.string()
            .when("autorizado", {
                is: 3,
                then: Yup.string()
                    .required("El campo es requerido.")
            }),
    });

    const [motivos, setMotivos] = useState([]);

    // useEffect(() => {
    //     getMotivosRechazo();
    // }, []);

    useEffect(() => {
        getMotivosRechazo();
    }, [modalMessage]);

    const getMotivosRechazo = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CitaMotivoRechazo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    if (Number(item.TipoCitaId) === 19) {
                        items.push({
                            value: item.CitaMotivoRechazoId,
                            label: item.CitaMotivoRechazo
                        });
                    }
                });
                setMotivos(items);
            })
            .catch(err => {
                console.error(err);
            })
    }

    const handleAutorizar = async (values) => {
        let data = {
            ...values,
            citaId: modalMessage?.citaId ?? "",
            contrato: modalMessage?.contrato ?? "",
            tipoServicio: modalMessage?.tipoServicio ?? "",
            nombreMarca: modalMessage?.nombreMarca ?? "",
            nombreModelo: modalMessage?.nombreModelo ?? "",
            nombreYear: modalMessage?.nombreYear ?? "",
            vin: modalMessage?.vin ?? "",
        }                
        await DSystemsServicios.updateCitaServicio(data)
            .then(resp => {
                if (resp.data.ban === 1) {
                    toast.success(message(resp.data.message));
                } else {
                    toast.error(message(resp.data.message))
                }
                getListado();
            })
            .catch(err => {
                toast.error(message(`Error al intentar autorizar el servicio`))
            })
            .finally(() => {
                setModalMessage({ isOpen: false })
            });
    };

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAutorizar(values);
            }}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Form>
                        <section className="p-4">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="fw-bold">Autorización de Cita</h4>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <SelectField
                                                id="servicioAutorizado"
                                                label="Resultado"
                                                name="autorizado"
                                                items={resultados}
                                                onChange={(event) => {
                                                    setFieldValue("autorizado", event.value);
                                                }}
                                            />
                                        </div>
                                        {
                                            values.autorizado === 3 &&
                                            <div className="col-lg-6 col-12">
                                                <SelectField
                                                    id="servicioMotivoRechazo"
                                                    label="Motivo"
                                                    name="motivoRechazoId"
                                                    items={motivos}
                                                    onChange={(event) => {
                                                        setFieldValue("motivoRechazoId", event.value);
                                                        setFieldValue("motivoRechazo", event.label);
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TextAreaField label="Comentarios" className="form-control col-12" rows="3" name="comentarios" type="text" holder="Escribe" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-end mt-4">
                                <div className="col-12 col-md-auto">
                                    <button className="btn btn-outline" onClick={() => setModalMessage({ isOpen: false })}>
                                        Regresar
                                    </button>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <button className="btn" type="submite">
                                        Confirmar
                                    </button>
                                </div>
                            </div>
                        </section>
                    </Form>
                </>
            )
            }
        </Formik>
    );
}

export default AutorizacionCitaModal;