import React from "react";
import { Field, ErrorMessage } from "formik";

const PhoneField = ({ name, label, placeholder, setFieldValue }) => {
    // Función para formatear el número para la vista
    const formatPhone = (value) => {
        if (!value) return value;
        const cleaned = value.replace(/\D/g, ""); // Eliminar caracteres no numéricos
        const limited = cleaned.slice(0, 10); // El formato solo acepta 10 digitos
        const match = limited.match(/^(\d{0,2})(\d{0,4})(\d{0,4})$/);

        if (match) {
            const part1 = match[1] ? `(${match[1]})` : "";
            const part2 = match[2] ? `-${match[2]}` : "";
            const part3 = match[3] ? `-${match[3]}` : "";
            return `${part1}${part2}${part3}`;
        }
        setFieldValue(name, cleaned)
        return limited;
    };

    const handleChange = (e, setFieldValue) => {
        const rawValue = e.target.value;
    
        // Mantener solo los números
        const cleaned = rawValue.replace(/\D/g, ""); 
        // Limitar a 10 dígitos
        const limited = cleaned.slice(0, 10);
        // Actualizar el valor limpio en Formik
        setFieldValue(name, limited);
      };

    return (
        <div className="mb-2">
            <label className='text' htmlFor={name}>{label}</label>
            <div>

            <Field
                name={name}
                placeholder={placeholder}
                value={(fieldProps) => formatPhone(fieldProps.field.value)} // Mostrar formateado
                >
                {({ field, form }) => (
                    <input
                    {...field}
                    value={formatPhone(field.value)} // Mostrar el número formateado
                    placeholder={placeholder}
                    className='programacionJobsCarteraModalSelect'
                    style={{width: '100%'}}
                    onChange={(e) => handleChange(e, form.setFieldValue)} // Manejar el cambio
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && field.value.length <= 4) {
                        form.setFieldValue(name, field.value.slice(0, -1));
                      }
                    }}
                    />
                )}
            </Field>
            </div>
            <ErrorMessage
                className="error"
                name={name}
                component="div"
                style={{ color: "red" }}
            />
        </div>
    );
};

export default PhoneField;