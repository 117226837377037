import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoGestoriaService from '../../../Services/Gestoria/SeguimientoGestoria';
import { message } from '../../../Utils/functions';
import Catalogo from '../../../Services/Catalogos/Catalogo';

const usePreparacionPapeleria = ({ toast, handleConcluido }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let initial = {
        gestoriaPreparacionPapeleriaId: "",
        gestoriaRegistroInicialId: "",
        paso: 2,
        estimadoAdeudos: null,
        clienteDeseaContinuarTramite: null,
        clienteNotificacionAdeudo: null,
        aplicaFinanciamiento: null,
        montoFinanciar: null,
        papeleriaCompleta: null,
        comentarios: null,
        concluido: false,
        fechaInicio: "",
        fechaFin: null,
        codEstatus: null,
        estatus: "No hay cita",
        ubicacionId: null,
        nombreUbicacion: null,
        bloqueoId: null,
        fechaHoraCita: null,
        fechaCita: null,
        horaCita: null
    }

    const [loading, setloading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const opcionesBool = [{ value: 1, label: 'Si' }, { value: 0, label: 'No' }];

    useEffect(() => {
        getData();
    }, []);


    const getData = async () => {
        setloading(true)
        await SeguimientoGestoriaService.EstimacionCostosGet({id : id , paso : 2})
            .then(resp => {

                resp.data.concluido = resp.data.concluido ? true : false

                setInitialValue({ ...initialValue, ...resp.data });
                handleConcluido({ concluido: resp.data.concluido })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setloading(false)
            })
    };

    const submitForm = async (values) => {
        setloading(true)
        await SeguimientoGestoriaService.EstimacionCostosActualizar(values)
            .then(resp => {
                toast.success(message("Se Actualizó el Registro Inicial correctamente"));
                getData()
            })
            .catch(err => {
                toast.error(message("¡Error, al intentar Actualizar el Registro Inicial!"));
            })
            .finally(() => {
                setloading(false);
            });
    }

    return {
        loading,
        initialValue,
        submitForm,
        opcionesBool
    };
};

export default usePreparacionPapeleria;
