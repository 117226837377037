import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { TextField } from "../../../../Components/TextField";
import CostoGruaModal from "./CostoGruaModal";
import Llamada from "../../../../Services/Cartera/Llamadas";
import { formatearMontoMx, message, NumFolio } from "../../../../Utils/functions";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import dataDocsServices from "../../../../Services/DataDocs/dataDocsServices";
import AuthLogin from "../../../../Utils/AuthLogin";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VisorFile from "../../../../Components/VisorFile";
import SubirDocumentoModal from "./SubirDocumentoModal";
import { v4 as uuidv4 } from 'uuid';
import Catalogo from '../../../../Services/Catalogos/Catalogo';
import { BrowserView, MobileView } from "react-device-detect";
import { setContrato, setItemGeneral } from "../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice";

const auth = new AuthLogin();
const RecoleccionDeUnidadCard = React.forwardRef(({ ...props }, ref) => {
  const { itemGeneral, contratoActual } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
  const [collpsableGrua, setCollpsableGrua] = useState(false);
  const [gruaSeleccionada, setGruaSeleccionada] = useState(0);
  const [openModal, setIsOpenModal] = useState(false);
  const [dataMunicipio, setDataMunicipio] = useState([]);
  const [dataColonia, setDataColonia] = useState([]);
  const [dataEstado, setDataEstado] = useState([])
  const userName = sessionStorage.getItem('usuario')
  const userId = sessionStorage.getItem('userId').trim()
  const [fileName, setFileName] = useState("");
  const [archivoSeleccionado, setArchivoSeleccionado] = useState({})
  let url = auth.UrlDataDocs();
  const navigate = useNavigate();
  const [SelectedDocs, setSelectedDocs] = useState([]);
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [archivoId, setArchivoId] = useState("")
  const [openModalSubirDocumento, setOpenModalSubirDocumento] = useState(false)
  const [confirmarOperacion, setConfirmarOperacion] = useState("")
  const [nombreDocumento, setNombreDocumento] = useState("")
  const [archivosCargados, setArchivosCargados] = useState([])
  const [show, setShow] = useState(false);
  const [dataUbicacion, setDataUbicacion] = useState([]);
  const [update, setUpdate] = useState(false)
  const [registradoPreviamente, setRegistradoPreviamente] = useState(false);
  const dispatch = useDispatch();
  const [dataEmpresa, setDataEmpresa] = useState([])

  let initial = {
    folio: NumFolio(),
    usuarioProgramo: userName,
    tipoGrua: "",
    empresa_nombre: "",
    empresa_id: "",
    grua_asignada: "",
    costo_grua: "",
    estado: "",
    id_estado: '',
    municipio: "",
    id_municipio: "",
    colonia: "",
    id_colonia: "",
    calle: "",
    numero: "",
    ubi_origen_cp: "",
    ubi_dest_cp: "",
    ubi_origen_comentarios: "",
    ubi_destino: "",
    id_ubi_destino: "",
    quien_entrega: "",
    usuario_recibe: "",
    fechaRecoleccion: "",
    horaRecoleccion: "",
    registro_concluido: false,
    fecha_inicio: "",
    fecha_fin: ""
  };

  let initialCostoGrua = {
    concluido: false,
    total: 0,
    iva: 0,
    subtotal: 0,
    aplicaIva: false
  }

  const [initialValueGrua, setInitialValueGrua] = useState(initialCostoGrua);

  useEffect(() => {
    if (itemGeneral?.recoleccionUnidad !== null && itemGeneral?.recoleccionUnidad?.grua !== '') {
      setRegistradoPreviamente(itemGeneral?.recoleccionUnidad?.registro_concluido);
      const fechahora = itemGeneral?.recoleccionUnidad?.fecha_hora_recoleccion.split('T') ?? ["", ""];
      setInitialValue({
        folio: itemGeneral?.recoleccionUnidad.folio === null || itemGeneral?.recoleccionUnidad?.folio === "" ? NumFolio() : itemGeneral?.recoleccionUnidad?.folio,
        usuarioProgramo: userName,
        tipoGrua: itemGeneral?.recoleccionUnidad?.grua ?? '',
        grua_id: itemGeneral?.recoleccionUnidad?.grua === "Externa" ? 1 : 2,
        empresa_nombre: itemGeneral?.recoleccionUnidad?.empresa_nombre ?? "",
        empresa_id: itemGeneral?.recoleccionUnidad?.empresa_id ?? '',
        grua_asignada: itemGeneral?.recoleccionUnidad?.grua_asignada ?? "",
        costo_grua: itemGeneral?.recoleccionUnidad?.costo_grua ?? "",
        estado: itemGeneral?.recoleccionUnidad?.ubi_origen_estado ?? "",
        id_estado: '',
        municipio: itemGeneral?.recoleccionUnidad?.ubi_origen_municipio ?? "",
        id_municipio: '',
        colonia: itemGeneral?.recoleccionUnidad?.ubi_origen_colonia ?? "",
        id_colonia: "",
        calle: itemGeneral?.recoleccionUnidad?.ubi_origen_calle ?? "",
        numero: itemGeneral?.recoleccionUnidad?.ubi_origen_numero ?? "",
        ubi_origen_cp: itemGeneral?.recoleccionUnidad?.ubi_origen_cp ?? "",
        ubi_dest_cp: itemGeneral?.recoleccionUnidad?.ubi_dest_cp ?? "",
        ubi_origen_comentarios: itemGeneral?.recoleccionUnidad?.ubi_origen_comentarios ?? "",
        ubi_destino: itemGeneral?.recoleccionUnidad?.ubi_destino ?? "",
        id_ubi_destino: '',
        quien_entrega: itemGeneral.recoleccionUnidad?.quien_entrega ?? '',
        usuario_recibe: itemGeneral.recoleccionUnidad?.usuario_recibe ?? '',
        fechaRecoleccion: fechahora[0],
        horaRecoleccion: fechahora[1],
        registro_concluido: itemGeneral?.recoleccionUnidad?.registro_concluido,
        fecha_inicio: itemGeneral?.recoleccionUnidad?.fecha_inicio ?? "",
        fecha_fin: itemGeneral?.recoleccionUnidad?.fecha_fin ?? ""
      })
      setGruaSeleccionada(itemGeneral?.recoleccionUnidad?.grua === "Externa" ? 1 : 2)
      setInitialValueGrua({
        concluido: false,
        total: itemGeneral?.recoleccionUnidad?.total ?? 0,
        iva: itemGeneral?.recoleccionUnidad?.iva ?? 0,
        subtotal: itemGeneral?.recoleccionUnidad?.costo_grua ?? 0,
        aplicaIva: false
      })
      Boolean(itemGeneral?.recoleccionUnidad?.registro_concluido) ? props.handleSetCerrarVisita(true) : props.handleSetCerrarVisita(false);
      setUpdate(!update)
    }

    setShow(props.show);
  }, []);

  const [initialValue, setInitialValue] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    isOpen: false,
    type: 1,
    title: "",
    message: "",
    data: [],
    authorizationType: 2,
    Origin: "Listado",
  });

  const dataGrua = [
    { value: 1, label: "Externa" },
    { value: 2, label: "Datamovil" },
  ];

  const handleGetEmpresas = async () => {
    const data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {},
      "kvp": {}
    }

    let Empresas = []
    await CobranzaService.GetAllEmpresas(data)
      .then(res => {
        if (res?.data?.callSucceded) {
          res?.data?.responseData?.map(item => {
            Empresas.push({
              value: item.empresaId,
              label: item.empresa,
              codigo_postal: item.codigoPostalId
            })
          })

          setInitialValue((prevState) => ({
            ...prevState,
            empresa_id: itemGeneral?.recoleccionUnidad?.empresa_id ?? ''
          }));
        }
      })
      .catch(e => {
        console.log(e)
      })
    setDataEmpresa(Empresas)
  }

  const HandleGetEstadoMx = async () => {
    await Llamada.GetEstadoMx().then(res => {
      let Estados = []
      res?.data?.data?.map(item => {
        if (itemGeneral?.recoleccionUnidad !== null && itemGeneral.recoleccionUnidad?.ubi_origen_estado === item?.estado) {
          initialValue.id_estado = item?.edoMexicoId
          HandleGetMunicpio(item?.edoMexicoId)

          setInitialValue((prevState) => ({
            ...prevState,
            id_estado: item?.edoMexicoId ?? ''
          }));
        }
        Estados.push({
          value: item?.edoMexicoId,
          label: item?.estado
        });
      });
      setDataEstado(Estados);
    })
  }

  const HandleGetMunicpio = async (id) => {
    setLoading(true);
    setDataMunicipio([]);
    await Llamada.GetMunicipio(id).then(res => {
      let Municipios = []
      res?.data?.data?.map(item => {
        if (itemGeneral?.recoleccionUnidad !== null && itemGeneral.recoleccionUnidad?.ubi_origen_municipio === item?.municipio) {
          initialValue.id_municipio = item?.municipioId
          HandleGetColonia(item?.municipioId)

          setInitialValue((prevState) => ({
            ...prevState,
            id_municipio: item?.municipioId ?? ''
          }));
        }
        Municipios.push({
          value: item?.municipioId,
          label: item?.municipio
        });
      });
      setDataMunicipio(Municipios);
      setLoading(false);
    })
  }
  const HandleGetColonia = async (id) => {
    setLoading(true);
    setDataColonia([])
    await Llamada.GetColonia(id).then(res => {
      let Colonias = []
      res?.data?.data?.map(item => {
        if (itemGeneral.recoleccionUnidad !== null && itemGeneral.recoleccionUnidad?.ubi_origen_colonia === item?.colonia) {
          initialValue.id_colonia = item?.coloniaId

          setInitialValue((prevState) => ({
            ...prevState,
            id_colonia: item?.coloniaId ?? ''
          }));
        }
        Colonias.push({
          value: item?.coloniaId,
          label: item?.colonia
        });
      });
      setDataColonia(Colonias);
      setLoading(false);
    })
  }

  useEffect(() => {
    handleGetAllData();
  }, [update])

  const handleGetAllData = async () => {
    setLoading(true)
    const [] = await Promise.all([HandleGetEstadoMx(), handleGetAllUbicaciones(), handleGetEmpresas(),]).finally(() => {
      setLoading(false)
    });
  }

  useEffect(() => {
    if (confirmarOperacion !== "") {
      CreateRelacionRecoleccionDocumento()
      setConfirmarOperacion("")
    }
  }, [confirmarOperacion])

  const handleGuardar = async (values, subirdocumento) => {
    setLoading(true);

    const now = new Date();
    let fechaR = values.fechaRecoleccion === "" ? `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}` : values.fechaRecoleccion;
    let horaR = values.horaRecoleccion === "" ? `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}` : values.horaRecoleccion;

    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {
        "id": itemGeneral?.recoleccionUnidad?.id ?? uuidv4(),
        "ruta_head_id": itemGeneral?.recoleccionUnidad?.ruta_head_id ?? itemGeneral?.ruta_head_id,
        "ruta_item_id": itemGeneral?.recoleccionUnidad?.ruta_item_id ?? itemGeneral?.id,
        "evento_head_id": itemGeneral?.recoleccionUnidad?.evento_head_id ?? itemGeneral?.evento_head_id,
        "evento_item_id": itemGeneral?.recoleccionUnidad?.evento_item_id ?? itemGeneral?.evento_item_id,
        "userid": userId,
        "folio": values.folio,
        "grua": values.tipoGrua,
        "empresa_id": `${values.empresa_id}`,
        "empresa_nombre": values.empresa_nombre,
        "costo_cliente": 0,
        "grua_asignada": values.grua_asignada,
        "ubi_origen_estado": values.estado,
        "ubi_origen_municipio": values.municipio,
        "ubi_origen_colonia": values.colonia,
        "ubi_origen_calle": values.calle,
        "ubi_origen_numero": values.numero,
        "ubi_origen_comentarios": values.ubi_origen_comentarios,
        "costo_grua": parseFloat(initialValueGrua.subtotal),
        "iva": parseFloat(initialValueGrua.iva),
        "total": initialValueGrua.total,
        "ubi_destino": values.ubi_destino,
        "ubi_origen_cp": values.ubi_origen_cp,
        "ubi_dest_cp": values.ubi_dest_cp,
        // "ubi_dest_cp": '',        
        "quien_entrega": values.quien_entrega,
        "usuario_recibe": values.usuario_recibe,
        "fecha_hora_recoleccion": fechaR + " " + horaR,
        "registro_concluido": values.registro_concluido,
        "fecha_inicio": values.fecha_inicio !== "" ? values.fecha_inicio : getSqlServerDateTime(),
        "fecha_fin": values.registro_concluido === true ? getSqlServerDateTime() : new Date(1990, 0, 1).toISOString().replace('T', ' ').slice(0, 23),
      }
    }

    console.log(data)

    await CobranzaService.CreateRecoleccionUnidad(data)
      .then(res => {
        console.log(res)
        setLoading(false)
        setRegistradoPreviamente(values.registro_concluido)
        Boolean(values.registro_concluido) ? props.handleSetCerrarVisita(true) : props.handleSetCerrarVisita(false);
        // props.handleSetCerrarVisita(true);
        GetAllCobranzaRecoleccionUnidadByFields();
        handleActualizarItem(subirdocumento);
        toast.success(message("¡Operación exitosa!", res.data.mensaje));
      }).catch(e => {
        console.log(e)
        setLoading(false)
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const GetAllCobranzaRecoleccionUnidadByFields = async () => {
    let data = {
      "verb": "string",
      "id": "string",
      "field": "ruta_item_id",
      "value": itemGeneral?.id,
      "level": 0,
      "flag": true,
      "anyObject": {},
      "kvp": {}
    };

    await CobranzaService.GetAllCobranzaRecoleccionUnidadByFields(data)
      .then(res => {
        res?.data?.responseData?.map(item => {
          if (item?.ruta_item_id === itemGeneral.id) {
            setRegistradoPreviamente(item?.registro_concluido);
            const fechahora = item?.fecha_hora_recoleccion.split('T') ?? ["", ""];
            setInitialValue({
              folio: item?.folio,
              usuarioProgramo: userName,
              tipoGrua: item?.grua ?? '',
              grua_id: item?.grua === "Externa" ? 1 : 2,
              empresa_nombre: item?.empresa_nombre ?? "",
              empresa_id: parseInt(item?.empresa_id) ?? '',
              grua_asignada: item?.grua_asignada ?? "",
              costo_grua: item?.costo_grua ?? "",
              estado: item?.ubi_origen_estado ?? "",
              id_estado: dataEstado.find(x => x.label === (item?.ubi_origen_estado ?? ""))?.value || '',
              municipio: item?.ubi_origen_municipio ?? "",
              // id_municipio: '',
              id_municipio: dataMunicipio.find(x => x.label === (item?.ubi_origen_municipio ?? ""))?.value || '',
              colonia: item?.ubi_origen_colonia ?? "",
              // id_colonia: "",
              id_colonia: dataColonia.find(x => x.label === (item?.ubi_origen_colonia ?? ""))?.value || '',
              calle: item?.ubi_origen_calle ?? "",
              numero: item?.ubi_origen_numero ?? "",
              codigo_postal: item?.ubi_origen_cp ?? "",
              ubi_origen_comentarios: item?.ubi_origen_comentarios ?? "",
              ubi_destino: item?.ubi_destino ?? "",
              // id_ubi_destino: '',
              id_ubi_destino: dataUbicacion.find(x => x.label === (item?.ubi_destino ?? ""))?.value || '',
              quien_entrega: item?.quien_entrega ?? '',
              usuario_recibe: item?.usuario_recibe ?? '',
              fechaRecoleccion: fechahora[0],
              horaRecoleccion: fechahora[1],
              registro_concluido: item?.registro_concluido,
              fecha_inicio: item?.fecha_inicio ?? "",
              fecha_fin: item?.fecha_fin ?? ""
            })
            setGruaSeleccionada(item?.grua === "Externa" ? 1 : 2)
            setInitialValueGrua({
              concluido: false,
              total: item?.total ?? 0,
              iva: item?.iva ?? 0,
              subtotal: item?.costo_grua ?? 0,
              aplicaIva: false
            })
            // setUpdate(false)
          }
        })
      })
      .catch(e => {
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const handleActualizarItem = async (subirdocumento) => {
    setLoading(true);

    await CobranzaService.GetAllRutasCobranza()
      .then(res => {
        res?.data?.responseData?.map(item => {
          if (item?.userid.toLowerCase() === userId.toLowerCase()) {
            for (const i of item?.items) {
              if (i?.contrato !== null && i?.contrato.contrato_id === contratoActual?.contrato_id && i?.id === itemGeneral?.id) {
                dispatch(setItemGeneral(i));
                dispatch(setContrato(i?.contrato));
                if (subirdocumento) {
                  handleUploadFile(i?.recoleccionUnidad?.id)
                }
              }
            }
          }
        })
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
    setLoading(false);
  }

  const CreateRelacionRecoleccionDocumento = async () => {
    // const data = {
    //   "verb": "string",
    //   "id": "string",
    //   "field": "string",
    //   "value": "string",
    //   "level": 0,
    //   "flag": true,
    //   "anyObject": {
    //     "id":archivoId,
    //     "visita_dom_id":infoVisitaDom.id,
    //     "tipo_doc":"documento",
    //     "extension_doc":".pdf",
    //     "nombre_doc":nombreDocumento,
    //     "descripcion":"",
    //     "url":"",
    //     "subido":1,
    //     }
    // }

    // await CobranzaService.(data)
    //   .then(res=>{
    //     toast.success(message("Documento relacionado correctamente", res.data.mensaje));
    //   })
    //   .catch(e => {
    //     setLoading(false)
    //     if (e.response !== undefined)
    //       toast.error(message("¡Error!", e.response.data.message));
    //     else
    //       toast.error(message("¡Error!", e.message));
    //   })
  }

  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => { })
  }
  const handleViewdocs = async () => {
    setLoading(true);
    setFileName(archivoSeleccionado.label);
    await handleGetTokenDatadocs();
    await axios.post(`${url}Documento/GetFileById/${archivoSeleccionado.idFolder}/${archivoSeleccionado.value}/false`, {}, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
      },
      responseType: "blob"
    })
      .then(res => {
        var file = new File([res.data], res.data.name, { type: res.data.type });
        setSelectedDocs([file])
        setLoading(false);
        setIsOpenDocs(true)
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.errors?.error));
        else
          toast.error(message("Error.!", e.message));
      })
  }

  const handleVisualizarDocumentos = async () => {
    await handleViewdocs()
  }

  const handleUploadFile = async (id) => {
    navigate(`/cobranza/seguimiento-visita/datadocs/fRecupera/eRecupera/${contratoActual?.contrato_id}/${id}`, {
      state: { ...contratoActual, registroId: id, vin: contratoActual.vin, nombreMarca: contratoActual?.vehiculo?.nombreMarca, nombreModelo: contratoActual?.vehiculo?.nombreModelo, nombreVersion: contratoActual?.vehiculo?.nombreVersion, nombreYear: contratoActual?.vehiculo?.nombreYear }
    });
  }

  const handleSubirDocumento = () => {
    const archivoId = uuidv4();
    setArchivoId(archivoId)
    setOpenModalSubirDocumento(true)
  }

  const handleGetAllUbicaciones = async () => {
    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {},
      "kvp": {}
    }
    await CobranzaService.getAllUbicacion(data)
      .then(resp => {
        let listaUbicaciones = [];
        resp.data?.responseData?.forEach(item => {
          if (itemGeneral.recoleccionUnidad !== null && itemGeneral.recoleccionUnidad?.ubi_destino === item?.nombre) {
            initialValue.id_ubi_destino = item?.ubicacionId

            setInitialValue((prevState) => ({
              ...prevState,
              id_ubi_destino: item?.ubicacionId ?? ''
            }));
          }

          if (item.status) {
            listaUbicaciones.push({
              value: item.ubicacionId,
              label: item.nombre,
              responsable: item.responsable ?? ""
            });
          }
        });
        setDataUbicacion(listaUbicaciones);
      })
      .catch(err => {
        console.error(err);
      });
  }

  function getSqlServerDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function formatearFecha(fechaISO) {
    if (!fechaISO) {
      return 'Fecha no válida'; // Mensaje para fechas nulas o indefinidas
    }

    const fecha = new Date(fechaISO);

    if (isNaN(fecha.getTime())) {
      return 'Fecha no válida'; // Mensaje si la fecha no puede ser convertida
    }

    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    const horas = (fecha.getHours() % 12 || 12).toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const ampm = fecha.getHours() >= 12 ? 'PM' : 'AM';

    return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos} ${ampm}`;
  }

  const handleCleanValues = (setFieldValue) => {
    setFieldValue("tipoGrua", "");
    setGruaSeleccionada(0);
    setFieldValue("empresa_nombre", "");
    setFieldValue("empresa_id")
    setFieldValue("grua_asignada", "");
    setFieldValue("costo_grua", "");
    setFieldValue("estado", "");
    setFieldValue("id_estado", "");
    setFieldValue("municipio", "");
    setFieldValue("id_municipio", "");
    setFieldValue("colonia", "");
    setFieldValue("id_colonia", "");
    setFieldValue("calle", "");
    setFieldValue("numero", "");
    setFieldValue("ubi_origen_cp", "");
    setFieldValue("ubi_dest_cp", "");
    setFieldValue("ubi_origen_comentarios", "");
    setFieldValue("ubi_destino", "");
    setFieldValue("id_ubi_destino", "");
    setFieldValue("fechaRecoleccion", "");
    setFieldValue("horaRecoleccion", "");
    setInitialValueGrua({
      concluido: false,
      total: 0,
      iva: 0,
      subtotal: 0,
      aplicaIva: false
    })
  }

  return (
    <>
      <Modal isOpen={loading} color="transparent">
        <Spinner message={""} />
      </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
        {modalMessage.type === 1 && (
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {modalMessage.type === 2 && (
          <SuccessModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
      </Modal>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />
      {show && (
        <div className="row wrapper-vehiculo">
          <div className="col col-12">
            <div className="row mx-md-4 my-4">
              <div className="col-12 px-md-3">
                <div className="p-4 white-wrapper mb-2">
                  <div
                    className="row mt-2 px-4"
                    data-bs-toggle="collapse"
                    href="#collapseRecoleccionDeUnidadCard"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseRegistroTramite"
                    onClick={() => setCollpsableGrua(!collpsableGrua)}
                  >
                    <div className="col-11">
                      <h6>
                        <span
                          className={`semaforo semaforo-${initialValue.color}`}
                        ></span>{" "}
                        <strong>Recolección de unidad</strong>
                      </h6>
                    </div>
                    <div
                      className="col-1 col-md-1 collapsable-icon"
                      style={{ textAlign: "right" }}
                    >
                      <i
                        className={
                          !collpsableGrua
                            ? "icon-blue bx bx-chevron-down x2"
                            : "icon-blue bx bx-chevron-up x2"
                        }
                      ></i>
                    </div>
                  </div>
                  <div className="collapse" id="collapseRecoleccionDeUnidadCard">
                  <div className="separator"></div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValue}
                    onSubmit={(values) => {
                      handleGuardar(values, false);
                    }}
                  >
                    {({ isValid, values, setFieldValue, errors }) => (
                      <>
                        <Form>
                          <div className="row mt-2 justify-content-end">
                            <div className="row col-lg-3 col-sm-12 justify-content-end">
                                <div className="col-sm-6 col-lg-6">
                                  <BrowserView>
                                    {/* <div className="col-auto"> */}
                                    <fieldset
                                      onClick={() => {
                                        if (registradoPreviamente) {
                                          handleActualizarItem(true)
                                        }
                                        else {
                                          handleGuardar(values, true)
                                        }
                                      }}
                                      className='btn btn-gray'>
                                      <i className="icon-light fa fa-file "></i> {registradoPreviamente ? "Ver documentos" : "Subir documento"}
                                    </fieldset>
                                    {/* </div> */}
                                  </BrowserView>
                                </div>
                                {
                                  !registradoPreviamente 
                                  ? 
                                    <div className="col-sm-6 col-lg-6">
                                    <button
                                        className="btn col-12"
                                        type="submit"
                                        // disabled={!(isValid) || registradoPreviamente}
                                        style={{
                                          padding: "10px",
                                          lineHeight: "15px",
                                        }}>
                                        Guardar
                                    </button>
                                    </div>
                                  : <div className="col-sm-6 col-lg-6">
                                    {
                                        registradoPreviamente && (
                                          <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                          <i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                        )
                                    }
                                    </div>
                                }
                              </div>
                            </div>
                            <MobileView>
                              <div className="row mt-2">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                  <button
                                    id="btnVisualizarDocumentos"
                                    className="btn btn-outline col-12 mw-100 d-flex"
                                    type="button"
                                    onClick={handleVisualizarDocumentos}
                                    disabled={archivosCargados.length === 0}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                    }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Visualizar Documentos
                                  </button>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                  <SelectField
                                    id="archivoscargados"
                                    label="Archivos"
                                    name="archivoscargados"
                                    items={archivosCargados}
                                    disabled={archivosCargados.length === 0}
                                    onChange={(event) => {
                                      setArchivoSeleccionado(event)
                                    }} />
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                  <button
                                    id="btnSubirDocumento"
                                    className="btn btn-outline col-12 mw-100 d-flex "
                                    type="button"
                                    onClick={() => {
                                      if (registradoPreviamente) {
                                        handleActualizarItem(true)
                                      }
                                      else {
                                        handleGuardar(values, true)
                                      }
                                    }}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                    }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Subir documento
                                  </button>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                  <button
                                    id="btnSubirDocumento"
                                    className="btn btn-outline col-12 mw-100 d-flex "
                                    type="button"
                                    onClick={handleSubirDocumento}
                                    style={{
                                      padding: "10px",
                                      lineHeight: "20px",
                                      border: "none",
                                      backgroundColor: "#f0f0f0",
                                    }}>
                                    <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                    Tomar foto
                                  </button>
                                </div>
                              </div>
                            </MobileView>
                            <div className="row mt-2 mb-2 justify-content-between">
                              <div className="col-3">
                                <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Asignación de grua</strong></span></p>
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <div className="col-sm-6 col-xl-3">
                                <TextField
                                  label="Folio"
                                  name="folio"
                                  type="text"
                                  value={values.folio}
                                  disabled={true}
                                />
                              </div>
                              <div className="col-sm-6 col-xl-3">
                                <TextField
                                  label="Usuario Programo"
                                  name="usuarioProgramo"
                                  type="text"
                                  value={values.usuarioProgramo}
                                  disabled={true}
                                />
                              </div>
                              <div className="col-sm-6 col-xl-3">
                                <SelectField
                                  id="grua"
                                  label="Grua"
                                  name="grua_id"
                                  disabled={registradoPreviamente}
                                  items={dataGrua}
                                  onChange={(event) => {
                                    setFieldValue("grua_id", event.value)
                                    if (event.value === 1 || event.value === 2) {
                                      setFieldValue("tipoGrua", event.label);
                                      setGruaSeleccionada(event.value);
                                    } else {
                                      handleCleanValues(setFieldValue)
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            {(gruaSeleccionada === 1 || gruaSeleccionada === 2) && (
                              <>
                                <div className="mb-3 row">
                                  <div className="col-sm-6 col-xl-3">
                                    <SelectField
                                      id="empresa_id"
                                      label="Empresa Grúa"
                                      name="empresa_id"
                                      items={dataEmpresa}
                                      disabled={registradoPreviamente}
                                      onChange={(event) => {
                                        setFieldValue("empresa_nombre", event.value === '' ? '' : event.label);
                                        setFieldValue("empresa_id", event.value === '' ? '' : event.value);
                                        // setFieldValue("ubi_origen_cp", event.value === '' ? '' : event.codigo_postal)
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    <TextField label="Grua asignada" name="grua_asignada" value={values.grua_asignada} type="text" holder="Escribe" disabled={registradoPreviamente}
                                      onChange={(event) => { setFieldValue("grua_asignada", event.target.value); }} />
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    <TextField
                                      label="Costo de grua"
                                      name="costo_grua"
                                      type="text"
                                      value={formatearMontoMx(initialValueGrua.total)}
                                      disabled={true}
                                    />
                                  </div>

                                  <div className="col-sm-6 col-xl-3">
                                    <button
                                      className="btn col-10"
                                      type="button"
                                      onClick={() => setIsOpenModal(true)}
                                      disabled={registradoPreviamente}
                                      style={{
                                        padding: "10px",
                                        lineHeight: "25px",
                                      }}
                                    >
                                      Registrar costo
                                    </button>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-3">
                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Indicar ubicación de origen</strong></span></p>
                                  </div>
                                </div>
                                <div className="mb-3 row">
                                  <div className="col-sm-6 col-xl-3">
                                    {
                                      !registradoPreviamente ?
                                        <SelectField label="Estado" name="id_estado" items={dataEstado} disabled={registradoPreviamente} onChange={(e) => {
                                          setFieldValue("id_estado", e.value)
                                          setFieldValue("estado", e.value === "" ? "" : e.label);
                                          HandleGetMunicpio(e.value);
                                        }} />
                                        : <TextField label="Estado" name="estado" type="text" holder={values.estado} disabled={true} />
                                    }
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    {
                                      !registradoPreviamente ?
                                        <SelectField label="Municipio" name="id_municipio" items={dataMunicipio} disabled={registradoPreviamente} onChange={(e) => {
                                          setFieldValue("id_municipio", e.value)
                                          setFieldValue("municipio", e.value === "" ? "" : e.label);
                                          HandleGetColonia(e.value);
                                        }} />
                                        : <TextField label="Municipio" name="municipio" type="text" holder={values.municipio} disabled={true} />
                                    }
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    {
                                      !registradoPreviamente ?
                                        <SelectField label="Colonia" name="id_colonia" items={dataColonia} disabled={registradoPreviamente} onChange={(e) => {
                                          setFieldValue("id_colonia", e.value)
                                          setFieldValue("colonia", e.value === "" ? "" : e.label);
                                        }} />
                                        : <TextField label="Colonia" name="colonia" type="text" holder={values.colonia} disabled={true} />
                                    }
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    <TextField label="Calle" name="calle" value={values.calle} type="text" holder="Escribe" disabled={registradoPreviamente}
                                      onChange={(event) => { setFieldValue("calle", event.target.value); }} />
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    <TextField label="Número" name="numero" value={values.numero} type="text" holder="Escribe" disabled={registradoPreviamente}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 10) {
                                          setFieldValue("numero", value);
                                        }
                                      }} />
                                  </div>
                                  <div className="col-sm-6 col-xl-3">
                                    <TextField label="Código Postal" name="ubi_origen_cp" type="text" holder="Escribe" disabled={registradoPreviamente}
                                      onChange={(event) => {
                                        const value = event.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 10) {
                                          setFieldValue("ubi_origen_cp", value);
                                        }
                                      }} />
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <TextField label="Quien entrega" name="quien_entrega" type="text" holder="" disabled={registradoPreviamente}
                                      onChange={(event) => { setFieldValue("quien_entrega", event.target.value); }} />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-6 mb-3">
                                    <TextAreaField label="Referencia de ubicación:" className="col-sm-12 col-xl-8 mt-2 form-control" rows="4" name="ubi_origen_comentarios" type="text" holder="Escribe" disabled={registradoPreviamente} />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-3">
                                    <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Indicar ubicación destino</strong></span></p>
                                  </div>
                                </div>
                                <div className="mb-3 row px-4">
                                  <div className="col-sm-6 col-xl-3">
                                    {
                                      !registradoPreviamente ?
                                        <SelectField
                                          id="id_ubi_destino"
                                          label="Ubicación"
                                          name="id_ubi_destino"
                                          items={dataUbicacion}
                                          disabled={registradoPreviamente}
                                          onChange={(event) => {
                                            setFieldValue("id_ubi_destino", event.value)
                                            setFieldValue("ubi_destino", event.value === "" ? "" : event.label);
                                            setFieldValue("usuario_recibe", event.value === "" ? "" : (initialValue.usuario_recibe == event.responsable ? initialValue.usuario_recibe : (event.value == initialValue.id_ubi_destino ? initialValue.usuario_recibe : event.responsable)));
                                          }}
                                        />
                                        : <TextField label="Ubicación" name="ubi_destino" type="text" holder={values.ubi_destino} disabled={true} />
                                    }
                                  </div>
                                  {/* <SelectField
                                    id="id_ubi_destino"
                                    label="Ubicación"
                                    name="id_ubi_destino"
                                    items={dataUbicacion}
                                    disabled={registradoPreviamente}
                                    onChange={(event) => {
                                      setFieldValue("id_ubi_destino", event.value)
                                      setFieldValue("ubi_destino", event.value === "" ? "" : event.label);
                                      setFieldValue("usuario_recibe", event.value === "" ? "" : event.responsable)
                                    }}
                                  />
                                </div> */}
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <TextField label="¿Quién Recibe?" name="usuario_recibe" type="text" holder="Escribe" disabled={registradoPreviamente}
                                      onChange={(event) => { setFieldValue("usuario_recibe", event.target.value); }} />
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <TextField id="fechaRecoleccion" label="Fecha de recolección" name="fechaRecoleccion" type="date" holder="Escribe" disabled={registradoPreviamente} onChange={(event) => { setFieldValue("fechaRecoleccion", event.target.value); }} />
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-3">
                                    <TextField id="horaRecoleccion" label="Hora de recolección" name="horaRecoleccion" type="time" holder="Escribe" disabled={registradoPreviamente} onChange={(event) => { setFieldValue("horaRecoleccion", event.target.value); }} />
                                  </div>
                                </div>
                                {/* <div className="mb-3 row px-4">
                                <div className="col-sm-12 col-md-6 col-lg-3">
                                  <TextField label="Código Postal" name="ubi_dest_cp" type="text" holder="Escribe" disabled={registradoPreviamente}
                                    onChange={(event) => {
                                      const value = event.target.value;
                                      if (/^\d*$/.test(value) && value.length <= 10) {
                                        setFieldValue("ubi_dest_cp", value);
                                      }
                                    }} />
                                </div>
                              </div> */}
                              </>
                            )}
                            <div className="mb-3 row">
                              <div className="col-sm-12 col-md-6 col-lg-3">
                                <Field className="form-check-input" type="checkbox" name="registro_concluido" disabled={registradoPreviamente} onClick={(event) => {
                                  setFieldValue("registro_concluido", event.target.value);
                                }} />
                                <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Grua programada</label>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                                {
                                  values.fecha_inicio !== "" &&
                                  <label>
                                    Inicio de proceso: {formatearFecha(values.fecha_inicio)}
                                  </label>
                                }
                                {
                                  registradoPreviamente &&
                                  <label style={{ whiteSpace: 'pre' }}>
                                    {" | "}Fin de proceso: {formatearFecha(values.fecha_fin)}
                                  </label>
                                }
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <CostoGruaModal
          isOpen={openModal}
          item={initialValueGrua}
          setIsOpen={setIsOpenModal}
          handleGetAll={setInitialValueGrua}
        />
      }
      {
        isOpenDocs &&
        <VisorFile
          isOpen={isOpenDocs}
          setIsOIpen={setIsOpenDocs}
          fileName={fileName}
          doc={SelectedDocs}
        />
      }
      {
        openModalSubirDocumento && (
          <SubirDocumentoModal
            contrato_id={contratoActual.contrato_id}
            archivoId={archivoId}
            isOpen={openModalSubirDocumento}
            setIsOpen={setOpenModalSubirDocumento}
            cardId={2}
            setConfirmarOperacion={setConfirmarOperacion}
            setNombreDocumento={setNombreDocumento}
          />
        )
      }
    </>
  );
});
export default RecoleccionDeUnidadCard;