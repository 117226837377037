import { Formik } from "formik";
import Modal from "../../Components/Modal/Modal";
import Spinner from "../../Components/Loadig";
import ConfiguracionUbicacionHook from "../../Hooks/ConfigCalendar/ConfiguracionUbicacionHook";
import { useLocation, useParams } from "react-router-dom";
import { SelectField } from "../../Components/SelectField";
import DataTable from "../../Components/datatable/DataTable";

const ConfiguracionUbicacion = () => {
    const {
        refFormik,
        initialValue,
        data,
        loading,
        navigate,
        ubicaciones,
        handleChooseLocation,
        ubicacion,
        infoUbicacion,
        schedules,
        Columns,
        handleCheckDay,
        handleSubmit,
        handleCheckAllDay,
        refreshData,
        key,
        tipoServicio
    } = ConfiguracionUbicacionHook();

    const { state } = useLocation();
    const { ubicacionId } = useParams();

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ values, setFieldValue }) => (
                    <>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='headerTab'>
                            <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                                <div>
                                    <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                                    Regresar a listado de ubicaciones
                                </div>
                            </header>
                        </section>
                        <section>
                            <div className='pb-4'>
                                <section className='mx-4 my-4 d-flex flex-column'>
                                    <div className="col-12 mt-2">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>Servicio: {tipoServicio}</strong>
                                            </div>
                                            <div className="col-3">
                                                <div className="row justify-content-end">
                                                    <div className="col-6">
                                                        <button className='btn btn-outline px-4 col-12' onClick={() => { navigate(-1) }}>
                                                            Cancelar
                                                        </button>
                                                    </div>
                                                    <div className="col-6">
                                                        <button className='btn px-4 col-12' onClick={() => { handleSubmit() }}>
                                                            Confirmar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>{ubicacionId === '0' ? 'Agregar ubicación' : 'Editar ubicación'}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-12 col-md-3">
                                                <SelectField id="ubicacionId" disabled={ubicacionId !== '0'} name="ubicacionId" items={ubicaciones} onChange={(event) => {
                                                    setFieldValue("ubicacionId", event.value);
                                                    handleChooseLocation(event.value);
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="mx-4">
                                    {
                                        values.ubicacionId !== null &&
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <span>Horarios de servicio en {ubicacion}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <span>Atención en Horario de {infoUbicacion?.HorarioAntencionInicio} a {infoUbicacion?.HorarioAntencionFin}</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="row mt-4">
                                        <div className="col-8">
                                            <DataTable
                                                key={key}
                                                headerColorClass={'header-secondary'}
                                                column={Columns}
                                                data={schedules}
                                                handleCheckDay={handleCheckDay}
                                                actions={false}
                                                noAddons={true}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </>
                )}
            </Formik>
        </>
    );
}

export default ConfiguracionUbicacion;