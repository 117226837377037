import HalfCircleChart from "../../../Components/HalfCircleChart/HalfCircleChart";
import Spinner from "../../../Components/Loadig";
import EstadisticasHook from "../../../Hooks/DatamovilSystems/Asignaciones/EstadisticasHook";
import EstadisticaCard from "./EstadisticaCard";
import './Estadistica.css';

const BarraEstadistica = () => {

    const {
        estadistica,
        loading,
        servicios,
        progreso,
    } = EstadisticasHook();

    return (
        <div className='col-12 mt-4'>
            <div className="row">
                <div className="col-12 col-md-2 mt-2">
                    <div className="row d-flex align-items-center" style={{ maxHeight: '100px' }}>
                        <div className="col-12 d-flex flex-column justify-content-center" style={{ height: '100%' }}>
                            <h6 className="fw-bold">Estadísticas de progreso</h6>
                            <span>Total de servicios</span>
                            <h4 className="fw-bold">{servicios}</h4>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-2 mt-2" style={{ backgroundColor: '#F5F5F5' }}>
                    <div className="row">
                        <div className="col-10 offset-1" style={{ maxHeight: '100px', maxWidth: '210px', overflow: 'hidden' }}>
                            <HalfCircleChart percentage={progreso} title={'Servicios asignados'} />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7 mt-2 ms-1">
                    <div className="row">
                        {
                            loading 
                            ?
                            <Spinner light={true} />
                            :
                            <>
                            {
                                estadistica.map(item => {
                                    return <div className="col-6 col-md-2 px-2 mx-2 wrapper-statistics" key={`estadistica_${item.tipoServicio}`}>
                                        <EstadisticaCard title={item.tipoServicio} servicios={item.totalRegistros} sinAsignar={item.serviciosNoAsignados} asignados={item.serviciosAsignados} />
                                    </div>
                                })
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BarraEstadistica;