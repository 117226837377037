import { useEffect, useState } from 'react'
import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { getDateFormatNoUTC } from '../../Utils/functions'


const SolicitudesHook = () => {


  const columns = [
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'cobrador', headerName: 'Cobrador' },
    { field: 'proceso', headerName: 'Proceso' },
    { field: 'fecha_de_cita', headerName: ' Fecha y hora de cita' },
    { field: 'dias_vencidos', headerName: 'Días Vencidos' },
    { field: 'monto_adeudo', headerName: 'Monto Vencido' },
    { field: 'motivo_visita_nombre', headerName: 'Motivo Visita' },
    { field: 'ubicacion', headerName: 'Ubicación' },
    { field: 'fecha', headerName: ' Fecha y hora de solicitud' },
  ];
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [data, setData] = useState([])
  
  const [pageRows, setpageRows] = useState(10)
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentData, setCurrentData] = useState(data)
  const [cobradores, setCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState(''); // Estado para el cobrador seleccionado

  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  useEffect(() => {
    setCurrentPage(1);
  }, [])

  useEffect(() => {
    const filteredData = data.filter(item => {
      const contractMatches = name === '' || (item.contrato && item.contrato.toLowerCase().includes(name.toLowerCase()));
      const nameMatches = name === '' || (item.cobrador && item.cobrador.toLowerCase().includes(name.toLowerCase()));
  
      return (
        (nameMatches || contractMatches) &&
        (cobradorSeleccionado === "" || item.cobrador.toLowerCase() === cobradorSeleccionado.toLowerCase())
      );
    });
  
    const indexOfLastItem = (currentPage + 1) * pageRows;
    const indexOfFirstItem = indexOfLastItem - pageRows;
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
    setCurrentData(newData);
  
    const newTotalPages = Math.ceil(filteredData.length / pageRows);
    setPageCount(newTotalPages);
  }, [currentPage, name, pageRows, data, cobradorSeleccionado]);

  useEffect(() => {
    setCurrentPage(0)
  }, [pageRows, name])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  useEffect(() => {
    handleGetSeguimientoContrato()

  }, [])


  const handleGetSeguimientoContrato = async () => {
    setLoading(true)

    let allMotivos = await CobranzaService.getAllMotivoVisita()

    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.GetAllContactoSeguimientoLog(data)
      .then(res => {

        const filteredData = res.data.responseData.filter(item => item.aplica_visita === 1);
        const cobradoresData = res.data.responseData;

        const update = filteredData.map(item => {
          const fechaFormat = (item.fecha === "1990-01-01T00:00:00" || item.fecha === "1900-01-01T00:00:00" || item.fecha === null) ? "-" : getDateFormatNoUTC(new Date(item.fecha), "")
          const fechaCitaFormat = (item.fecha_de_cita === "1990-01-01T00:00:00" || item.fecha_de_cita === "1900-01-01T00:00:00" || item.fecha_de_cita === null) ? "-" :getDateFormatNoUTC(new Date(item.fecha_de_cita), "")
          const nombreMotivo = allMotivos.data.responseData.find(objeto =>objeto.id.toLowerCase() === item.motivo_visita_id.toLowerCase())
          
          return {
            ...item,
            fecha: fechaFormat,
            message: item.comentario_visita,
            fecha_de_cita: fechaCitaFormat,
            motivo_visita_nombre: nombreMotivo?.nombre
          }
        })

        // Eliminar duplicados por nombre
        const cobradoresUnicos = cobradoresData.filter(
          (cobrador, index, self) =>
            index === self.findIndex((t) => t.cobrador.trim().toLowerCase() === cobrador.cobrador.trim().toLowerCase())
        ).map(c => ({ ...c, cobrador: c.cobrador.trim() })); // Limpia espacios
        
        setCobradores(cobradoresUnicos);
        setData(update)
        setLoading(false)
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    setName,
    setpageRows,
    columns,
    currentData,
    pageCount,
    currentPage,
    handlePageClick,
    cobradores,
    setCobradorSeleccionado
  }
}
export default SolicitudesHook