import { useEffect, useState } from "react";
import ClavesServices from "../../Services/Inventario/ClavesService";
import { getDateFormat, getDateFormatNoUTC, getOnlyDateFormat } from "../../Utils/functions";

const useSeguimientoLecturaClaves = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [buscar, setBuscar] = useState("");
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });

    const columns = [
        { field: 'contrato', headerName: 'Contrato', width: '150' },
        { field: 'usuarioSolicita', headerName: 'Cobrador solicito', width: '260'  },
        { field: 'motivo_clave', headerName: 'Motivo de solicitud', width: '230'  },
        { field: 'fecha_solicitud', headerName: 'Vigencia clave', width: '120'  },
        { field: 'fechaAlta', headerName: 'Fecha y hora de solicitud', width: '190' },
        { field: 'comentariosUI', headerName: 'Comentarios', width: '150' },
    ];

    useEffect(() => {
        getSeguimiento();
    }, []);
    
    useEffect(() => {
        getSeguimiento();
    }, [buscar, page, rows]);

    const getSeguimiento = async () => {
        setLoading(true);

        let params = { buscar, page, rows };

        await ClavesServices.getSeguimientoClaves(params)
        .then(resp => {
            let items = resp.data.data.map(item => {
                item.fecha_solicitud = getOnlyDateFormat(item.fecha_solicitud);
                item.fechaAlta = getDateFormatNoUTC(item.fechaAlta);
                item.comentariosUI = item.comentario_clave;

                return item;
            });
            setTotalPages(resp.data.totalPages);
            setData(items);
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const setOnlyDateFormat = (date) => {
        const stringDate = date.split("T")[0].split("-").reverse().join("-");
        return stringDate;
    }

    const setDateHourFormat = (date) => {
        const stringDate = date.split("T")[0].split("-").reverse().join("-");
        const tiempo = date.split("T")[1].split(".")[0].split(":");
        const suffix = tiempo[0] >= 12 ? 'PM' : 'AM';
        return `${stringDate} ${tiempo[0]}:${tiempo[1]} ${suffix}`;
    }

    const handlePageClick = (page) => {
        setPage(page.selected+1);
    };

    return {
        loading,
        setLoading,
        data,
        getSeguimiento,
        buscar,
        setBuscar,
        page,
        setPage,
        rows,
        setRows,
        columns,
        modalMessage,
        setModalMessage,
        totalPages,
        handlePageClick
    }
}

export default useSeguimientoLecturaClaves;