import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "./Modal";
import Spinner from "../Loadig";
import DataTable from "../datatable/DataTable";
import Catalogo from "../../Services/Catalogos/Catalogo";
import { SelectField } from "../SelectField";
import { TextAreaField } from "../TextAreaField";
import { useLocation, useParams } from "react-router-dom";
import DSystemsServicios from "../../Services/DatamovilSystems/ServiciosDatamovil";
import { message } from "../../Utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { increment } from "../../Redux/Slice/reloadSlice";

const AgregarServicioModal = ({ item, setIsOpenModalPago, isOpenModalPago, handleGetServicesByGeneralId }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { count } = useSelector((state) => state.reloadSlice);
    const initial = {
        generalId: state.generalId,
        tipoServicioId: '',
        comentarios: '',
        procesoId: 8,
        tipoSolicitudId: "E9C872CE-9107-4EB0-8D6E-D82A6B5421E7",
        subprocesoId: 1,
        siniestroId:id
    };

    const columns = [
        { field: 'id', headerName: 'Folio' },
        { field: 'id', headerName: 'Tipo de Servicio' },
        { field: 'id', headerName: 'Técnico' },
        { field: 'id', headerName: 'Comentarios' },
        { field: 'id', headerName: 'Estatus' },
        { field: 'id', headerName: 'Último movimiento' },
    ];

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [servicios, setServicios] = useState([]);

    useEffect(() => {
        handleGetAllServices();
    }, []);

    const handleGetAllServices = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Servicios')
            .then(resp => {
                let items = resp.data.map(item => {
                    return {
                        value: item.TipoServicioId,
                        label: item.TipoServicio
                    }
                });
                setServicios(items);
            })
            .catch(err => {

            });
    };

    const handleSaveData = async (values) => {
        await DSystemsServicios.postServicio(values)
            .then(resp => {
                if(resp.data.ban === 1){
                    dispatch(increment({ count: count }));
                    toast.success(message('Servicio registrado correctamente'));
                    handleGetServicesByGeneralId();
                    setIsOpenModalPago(false);
                }else{
                    toast.error(message("Error!",resp.data.message));
                }               
            })
            .catch(err => {
                console.error(err);
                toast.error(message('No se pudo registrar el servicio, intente más tarde'));
            })
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
            // validationSchema={validate}
            >
                {({ isValid, values, setFieldValue }) => (
                    <>
                        <Modal isOpen={isOpenModalPago} color='#fff' width={500}>
                            <section className='my-4 mx-4'>
                                <span className='fs-6 fw-bold'><strong>Solicitud de servicio</strong></span>
                                <Form>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <SelectField
                                                        label="Tipo de Servicio"
                                                        disabled={initialValue?.concluido}
                                                        name="tipoServicioId"
                                                        items={servicios}
                                                        onChange={(event) => {
                                                            setFieldValue("tipoServicioId", event.value);
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <TextAreaField
                                                        id="comentarios"
                                                        label="Comentarios"
                                                        className="form-control"
                                                        rows="3"
                                                        name="comentarios"
                                                        type="text"
                                                        holder="Escribe"
                                                    />
                                                </div>
                                            </div>
                                            <section className='d-flex justify-content-end'>
                                                <div className="row mt-4">
                                                    <div className="col-auto d-flex">
                                                        <button
                                                            type='reset'
                                                            id="AT_BtnCloseModal"
                                                            className='btn btn-outline me-2'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            onClick={() => { setIsOpenModalPago(false) }}
                                                        >
                                                            Cancelar
                                                        </button>
                                                        <button
                                                            type='reset'
                                                            id="AT_BtnCloseModal"
                                                            className='btn'
                                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                            onClick={() => { handleSaveData(values); }}
                                                        >
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </Modal>
                        {
                            loading &&
                            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        }
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default AgregarServicioModal;