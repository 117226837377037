import axios from '../../Utils/ApiService';

const CobranzaService = {
    /* SEGUIMIENTO COBRANZA */
    seguimientoAccesorios: (data) => {
        return axios.get(`/cartera/GetCobranzaFinanciamiento?Buscar=${data.buscar}&codConcepto=${data.codConcepto}&codProceso=${data.codProceso}&FechaInicio=${data.fechaInicio}&FechaFin=${data.fechaFin}&Page=${data.page}&Rows=${data.rows}`);
    },
    /* REPORTE SEGUIMIENTO COBRANZA */
    reporteAccesorios: (data) => {
        return axios.get(`/cartera/ExportCobranzaFinanciamiento?Buscar=${data.buscar}&codConcepto=${data.codConcepto}&codProceso=${data.codProceso}&FechaInicio=${data.fechaInicio}&FechaFin=${data.fechaFin}`, { responseType: 'blob' });
    },
    getAllEstados: async () => {
        return axios.post(`/cartera/GetEstados`);
    },
    getMunicipios: async (data) => {
        return axios.post(`/cartera/GetMunicipios`, data);
    },
    getColonias: async (data) => {
        return axios.post(`/cartera/GetColonias`, data);
    },
    getGetZonaReport: async () => {
        return axios.post(`/cartera/GetZonaReport`);
    },
    getAllZonaHead: async () => {
        return axios.post(`/cartera/GetAllZonaHead`);
    },

    getAllZonaItemByField: async (data) => {
        return axios.post(`/cartera/GetAllZonaItemByField`, data);
    },
    postCreateZonaItemBatch: async (data) => {
        return axios.post(`/cartera/CreateZonaItemBatch`, data);
    },
    getEjecutivosFullDetail: async () => {
        return axios.post(`/cartera/GetAllEjecutivosWithFullDetail`);
    },
    getAllCobranzaEventoItem: async()=>{
        return axios.post(`/cartera/GetAllCobranzaEventoItem`);

    },
    getAllUsersNotInCobranza: async () => {
        return axios.post(`/cartera/GetAllUsersNotInCobranza`);
    },
    createEjecutivoCobranza: async (data) => {
        return axios.post(`/cartera/CreateEjecutivoCobranza`, data);
    },
    reAsignarCarga: async (data) => {
        return axios.post(`/cartera/ReAsignarCargaEjecutivosCobranza`, data);
    },
    UpsertZonasEjecutivos: async (data) => {
        return axios.post(`/cartera/UpsertZonasEjecutivos`, data);
    },
    distribucionDeCarga: async (data) => {
        return axios.post(`/cartera/DistribuirEjecutivosCobranza`, data);
    },
    /* SEGUIMIENTO COBRANZA */
    getAllEjecutivosWithFullDetail: (data) => {
        return axios.post(`/cartera/GetAllEjecutivosWithFullDetail`, data);
    },
    /* PROMESAS DE PAGO */
    getAllPromesasDePago: () => {
        return axios.get(`/Cartera/GetAllPromesa_de_Pago`);
    },
    getAllPromesa_de_PagoxPeriodo: (data) => {
        return axios.post(`/Cartera/GetAllPromesa_de_PagoxPeriodo`, data);
    },
    // getAnotherExcelReport: (data) => {
    //     return axios.post(`/Cartera/GetAnotherExcelReport`, data);
    // },
    getAllMotivosPromesa: () => {
        return axios.get(`/Cartera/GetAllMotivoPromesa`);
    },
    getAllEstatusPromesa: () => {
        return axios.get(`/Cartera/GetAllPromesaStatus`);
    },
    getAllTipoResultado: () => {
        return axios.get(`/Cartera/GetAllTipoResultado`);
    },
    getAllMotivoVisita: () => {
        return axios.get(`/Cartera/GetAllMotivoVisita`);
    },
    getAllRelacionSolicitante: () => {
        return axios.get(`/Cartera/GetAllRelacionSolicitante`);
    },
    getAllTipoContacto: () => {
        return axios.get(`/Cartera/GetAllTipoContacto`);
    },
    getAllSubTipoContacto: () => {
        return axios.get(`/Cartera/GetAllSubTipoContacto`);
    },
    getAllMotivoIngresoCartera: () => {
        return axios.get(`/Cartera/GetAllMotivoIngresoCartera`);
    },
    getAllTipoOperacion: () => {
        return axios.get(`/cartera/GetAllTipoOperacion`);
    },
    getAllOperacionResultado: () => {
        return axios.get(`/cartera/GetAllOperacionResultado`);
    },
    getxNivelOperacionResultado: (data) => {
        return axios.post(`/Cartera/GetxNivelOperacionResultado`, data);
    },
    getAllMotivoEliminacion: () => {
        return axios.get(`/cartera/GetAllMotivoEliminacion`);
    },
    getAllUsuarios: (page, rows, IdRol, status) => {
        return axios.get(`/Functions/GetAllUsuarios?page=${page}&rows=${rows}&IdRol=${IdRol}&Status=${status}&Buscar`);
    },
    eliminaPromesaPago: (data) => {
        return axios.post(`/Cartera/EliminaPromesa_de_Pago`, data);
    },
    getAllSeguimientoContactosLogs: (data) => {
        return axios.post(`/Cartera/GetAllContactoSeguimientoLogByContrato`, data);
    },
    eliminaPromesaPagoAscendes: (data) => {
        return axios.post(`/Cartera/EliminaPromesaPago`, data)
    },
    nuevaReferenciaContacto: (data) => {
        return axios.post(`/Cartera/NuevaReferenciaContacto`, data)
    },
    getInformacionContactos: (data) => {
        return axios.post(`/Cartera/GetInformacionContactos`, data)
    },
    
    getAllContactoReferenciaDireccionByField: (data) => {
        return axios.post(`/Cartera/GetAllContactoReferenciaDireccionByField`, data)
    },
    createContactoReferenciaDireccion: (data) => {
        return axios.post(`/Cartera/CreateContactoReferenciaDireccion`, data)
    },
    getDatosdeContactos: (data) => {
        return axios.post(`/Cartera/DatosdeContactos`, data)
    },
    createNuevoDatoContacto: (data) => {
        return axios.post(`/Cartera/NuevoDatoContacto`, data)
    },
    createContactoSeguimientoLog: (data) => {
        return axios.post(`/Cartera/CreateContactoSeguimientoLog`, data)
    },
    getAllParametrosByField: (data) => {
        return axios.post(`/Cartera/GetAllParametrosByField`, data)
    },
    getRowsIteractions: (data) => {
        return axios.post(`/Cartera/GetRowsIteractions`, data)
    },
    actualizaVisitabyContrato: (data) => {
        return axios.post(`/Cartera/ActualizaVisitabyContrato`, data)
    },
    getValidacionUltimaAct: (data) => {
        return axios.post(`/Cartera/GetValidacionUltimaAct`, data)
    },
    getValidacionTipoResultado: (data) => {
        return axios.post(`/Cartera/GetValidacionTipoResultado`, data)
    },
    getAllMotivoEliminacionDatoContacto: (data) => {
        return axios.post(`/Cartera/GetAllMotivoEliminacionDatoContacto`, data)
    },
    eliminaDatoContacto: (data) => {
        return axios.post(`/Cartera/EliminaDatoContacto`, data)
    },
    //Create Promesa de pago
    creaPromesa_de_Pago: (data) => {
        return axios.post(`/Cartera/CreaPromesa_de_Pago`, data)
    },
    creaAgendaPromesaPago: (data) => {
        return axios.post(`/Cartera/AgendaPromesaPago`, data)
    },
    actualizaPromesa_de_Pago: (data) => {
        return axios.put(`/Cartera/ActualizaPromesa_de_Pago`, data)
    },

    // RUTA DE COBRANZA
    GetAllRutasCobranza: () => {
        return axios.post(`/cartera/GetAllCobranzaRutaHead`)
    },
    CreateNewRoute: (data) => {
        return axios.post(`/cartera/UpsertCobranzaRutaHead`, data)
    },
    GetAllMotivosEliminacionContratoEC: () => {
        return axios.post(`/cartera/GetAllMotivoEliminacionContratoEC`)
    },
    GetAllMotivosEliminacionContratoJC: () => {
        return axios.post(`/cartera/GetAllMotivoEliminacionContratoJC`)
    },
    GetAllEjecutivosWithContratosSinAsignar: () => {
        return axios.post(`/cartera/GetAllEjecutivosWithContratosSinAsignar`);
    },
    GetNextFolioRutaHead:() => {
        return axios.post(`/cartera/GetNextFolioRutaHead`);
    },

    // resguardo unidad
    getAllMotivoResguardo: (data) => {
        return axios.post(`/cartera/GetAllMotivoResguardo`, data)
    },
    getAllFormaArribo: (data) => {
        return axios.post(`/cartera/GetAllFormaArribo`, data)
    },
    getAllUbicacionEntrega: (data) => {
        return axios.post(`/cartera/GetAllUbicacionEntrega`, data)
    },
    getAllUbicacion: (data) => {
        return axios.post(`/cartera/GetAllUbicacion`, data);
    },
    CreateVisitaDomiciliaria: (data) => {
        return axios.post(`/cartera/UpsertCobranzaVisitaDom`, data)
    },
    CreateRecoleccionUnidad: (data) => {
        return axios.post(`/cartera/UpsertCobranzaRecoleccionUnidad`, data)
    },
    createUpsertCobranzaResguardoUnidad: (data) => {
        return axios.post(`/cartera/UpsertCobranzaResguardoUnidad`, data)
    },
    DeleteCobranzaResguardoCosto: (data) =>{
        return axios.post(`/cartera/DeleteCobranzaResguardoCosto`, data)
    },
    createUpsertCobranzaPromesaPago: (data) => {
        return axios.post(`/cartera/UpsertCobranzaPromesaPago`, data)
    },
    AgendaPromesaPago: (data) => {
        return axios.post(`/cartera/AgendaPromesaPago`, data)
    },
    GetAllUbicacionesCliente: (data) => {
        return axios.post(`/cartera/GetCustomerGeolocation`, data)
    },
    GetAllMotivoBoletinado: () => {
        return axios.post(`/cartera/GetAllMotivoBoletinado`)
    },
    CreateBoletinarCliente: (data) => {
        return axios.post(`/cartera/SiniestroClienteBoletinarCrear`, data)
    },
    ClienteBoletinarGet: (data) => {
        return axios.get(`/cartera/siniestroClienteBoletinarGet?siniestroId=${data}`);
    },
    GetAnyPdf: (data) => {
        return axios.post(`/cartera/GetAnyPdf`, data, { responseType: 'blob' })
    },
    

    GetAllContactoSeguimientoLog: (data) => {
        return axios.post(`/Cartera/GetAllContactoSeguimientoLog`, data)
    },
    GetMotivoEliminacion: async () => {
        return axios.get(`/Cartera/GetAllMotivoVisita`)
    },
    GetAllEmpresas: (data) => {
        return axios.post(`/cartera/GetAllEmpresas`, data)
    },

    //Seguimiento Boletinado
    GetSeguimientoBoletinado: async () => {
        return axios.get(`/Cartera/GetSeguimientoBoletinado`)
    },
    GetAllBoletinadoMotivoRechazo: async () => {
        return axios.get(`/Cartera/GetAllBoletinadoMotivoRechazo`)
    },
    AutorizarRechazarBoletinado: (data) => {
        return axios.post(`/Cartera/AutorizarRechazarBoletinado`, data)
    },
    GetAllVisitasDomiciliarias: () => {
        return axios.post(`/Cartera/GetAllCobranzaVisitaDom`)
    },
    CreateRelacionRutaDocumento: (data) => {
        return axios.post(`/Cartera/UpsertCobranzaVisitaDomDocs`, data)
    },

    CreateRelacionRutaDocumentoResguardoUnidad: (data) => {
        return axios.post(`/Cartera/UpsertResguardoUnidadAnything`, data)
    },
    ExportReport: (data) => {
        return axios.post(`/cartera/GetAnotherExcelReport`, data, { responseType: 'blob' })
    },

    //visita domiciliaria->tipo de documento
    GetAllDocumentoVisita: (data) => {
        return axios.post(`/cartera/GetAllDocumentoVisita`, data)
    },
    UpsertCobranzaRutaItem: (data) => {
        return axios.post(`/Cartera/UpsertCobranzaRutaItem`, data)
    },

    //carga colonias sepomex
    UpLoadNewColonias: (data) => {
        return axios.post(`/Cartera/UpLoadNewColonias`, data)
    },

    //obtener contratos
    GetAllCobranzaEventoItem: (data) => {
        return axios.post(`/cartera/GetAllCobranzaEventoItem`, data)
    },
    GetAllCobranzaEventoItemByField: (data) => {
        return axios.post(`/cartera/GetAllCobranzaEventoItemByField`, data)
    },

    //obtener la recoleccion de unidad guardada
    GetAllCobranzaRecoleccionUnidadByFields: (data) => {
        return axios.post(`/cartera/GetAllCobranzaRecoleccionUnidadByFields`, data)
    },

     //obtener contratos y estatus de contrato por usuario
     GetUserStats: (data) => {
        return axios.post(`/cartera/GetUserStats`, data)
    },

}

export default CobranzaService