import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { NavigaSlice, navigaSliceParam, navigaSliceLogs, navigaSliceUser, navigaSliceEtapa ,navigaSliceSyncInv,navigaSlicePrealta,
  navigaSlicedataDocs, quickActionsSlice, 
  DataVinSlice,LlamadaSlice,MotivoLlamadaSlice,GarantiaSlice,GruaSlice,loginSlice,rolSlice,
  navigaSlicePoliza,navigaSliceInstalacion,navigaSliceGestoria,
  navigaSliceEndoso, navigaSliceGarantias, ContratoSlice, LlamadasSlice, InformacionLlamadaSlice, CalendarSlice, GarantiasSlice, GruasSlice,TallerSlice,
  ListaGarantiasReparacionDetalleSlice, GarantiaReparacionSlice, GarantiaDevolucionSlice, navigateSliceEntrega, UrlSlice, EntregaSlice, DevolucionesSlice, BoletinadoSlice,
  FirmaSlice,navigaSliceCancelacionSeguro,navigaSlicePolizasCanceladas,navigaSlicePagoDesembolso,EntregaExpedienteSlice, CitaDatamovilSlice,
  navigateSliceComisiones,
  navigateSliceComisionesInventario,InformacionUltimoCreditoSlice,
  CambioPlacasSlice, AltaPlacasSlice, TarjetaCirculacionSlice, GestoriaEntregaSlice, BalanceSlice, GestoriaEncabezadoSlice,NavigaSliceEnganche,
  MenuSlice,navigateSliceSeguimientoTelefonico, NuevaAreaSlice, NavigateCobradoresSlice, NavigateSupervisionCargaSlice,
  ParametrosBusquedaSlice,
  UsuarioSeleccionadoSlice,
  ReasignarCargaNivelUnoSlice,
  ReasignarCargaNivelDosSlice,
  DetalleRutaSlice,
  SeguimientoVisitaDetalleSlice,
  navigateSliceAutorizacion,
  TipoDocumentoSlice, CalendarDynamicalSlice,
  UnidadesEnRemateSlice,reloadSlice, ContratoCardSlice  } from './Slice'
import { ElementosDataDocsSlice } from './Slice/ElementosDataDocsSlice'
import { 
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 

const rootReducer = combineReducers({
  tabNavigate:NavigaSlice.reducer,
  tabNavigateParam:navigaSliceParam.reducer,
  tabNavigateLogs:navigaSliceLogs.reducer,
  tabNavigateUser:navigaSliceUser.reducer,
  tabNavigateEtapa:navigaSliceEtapa.reducer,
  tabNavigateSyncInv:navigaSliceSyncInv.reducer,
  navigaSlicePrealta:navigaSlicePrealta.reducer,
  navigaSlicedataDocs:navigaSlicedataDocs.reducer,
  quickActionsSlice:quickActionsSlice.reducer,
  loginSlice:loginSlice.reducer,
  rolSlice:rolSlice.reducer,
  tabNavigateSlicePoliza:navigaSlicePoliza.reducer,
  tabNavigateInstalaciones:navigaSliceInstalacion.reducer,
  tanNavigateSliceGestoria:navigaSliceGestoria.reducer,
  tabNavigateSliceEntrega:navigateSliceEntrega.reducer,
  tabNavigateSliceEndoso:navigaSliceEndoso.reducer,
  tabActiveGarantias:navigateSliceEntrega,
  contrato: ContratoSlice.reducer,
  listaLlamadas: LlamadasSlice.reducer,
  InformacionLlamada: InformacionLlamadaSlice.reducer,
  CalendarSlice: CalendarSlice.reducer,
  DataVinSlice : DataVinSlice.reducer,
  LlamadaSlice : LlamadaSlice.reducer,
  MotivoLlamadaSlice : MotivoLlamadaSlice.reducer,
  GarantiaSlice : GarantiaSlice.reducer,
  GruaSlice : GruaSlice.reducer,
  GarantiasSlice : GarantiasSlice.reducer,
  GruasSlice : GruasSlice.reducer,
  TallerSlice : TallerSlice.reducer,
  ListaGarantiasReparacionDetalleSlice :ListaGarantiasReparacionDetalleSlice.reducer,
  GarantiaReparacionSlice : GarantiaReparacionSlice.reducer,
  GarantiaDevolucionSlice : GarantiaDevolucionSlice.reducer,
  UrlSlice: UrlSlice.reducer,
  EntregaSlice : EntregaSlice.reducer,
  DevolucionesSlice: DevolucionesSlice.reducer,
  BoletinadoSlice: BoletinadoSlice.reducer,
  FirmaSlice: FirmaSlice.reducer,
  tabCancelacionSeguro:navigaSliceCancelacionSeguro.reducer,
  tabNavigatePolizaCancelada:navigaSlicePolizasCanceladas.reducer,
  tabNavigaSlicePagoDesembolso: navigaSlicePagoDesembolso.reducer,
  EntregaExpedienteSlice: EntregaExpedienteSlice.reducer,
  CitaDatamovilSlice: CitaDatamovilSlice.reducer,
  tabNavigateComisiones:navigateSliceComisiones.reducer,
  tabNavigateComisionesInventario:navigateSliceComisionesInventario.reducer,
  InformacionUltimoCreditoSlice: InformacionUltimoCreditoSlice.reducer,
  CambioPlacasSlice: CambioPlacasSlice.reducer,
  AltaPlacasSlice: AltaPlacasSlice.reducer,
  TarjetaCirculacionSlice: TarjetaCirculacionSlice.reducer,
  GestoriaEntregaSlice: GestoriaEntregaSlice.reducer,
  BalanceSlice: BalanceSlice.reducer,
  GestoriaEncabezadoSlice: GestoriaEncabezadoSlice.reducer,
  tabActiveEnganche:NavigaSliceEnganche.reducer,
  changeMenu:MenuSlice.reducer,
  navigateSliceSeguimientoTelefonico: navigateSliceSeguimientoTelefonico.reducer,
  nuevaAreaSlice: NuevaAreaSlice.reducer,
  tabCobradoresSlice: NavigateCobradoresSlice.reducer,
  tabSupervisionCargaMenu: NavigateSupervisionCargaSlice.reducer,
  ParametrosBusquedaSlice: ParametrosBusquedaSlice.reducer,
  UsuarioSeleccionadoSlice: UsuarioSeleccionadoSlice.reducer,
  ReasignarCargaNivelUnoSlice: ReasignarCargaNivelUnoSlice.reducer,
  ReasignarCargaNivelDosSlice: ReasignarCargaNivelDosSlice.reducer,
  DetalleRutaSlice: DetalleRutaSlice.reducer,
  SeguimientoVisitaDetalleSlice: SeguimientoVisitaDetalleSlice.reducer,
  navigateSliceAutorizacion: navigateSliceAutorizacion.reducer,
  TipoDocumentoSlice: TipoDocumentoSlice.reducer,
  UnidadesEnRemateSlice: UnidadesEnRemateSlice.reducer,
  ElementosDataDocsSlice: ElementosDataDocsSlice.reducer,
  UnidadesEnRemateSlice: UnidadesEnRemateSlice.reducer,
  reloadSlice:reloadSlice.reducer,
  CalendarDynamicalSlice: CalendarDynamicalSlice.reducer,
  ContratoCardSlice: ContratoCardSlice.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage, 
  whitelist: ['CalendarDynamicalSlice', 'ContratoCardSlice'] // solo persiste este slice
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(Store);