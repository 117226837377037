import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setItemUnidadesRemate, setNombreUsuario, setLimpiarNombreUsuario, setDataCobradores } from '../../Redux/Slice/UnidadesEnRemateSlice';
import UnidadesRemateService from '../../Services/Inventario/UnidadesEnRemateService';
import CobranzaService from '../../Services/Cobranza/Cobranza';

const useUnidadesEnRemateInventario = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const columns = [
        { field: 'vin', headerName: 'VIN', width: 200 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'proceso', headerName: 'Proceso', width: 200 },
        { field: 'subproceso', headerName: 'Subproceso', width: 200 },
        { field: 'marca', headerName: 'Marca', width: 200 },
        { field: 'modelo', headerName: 'Modelo', width: 130 },
        { field: 'year', headerName: 'Año', width: 80 },
        { field: 'ingreso_remate', headerName: 'Ingreso a remate', width: 150 }
    ];

    const [textoBusqueda, setTextoBusqueda] = useState("")
    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{}]);
    const [pageRows, setpageRows] = useState(10);
    const [filtroProceso, setProceso] = useState('')

    const [dataFiltro, setDataFiltro] = useState({
        estatus: "",
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });

    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

    const dataProceso = [
        { value: "", label: "Procesos"},
        { value: 0, label: "Seguro"},
        { value: 1, label: "Proceso 2"},
        { value: 2, label: "Proceso 3"}
    ]

    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        handleGetNombreEjecutivos()
        handleGetSeguimientoContrato();
    }, [])

    //Filtro avanzado
    useEffect(() => {
        if(dataFiltro.busquedaAvanzada){
            setCurrentData(data)
            // Filtra los datos según los filtros seleccionados
            const filteredData = data.filter(item => {
                let fechaFiltrada = true
                if (dataFiltro.busquedaAvanzada && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {
                    const FechaVencimiento = new Date(dataFiltro.fechaFin)
                    const FechaInicio = new Date(dataFiltro.fechaInicio)
                    const fechaItem = new Date(item.fechaAlta)
                    fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
                }
                const nameMatches = dataFiltro.strAvanzado === '' || (item.vin && item.vin.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
                    || (item?.contrato && item?.contrato.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
                    || (item?.proceso && item?.proceso.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
                    || (item?.subproceso && item?.subproceso.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
                    || (item?.marca && item?.marca.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
                    || (item?.modelo && item?.modelo.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
                    || (item?.year && item?.year.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()));

                if(dataFiltro.strAvanzado === '' && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== ""){
                    return (dataFiltro.busquedaAvanzada === false || fechaFiltrada)
                }else if(dataFiltro.strAvanzado !== '' && dataFiltro.fechaFin === "" && dataFiltro.fechaInicio === ""){
                    return (nameMatches)
                }else{
                    return (nameMatches || (dataFiltro.busquedaAvanzada === false || fechaFiltrada))
                }
            })

            const indexOfLastItem = currentPage * pageRows
            const indexOfFirstItem = indexOfLastItem - pageRows
            const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
            setCurrentData(newData)

            const newTotalPages = Math.ceil(filteredData.length / pageRows)
            setPageCount(newTotalPages)
            setDataFiltro({
                estatus: "",
                strAvanzado: "",
                fechaInicio: "",
                fechaFin: "",
                concluido: false,
                busquedaAvanzada: false
            })
        }
    }, [dataFiltro])

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        let filteredData = data.filter(item => {
            const nameMatches = textoBusqueda === '' || (item.vin && item.vin.toLowerCase().includes(textoBusqueda.toLowerCase()))
                || (item?.contrato && item?.contrato.toLowerCase().includes(textoBusqueda.toLowerCase()));
            return (
                nameMatches &&
                (filtroProceso === "" || item.proceso === filtroProceso || filtroProceso === "Procesos")
            )
        })
        const indexOfLastItem = currentPage * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)
    
        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)
    }, [currentPage, textoBusqueda, pageRows, data, filtroProceso])

    const handleGetSeguimientoContrato = async () => {
        let NewData = []
        setLoading(true);
        const data = {
            "verb": "string",
            "id": "valuacion",
            "field": "string",
            "value": "string",
            "level": -1,
            "flag": true,
            "anyObject": {},
            "kvp": {}
        }

        // await CobranzaService.getAllEjecutivosWithFullDetail(data)
        await UnidadesRemateService.GetAllValuaciones(data)
            .then(res => {
                if(res.data.callSucceded){
                    res.data.responseData?.map(item=>{
                        NewData.push({
                            vin: item.vehiculo.vin,
                            contrato: item.general.numContrato,
                            proceso: item.proceso,
                            subproceso: item.subProceso,
                            marca: item.vehiculo.nombreMarca, //No hay campo marca
                            modelo: item.vehiculo.nombreModelo,
                            year: item.vehiculo.nombreYear,
                            ingreso_remate: item.vehiculo.ingreso_remate,//No hay campo ingreso a remate
                            fechaAlta: item.fechaAlta,
                            item: item
                        },)
                    })
                }
                setLoading(false);
            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
            setData(NewData)
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
        handleGetSeguimientoContrato();
    };

    const handleDetails = (value) => {
        dispatch(setLimpiarNombreUsuario())
        dispatch(setItemUnidadesRemate(value.item))
        if(value?.item?.valoracionUnidad !== null){
            dispatch(setNombreUsuario(value?.item?.valoracionUnidad?.usuarioDetalle?.names))
        }
        navigate(`/inventario/unidades-en-remate/detalle`)
        // handleGetNombre(value.item)
    }

    const handleDownloadFile = async () => {
        let datosAExportar = []
        let nombreArchivo = `UnidadesEnRemateInventario`

        data.map(item=>{
            datosAExportar.push({
                "VIN":item?.vin === null ? "-" : item?.vin,
                "contrato": item?.contrato === null ? "-" : item?.contrato,
                "proceso": item?.proceso === null ? "-" : item?.proceso,
                "subproceso": item?.subproceso === null ? "-" : item?.subproceso,
                "claveMarca": item?.item?.vehiculo === null ? "-" : item?.item?.vehiculo?.claveMarca === null ? "-" : item?.item?.vehiculo?.claveMarca,
                "modelo": item?.modelo === null ? "-" : item?.modelo,
                "year": item?.year === null ? "-" : item?.year,
                "fechaAlta": item?.item?.fechaAlta === null ? "-" : item?.item?.fechaAlta,
                "usuarioAlta": item?.item?.usuarioAlta === null ? "-" : item?.item?.usuarioAlta,
                "usuarioUpd": item?.item?.usuarioUpd === null ? "-" : item?.item?.usuarioUpd,
                "generalId": item?.item?.general?.generalId === null ? "-" : item?.item?.general?.generalId,
                "valuacionId": item?.item?.valuacionId === null ? "-" : item?.item?.valuacionId,
                "valuacionInicioId": item?.item?.valuacionInicio === null ? "-": item?.item?.valuacionInicio?.valuacionInicioId === null ? "-" : item?.item?.valuacionInicio?.valuacionInicioId,
            })
        })
    
        let reporte = {
            "verb": "string",
            "id": nombreArchivo,
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": datosAExportar,
            "kvp": {}
        }
    
        console.log(reporte)
    
        setLoading(true);
        await CobranzaService.ExportReport(reporte)
            .then(res => {
                var FileSaver = require('file-saver');
                FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);
        
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
      }

    const handleGetNombre = async(element)=>{
        setLoading(true)
        await CobranzaService.getAllEjecutivosWithFullDetail()
            .then(res => {
                if(res.data.callSucceded){
                    res.data.responseData?.map(item => {
                        if(element.valoracionUnidad !== null && item.userid.toLowerCase() === element.valoracionUnidad?.usuario.toLowerCase()){
                            dispatch(setNombreUsuario(item.user_detail.names))
                        }
                    })
                }
                navigate(`/inventario/unidades-en-remate/detalle`)
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
                console.log(`ERROR -- ${e}`)
            })
    }

    const handleGetNombreEjecutivos = async()=>{
        setLoading(true)
        let NewData = [];
        await CobranzaService.getAllEjecutivosWithFullDetail()
            .then(res => {
            if(res.data.callSucceded){
                res.data.responseData?.map(item => {
                    NewData.push({id:item.userid, nombre:item.user_detail.names})
                })
            }
            setLoading(false);
            })
            .catch(e => {
            setLoading(false);
            console.log(`ERROR -- ${e}`)
            })
        dispatch(setDataCobradores(NewData));
    }

    return {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        setProceso,
        setpageRows,
        dataProceso,
        handleDownloadFile,
        dataFiltro,
        setDataFiltro,
        openModalAvanzado, 
        setOpenModalAvanzado
    }
}

export default useUnidadesEnRemateInventario