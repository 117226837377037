import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import Modal from '../../../Components/Modal/Modal';
import { SelectField } from '../../../Components/SelectField';
import { consecutive } from '../../../Utils/functions';

const ModalInstalacionConfirCartera = ({ isOpen, setIsOpen, item, handleGetAll, submit }) => {
    const refBtn = useRef();        
    let date = new Date();
    let mes = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    const [documentossubidos, setDocumentosSubidos] = useState(0)
    console.log("satte modal conf->",item)
    let initial = {
        // filename: item.card ? `${item.folio}.${item.filename.split(".").pop()}`:item.filename,
        filename: `${item.item.prefijoEtapa}${item.item.prefijo}${year.toString().slice(2,4)}${mes >= 10 ? mes : '0' + mes}${day >= 10 ? day : '0' + day}-${consecutive((item.item.documentosSubidos ? item.item.documentosSubidos.length : item.item.totalSubidos) + 1)}.${item.filename.split(".").pop()}`,
        legibilidad: false        
    }    
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        filename: Yup.string()
            .required("El campo es requerido."),
    });

    const dataModal = [
        { label: "Separado", value: "Separado" },
        { label: "Liberado", value: "Liberado" },
    ];
    
    const handleAddEdit = async (item) => {
        console.log(item)
        submit(item);

        // setIsOpen(false);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                refBtn.current.disabled = true;
                handleAddEdit(values);
            }}
        >
            {({ setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <div className='container-buttons'>
                        <h5><strong>Completa la carga de archivo</strong></h5>
                     
                        <Form>
                            {/* <div className="mb-3 mt-4">
                                <label htmlFor="staticEmail" className="col-sm-6 col-form-label">Confirmar legibilidad</label>
                                <div className="col-ms-12 form-check form-switch">
                                    <label className="form-check-label">{values?.status ? "Activo" : "Inactivo"}</label>
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                </div>
                            </div> */}
                            <div className="mb-3 row">
                                <label style={{ fontSize: '14px' }}><strong>1. Confirmar legibilidad</strong></label>
                                <span style={{fontSize:'13px'}}>
                                ¿Seguro que el documento es legible? Se debe revisar y confirmar la legibilidad del archivo.
                                </span>
                                <div className="col-sm-12 my-2">
                                    <TextField disabled={item.card} label="Nombre del archivo:" name="filename" type="text" holder="Escribe" />
                                </div>
                                <div className="col-ms-12 mb-2">                                    
                                    <Field id="AT_Estatus" className="form-check-input" type="checkbox" name="legibilidad" />
                                    <span className='ms-2'>  El documento es legible</span>
                                </div>

                                {/* <label style={{ fontSize: '14px' }}><strong>2. Establece el estado del vehiculo</strong></label>
                                <span style={{fontSize:'13px'}}>
                                A continuación debes indicar si la unidad será separaba o liberada.
                                </span>
                                <div className="col-sm-12 mt-2">
                                    <SelectField id="estadoPM" label="Resultado" name="estadoId" items={dataModal} onChange={(event) => {
                                        setFieldValue("estadoId", event.value);
                                    }} />
                                </div> */}
                            </div>
                            <section className='my-3 d-flex justify-content-end'>
                                <button className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button className='btn mx-2' ref={refBtn} type="submit" disabled={!values.legibilidad}>Confirmar</button>
                            </section>
                        </Form>
                        {/* <div className='d-flex justify-content-around mt-5'>
                            <button className="btn btn-outline col-5" type="button" onClick={() => { setIsOpen(false) }}>Regresar</button>
                            <button className='btn mx-2' type="submit" disabled={!(isValid)}>Confirmar</button>
                        </div> */}
                    </div>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalInstalacionConfirCartera