import { Formik } from "formik";
import { useRef, useState } from "react";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import AsignarServiciosModal from "../../../Components/Modal/AsignarServiciosModal";
import DSAsignacionServiciosHook from "../../../Hooks/DatamovilSystems/Asignaciones/DSAsignacionServiciosHook";
import AsignacionServiciosSinAsignar from "./AsignacionServiciosSinAsignar";
import AsignacionServiciosAsignados from "./AsignacionServiciosAsignados";
import { Toaster } from "react-hot-toast";

const AsignacionServicios = () => {
    const SinAsignarRef = useRef();
    const AsignadosRef = useRef();
    const {
        refFormik,
        navigate,
        folioId,
        initialValue,
        modalMessage,
        setModalMessage,
        loading,
        asignacion,
        getAsignacion
    } = DSAsignacionServiciosHook();

    const llamarGetDataSinAsignar = () => {
        if (AsignadosRef.current) {
            console.log("Asignados");
            AsignadosRef.current.getServiciosAsignados();
        }
    };

    const llamarGetDataAsignados = () => {
        if (SinAsignarRef.current) {
            console.log("Sin Asignar");
            SinAsignarRef.current.getServiciosSinAsignar();
        }
    };


    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                        {
                            modalMessage.type === 1 &&
                            <AsignarServiciosModal
                                modalMessage={modalMessage}
                                setModalMessage={setModalMessage}
                                edit={true}
                                servicioAsignacion={asignacion?.servicioAsignacion}
                                getAsignacion={getAsignacion}
                            />
                        }
                    </Modal>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <section className="mt-2 mx-2">
                        <header style={{ height: '55px' }} className='d-flex justify-content-between align-items-center border-bottom px-2'>
                            <div>
                                <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
                                Regresar a listado de servicios
                            </div>
                        </header>
                        <div className='pb-4'>
                            <section className='mx-4 my-4'>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="row d-flex align-items-center mb-2">
                                            <div className="col-auto">
                                                <strong className='mt-2'>Asignación - {folioId}</strong>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-secondary" onClick={() => setModalMessage({ isOpen: true, type: 1})}>
                                                    <i className="ri-pencil-fill text-sm"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row col-12">
                                                    <span className="fw-bold text-sm">Técnico</span>
                                                </div>
                                                <div className="row col-12">
                                                    <span className="text-xs">{asignacion?.tecnico}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="row col-12">
                                                    <span className="fw-bold text-sm">Fecha de asignación</span>
                                                </div>
                                                <div className="row col-12">
                                                    <span className="text-xs">{ asignacion?.fechaAsignacion ? asignacion.fechaAsignacion : ' - '}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <AsignacionServiciosSinAsignar 
                                    ref={SinAsignarRef}
                                    servicioAsignacion={asignacion?.servicioAsignacion}
                                    onUpdated={llamarGetDataAsignados}
                                    />

                                <div className="d-flex align-items-center my-4">
                                    <hr className="flex-grow-1" />
                                    <span className="mx-2 btn">
                                        <i className="ri-arrow-up-down-fill"></i>
                                    </span>
                                    <hr className="flex-grow-1" />
                                </div>

                                <AsignacionServiciosAsignados 
                                    ref={AsignadosRef}
                                    servicioAsignacion={asignacion?.servicioAsignacion}
                                    onUpdated={llamarGetDataSinAsignar}
                                    />
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default AsignacionServicios;