import React from "react"
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard";
import ContratoCard from "../../../Components/ContratoCard";
import ValoracionInicialInventarioCard from "./Cards/ValoracionInicialCard";
import AutorizacionDeValorDeUnidadInventarioCard from "./Cards/AutorizacionDeValorDeUnidadCard";
import useUnidadesEnRemateDetalleInventario from "../../../Hooks/GestionDeRemate/useUnidadesEnRemateDetalleInventario";

const UnidadesEnRemateDetalleInventario = () => {
   const {
      vehiculo,
      contrato,
      loading,
      handleReturnPage
   } = useUnidadesEnRemateDetalleInventario();

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                     <i className="bx bxs-chevron-left mx-2"></i>
                     Regresar a unidades en remate
                  </div>
               </header>
               <div className="col col-11 d-flex">
                  <div className="col col-6 px-4 py-2">
                     <VehiculoInfoCard
                        VIN={vehiculo.vin}
                        verClaves = {true}
                        datosVehiculo={vehiculo}/>
                  </div>
                  <div className="col col-6 px-4 py-2">
                     <ContratoCard vin={vehiculo.vin} />
                  </div>
               </div>
               <div className="col col-11 px-4 py-2">
                  <ValoracionInicialInventarioCard />
               </div>
               <div className="col col-11 px-4 py-2">
                  <AutorizacionDeValorDeUnidadInventarioCard />
               </div>
         </div>
      </>
   )
}

export default UnidadesEnRemateDetalleInventario