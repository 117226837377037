import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField } from '../../../../Components/TextField';
import { SelectField } from '../../../../Components/SelectField';
import RecoleccionDeUnidad from "./RecoleccionDeUnidad";
import InicioDeValuacionService from '../../../../Services/Inventario/Valuacion/InicioDeValuacionService'
import CostosAdicionales from './CostosAdicionales';

const UbicacionDeVehiculo = ({ handleConcluido }) => {
    const { state } = useLocation();
    const { id } = useParams();

    let initial = {
        valuacionInicioId: "",
        valuacionId: "",
        ubicacionActual: "",
        requiereCambioUbicacion: 0,
        unidadPuedeMoverse: 0,
        ubicacionOrigen: "",
        ubicacionDestino: "",
        quienRecibe: "",
        fechaArribo: "",
        horaArribo: "",
        usuarioInicio: "",
        quienEntrega: "",
        fechaEntrega: "",
        horaEntrega: "",
        total: 0,
        concluido: false,
        fechaInicio: "",
        fechaFin: ""
    };

    const [loading, setLoading] = useState(false);
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const [initialValue, setInitialValue] = useState(initial);
    const [pageRows, setpageRows] = useState(10);
    const [disableCheck, setDisableCheck] = useState(false);
    const [boolSelect, setboolSelect] = useState([{ label: "Sí", value: true }, { label: "No", value: false }]);
    const [tipoUbicacion, setTipoUbicacion] = useState([]);

    const { refFormik } = useRef();

    useEffect(() => {
        getAll();
        handleGetTipoUbicacion();
    }, []);

    const formatDate = (fecha) => {
        const [day, month, year] = fecha.split("-");
        return `${year}-${month}-${day}`;
    };

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const day = String(today.getDate()).padStart(2, '0'); // Add leading zero if needed
        return `${year}-${month}-${day}`;
    };

    const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0'); // Add leading zero if needed
        const minutes = String(now.getMinutes()).padStart(2, '0'); // Add leading zero if needed
        return `${hours}:${minutes}`;
    };

    const handleGetTipoUbicacion = async () => {
        await InicioDeValuacionService.getdataTipoUbicacion()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.ubicacionId,
                        label: item.ubicacion
                    })
                });
                setTipoUbicacion(NewData)
            }).catch(e => {
            })
    }

    const getAll = async () => {
        setLoading(true);
        await InicioDeValuacionService.getUbicacionVehiculo(id)
            .then((resp) => {
                let ubicacionVehiculo = resp.data;
                console.log(resp.data);

                const fechaEntregaFormateada = ubicacionVehiculo.fechaEntrega
                    ? formatDate(ubicacionVehiculo.fechaEntrega)
                    : getTodayDate();

                const horaEntregaFormateada = ubicacionVehiculo.horaEntrega
                    ? formatDate(ubicacionVehiculo.horaEntrega)
                    : getCurrentTime();


                setDisableCheck(ubicacionVehiculo.concluido)
                setInitialValue({
                    ...ubicacionVehiculo,
                    fechaEntrega: fechaEntregaFormateada,
                    horaEntrega: horaEntregaFormateada, // Asigna la fecha convertida
                    concluido: ubicacionVehiculo?.concluido ?? false
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));

    }

    const validate = Yup.object({

    });

    const submitForm = async (values) => {
        setLoading(true);
        values.concluido = disableCheck
        values.procesoId = state.procesoId
        values.paso = 1
        values.porcentaje = values.requiereCambioUbicacion ? 50 : 100
        values.status = 2

        // values.fechaEntrega = values.fechaEntrega.replace(/\//g, "-");
        await InicioDeValuacionService.updateUbicacion(values)
            .then((resp) => {
                console.log(resp.data)
                setInitialValue({ ...values, concluido: disableCheck })
                //setInitialValue({ ...ubicacionVehiculo });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
        handleConcluido(values)
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setDisableCheck(isChecked)
    };

    const handleChangeStatusBtn = async () => {

    }

    return (
        <>
            <div className='row mx-md-4 my-4' >
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">

                        <div className="row mt-2" data-bs-toggle="collapse" href="#collapsePagoTramites" role="button" aria-expanded="false" aria-controls="collapsePagoTramites" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                            <div className="col-11">
                                <h6><span></span> <strong>Ubicación de vehículo</strong></h6>
                            </div>
                            <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                            <div className='col-12'>
                                <small>{initialValue.siniestroPagoTramitesStatus}</small>
                            </div>
                        </div>
                        <div className="collapse" id="collapsePagoTramites">
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                    submitForm(values);
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <>
                                        <Form >
                                            {/* <div className="separator"></div> */}
                                            <div className="row mt-2 mb-2">
                                                <div className='col-4'>
                                                    {/* <h6><strong>Ubicación de vehículo</strong></h6> */}
                                                </div>
                                                <div className="col-8">
                                                    <div className="row justify-content-end">
                                                        <div className="col-auto">
                                                            {
                                                                initialValue.concluido == true ?
                                                                    <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                                                    : <button
                                                                        className='btn'
                                                                        disabled={initialValue.concluido}
                                                                        type='submit'>
                                                                        Guardar
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mt-2 mb-2'>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <TextField label="Ubicación actual"
                                                        name="ubicacionActual"
                                                        disabled
                                                        type="text" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("ubicacionActual", event.target.value);
                                                        }} />
                                                </div>
                                                <div className='col-lg-4 col-md-4 col-sm-12'>
                                                    <SelectField
                                                        id="requiereCambioUbicacion"
                                                        label="¿Vehículo requiere cambio de ubicación?"
                                                        name="requiereCambioUbicacion"
                                                        items={boolSelect}
                                                        onChange={(event) => {
                                                            setFieldValue("requiereCambioUbicacion", event.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {values.requiereCambioUbicacion === true &&
                                                <>
                                                    <div className='pt-3'>
                                                        <h6><strong>Cambio de ubicación</strong></h6>
                                                    </div>
                                                    <div className='row mt-2 mb-2'>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <SelectField
                                                                id="unidadPuedeMoverse"
                                                                label="¿La unidad puede moverse?"
                                                                name="unidadPuedeMoverse"
                                                                items={boolSelect}
                                                                onChange={(event) => {
                                                                    setFieldValue("unidadPuedeMoverse", event.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <TextField
                                                                label="Ubicación origen"
                                                                name="ubicacionOrigen"
                                                                type="text"
                                                                disabled
                                                                holder="Escribe" onChange={(event) => {
                                                                    setFieldValue("ubicacionOrigen", event.target.value);
                                                                }} />
                                                        </div>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <SelectField
                                                                id="ubicacionDestino"
                                                                label="Ubicación destino"
                                                                name="ubicacionDestino"
                                                                items={tipoUbicacion}
                                                                onChange={(event) => {
                                                                    setFieldValue("ubicacionDestino", event.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <TextField label="¿Quien entrega?" name="quienEntrega" type="text" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("quienEntrega", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className='row mt-2 mb-2'>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <TextField label="¿Quien recibe?" name="quienRecibe" type="text"
                                                                holder="Escribe" onChange={(event) => {
                                                                    setFieldValue("quienRecibe", event.target.value);
                                                                }} />
                                                        </div>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <TextField label="Fecha de entrega" name="fechaEntrega" type="date" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("fechaEntrega", event.target.value);
                                                            }} />
                                                        </div>
                                                        <div className='col-lg-3 col-md-6 col-sm-12'>
                                                            <TextField label="Hora de entrega" name="horaEntrega" type="time" holder="Escribe" onChange={(event) => {
                                                                setFieldValue("horaEntrega", event.target.value);
                                                            }} />
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                        </Form>

                                        {
                                            values.requiereCambioUbicacion === true &&
                                            <>
                                                <CostosAdicionales
                                                    handleConcluido={{ handleChangeStatusBtn }}
                                                    valuesInit={initialValue}
                                                />

                                            </>
                                        }
                                    </>
                                )
                                }



                            </Formik>



                            <div className="row mt-2 px-4">
                                <div className="col-12 col-lg-6">
                                    <input
                                        className="form-check-input"
                                        disabled={initialValue?.concluido}
                                        type="checkbox"
                                        name="concluido"
                                        checked={disableCheck}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" style={{ marginLeft: '10px' }}>
                                        Registro concluido
                                    </label>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            <small className='text-body-tertiary'>Fecha Inicio: {initialValue.fechaInicio}</small>
                                        </div>
                                        <div className="col-auto">
                                            {initialValue.concluido && (
                                                <small className='text-body-tertiary'>Fecha Fin: {initialValue.fechaFin}</small>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {
                initialValue.requiereCambioUbicacion && initialValue.concluido &&
                <RecoleccionDeUnidad handleConcluido={handleConcluido} />

            } */}
            {
                initialValue.requiereCambioUbicacion && initialValue.concluido ? (
                    <RecoleccionDeUnidad handleConcluido={handleConcluido} />
                ) : null
            }
        </>
    )
}

export default UbicacionDeVehiculo;