import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik'
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaField } from '../../../../Components/TextAreaField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import { TextField } from '../../../../Components/TextField';
import usePreparacionPapeleria from '../../../../Hooks/Gestoria/seguimiento/usePreparacionPapeleria';

const PreparacionPapeleria = ({ handleConcluido }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [collpsableGrua, setCollpsableGrua] = useState(false);
    const { refFormik } = useRef();
    const { vin, id } = useParams();

    let {
        loading,
        initialValue,
        submitForm,
        opcionesBool
    } = usePreparacionPapeleria({ toast, handleConcluido });

    const validate = Yup.object({

    });

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <div className='d-flex flex-column background-grey' style={{ height: 'auto' }}>
                <div className='row mx-md-4 my-4' >
                    <div className="col-12 px-md-3">
                        <div className="p-4 white-wrapper mb-2">
                            <div className="row mt-2 px-4" data-bs-toggle="collapse" href="#collapseTarjetaCirculacion" role="button" aria-expanded="false" aria-controls="collapseTarjetaCirculacion" onClick={() => setCollpsableGrua(!collpsableGrua)}>
                                <div className="col-11">
                                    <h6>
                                        <span className={`semaforo semaforo-${initialValue?.concluido ? "green" : "default"}`}></span>
                                        &nbsp;
                                        <strong>Estimación de costos y confirmación</strong>
                                    </h6>
                                </div>
                                <div className="col-1 col-md-1 collapsable-icon" style={{ 'textAlign': 'right' }}>
                                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                                </div>
                                <div className='col-12'>
                                    <small>{initialValue.concluido ? "Concluido" : "En Proceso"}</small>
                                </div>
                            </div>
                            <div className="collapse" id="collapseTarjetaCirculacion">
                                <Formik
                                    innerRef={refFormik}
                                    enableReinitialize={true}
                                    validationSchema={validate}
                                    initialValues={initialValue}
                                    onSubmit={(values) => submitForm(values)}
                                >
                                    {({ isValid, values, setFieldValue, errors }) => (
                                        <>
                                            <Form>
                                                <div className="separator"></div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-3">
                                                        <small><strong>Estimación</strong></small>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                {
                                                                    initialValue.concluido ?
                                                                        <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                                            <i className="icon-dark bx bx-hide mx-1" ></i>
                                                                            Modo lectura
                                                                        </fieldset>
                                                                        : <button
                                                                            className='btn'

                                                                            type='submit'>
                                                                            Guardar
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <TextField
                                                            id="estimadoAdeudos"
                                                            disabled={true}
                                                            label="Estimado de adeudos ($)"
                                                            holder=""
                                                            name="estimadoAdeudos"
                                                            type="text"
                                                            onChange={(event) => {
                                                                setFieldValue("estimadoAdeudos", event.target.value);
                                                            }} />
                                                    </div>
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="clienteDeseaContinuarTramite"
                                                            label="¿Cliente desea continuar trámite?"
                                                            name="clienteDeseaContinuarTramite"
                                                            items={opcionesBool}
                                                            disabled={false}
                                                            onChange={(event) => {
                                                                setFieldValue("clienteDeseaContinuarTramite", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field
                                                            className="form-check-input"
                                                            disabled={false} type="checkbox"
                                                            name="clienteNotificacionAdeudo"
                                                            onClick={(event) => {
                                                                setFieldValue("clienteNotificacionAdeudo", event.target.value);
                                                            }} />
                                                        <label
                                                            className="form-check-label"
                                                            style={{ 'marginLeft': '10px' }}>
                                                            Cliente notificado de adeudos
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Programar cita</strong></small>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className='col-3'>

                                                        <TextField
                                                            disabled={true}
                                                            label="Ubicacion"
                                                            holder=""
                                                            name="nombreUbicacion"
                                                            type="text"
                                                        />

                                                    </div>
                                                    <div className="col-3">
                                                        <TextField
                                                            disabled={true}
                                                            label="Fecha y hora de cita"
                                                            holder=""
                                                            name="fechaHoraCita"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <div className="col-auto">
                                                        <button
                                                            className='btn mt-4'
                                                            disabled={false}>
                                                            Ver Calendario
                                                        </button>
                                                    </div>
                                                    <div className="col-3">
                                                        <TextField
                                                            disabled={true}
                                                            label="Autorizacion de Supervisor"
                                                            holder=""
                                                            name="FechaHoraCita"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Cita de entrega de papalería para trámite</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn btn-gray'
                                                                >
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-3">
                                                        <SelectField id="papeleriaCompleta"
                                                            label="Papelería completa"
                                                            name="papeleriaCompleta"
                                                            disabled={false}
                                                            items={opcionesBool}
                                                            onChange={(event) => {
                                                                setFieldValue("papeleriaCompleta", event.value);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <TextAreaField id="comentarios"
                                                            label="Comentarios"
                                                            disabled={false}
                                                            className="form-control col-12"
                                                            rows="3"
                                                            name="comentarios"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => {
                                                                setFieldValue("comentarios", event.target.value);
                                                            }} />
                                                    </div>
                                                </div>


                                                <div className="row mt-4 px-4">
                                                    <div className="col-auto">
                                                        <small><strong>Subir evidencia</strong></small>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <button
                                                                    className='btn btn-gray'
                                                                >
                                                                    <i className="icon-blue fa fa-file"></i> Subir documento
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-2 px-4">
                                                    <div className="col-12 col-lg-6">
                                                        <Field className="form-check-input" disabled={initialValue.concluido} type="checkbox" name="concluido" onClick={(event) => {
                                                            setFieldValue("concluido", event.target.value);
                                                        }} />
                                                        <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Papeleria completa para iniciar tramite</label>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 px-4 d-flex justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.fechaInicio !== "" &&
                                                            <small className='text-body-tertiary'>Inicio de proceso: {initialValue?.fechaInicio}</small>
                                                        }
                                                    </div>
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluido &&
                                                            <small className='text-body-tertiary'>Fin de proceso: {initialValue?.fechaFin}</small>
                                                        }
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    )
                                    }
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PreparacionPapeleria