import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Catalogo from "../../Services/Catalogos/Catalogo";
import HorariosCalendarioService from "../../Services/ConfigCalendar/HorariosCalendarioService";
import toast from "react-hot-toast";
import { message } from "../../Utils/functions";

const ConfiguracionUbicacionHook = () => {
    const { id, ubicacionId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const refFormik = useRef();
    const initial = {
        ubicacionId: null
    }

    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [ubicacion, setUbicacion] = useState("");
    const [listadoUbicaciones, setListadoUbicacion] = useState([]);
    const [infoUbicacion, setInfoUbicacion] = useState({});
    const [schedules, setSchedules] = useState([]);
    const [key, setKey] = useState(0);
    const [refreshData, setRefreshData] = useState(false);
    const [tipoServicio, setTipoServicio] = useState(state?.tipo);

    const Columns = [
        { field: 'horario', headerName: 'Horario', width: '80' },
        { field: 'lunesCheck', headerName: 'Lunes', width: '60' },
        { field: 'martesCheck', headerName: 'Martes', width: '60' },
        { field: 'miercolesCheck', headerName: 'Miércoles', width: '80' },
        { field: 'juevesCheck', headerName: 'Jueves', width: '60' },
        { field: 'viernesCheck', headerName: 'Viernes', width: '70' },
        { field: 'sabadoCheck', headerName: 'Sábado', width: '60' },
        { field: 'domingoCheck', headerName: 'Domingo', width: '70' },
    ];

    useEffect(() => {
        getUbicaciones();

        if (ubicacionId !== "0") {
            getDataUbicacion();
        }
    }, []);

    const handleChooseLocation = (value) => {
        const location = ubicaciones.filter(item => item.value === value)[0]?.label;
        const findLocation = listadoUbicaciones.filter(item => item.UbicacionId === value)[0];

        setInfoUbicacion(findLocation);
        setUbicacion(location);
        if (ubicacionId === "0") {
            setSchedules([]);
            generateSchedules(findLocation);
        }
    }

    const getUbicaciones = async () => {
        let UbicacionesEnLista = [];

        let params = {
            buscar: '',
            calendarioTipoId: id,
            page: 1,
            rows: 1000
        };
        await HorariosCalendarioService.getSeguimientoUbicaciones(params)
            .then(resp => {
                resp.data.data.forEach(item => {
                    if(item.ubicacionId !== ubicacionId) {
                        UbicacionesEnLista.push(item.ubicacionId);
                    }
                });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(async () => {
                console.log(UbicacionesEnLista)
                await Catalogo.getDropdownsByTable('Sistema.Ubicacion')
                    .then(resp => {
                        const rsUbicaciones = resp.data;
                        setListadoUbicacion(rsUbicaciones);
                        const items = [];
                        resp.data.forEach(item => {
                            if (!UbicacionesEnLista.includes(item.UbicacionId)) {
                                items.push({
                                    value: item.UbicacionId,
                                    label: item.Nombre
                                });
                            }
                            // else if(ubicacionId !== "0" && )
                        });
                        setUbicaciones(items);
                        if (ubicacionId !== "0") {
                            setInitialValue({ ubicacionId });
                            console.log(items)
                            const location = items.filter(item => item.value === ubicacionId)[0]?.label;
                            const findLocation = rsUbicaciones.filter(item => item.UbicacionId === ubicacionId)[0];
                            setInfoUbicacion(findLocation);
                            setUbicacion(location);

                            console.log(ubicacionId, findLocation, location);
                        }
                    })
                    .catch(err => {
                    });
            });
    };

    const getDataUbicacion = async () => {
        setLoading(true);

        await HorariosCalendarioService.getHorarios(id, ubicacionId)
            .then(resp => {
                console.log(resp.data[0].tipo)
                setTipoServicio(resp.data[0].tipo);
                const generatedSchedules = resp.data.map(item => {
                    return {
                        calendarioId: item.calendarioId,
                        calendarioTipoId: id,
                        ubicacionId: ubicacionId,
                        horario: item.horario,
                        domingoCheck: item.domingo,
                        lunesCheck: item.lunes,
                        martesCheck: item.martes,
                        miercolesCheck: item.miercoles,
                        juevesCheck: item.jueves,
                        viernesCheck: item.viernes,
                        sabadoCheck: item.sabado
                    }
                });
                setKey(prevKey => prevKey + 1);
                setSchedules(generatedSchedules);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const generateSchedules = (sucursalInfo) => {
        const generatedSchedules = [];

        const convertToMinutes = (time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes;
        }

        const convertToTimeFormat = (totalMinutes) => {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        };

        const startMinutes = convertToMinutes(sucursalInfo?.HorarioAntencionInicio);
        const endMinutes = convertToMinutes(sucursalInfo?.HorarioAntencionFin);


        let currentMinutes = startMinutes;
        while (currentMinutes <= (endMinutes - state?.minutos)) {
            generatedSchedules.push({
                calendarioTipoId: id,
                ubicacionId: sucursalInfo?.UbicacionId,
                horario: convertToTimeFormat(currentMinutes),
                domingoCheck: false,
                lunesCheck: false,
                martesCheck: false,
                miercolesCheck: false,
                juevesCheck: false,
                viernesCheck: false,
                sabadoCheck: false
            });
            currentMinutes += state?.minutos;
        }

        setKey(prevKey => prevKey + 1);
        setSchedules(generatedSchedules);
    }

    const handleCheckDay = (value, object, field) => {
        object[field] = value;
        let copyData = schedules;

        const findIndexItem = (element) => element.horario === object.horario;
        const index = copyData.findIndex(findIndexItem);

        copyData[index] = object;
        setSchedules(copyData);
    }

    const handleCheckAllDay = (value, day) => {
        setLoading(true);
        setRefreshData(true);
        const field = `${day}Check`;
        let copyData = schedules.map(item => {
            let newItem = item;
            newItem[field] = value;
            return newItem;
        });
        setSchedules(copyData);
        setTimeout(() => {
            setLoading(false);
            setRefreshData(false);
        }, 500);
    }

    const handleSubmit = () => {
        if (ubicacionId === "0") handleCreateHorarios();
        else handleActualizaHorarios();
    }

    const prepareDataForSubmit = () => {
        const items = schedules.map(item => {
            return {
                ...item,
                domingo: item.domingoCheck,
                lunes: item.lunesCheck,
                martes: item.martesCheck,
                miercoles: item.miercolesCheck,
                jueves: item.juevesCheck,
                viernes: item.viernesCheck,
                sabado: item.sabadoCheck
            };
        });
        return items;
    }

    const handleCreateHorarios = async () => {
        setLoading(true);
        let horarios = prepareDataForSubmit();


        let params = {
            Horarios: horarios
        }
        await HorariosCalendarioService.crearHorarios(params)
            .then(resp => {
                navigate(-1);
            })
            .catch(err => {
                toast.error(message('¡Error, no se pudieron crear los horarios!'))
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleActualizaHorarios = async () => {
        setLoading(true);
        let horarios = prepareDataForSubmit();
        let params = {
            Horarios: horarios
        }
        await HorariosCalendarioService.actualizarHorarios(params)
            .then(resp => {
                navigate(-1);
            })
            .catch(err => {
                toast.error(message('¡Error, no se pudieron actualizar los horarios!'))
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return {
        refFormik,
        initialValue,
        data,
        loading,
        navigate,
        ubicaciones,
        handleChooseLocation,
        ubicacion,
        infoUbicacion,
        schedules,
        Columns,
        handleCheckDay,
        handleSubmit,
        handleCheckAllDay,
        refreshData,
        key,
        tipoServicio
    }
}

export default ConfiguracionUbicacionHook;