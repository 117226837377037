import React, { useRef, useEffect, useState } from 'react'
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import ErrorModal from "../../../../Components/Modal/ErrorModal";
import SuccessModal from "../../../../Components/Modal/SuccessModal";
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { TextField } from "../../../../Components/TextField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import { FormatFecha, message, FechaYHoraFront } from "../../../../Utils/functions";
import { useDispatch, useSelector } from "react-redux";
import SubirDocumentoModal from "./SubirDocumentoModal";
import dataDocsServices from "../../../../Services/DataDocs/dataDocsServices";
import AuthLogin from "../../../../Utils/AuthLogin";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import VisorFile from "../../../../Components/VisorFile";
import { setIdDataDocs } from "../../../../Redux/Slice/ElementosDataDocsSlice";
import { v4 as uuidv4 } from 'uuid';
import { BrowserView, MobileView } from "react-device-detect";
import { setContrato, setItemGeneral } from "../../../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice";

const auth = new AuthLogin();
const VisitaDomiciliariaCard = React.forwardRef(({ ...props }, ref) => {
  const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
  const [collpsableGrua, setCollpsableGrua] = useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
  const [dataMotivoIngreso, setDataMotivoIngreso] = useState([])
  const [openModalSubirDocumento, setOpenModalSubirDocumento] = useState(false)
  let url = auth.UrlDataDocs();
  const [fileName, setFileName] = useState("");
  const [SelectedDocs, setSelectedDocs] = useState([]);
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [cierreVisita, setCierreVisita] = useState(false)
  const navigate = useNavigate();
  const [registradoPreviamente, setRegistradoPreviamente] = useState(false);
  const [confirmarOperacion, setConfirmarOperacion] = useState("")
  const [archivoId, setArchivoId] = useState("")
  const [infoVisitaDom, setInfoVisitaDom] = useState({})
  const [nombreDocumento, setNombreDocumento] = useState("")
  const dispatch = useDispatch();
  const [archivosCargados, setArchivosCargados] = useState([])
  const [archivoSeleccionado, setArchivoSeleccionado] = useState({})
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const userId = sessionStorage.getItem('userId').trim()
  let listaTipoDocumento = [];
  let listaMotivoIngreso = [];
  let registroConcluido = false;
  const refFormik = useRef();

  let initial = {
    id: 0,
    num_visita: contratoActual.visitas === 0 ? contratoActual.visitas + 1 : contratoActual.visitas,
    encontraste_cliente: 0,
    resultado: '',
    entregaste_documento: '',
    tipo_documento: '',
    motivo: '',
    especificacion: '',
    motivo_ingreso: '',
    comentarios: '',
    comentario_ingreso: '',
    reg_concluido: false,
    fecha_inicio: '',
    fecha_fin: ''
  };

  const [initialValue, setInitialValue] = useState(initial);
  const [loading, setLoading] = useState(false);
  const [loadingdocumentos, setLoadingdocumentos] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    isOpen: false,
    type: 1,
    title: "",
    message: "",
    data: [],
    authorizationType: 2,
    Origin: "Listado",
  });

  const GetAllMotivosIngreso = async () => {
    let NewData = []
    await CobranzaService.getAllMotivoIngresoCartera()
      .then(res => {
        res.data.responseData?.map(item => {
          NewData.push({
            value: item.id,
            label: item.nombre
          })
        })
      })
      .catch(e => {
      })
    setDataMotivoIngreso(NewData);
    listaMotivoIngreso = NewData;


    setInitialValue((prevState) => ({
      ...prevState,
      motivo_ingreso: prevState.motivo_ingreso !== ''
        ? prevState.motivo_ingreso
        : listaMotivoIngreso.find(x => x.label === prevState.motivo_ingreso)?.value,
    }));
  }

  useEffect(() => {
    console.log(itemGeneral)
    handleGetAllData();
  }, [])


  const handleGetAllData = async () => {
    setLoading(true);
    try {
      const motivosIngreso = GetAllMotivosIngreso();
      const documentoVisita = GetAllDocumentoVisita();
      const visitasDomiciliarias = GetAllVisitasDomiciliarias();
      // Usa los valores obtenidos si es necesario
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    if (confirmarOperacion !== "") {
      CreateRelacionVisitaDocumento()
      setConfirmarOperacion("")
    }
  }, [confirmarOperacion])

  const dataOpcionSiNo = [
    { value: 1, label: "Si" },
    { value: 2, label: "No" }
  ];

  const validate = Yup.object({
    encontraste_cliente: Yup.string().required("El campo es requerido."),
    resultado: Yup.string().required("El campo es requerido."),
    entregaste_documento: Yup.string().required("El campo es requerido."),
    tipo_documento: Yup.string().when('entregaste_documento', {
      is: "Si",
      then: Yup.string().required('El campo es requerido.')
    }),
    motivo: Yup.string().required("El campo es requerido."),
    especificacion: Yup.string().required("El campo es requerido."),
    motivo_ingreso: Yup.string().required("El campo es requerido."),
  });

  const handleGuardar = async (values, subirdocumento)  => {
    setLoading(true);
    if (subirdocumento) {
      setLoadingdocumentos(true)
    }

    let id = uuidv4();
    let numvisita = itemGeneral.visitaDoms?.length ? Math.max(...itemGeneral.visitaDoms.map(v => v.num_visita || 0)) + 1 : 1;
    let motivoIngreso = values.motivo_ingreso === "" || values.motivo_ingreso === undefined ? ""
      : !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(values.motivo_ingreso)
        ? values.motivo_ingreso : dataMotivoIngreso.find(x => x.value === values.motivo_ingreso)?.label;
    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "flag": false,
      "anyObject": {
        "id": values.id === 0 ? id : values.id,
        "ruta_head_id": itemGeneral.ruta_head_id,
        "ruta_item_id": itemGeneral.id,
        "evento_item_id": itemGeneral.evento_item_id,
        "evento_head_id": itemGeneral.evento_head_id,
        "num_visita": numvisita,
        "encontraste_cliente": values.encontraste_cliente === "" ? 0 : values.encontraste_cliente,
        "resultado": Number.isInteger(values.resultado) ? dataResultados.find(x => x.value === values.resultado)?.label : values.resultado,
        "entregaste_documento": (values.entregaste_documento == "Si" || values.entregaste_documento == 1) ? 1 : 2,
        "tipo_documento": !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(values.tipo_documento) ? values.tipo_documento : tipoDocumento.find(x => x.value === values.tipo_documento)?.label,
        // "motivo": values.motivo,  
        "motivo": Number.isInteger(values.motivo) ? dataMotivoNoEncontrado.find(x => x.value === values.motivo)?.label : values.motivo,  
        //"motivo": dataMotivoNoEncontrado.find(x => x.value === values.motivo)?.label,
        // "especificacion": values.especificacion,
        "especificacion": Number.isInteger(values.especificacion) ? dataEspecificacionDetalle.find(x => x.value === values.especificacion)?.label : values.especificacion,  
        "comentarios": values.comentarios,
        "motivo_ingreso": motivoIngreso,
        "comentario_ingreso": values.comentario_ingreso,
        "registro_concluido": values.reg_concluido === true ? 1 : 0,
        "fecha_inicio": values.fecha_inicio !== "" ? values.fecha_inicio : getSqlServerDateTime(),
        "fecha_fin": values.reg_concluido === true ? getSqlServerDateTime() : new Date(1990, 0, 1).toISOString().replace('T', ' ').slice(0, 23),
      }
    }

    registroConcluido = values.reg_concluido;

    console.log(data)

    await CobranzaService.CreateVisitaDomiciliaria(data)
      .then(res => {
        console.log(res)
        UpsertCobranzaRutaItem();
        handleActualizarItem(subirdocumento);
        GetAllVisitasDomiciliarias();
        toast.success(message("¡Operación exitosa!", res.data.mensaje));
        setLoading(false);
      }).catch(e => {
        setLoading(false)
        props.handleNextSteps(false);
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const UpsertCobranzaRutaItem = async () => {
    setLoading(true);
    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": false,
      "anyObject": {
        "id": itemGeneral?.id,
        "ruta_head_id": itemGeneral?.ruta_head_id,
        "evento_item_id": itemGeneral?.evento_item_id,
        "evento_head_id": itemGeneral?.evento_head_id,
        "fecha": itemGeneral?.fecha,
        "tipo_visita": itemGeneral?.tipo_visita,
        "estatus": "Proceso",
        "resultado": itemGeneral?.resultado,
        "userid": itemGeneral?.userid,
        "fecha_inicio": itemGeneral?.fecha_inicio != null ? itemGeneral?.fecha_inicio : getSqlServerDateTime(),
        "fecha_fin": itemGeneral?.fecha_fin != null ? itemGeneral?.fecha_fin : getSqlServerDateTime(),
        "cierre_visita": cierreVisita
      }
    }

    await CobranzaService.UpsertCobranzaRutaItem(data)
      .then(res => {
        setLoading(false)
      }).catch(e => {
        setLoading(false)
        props.handleNextSteps(false);
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const GetAllVisitasDomiciliarias = async () => {
    let NewData = []
    // setLoading(true)
    await CobranzaService.GetAllVisitasDomiciliarias()
      .then(res => {
        res?.data?.responseData?.map(item => {
          if (item?.ruta_item_id === itemGeneral.id) {
            if (item?.visitaDomDocs !== null) {
              item?.visitaDomDocs?.map(file => {
                if (file.binario != null) {
                  NewData.push({
                    value: file.binario.documentoId,
                    label: file.binario.nombreDocumento,
                    idFolder: file.binario.idFolder
                  })
                }
              })
            }
            dispatch(setIdDataDocs(item?.id))
            setInfoVisitaDom(item);
            let registroConcluido = Boolean(item?.registro_concluido);
            setRegistradoPreviamente(Boolean(item?.registro_concluido));
            setOpcionSeleccionada(item?.encontraste_cliente)
            setInitialValue({
              id: item?.id,
              num_visita: itemGeneral.visitaDoms?.length ? Math.max(...itemGeneral.visitaDoms.map(v => v.num_visita || 0)) : 1,
              // encontraste_cliente: registroConcluido ? (item?.encontraste_cliente === 1 ? "Si" : "No") : (item?.encontraste_cliente === "Si" ? 1 : 2),
              encontraste_cliente: registroConcluido ? (item?.encontraste_cliente == 1 ? "Si" : "No") : (item?.encontraste_cliente),
              resultado: (item?.encontraste_cliente == 1) ? (registroConcluido ? item?.resultado : dataResultados.find(x => x.label == item?.resultado)?.value) :  (item?.resultado ?? ""),
              entregaste_documento: registroConcluido ? (item?.entregaste_documento == 1 ? "Si" : "No") : (item?.entregaste_documento),
              tipo_documento: registroConcluido ? item?.tipo_documento : item.tipo_documento == "" ? "" : (listaTipoDocumento.length > 0 ? listaTipoDocumento.find(x => x.label == item?.tipo_documento)?.value : tipoDocumento.find(x => x.label == item?.tipo_documento)?.value),
              motivo:  registroConcluido ? item?.motivo : (item?.encontraste_cliente == 2 ? dataMotivoNoEncontrado.find(x => x.label == item?.motivo)?.value : "NA"),
              especificacion: registroConcluido ? item?.especificacion : (item?.encontraste_cliente == 2 ? dataEspecificacionDetalle.find(x => x.label == item?.especificacion)?.value : "NA"),
              motivo_ingreso: registroConcluido ? item?.motivo_ingreso : (listaMotivoIngreso.length > 0 ? listaMotivoIngreso.find(x => x.label == item?.motivo_ingreso)?.value : dataMotivoIngreso.find(x => x.label == item?.motivo_ingreso)?.value),
              comentarios: item?.comentarios,
              comentario_ingreso: item?.comentario_ingreso,
              reg_concluido: item?.registro_concluido == 1 ? true : false,
              fecha_inicio: item?.fecha_inicio,
              fecha_fin: item?.fecha_fin
            });

            if (registroConcluido)
              props.handleNextSteps(true);

            if (item !== null && item !== undefined) {
              if (item?.resultado !== "Promesa de auto garantía" && item?.resultado !== "Promesa de pago" && item?.resultado !== "Promesa de auto definitiva") {
                if (item?.registro_concluido === 1)
                  props.handleSetCerrarVisita(true);
                else
                  props.handleSetCerrarVisita(false);
              }
            }
          }
        })
      })
      .catch(e => {
        props.handleNextSteps(false);
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
    setArchivosCargados(NewData)
  }

  const CreateRelacionVisitaDocumento = async () => {
    const data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {
        "id": archivoId,
        "visita_dom_id": infoVisitaDom.id,
        "tipo_doc": "documento",
        "extension_doc": ".pdf",
        "nombre_doc": nombreDocumento,
        "descripcion": "",
        "url": "",
        "subido": 1,
      }
    }

    await CobranzaService.CreateRelacionRutaDocumento(data)
      .then(res => {
        toast.success(message("Documento relacionado correctamente", res.data.mensaje));
      })
      .catch(e => {
        setLoading(false)
        if (e.response !== undefined)
          toast.error(message("¡Error!", e.response.data.message));
        else
          toast.error(message("¡Error!", e.message));
      })
  }

  const dataResultados = [
    { value: 1, label: "No dan acceso" },
    { value: 2, label: "Carta visita" },
    { value: 3, label: "Carta jurídica" },
    { value: 4, label: "Cita despacho externo", },
    { value: 5, label: "Promesa de pago" },
    { value: 6, label: "Promesa de auto garantía", },
    { value: 7, label: "Promesa de auto definitiva", },
  ]

  const dataMotivoNoEncontrado = [
    { value: 1, label: "Ilocalizable" },
    { value: 2, label: "Contacto vía tercero*" }
  ]

  const dataEspecificacionDetalle = [
    { value: 1, label: "No vive ahí" },
    { value: 2, label: "Casa abandonada" },
    { value: 3, label: "Casa en venta" },
    { value: 4, label: "No existe domicilio" },
    { value: 5, label: "No trabaja ahí" },
    { value: 6, label: "No exite negocio" },
  ]

  const handleGetTokenDatadocs = async () => {
    await dataDocsServices.UpdateTokenDataDc()
      .then(res => {
        auth.handleAuthSesionDataDocs(res.data.data);
      }).catch(e => { })
  }
  const handleViewdocs = async () => {
    setLoading(true);
    setFileName(archivoSeleccionado.label);
    await handleGetTokenDatadocs();
    await axios.post(`${url}Documento/GetFileById/${archivoSeleccionado.idFolder}/${archivoSeleccionado.value}/false`, {}, {
      headers: {
        'Authorization': 'Bearer ' + auth.tokenDataDocs(),
      },
      responseType: "blob"
    })
      .then(res => {
        var file = new File([res.data], res.data.name, { type: res.data.type });
        setSelectedDocs([file])
        setLoading(false);
        setIsOpenDocs(true)
      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          toast.error(message("Error.!", e.response.data.errors?.error));
        else
          toast.error(message("Error.!", e.message));
      })
  }

  const handleVisualizarDocumentos = async () => {
    await handleViewdocs()
  }

  const handleUploadFile = async (visitaDomsId = null) => {
    // const archivoid = uuidv4();
    // if (itemGeneral.visitaDoms[0]?.visitaDomDocs != null) {
    // setArchivoId(itemGeneral.visitaDoms[0].visitaDomDocs[0].id)

    const visitaDomId = visitaDomsId || itemGeneral?.visitaDoms[0]?.id;
    navigate(`/cobranza/seguimiento-visita/datadocs/fCobranza/eVisitaD/${contratoActual?.contrato_id}/${visitaDomId}`, {
      state: { ...contratoActual, registroId: visitaDomId, vin: contratoActual.vin, nombreMarca: contratoActual?.vehiculo?.nombreMarca, nombreModelo: contratoActual?.vehiculo?.nombreModelo, nombreVersion: contratoActual?.vehiculo?.nombreVersion, nombreYear: contratoActual?.vehiculo?.nombreYear }
    });
    // }
    // else {
    //   const archivoId = uuidv4();
    //   setArchivoId(archivoId)
    //   navigate(`/cobranza/seguimiento-visita/datadocs/fCobranza/eVisitaD/${contratoActual?.contrato_id}/${archivoId}`, {
    //     state: { ...contratoActual, registroId: archivoId, vin: contratoActual.vin, nombreMarca: contratoActual?.vehiculo?.nombreMarca, nombreModelo: contratoActual?.vehiculo?.nombreModelo, nombreVersion: contratoActual?.vehiculo?.nombreVersion, nombreYear: contratoActual?.vehiculo?.nombreYear }
    //   });
    // }
  }

  const handleSubirDocumento = () => {
    const archivoId = uuidv4();
    setArchivoId(archivoId)
    setOpenModalSubirDocumento(true)
  }

  const GetAllDocumentoVisita = async () => {
    setTipoDocumento([]);
    try {
      const res = await CobranzaService.GetAllDocumentoVisita();
      const documentosVisita = res.data.responseData?.map(item => ({
        value: item.id,
        label: item.nombreDocumento,
      })) || [];
      setTipoDocumento(documentosVisita);
      listaTipoDocumento = documentosVisita;
    } catch (error) {
      console.error('Error al cargar motivos:', error);
    }
  };


  const handleActualizarItem = async (subirdocumento) => {
    setLoading(true);

    await CobranzaService.GetAllRutasCobranza()
      .then(res => {
        res?.data?.responseData?.map(item => {
          if (item?.userid.toLowerCase() === userId.toLowerCase()) {
            for (const i of item?.items) {
              if (i?.contrato !== null && i?.contrato.contrato_id === contratoActual?.contrato_id && i?.id === itemGeneral?.id) {
                dispatch(setItemGeneral(i));
                dispatch(setContrato(i?.contrato));
                if (i?.visitaDoms !== null && i?.visitaDoms !== undefined && i?.visitaDoms[0] !== null) {
                  if (i?.visitaDoms[0]?.resultado !== "Promesa de auto garantía" && i?.visitaDoms[0]?.resultado !== "Promesa de pago" && i?.visitaDoms[0]?.resultado !== "Promesa de auto definitiva") {
                    if (registroConcluido === true)
                      props.handleSetCerrarVisita(true);
                    else
                      props.handleSetCerrarVisita(false);
                  }
                }
                if (subirdocumento) {
                  handleUploadFile(i?.visitaDoms[0]?.id)
                }
              }
            }
          }
        })

        if (registroConcluido === true)
          props.handleNextSteps(true);
      }).catch(e => {
        setLoading(false);
        props.handleNextSteps(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
    setLoading(false);
  }

  function formatearFecha(fechaISO) {
    if (!fechaISO) {
      return 'Fecha no válida'; // Mensaje para fechas nulas o indefinidas
    }

    const fecha = new Date(fechaISO);

    if (isNaN(fecha.getTime())) {
      return 'Fecha no válida'; // Mensaje si la fecha no puede ser convertida
    }

    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear();
    const horas = (fecha.getHours() % 12 || 12).toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const ampm = fecha.getHours() >= 12 ? 'PM' : 'AM';

    return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos} ${ampm}`;
  }

  function getSqlServerDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <>
      <Modal isOpen={loading} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={loadingdocumentos} color="transparent"> <Spinner message={""} /> </Modal>
      <Modal isOpen={modalMessage.isOpen} color="#fff" width={660}>
        {modalMessage.type === 1 && (
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        )}
        {modalMessage.type === 2 && (<SuccessModal modalMessage={modalMessage} setModalMessage={setModalMessage} />)}
      </Modal>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "#47a066",
              color: "#FFFF",
              borderLeft: "10px solid #2f7246",
            },
          },
          error: {
            style: {
              background: "#d53f3f",
              color: "#FFFF",
              borderLeft: "10px solid #ac241a",
            },
          },
        }}
      />
      <div className="row wrapper-vehiculo">
        <div className="col-12">
          <div className="row mx-md-4 my-4">
            <div className="col-12 px-md-3">
              <div className="p-4 white-wrapper mb-2">
                <div
                  className="row mt-2 px-4"
                  data-bs-toggle="collapse"
                  href="#collapseVisitaDomiciliaria"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseRegistroTramite"
                  onClick={() => setCollpsableGrua(!collpsableGrua)}
                >
                  <div className="col-11">
                    <h6>
                      <span className={`semaforo semaforo-${initialValue.color}`} ></span>{" "}
                      <strong>Visita Domiciliaria</strong>
                    </h6>
                  </div>
                  <div className="col-1 col-md-1 collapsable-icon" style={{ textAlign: "right" }} >
                    <i className={!collpsableGrua ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                  </div>
                </div>
                <div className="collapse" id="collapseVisitaDomiciliaria">
                  <div className="separator"></div>
                  <Formik innerRef={refFormik}
                    enableReinitialize={true}
                    validationSchema={validate}
                    initialValues={initialValue}
                    onSubmit={(values) => {
                      handleGuardar(values, false);
                    }}>
                    {({ values, setFieldValue, errors, actions }) => (
                      <>
                        <Form>
                          <div className="row mt-2 justify-content-end">
                            <div className="row col-lg-3 col-sm-12 justify-content-end">
                              <div className="col-sm-6 col-lg-6">
                                <BrowserView>
                                  {/* <div className="col-auto"> */}
                                    <fieldset
                                      onClick={() => {
                                        if (registradoPreviamente) {
                                          handleActualizarItem(true)
                                        }
                                        else {
                                          handleGuardar(values, true)
                                        }
                                      }}
                                      className='btn btn-gray'>
                                      <i className="icon-light fa fa-file "></i> { registradoPreviamente ? "Ver documentos" : "Subir documento" } 
                                    </fieldset>
                                  {/* </div> */}
                                  </BrowserView>
                                </div>
                                {
                                  !registradoPreviamente 
                                  ? <div className="col-sm-6 col-lg-6">
                                      <button
                                        className="btn col-12"
                                        type="submit"
                                        // disabled={!(isValid) || registradoPreviamente}
                                        style={{
                                          padding: "10px",
                                          lineHeight: "15px",
                                        }}>
                                        Guardar
                                      </button>
                                    </div>
                                  : <div className="col-sm-6 col-lg-6">
                                      {
                                        registradoPreviamente && (
                                          <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                            <i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                        )
                                      }
                                    </div>
                                }
                            </div>
                          </div>
                          <MobileView>
                            <div className="row mt-2">
                              {registradoPreviamente && (
                                <>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <button
                                      id="btnVisualizarDocumentos"
                                      className="btn btn-outline col-12 mw-100 d-flex"
                                      type="button"
                                      onClick={handleVisualizarDocumentos}
                                      disabled={archivosCargados.length === 0}
                                      style={{
                                        padding: "10px",
                                        lineHeight: "20px",
                                        border: "none",
                                        backgroundColor: "#f0f0f0",
                                      }}>
                                      <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                      Visualizar Documentos
                                    </button>
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <SelectField
                                      id="archivoscargados"
                                      label="Archivos"
                                      name="archivoscargados"
                                      items={archivosCargados}
                                      disabled={archivosCargados.length === 0}
                                      onChange={(event) => {
                                        setArchivoSeleccionado(event)
                                      }} />
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <button
                                      id="btnSubirDocumento"
                                      className="btn btn-outline col-12 mw-100 d-flex "
                                      type="button"
                                      onClick={handleUploadFile}
                                      style={{
                                        padding: "10px",
                                        lineHeight: "20px",
                                        border: "none",
                                        backgroundColor: "#f0f0f0",
                                      }}>
                                      <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                      Subir documento
                                    </button>
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <button
                                      id="btnSubirDocumento"
                                      className="btn btn-outline col-12 mw-100 d-flex "
                                      type="button"
                                      onClick={handleSubirDocumento}
                                      style={{
                                        padding: "10px",
                                        lineHeight: "20px",
                                        border: "none",
                                        backgroundColor: "#f0f0f0",
                                      }}>
                                      <i className="ri-file-3-fill fs-4" style={{ color: "#004C97", marginRight: "8px", }} ></i>
                                      Tomar foto
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </MobileView>
                          <div className="row mt-2 mb-2 justify-content-between">
                            <div className="col-3">
                              <p><span style={{ fontSize: "14px", fontWeight: "bold" }}><strong>Detalle de visita</strong></span></p>
                            </div>
                          </div>

                          <div className="mb-3 row" >
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                              <TextField label="Número de Visita" name="num_visita" type="text" holder={0} disabled={true} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                              {
                                !registradoPreviamente
                                  ?
                                  <SelectField
                                    id="encontraste_cliente"
                                    label="¿Encontraste al Cliente?"
                                    name="encontraste_cliente"
                                    items={dataOpcionSiNo}
                                    onChange={(event) => {
                                      if (event.value === 1) {
                                        setFieldValue("encontraste_cliente", 1);
                                        setOpcionSeleccionada(1);
                                        setFieldValue("motivo", 'NA');
                                        setFieldValue("especificacion", 'NA');
                                        setFieldValue("resultado", '');
                                      }
                                      else if (event.value === 2) {
                                        setFieldValue("encontraste_cliente", 2);
                                        setOpcionSeleccionada(2);
                                        setFieldValue("resultado", 'NA');
                                        setFieldValue("motivo", '');
                                        setFieldValue("especificacion", '');
                                      }
                                      else {
                                        setFieldValue("encontraste_cliente", "");
                                        setOpcionSeleccionada(0);
                                        setFieldValue("motivo", '');
                                        setFieldValue("especificacion", '');
                                        setFieldValue("resultado", '');
                                      }
                                      setFieldValue("entregaste_documento", "");
                                      setFieldValue("tipo_documento", "");
                                    }}
                                  />
                                  : <TextField label="¿Encontraste al Cliente?" name="encontraste_cliente" type="text" holder={values.encontraste_cliente} disabled={true} />
                              }
                            </div>
                            {
                              opcionSeleccionada === 1 && (
                                <>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                        ? <SelectField
                                          id="resultado"
                                          label="Resultado"
                                          name="resultado"
                                          items={dataResultados}
                                          onChange={(event) => {
                                            setFieldValue("resultado", event.value === "" ? "" : event.label);
                                          }} />
                                        : <><TextField label="Resultado" name="resultado" type="text" holder={values.resultado} disabled={true} /></>
                                    }
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                        ? <SelectField
                                          id="entregaste_documento"
                                          label="¿Entregaste documentos?"
                                          name="entregaste_documento"
                                          items={dataOpcionSiNo}
                                          onChange={(event) => {
                                            setFieldValue("entregaste_documento", event.value === "" ? "" : event.value === 1 ? "Si" : "No");
                                            if (event.value === 1 || event.value === "") {
                                              setFieldValue("tipo_documento", "");
                                            }
                                            else {
                                              setFieldValue("tipo_documento", "");
                                            }
                                          }} />
                                        : <TextField label="¿Entregaste documentos?" name="entregaste_documento" type="text" holder={values.entregaste_documento} disabled={true} />
                                    }
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                      ? (values.entregaste_documento == "Si" || values.entregaste_documento == 1) ?
                                          <SelectField
                                            id="tipo_documento"
                                            label="Tipo de documento"
                                            name="tipo_documento"
                                            items={tipoDocumento}
                                            onChange={(event) => {
                                              setFieldValue("tipo_documento", event.value === "" ? "" : event.label);
                                            }} />
                                          : <></>
                                          : (values.entregaste_documento == 0 || values.entregaste_documento == "" || values.entregaste_documento == "No" || values.entregaste_documento == "2")
                                          ? <></>
                                          : <><br></br><TextField label="Tipo de documento" name="tipo_documento" type="text" holder={values.tipo_documento} disabled={true} /></>
                                    }
                                  </div>
                                </>
                              )}
                            {
                              opcionSeleccionada === 2 && (
                                <>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                        ? <SelectField
                                          id="motivo"
                                          label="Motivo"
                                          name="motivo"
                                          items={dataMotivoNoEncontrado}
                                          onChange={(event) => {
                                            if (event.value == "") {
                                              setFieldValue("motivo", "");
                                            }
                                            else {
                                              setFieldValue("motivo", event.label);
                                            }
                                          }} />
                                        : <TextField label="Motivo" name="motivo" type="text" holder={values.motivo} disabled={true} />
                                    }
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                        ? <SelectField
                                          id="especificacion"
                                          label="Especificación"
                                          name="especificacion"
                                          items={dataEspecificacionDetalle}
                                          onChange={(event) => {
                                            setFieldValue("especificacion", event.value === "" ? "" : event.label);
                                          }} />
                                        : <TextField label="Especificación" name="especificacion" type="text" holder={values.especificacion} disabled={true} />
                                    }
                                  </div>
                                </>
                              )}
                          </div>

                          {
                            opcionSeleccionada === 2 && (
                              <>
                                <div className="mb-3 row" >
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                        ? <SelectField
                                          id="entregaste_documento"
                                          label="¿Entregaste Documentos?"
                                          name="entregaste_documento"
                                          items={dataOpcionSiNo}
                                          onChange={(event) => {
                                            setFieldValue("entregaste_documento", event.value === "" ? "" : event.value === 1 ? "Si" : "No");
                                            if (event.value === 1 || event.value === "") {
                                              setFieldValue("tipo_documento", "");
                                            }
                                            else {
                                              setFieldValue("tipo_documento", "");
                                            }
                                          }} />
                                        : <TextField label="¿Entregaste Documentos?" name="entregaste_documento" type="text" holder={values.entregaste_documento} disabled={true} />
                                    }
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    {
                                      !registradoPreviamente
                                      ? values.entregaste_documento == 1 || values.entregaste_documento == "Si" ?
                                          <SelectField
                                            id="tipo_documento"
                                            label="Tipo de documento"
                                            name="tipo_documento"
                                            items={tipoDocumento}
                                            onChange={(event) => {
                                              setFieldValue("tipo_documento", event.value === "" ? "" : event.label);
                                            }} />
                                          : <></>
                                          : (values.entregaste_documento == 0 || values.entregaste_documento == "" || values.entregaste_documento == "No" || values.entregaste_documento == "2")
                                          ? <></>
                                          : <TextField label="Tipo de documento" name="tipo_documento" type="text" holder={values.tipo_documento} disabled={true} />
                                    }
                                  </div>
                                </div>
                              </>
                            )}
                          <div className="mb-3 row" style={{ marginTop: 30 }}>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                              <TextAreaField
                                label="Comentarios:"
                                className="col-8 mt-2"
                                rows="4"
                                name="comentarios"
                                type="text"
                                holder={registradoPreviamente ? "Escribe" : values.comentarios}
                                disabled={registradoPreviamente}
                                style={{
                                  border: "none",
                                  backgroundColor: "#f0f0f0",
                                  resize: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="row mb-3 px-12">
                            <div className="col-3">
                              <p><span style={{ fontSize: "14px", fontWeight: "bold" }} >Ingreso a cartera</span></p>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                              {
                                !registradoPreviamente
                                  ? <SelectField
                                    id="motivo_ingreso"
                                    label="Motivo de ingreso"
                                    name="motivo_ingreso"
                                    items={dataMotivoIngreso}
                                    onChange={(event) => {
                                      setFieldValue("motivo_ingreso", event.value === "" ? "" : event.label);
                                    }} />
                                  : <TextField label="Motivo de ingreso" name="motivo_ingreso" type="text" holder={values.motivo_ingreso} disabled={true} />
                              }
                            </div>
                          </div>
                          <div className="mb-3 row" style={{ marginTop: 30 }}>
                            <div className="col-10">
                              <TextAreaField
                                label="Comentarios de ingreso a cartera:"
                                className="col-8 mt-2"
                                rows="4"
                                name="comentario_ingreso"
                                type="text"
                                holder={registradoPreviamente ? "Escribe" : values.comentario_ingreso}
                                disabled={registradoPreviamente}
                                style={{
                                  border: "none",
                                  backgroundColor: "#f0f0f0",
                                  resize: "none",
                                }}
                              />
                            </div>
                          </div>
                          <div className="mb-3 my-2 mt-4 row">
                            <div className="col-sm-4 col-xl-3">
                              <Field className="form-check-input" type="checkbox" value={values.reg_concluido} checked={values.reg_concluido} disabled={registradoPreviamente} name="reg_concluido" onClick={(event) => {
                                setFieldValue("reg_concluido", event.target.checked);
                              }} />
                              <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Registro concluido</label>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-9 text-end">
                              {
                                values.fecha_inicio !== "" &&
                                <label>
                                  Inicio de proceso: {formatearFecha(values.fecha_inicio)}
                                </label>
                              }
                              {
                                registradoPreviamente &&
                                <label style={{ whiteSpace: 'pre' }}>
                                  {" | "}Fin de proceso: {formatearFecha(values.fecha_fin)}
                                </label>
                              }
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isOpenDocs &&
        <VisorFile
          isOpen={isOpenDocs}
          setIsOIpen={setIsOpenDocs}
          fileName={fileName}
          doc={SelectedDocs}
        />
      }
      {
        openModalSubirDocumento && (
          <SubirDocumentoModal
            contrato_id={contratoActual.contrato_id}
            archivoId={archivoId}
            isOpen={openModalSubirDocumento}
            setIsOpen={setOpenModalSubirDocumento}
            cardId={1}
            setConfirmarOperacion={setConfirmarOperacion}
            setNombreDocumento={setNombreDocumento}
          />
        )
      }
    </>
  );
});
export default VisitaDomiciliariaCard;
