import React, { useEffect, useState } from 'react'
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { useParams } from 'react-router-dom'

const ModalInfo = ({ isOpen, setIsOpen, elementSelected }) => {

    const [loading, setLoading] = useState(false);

    return (
        <>
            <Modal isOpen={isOpen} color='#fff' width={400}>
                {/* <section className='d-flex justify-content-end'>
                    <button
                        className='btn btn-outline d-flex justify-content-center align-items-center'
                        style={{ width: '23px', height: '23px' }}
                        onClick={() => { setIsOpen(false) }}>
                        <i className="ri-close-fill"></i>
                    </button>
                </section> */}
                <section className='d-flex justify-content-between my-2 mx-4'>
                    <span className='text-md'>
                        <strong>
                            Información de contrato / vehículo
                        </strong>
                    </span>
                    <button
                        className='btn btn-outline d-flex justify-content-center align-items-center'
                        style={{ width: '23px', height: '23px' }}
                        onClick={() => { setIsOpen(false) }}>
                        <i className="ri-close-fill"></i>
                    </button>
                </section>
                <section className='my-4 mx-4 d-flex flex-column gap-3'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-xs">
                            <strong>A nombre de: </strong>
                            {elementSelected.aNombreDe}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-xs">
                            <strong>Contrato: </strong>
                            {elementSelected.numContrato}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-xs">
                            <strong>VIN: </strong>
                            {elementSelected.vin}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-xs">
                            <strong>Marca: </strong>
                            {elementSelected.marca}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-xs">
                            <strong>Modelo: </strong>
                            {elementSelected.modelo}
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-xs">
                            <strong>Año: </strong>
                            {elementSelected.año}
                        </div>
                    </div>
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ModalInfo