import axios from '../../Utils/ApiService';

const RegresionManualService = {
    GetInfoVehiculoById:(vin)=> {        
        return axios.get(`/functions/GetInfoVehiculoById?Buscar=${vin}`);
    }, 
    GetAllEtapasById:(generalId,tiporegla)=> {        
        return axios.get(`/functions/GetAllEtapasById?GeneralId=${generalId}&TipoReglaId=${tiporegla}`);
    },
    GetHistorialRegresion:(generalId,page,rows)=> {        
        return axios.get(`/functions/GetHistorialRegresion?GeneralId=${generalId}&page=${page}&rows=${rows}`);
    },
    CSTipoReglaRegresion:()=> {        
        return axios.get(`/functions/CSTipoReglaRegresion`);
    },
    AddRegresionManual:async(data)=>{
        return await axios.post(`/functions/AddRegresionManual`,data);
    }
}

export default RegresionManualService