import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import AuthLogin from '../../../Utils/AuthLogin';

const useRevisionDatamovil = ({ handleConcluido, setRequiereCosto }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const auth = new AuthLogin();

    const initial = {
        servicioRevisionDatamovilId: "",
        servicioId: "",
        alteracionDatamovil: null,
        comentariosAlteracion: null,
        datamovilAlterado: null,
        equipoCompleto: null,
        folioId: null,
        formatoHojaId: null,
        funciona: null,
        requiereReparacion: null,
        teclado: null,
        bloqueador: null,
        gps: null,
        aplicaCobro: null,
        observaciones: null,
        ejecutivo: auth.getNombreUsuario(),
        concluido: null,
        usuarioAlta: "",
        usuarioActualiza: null,
        fechaConcluido: null,
        fechaAlta: "",
        fechaActualiza: null,
        numeroServicio: ""
    };

    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState(false);
    const [formatoHoja, setFormatoHoja] = useState([]);

    const opcionesBool = [{ value: true, label: 'Si' }, { value: false, label: 'No' }];

    useEffect(() => {
        getDataRevision();
        getFormatoHoja();
    }, []);

    const getDataRevision = async () => {
        setLoading(true);
        await DSystemsServicios.getServicioRevision(id)
            .then(res => {
                let resData = res.data
                resData.ejecutivoName = auth.getNombreUsuario()
                // resData.datamovilAlterado = resData.datamovilAlterado ?? false
                // resData.alteracionDatamovil = resData.alteracionDatamovil ?? false
                // resData.equipoCompleto = resData.equipoCompleto ?? false
                // resData.requiereReparacion = resData.requiereReparacion ?? false
                // resData.funciona = resData.funciona ?? false
                // resData.aplicaCobro = resData.aplicaCobro ?? false

                setInitialValue({ ...initialValue, ...resData })
                handleConcluido({ concluido: resData.concluido,concluidoGeneral: resData?.concluidoGeneral ?? false })

                if (resData.alteracionDatamovil && resData.concluido) {
                    setRequiereCosto(true)
                }

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const getFormatoHoja = async () => {
        let items = [];
        await Catalogo.getDropdownsByTable('Catalogos.FormatoServicios')
            .then(resp => {
                resp.data.forEach(item => {
                    items.push({
                        value: item.FormatoServiciosId,
                        label: item.FormatoServicio
                    });
                });
                setFormatoHoja(items);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const submitForm = async (values) => {
        setLoading(true);
        DSystemsServicios.updateServicioRevision(values)
            .then(res => {
                if (values.alteracionDatamovil && values.concluido) {
                    setRequiereCosto(true)
                    submitCostoAdicional({ servicioId: values.servicioId })
                }
                // handleConcluido({ concluido: values.concluido })
                // setInitialValue(values)
                getDataRevision();
            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitCostoAdicional = async (values) => {
        setLoading(true);
        DSystemsServicios.agregarServicioCostosAdicionales(values)
            .then(res => {

            })
            .catch(error => {
                //Error al obtener datos de revision
            })
            .finally(() => {
                setLoading(false);
            })
    };


    return {
        initialValue,
        loading,
        setLoading,
        submitForm,
        opcionesBool,
        formatoHoja
    };
};

export default useRevisionDatamovil;
