import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SelectField } from '../SelectField';
import { toast, Toaster } from 'react-hot-toast';
import { TextAreaField } from '../TextAreaField';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { useLocation, useParams } from 'react-router-dom';
import { message, Normalize } from '../../Utils/functions';
import Siniestros from '../../Services/Siniestro/Siniestro';

const ClienteEntregaUnidadModal = ({ modalMessage, setModalMessage, handleOk }) => {
    const refFormik = useRef();
    const { state } = useLocation();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const initial = {
        siniestroClienteEntregaUnidadId: "",
        siniestroId: id,
        siniestroClienteEntregaUnidadMotivoId: "",
        comentarios: "",
        concluido: true
    }
    const [initialValue, setInitialValue] = useState(initial)

    const [motivoEntrega, setMotivoEntrega] = useState([])
    useEffect(() => {
        if (modalMessage.isOpen) {
            getSiniestroClienteEntregaUnidadMotivo();
            getSiniestroClienteEntregaUnidad();
        }
    }, [modalMessage])

    const handleRegresar = () => {
        refFormik.current.resetForm();
        setModalMessage({ ...modalMessage, isOpen: false })
        handleOk()
    }

    const validate = Yup.object({
        siniestroClienteEntregaUnidadMotivoId: Yup.string()
            .required("El campo es requerido."),
        comentarios: Yup.string()
            .required("El campo es requerido."),
    });
    const getSiniestroClienteEntregaUnidad = async () => {
        setLoading(true);
        await Siniestros.siniestroClienteEntregaUnidadGet(id)
            .then(resp => {
                let item = resp.data;
                let newData = {
                    siniestroClienteEntregaUnidadId: item?.siniestroClienteEntregaUnidadId ?? "",
                    siniestroId: id,
                    siniestroClienteEntregaUnidadMotivoId: item?.siniestroClienteEntregaUnidadMotivoId ?? "",
                    comentarios: item?.comentarios ?? "",
                    concluido: item?.concluido
                }
                setInitialValue(newData);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };
    const getSiniestroClienteEntregaUnidadMotivo = async () => {
        setLoading(true)
        await Catalogo.getDropdownsByTable('Catalogos.SiniestroClienteEntregaUnidadMotivo')
            .then(resp => {
                let items = [];
                resp.data.forEach(item => {
                    items.push({ value: item.SiniestroClienteEntregaUnidadMotivoId, label: item.SiniestroClienteEntregaUnidadMotivo })
                });
                setMotivoEntrega(items);
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
                setLoading(false)
            });
    };

    const submitForm = async (values) => {
        setLoading(true);
        await Siniestros.siniestroClienteEntregaUnidadCrear(values)
            .then(resp => {
                if (resp.data.data.ban === 1) {
                    toast.success(message("¡Correcto.!", resp.data.data.mensaje));
                    handleRegresar();
                } else {
                    toast.error(message("¡Error.!", resp.data.data.mensaje));
                }
            })
            .catch(err => {
                console.error(err);
                toast.error(message("¡Error, al intentar actualizar el registro de entrega de unidad!"));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                isInitialValid={false}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <div className='justify-content-start p-2 align-items-center'>
                            <Form>
                                <div className="row">
                                    <div className="col col-12">
                                        <h5><strong>{modalMessage.title}</strong></h5>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col col-12">
                                        {
                                            Normalize(state?.aNombreDe) == "cliente" &&
                                            <div className="row">
                                                <small id="AT_TxtBasicModalMenssage">
                                                    <strong className="d-flex align-items-center text-blue"><i className="ri-information-line me-2 fs-4"></i> Se requiere trámite de gestoría</strong>
                                                </small>
                                            </div>
                                        }

                                        <div className="row mt-2">
                                            <div className="col-7">
                                                <SelectField className="col-12" disabled={initialValue?.concluido} label="Motivo de la entrega de la unidad:" name={`siniestroClienteEntregaUnidadMotivoId`} items={motivoEntrega} onChange={(event) => {
                                                    setFieldValue(`siniestroClienteEntregaUnidadMotivoId`, event.value);
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextAreaField
                                                    disabled={initialValue?.concluido}
                                                    label="Comentarios"
                                                    className="form-control col-12"
                                                    rows="3"
                                                    name="comentarios"
                                                    id="comentariosId"
                                                    type="text"
                                                    holder="Escribe"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className='d-flex justify-content-end'>
                                    <div className="row">
                                        <div className="col-auto d-flex">
                                            <button
                                                type='reset'
                                                id="AT_BtnCloseModal"
                                                className='btn btn-outline me-2'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                onClick={() => handleRegresar()}
                                            >
                                                Cerrar
                                            </button>
                                            <button
                                                type='submit'
                                                id="AT_BtnCloseModal"
                                                className='btn'
                                                style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                                // onClick={() => handleOk(values)}
                                                disabled={initialValue?.concluido ? true : !(isValid)}
                                            >
                                                Guardar
                                            </button>
                                        </div>

                                    </div>
                                </section>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default ClienteEntregaUnidadModal;
