import { Formik } from "formik";
import Modal from "../../../Components/Modal/Modal";
import Spinner from "../../../Components/Loadig";
import { TextField } from "../../../Components/TextField";
import DataTable from "../../../Components/datatable/DataTable";
import DSAutorizarCitasHook from "../../../Hooks/DatamovilSystems/Autorizaciones/DSAutorizarCitasHook";
import AutorizacionCitaModal from "./AutorizacionCitaModal";
import toast, { Toaster } from "react-hot-toast";
import RowPages from "../../../Components/RowPages";

const SeguimientoCitasServicios = () => {

    const {
        refFormik,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        handleDetail,
        getListado,
        handlePageClick,
        pageCount,
        currenPage,
        setRows
    } = DSAutorizarCitasHook();

    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
        // validationSchema={validate}
        >
            {({ isValid, values, setFieldValue, errors }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <Modal isOpen={modalMessage.isOpen} color='#fff' width={600}>
                        {
                            modalMessage.type === 1 &&
                            <>
                                <AutorizacionCitaModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                    getListado={getListado}
                                    toast={toast}
                                />
                            </>
                        }
                    </Modal>
                    <section className="mt-2 mx-2">
                        <div className='pb-4'>
                            <section className='mx-4 my-4 d-flex flex-column'>
                                <div className="col-12 mt-2">
                                    <div className="row justify-content-between">
                                        <div className="col-auto">
                                            <strong className='mt-2'>Citas de Servicios Datamovil</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-12 col-md-2 mt-2 mr-1 inner-addon right-addon">
                                            <i className="glyphicon fas fa-search"></i>
                                            <TextField id="buscar" label="" name="buscar" type="text" holder="Escribe" onChange={(event) => {
                                                setFieldValue("buscar", event.target.value);
                                                setBuscar(event.target.value)
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                <RowPages
                                    setpageRows={(value) => { setRows(value) }}
                                />
                                <div className="row">
                                    <div className='col-12'>
                                        <DataTable
                                            column={columns}
                                            data={data}
                                            detailable={true}
                                            handleDetail={handleDetail}
                                            paginate={true}
                                            pageCount={pageCount}
                                            handlePageClick={handlePageClick}
                                            currentPage={currenPage - 1}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </>
            )
            }
        </Formik>
    );
}

export default SeguimientoCitasServicios;