import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeguimientoCierre from '../../Services/Cierre/SeguimientoCierre';
import Catalogo from '../../Services/Catalogos/Catalogo';
import { getDateFormat } from '../../Utils/functions';

const useSeguimientoCierre = (toast) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    let initial =
    {
        totalRows: 0,
        pageSize: 0,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        data: []
    }


    const [dataValue, setDataValue] = useState(initial);
    const [loading, setloading] = useState(false);
    const [cierreContrato, setCierreContrato] = useState([])

    const columns = [
        { field: 'vin', headerName: 'VIN', width: '150' },
        { field: 'numContrato', headerName: 'Contrato', width: '150' },
        { field: 'cierreContratoProceso', headerName: 'Proceso', width: '200' },
        { field: 'subproceso', headerName: 'SubProceso', width: '200' },
        { field: 'fecha', headerName: 'Fecha', width: '200' },
        { field: 'estatus', headerName: 'Estatus', width: '200' },
        { field: 'subestatus', headerName: 'Subestatus', width: '150' },
        { field: 'ultimaActualizacion', headerName: 'Últ. Actualización', width: '200' },
    ];

    useEffect(() => {
        getData({
            page: 1,
            rows: 10
        });
        getCierreContratoProceso();
    }, [])

    const getData = async (values) => {
        setloading(true)
        values.page = values.selected + 1;
        await SeguimientoCierre.getSeguimientoCierre(values) //state.procesoId
            .then((res) => {
                let items = [];
                res.data.data.forEach(item => {
                    console.log(item.fecha)
                    item.fecha = getDateFormat(item?.fecha, '');
                    items.push(item);
                });
                res.data.data = items;
                setDataValue(res.data)
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                setloading(false)
            })
    }

    const handleEdit = () => {

    }

    const downloadExcel = () => {

    }

    const getCierreContratoProceso = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.CierreContratoProceso')
            .then(res => {
                const cierreContratoArray = res.data.map(item => ({
                    value: item.CierreContratoProcesoId,
                    label: item.CierreContratoProceso.trim()  // Usamos trim para eliminar espacios extras si es necesario
                }));
                setCierreContrato(cierreContratoArray);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    };

    return {
        columns,
        dataValue,
        setDataValue,
        getData,
        handleEdit,
        downloadExcel,
        loading,
        cierreContrato
    }
}

export default useSeguimientoCierre