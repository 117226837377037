import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../Auth/Authorization';
import SeguimientoTelefonicoDetalleHook from "../../../Hooks/SeguimientoTelefonico/SeguimientoTelefonicoDetalleHook"
import VehiculoInfoCard from "../../../Components/VehiculoInfoCard"
import CarteraInfoCard from "../../../Components/CarteraInfoCard"
import Accordion from "../../../Components/TitleAcordeon"
import DataTable from "../../../Components/datatable/DataTable"
import TablaAnidada from "../../../Components/TablaAnidada"
import Spinner from "../../../Components/Loadig"
import Modal from "../../../Components/Modal/Modal"
import RegistrarReferenciaModal from "../../../Components/Modal/RegistrarReferencia"
import DatosPromesaPagoModal from "../../../Components/Modal/DatosPromesaPagoModal"
import EliminarPromesaPagoModal from "../../../Components/Modal/EliminarPromesaPagoModal"
import DireccionesContactoModal from "../../../Components/Modal/DireccionesContactoModal";
import AgregarDatosContactoModal from "../../../Components/Modal/AgregarDatosContactoModal";
import DesactivarContactoModal from "./Modal/DesactivarContactoModal";
import RegistroLlamadaModal from "../../../Components/Modal/RegistroLlamadaModal";
import VisorFile from "../../../Components/VisorFile";
import ContratoCard from "../../../Components/ContratoCard";

const SeguimientoCobranzaDetalle = () => {

    const { state } = useLocation();

    const {
        handleReturnPage,
        columnsPromesas,
        columnsSeguimientoLog,
        columnsInformacionContacto,
        columnsSubInformacionContacto,

        currentDataPromesas,
        currentDataSeguimiento,
        currentDataSeguimientoLog,
        userDetail,
        promesasRotas,
        disableInteractions,

        loading,
        setLoading,
        eliminarDatoModal,
        setEliminarDatoModal,

        isOpenModalReferencias,
        setisOpenModalReferencias,
        isOpenModalAgregarContacto,
        setisOpenModalAgregarContacto,
        isOpenModalPromesaPagoData,
        setisOpenModalPromesaPagoData,
        promesaPagoData,
        isOpenModalPromesaPagoDelete,
        setisOpenModalPromesaPagoDelete,
        isOpenModalDireccionContactos,
        setIsOpenModalDireccionContactos,
        isOpenModalRegistroLlamada,
        setIsOpenModalRegistroLlamada,
        setDisableInteractions,

        handleModalRef,
        handleModalAdd,
        handlePromesasPago,
        handlePromesasPagoDelete,
        handleDireccionesModal,
        handleRegistroLlamada,
        handleChange,

        motivosVisita,
        relacionSolicitante,
        dataEstado,
        tipoContacto,
        subtipoContacto,
        infoContactosFiltered,
        infoAvaliable,
        infoContactoData,
        motivoPromesa,
        motivoIngresoCartera,
        tipoOperacion,
        tipoOperacionResultado,
        tipoResultado,
        promesasStatus,
        motivoEliminacion,

        directorioValue,
        setDirectorioValue,
        handleSetStateTab,

        handleGetPromesasdePago,
        HandleGetInformacionContactos,
        handleContactosSeguimientoLog,

        handleUploadFile,
        handleViewdocs,
        isOpenDocs,
        setIsOpenDocs,
        fileName,
        SelectedDocs,
        setCurrentDataSeguimiento
    } = SeguimientoTelefonicoDetalleHook(state)

    let promesasDisabled = (disableInteractions) && infoAvaliable.cont_aval !== "0"

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            {
                loading &&
                <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            }
            {
                isOpenModalReferencias &&
                <RegistrarReferenciaModal
                    directorioValue={directorioValue}
                    setIsOpen={setisOpenModalReferencias}
                    isOpen={isOpenModalReferencias}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
            {
                isOpenModalDireccionContactos &&
                <DireccionesContactoModal
                    data={infoContactoData}
                    isOpen={isOpenModalDireccionContactos}
                    setIsOpen={setIsOpenModalDireccionContactos}

                />
            }
            {
                isOpenModalAgregarContacto &&
                <AgregarDatosContactoModal
                    setIsOpen={setisOpenModalAgregarContacto}
                    isOpen={isOpenModalAgregarContacto}
                    relacionSolicitante={relacionSolicitante}
                    dataEstado={dataEstado}
                    tipoContacto={tipoContacto}
                    subtipoContacto={subtipoContacto}
                    infoContactoData={infoContactoData}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                />
            }
            {
                isOpenModalPromesaPagoData &&
                <DatosPromesaPagoModal
                    data={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoData}
                    setIsOpen={setisOpenModalPromesaPagoData}

                />
            }
            {
                isOpenModalPromesaPagoDelete &&
                <EliminarPromesaPagoModal
                    item={promesaPagoData}
                    isOpen={isOpenModalPromesaPagoDelete}
                    setIsOpen={setisOpenModalPromesaPagoDelete}
                    state={state}
                    toast={toast}
                    motivoEliminacion={motivoEliminacion}
                    GetData={handleGetPromesasdePago}
                />
            }
            {
                isOpenModalRegistroLlamada &&
                <RegistroLlamadaModal
                    setIsOpen={setIsOpenModalRegistroLlamada}
                    isOpen={isOpenModalRegistroLlamada}
                    // motivoPromesa={motivoPromesa}
                    // motivoIngresoCartera={motivoIngresoCartera}
                    motivosVisita={motivosVisita}
                    // tipoOperacion={tipoOperacion}
                    // tipoOperacionResultado={tipoResultado}
                    infoContactoData={infoContactoData}
                    // promesasStatus={promesasStatus}
                    user={userDetail}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                    GetDataPromesa={handleGetPromesasdePago}
                    GetDataSeguimiento={handleContactosSeguimientoLog}
                    directorioValue={directorioValue}
                    generadoEn={"Seguimiento Telefónico"}
                    currentDataSeguimiento={currentDataSeguimiento}
                    setDisableInteractions={setDisableInteractions}
                    setDirectorioValue={setDirectorioValue}
                    setCurrentDataSeguimiento={setCurrentDataSeguimiento}
                    infoAval={infoAvaliable?.cont_aval}
                />
            }
            {
                isOpenDocs &&
                <VisorFile
                    isOpen={isOpenDocs}
                    setIsOIpen={setIsOpenDocs}
                    fileName={fileName}
                    doc={SelectedDocs}
                />
            }
            {eliminarDatoModal &&
                <DesactivarContactoModal
                    item={infoContactoData}
                    isOpen={eliminarDatoModal}
                    setIsOpen={setEliminarDatoModal}
                    state={state}
                    toast={toast}
                    GetDataContactos={HandleGetInformacionContactos}
                />

            }
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
                <header className='heder-subtitle d-flex justify-content-between align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                    <div className='cursor-pointer' onClick={() => { handleReturnPage() }}>
                        <i className="bx bxs-chevron-left mx-2" ></i>
                        Regresar a listado de seguimiento de cobranza
                    </div>
                    {promesasDisabled && <div className="error"> <i className='bx bxs-error'> </i> Politica Aval, favor de contactar aval</div>}
                </header>
                <div className="col col-11 d-flex">
                    {/* <div className="row mt-1"> */}
                    <div className="col col-6 px-4 py-2">
                        <VehiculoInfoCard
                            VIN={state.vin}
                            verClaves={true}
                            datosVehiculo={currentDataSeguimiento}
                            ocultarContrato={true}
                        />
                    </div>
                    <div className="col col-6 px-4 py-2">
                        <ContratoCard
                            vin={state?.vin} />
                    </div>
                </div>
                <div className="col col-11 px-4 py-2">
                    <CarteraInfoCard
                        datos={currentDataSeguimiento}
                        user={userDetail}
                        promesasRotas={promesasRotas}
                        updateCurrentData={true}
                        setCurrentDataSeguimiento={setCurrentDataSeguimiento}
                    />
                </div>
                <ValidatePermission
                    isActive={
                        state.nivel_cobranza === "1" ? ValidatePermissionById(3, Section.SeguimientoCobranza_PromesadePago) :
                            state.nivel_cobranza === "2" ? ValidatePermissionById(3, Section.SeguimientodeVisitaNivel2_PromesadePago) :
                                state.nivel_cobranza === "3" ? ValidatePermissionById(3, Section.SeguimientoCobranzaJuridicoNivel3_PromesadePago) :
                                    state.nivel_cobranza === "4" ? ValidatePermissionById(3, Section.SeguimientoCobranzaPerdidaCreditoNivel4) :
                                        false
                    }
                >
                    <div>
                        {/* /validacion usando nivel 1*/}
                        <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                            <Accordion title="Promesa de pago">
                                <p className="text-base"><strong>Detalle de promesa</strong></p>
                                <div style={{ overflow: 'auto' }}>
                                    <DataTable
                                        column={columnsPromesas}
                                        data={currentDataPromesas}
                                        detailable={true}
                                        handleDetail={handlePromesasPago}
                                        actionColumns
                                        deleteableColumn
                                        handleDelete={handlePromesasPagoDelete}
                                    />
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </ValidatePermission>
                <ValidatePermission
                    isActive={
                        state.nivel_cobranza === "1" ? ValidatePermissionById(3, Section.SeguimientoCobranza_DirectoriodeCobranza) :
                            state.nivel_cobranza === "2" ? ValidatePermissionById(3, Section.SeguimientodeVisitaNivel2_DirectoriodeCobranza) :
                                state.nivel_cobranza === "3" ? ValidatePermissionById(3, Section.SeguimientoCobranzaJuridicoNivel3_DirectoriodeCobranza) :
                                    state.nivel_cobranza === "4" ? ValidatePermissionById(3, Section.SeguimientoCobranzaPerdidaCreditoNivel4_DirectoriodeCobranza) :
                                        false
                    }
                >
                    <div className="col col-11 mx-3 my-2 wrapper-vehiculo " style={{ background: "#FFF" }}>
                        <Accordion title="Directorio de Contactos">
                            <ul className="nav nav-tabs text-sm" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Cliente" && "tab-active"}`} value={'Cliente'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled}>Cliente</button>
                                </li>
                                {infoAvaliable.cont_aval !== "0" ?
                                    <li className="nav-item" role="presentation">
                                        <button className={`seguimiento-telefonico-detalle-nav-link ${directorioValue == "Aval" && "tab-active"}`} value={'Aval'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} >Aval</button>
                                    </li>
                                    : null
                                }
                                {infoAvaliable.cont_coacreditado !== "0" ?
                                    <li className="nav-item" role="presentation">
                                        <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Coacreditado" && "tab-active"}`} value={'Coacreditado'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled} >Coacreditado</button>
                                    </li>
                                    : null
                                }
                                {infoAvaliable.cont_apoderado !== "0" ?
                                    <li className="nav-item" role="presentation">
                                        <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Apoderado" && "tab-active"}`} value={'Apoderado'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled} >Representante Legal</button>
                                    </li>
                                    : null
                                }
                                <li className="nav-item" role="presentation">
                                    <button className={`seguimiento-telefonico-detalle-nav-link  ${directorioValue == "Referencia" && "tab-active"}`} value={'Referencia'} type="button" role="tab" onClick={(e) => handleSetStateTab(e.target.value)} disabled={promesasDisabled} >Referencias</button>
                                </li>
                                <li className="d-flex align-items-center">
                                    {promesasRotas >= 3 && <div className="error"> <i className='bx bxs-error'> </i> Registro de llamada, inhabilitado</div>}
                                </li>
                                <div className="container mt-2">
                                </div>
                            </ul>
                            <div>
                                <div className="d-flex align-items-center my-2">
                                    <span className="mx-2"><strong>Datos de contacto</strong></span>
                                    {directorioValue === 'Referencia' && (
                                        <button
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={handleModalRef}
                                            disabled={
                                                !(state.nivel_cobranza === "1" ? ValidatePermissionById(4, Section.SeguimientoCobranza_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                    state.nivel_cobranza === "2" ? ValidatePermissionById(4, Section.SeguimientodeVisitaNivel2_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                        state.nivel_cobranza === "3" ? ValidatePermissionById(4, Section.SeguimientoCobranzaJuridicoNivel3_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                            state.nivel_cobranza === "4" ? ValidatePermissionById(4, Section.SeguimientoCobranzaPerdidaCreditoNivel4_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                                false
                                                )
                                            }
                                        >
                                            Agregar Referencias
                                        </button>
                                    )}
                                </div>
                                <div style={{ overflow: 'auto' }}>
                                    <TablaAnidada
                                        state={state}
                                        data={infoContactosFiltered}
                                        column={columnsInformacionContacto}
                                        addColumns={3}
                                        buttonText={"Agregar"}
                                        buttonDisabled={
                                            !(state.nivel_cobranza === "1" ? ValidatePermissionById(4, Section.SeguimientoCobranza_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                state.nivel_cobranza === "2" ? ValidatePermissionById(4, Section.SeguimientodeVisitaNivel2_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                    state.nivel_cobranza === "3" ? ValidatePermissionById(4, Section.SeguimientoCobranzaJuridicoNivel3_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                        state.nivel_cobranza === "4" ? ValidatePermissionById(4, Section.SeguimientoCobranzaPerdidaCreditoNivel4_DirectoriodeCobranza, SubModule.SeguimientoTelefonico).Agregar :
                                                            false
                                            )
                                        }
                                        handleClick={handleModalAdd}
                                        moreDirections={true}
                                        interacciones={true}
                                        handleMoreDirections={handleDireccionesModal}
                                        handleRegistro={handleRegistroLlamada}
                                        handleChange={handleChange}
                                        childColumn={columnsSubInformacionContacto}
                                        childCommentColumn={true}
                                        childActionColumns={true}
                                        childRegistroColumn={true}
                                        registroColumnText="Registrar llamada"
                                        setLoading={setLoading}
                                        toast={toast}
                                    >
                                        <DataTable />
                                    </TablaAnidada>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </ValidatePermission>
                <ValidatePermission
                    isActive={
                        state.nivel_cobranza === "1" ? ValidatePermissionById(3, Section.SeguimientoCobranza_SeguimientodeContactos) :
                            state.nivel_cobranza === "2" ? ValidatePermissionById(3, Section.SeguimientodeVisitaNivel2_SeguimientodeContactos) :
                                state.nivel_cobranza === "3" ? ValidatePermissionById(3, Section.SeguimientoCobranzaJuridicoNivel3_SeguimientodeContactos) :
                                    state.nivel_cobranza === "4" ? ValidatePermissionById(3, Section.SeguimientoCobranzaPerdidaCreditoNivel4_SeguimientodeContacto) :
                                        false
                    }
                >
                    <div>
                        <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                            <Accordion title="Seguimiento de contactos">
                                <p className="text-base"><strong>Seguimiento telefónico</strong></p>
                                <div style={{ overflow: 'auto' }}>
                                    <DataTable
                                        column={columnsSeguimientoLog}
                                        data={currentDataSeguimientoLog}
                                        handleDocuments={handleViewdocs}
                                        handleAction={(value, idx) => { handleUploadFile(value, idx) }}
                                    />
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </ValidatePermission>
            </div>
        </>
    )
}

export default SeguimientoCobranzaDetalle