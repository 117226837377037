import React, { useEffect, useState } from 'react'
import Modal from '../../../../Components/Modal/Modal'
import Spinner from '../../../../Components/Loadig'
import { useLocation, useParams } from 'react-router-dom'

const ModalConfirm = ({ isOpen, setIsOpen, title, handleConfirm }) => {

    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({ adeudo: false, monto: 0 });

    const location = useLocation();
    let url = location.pathname.split("/");

    let paso = url[5]
    let tipoServicio = url[2]

    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                <section className='d-flex justify-content-between mx-4'>
                    <span className=''><strong>{item?.adeudo ? "Seguimiento de proceso" : title}</strong></span>
                </section>
                <section className='my-4 mx-4' style={{ width: '25rem' }}>
                    <div className="mb-3">
                        <label htmlFor="staticEmail" className="col-sm-12 col-form-label">
                            {(tipoServicio === '6' && paso === '4') || (tipoServicio === '5' && paso === '3') ? '¿Estas seguro que deseas finalizar el proceso?' : '¿Estas seguro que deseas continuar con el siguiente paso?'}
                        </label>
                    </div>
                    <section className='my-3 d-flex justify-content-end'>
                        <button id="AT_btnCancelar" className='btn btn-outline' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                        <button id="AT_btnGuardar" className='btn mx-2' onClick={handleConfirm}> Confirmar</button>
                    </section>
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ModalConfirm