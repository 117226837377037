import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { TextField } from '../../../../Components/TextField';
import SeguimientoCierre from '../../../../Services/Cierre/SeguimientoCierre';
import { SelectField } from '../../../../Components/SelectField';

const ModalBuscarVin = ({ isOpen, setIsOpen, cierreContrato }) => {
    const refFormik = useRef();
    const navigate = useNavigate();

    let initial = {
        vin: ""
    }

    let initialRegister = {
        procesoCierre: ""
    }

    let iContrato = {
        cierreContratoId: "",
        generalId: "",
        vin: "",
        marca: "",
        modelo: "",
        year: "",
        ban: 1,
        message: ""
    }



    const [loading, setLoading] = useState(false);
    const [initialValue, setInitialValue] = useState(initial)
    const [initialContrato, setInitialContrato] = useState(iContrato)
    const [buscar, setBuscar] = useState(true)
    const [registerCierre, setRegisterCierre] = useState(initialRegister)

    const validate = Yup.object({
        vin: Yup.string()
            .required("El VIN es requerido para su busqueda.")
    });

    const validateContrato = Yup.object({
        // vin: Yup.string()
        //     .required("El VIN es requerido para su busqueda.")
    });

    const getVinData = async (values) => {
        setLoading(true);
        await SeguimientoCierre.getContratoByVIN(values.vin)
            .then(res => {
                setInitialContrato(res.data)
                setBuscar(false)
                console.log(res)
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false);
            })
    };

    const submitForm = async (values) => {
        setLoading(true);
        let dataSend = {
            cierreContratoProcesoId: values.procesoCierre,
            generalId: initialContrato.generalId
        }
        await SeguimientoCierre.postCierreContratoCrear(dataSend)
            .then(res => {
                navigate(`/procesocierre/seguimientocierre/${res.data.cierreContratoId}/1`)
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <>
            <Modal isOpen={isOpen} color='#fff'>
                <section className='d-flex justify-content-between mx-4'>
                    {
                        buscar &&
                        <span className=''>
                            <strong>
                                Buscador de VIN
                            </strong>
                        </span>
                    }
                    {
                        !buscar &&
                        <span className=''>
                            <strong>
                                Nuevo registro
                            </strong>
                        </span>
                    }
                </section>
                <section className='my-4 mx-4' style={{ width: '30rem' }}>
                    {
                        buscar &&
                        <div className="mb-3">
                            <label htmlFor="staticEmail" className="col-sm-12 col-form-label">A continuación ingresa el VIN de la unidad que deseas registrar</label>
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validate}
                                initialValues={initialValue}
                                onSubmit={(values) => {
                                    getVinData({ ...values });
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form>
                                        <div className='row mt-2 mb-2'>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <TextField
                                                    label="VIN"
                                                    name="vin"
                                                    type="text"
                                                    holder="Escribe un VIN para buscar"
                                                    onChange={(event) => {
                                                        setFieldValue("vin", event.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <section className='my-3 d-flex justify-content-end'>
                                            <button id="AT_btnCancelar" className='btn btn-outline' onClick={() => { setIsOpen(false) }}>Regresar</button>
                                            <button id="AT_btnGuardar" className='btn mx-2' type='submit'> Continuar</button>
                                        </section>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    }
                    {
                        !buscar &&
                        <div className="mb-3">
                            <div className='row mt-2 mb-2'>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <b>
                                        VIN ingresado : {initialContrato.vin}
                                    </b>
                                </div>
                            </div>

                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                validationSchema={validateContrato}
                                initialValues={registerCierre}
                                onSubmit={(values) => {
                                    submitForm({ ...values });
                                }}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form>
                                        {
                                            initialContrato.ban === 1 &&
                                            <>
                                                <span>
                                                    ¿Deseas registrar el siguiente vehículo
                                                </span>
                                                <div className='row mt-2 mb-2'>
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <b>
                                                            Marca: {initialContrato.marca} | Modelo: {initialContrato.modelo} | Año: {initialContrato.year}
                                                        </b>
                                                    </div>
                                                </div>

                                                <SelectField className="col-12"
                                                    disabled={initialContrato.ban == 0}
                                                    label="Tipo:" name={`procesoCierre`}
                                                    items={cierreContrato}
                                                    onChange={(event) => {
                                                        setFieldValue(`procesoCierre`, event.value);
                                                    }} />
                                            </>

                                        }
                                        {
                                            initialContrato.ban == 0 &&
                                            <div style={{ "color": "red" }}>
                                                {initialContrato.message}
                                            </div>
                                        }
                                        <section className='my-3 d-flex justify-content-end'>
                                            <button id="AT_btnCancelar" className='btn btn-outline' onClick={() => { setBuscar(true) }}>Regresar</button>
                                            <button id="AT_btnGuardar" disabled={initialContrato.ban == 0} className='btn mx-2' type='submit'> Registrar</button>
                                        </section>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    }
                </section>
            </Modal>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ModalBuscarVin