import { Formik } from "formik";
import SeguimientoServiciosDatamovil from "../../Hooks/ConfigCalendar/SeguimientoServiciosDatamovil";
import Modal from "../../Components/Modal/Modal";
import Spinner from "../../Components/Loadig";
import DataTable from "../../Components/datatable/DataTable";
import TipoServicioModal from "./TipoServicioModal";
import { Toaster } from "react-hot-toast";

const ServiciosDatamovil = () => {
    const {
        refFormik,
        initialValue,
        page,
        rows,
        data,
        getData,
        loading,
        handlePageClick,
        setBuscar,
        columns,
        handleDetail,
        totalRows,
        modalMessage,
        setModalMessage
    } = SeguimientoServiciosDatamovil();

    return (
        <>
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ values, setFieldValue }) => (
                    <>
                        <Toaster
                            position="top-right"
                            toastOptions={{
                                success: {
                                    style: {
                                        background: '#47a066',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #2f7246'
                                    },
                                },
                                error: {
                                    style: {
                                        background: '#d53f3f',
                                        color: '#FFFF',
                                        borderLeft: '10px solid #ac241a'
                                    },
                                },
                            }}
                        />
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <Modal isOpen={modalMessage.isOpen} color='#fff' width={460}>
                            {
                                modalMessage.type === 1 &&
                                <TipoServicioModal
                                    modalMessage={modalMessage}
                                    setModalMessage={setModalMessage}
                                    getData={getData}
                                />
                            }
                        </Modal>
                        <section>
                            <div className='pb-4'>
                                <section className='mx-4 my-4 d-flex flex-column'>
                                    <div className="col-12 mt-4">
                                        <div className="row justify-content-between">
                                            <div className="col-9">
                                                <strong className='mt-2'>Servicios Datamovil</strong>
                                            </div>
                                            <div className="col-auto">
                                                <button className='btn' onClick={() => { setModalMessage({ isOpen: true, type: 1 }) }}>
                                                    Agregar servicio
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row">
                                            <div className="col-3 inner-addon right-addon  mt-2 mr-1">
                                                <i className="glyphicon fas fa-search"></i>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Buscar"
                                                    id="buscar"
                                                    name="buscar"
                                                    onChange={
                                                        (e) => {
                                                            setFieldValue("buscar", e.target.value);
                                                            setBuscar(e.target.value);
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-12'>
                                            <DataTable
                                                column={columns}
                                                data={data}
                                                currentPage={page - 1}
                                                paginate={true}
                                                pageCount={totalRows / rows}
                                                handlePageClick={handlePageClick}
                                                detailable={true}
                                                handleDetail={handleDetail}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </>
                )}
            </Formik>
        </>
    );
}

export default ServiciosDatamovil;