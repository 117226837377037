import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { IsNullOrEmpty, message } from "../../../Utils/functions";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";
import { useNavigate, useParams } from "react-router-dom";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import { IncrementFetching } from "../../../Redux/Slice/reloadSlice";
import { useDispatch, useSelector } from "react-redux";

const AsignacionServiciosSinAsignarHook = ({ servicioAsignacion, onUpdated }) => {
    const refFormik = useRef();
    const navigate = useNavigate();
    const selectRef = useRef();
    const dispatch = useDispatch();
    const { countFetching } = useSelector((state) => state.reloadSlice);
    let initial = {};
    const { folioId } = useParams();

    const [data, setData] = useState([]);
    const [initialValue, setInitialValue] = useState(initial);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [tipoServicios, setTipoServicios] = useState([]);

    /* busquedas */
    const [buscar, setBuscar] = useState('');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [tipoServicio, setTipoServicio] = useState('');
    const [tipoUbicacion, setTipoUbicacion] = useState('');
    const [servicios, setServicios] = useState([]);
    const [totalServicios, setTotalServicios] = useState(0);
    let ubicacionesHash = {}

    const tipoUbicaciones = [
        { value: '', label: 'Todos' },
        { value: '1', label: 'Interna' },
        { value: '2', label: 'Externa' },
    ];


    const columns = [
        { field: 'contrato', headerName: 'Contrato', width: '150px' },
        { field: 'vin', headerName: 'VIN', width: '150px' },
        { field: 'tipoServicio', headerName: 'Tipo de servicio', width: '150px' },
        { field: 'proceso', headerName: 'Proceso', width: '150px' },
        { field: 'fechaHoraCita', headerName: 'Fecha y hora de cita', width: '150px' },
        { field: 'tipoUbicacion', headerName: 'Tipo de Ubicación', width: '150px' },
        { field: 'ubicacionStr', headerName: 'Ubicación', width: '150px' },
    ];


    useEffect(() => {
        getTipoServicios();
        getUbicaciones();
    }, []);

    useEffect(() => {
        getServiciosSinAsignar();
        getUbicaciones();
    }, [buscar, tipoUbicacion, tipoServicio, countFetching]);



    const getUbicaciones = async () => {
        ubicacionesHash = {};
        Catalogo.getDropdownsByTable('Sistema.Ubicacion')
            .then(resp => {
                resp.data.forEach(item => {
                    ubicacionesHash[item.UbicacionId] = item.Nombre;
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    const getTipoServicios = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Servicios')
            .then(resp => {
                let items = [{ value: '', label: 'Todos' }];
                resp.data.forEach(item => items.push({ value: item.TipoServicioId, label: item.TipoServicio }));
                setTipoServicios(items);
            })
            .catch(err => {

            })
    };
    const getServiciosSinAsignar = async () => {
        const params = {
            buscar,
            proceso: '',
            tipoSolicitud: tipoServicio,
            tipoUbicacion,
            page,
            rows,
        }
        DSAsignaciones.getSeguimientoSinAsignar(params)
            .then(resp => {
                const items = resp?.data?.data.map(item => {
                    item.fechaHoraCita = !IsNullOrEmpty(item?.fechaCita) ? getOnlyDateFormat(item.fechaCita) + " " + getHourFormat(item.horaCita) : "-";
                    item.ubicacionStr = item.ubicacion;//item.tipoUbicacion.toLowerCase() === 'interna' ? item.ubicacion : getUbicacionContatenated(item);
                    return item;
                });
                setData(items);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };


    const getUbicacionNameFromGuid = (UbicacionId) => {
        return ubicacionesHash[UbicacionId];
    }

    const getUbicacionContatenated = (item) => {
        return `${item.ubicacion} - ${item.calle} #${item.numeroExt}, ${item.colonia}, CP ${item.codigoPostal}, ${item.municipio}, ${item.estado}`;
    };


    const getOnlyDateFormat = (date) => {
        return date?.split("T")[0].split("-").reverse().join("-");
    }


    const getHourFormat = (hours) => {
        let [hour, minutes] = hours.split(":");

        let newHour = '';
        let meridiam = 'AM'
        if (Number(hour) > 12) {
            newHour = ("0" + (Number(hour) - 12)).slice(-2);
            meridiam = 'PM';
        } else {
            if (Number(hour) === 12) {
                meridiam = 'PM';
            }
            newHour = ("0" + Number(hour)).slice(-2);
        }
        return `${newHour}:${minutes}:00 ${meridiam}`;
    }

    const handleCheck = (evecheck, item, int) => {
        let items = servicios;

        if (evecheck) {
            items.push(item);
        } else {
            const idx = (element) => element.servicioId === item.servicioId
            const idxFound = items.findIndex(idx)
            items.splice(idxFound, 1)
        }

        setTotalServicios(items.length);
    }

    const handleAsignar = async (values) => {
        let ServiciosId = [];
        servicios.forEach(servicio => {
            ServiciosId.push(servicio.servicioId);
        });

        let params = {
            servicioAsignacion,
            concluido: values.concluido,
            servicios: ServiciosId
        }

        setLoading(true);
        await DSAsignaciones.postAsignarServicios(params)
            .then(resp => {
                toast.success(message('Servicios asignados'));
                setModalMessage({ isOpen: false });
                getServiciosSinAsignar();
                onUpdated();
                setTotalServicios(0);
                setServicios([]);
                dispatch(IncrementFetching({ countFetching }));
                if (values.concluido) {
                    navigate(-1);
                }
            })
            .catch(err => {
                toast.error(message('¡Algo salió mal, no se pudieron agregar los servicios!'));
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return {
        tipoUbicaciones,
        data,
        handleCheck,
        refFormik,
        selectRef,
        initialValue,
        modalMessage,
        setModalMessage,
        loading,
        setLoading,
        buscar,
        setBuscar,
        tipoServicios,
        columns,
        totalServicios,
        handleAsignar,
        getServiciosSinAsignar,
        setTipoUbicacion,
        setTipoServicio
    };
};

export default AsignacionServiciosSinAsignarHook;