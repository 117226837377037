import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { SubModule } from '../Auth/Authorization';
import { validateManySubModule, ValidatePermission, ValidatePermissionById } from '../Auth/ValidatePermission';
import { AuthExpireSesion, ChangevalueMenu } from '../Utils/functions';
import AuthLogin from '../Utils/AuthLogin';
import { useDispatch, useSelector } from 'react-redux';
import { handleMenu } from '../Redux/Slice/menuSlice';

const auth = new AuthLogin();

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { visible } = useSelector((state) => state.changeMenu);

    if (AuthExpireSesion()) {
        auth.logout();
        return navigate("/")
    }
    const handleChangeValue = () => {
        ChangevalueMenu(visible);
        dispatch(handleMenu(!visible));
    }
    return (
        <>
            <aside id="sidebar" className={`sidebar ${visible ? "toggle-sidebar-aside" : ""}`}>
                <div className={`title_sidebar fs-2 mb-2 d-flex ${!visible ? "flex-column justify-content-center" : "justify-content-center"}`}>
                    <div className={`d-flex ${!visible ? "justify-content-between" : ""} `}>
                        {visible ? "" : "Panel"}
                        <i className={`icon_burger ${visible ? "ri-menu-unfold-fill" : "ri-menu-fold-fill"}`} onClick={(e) => { handleChangeValue(); }}></i>
                    </div>
                    <div>{visible ? "" : "Administrativo"}</div>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.Roles),
                        ValidatePermissionById(2, SubModule.Usuarios),
                        ValidatePermissionById(2, SubModule.Notificaciones)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "GU" : "Gestión de Usuarios"}</strong></li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Usuarios)}
                    >
                        <li id="AT_usuarios" className="nav-item">
                            <NavLink to="/admin/usuario" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Usuarios">
                                <i className="icon-dark bi bi-people"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Usuarios</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Roles)}
                    >
                        <li id="AT_catalogos" className="nav-item">
                            <NavLink to="/admin/rol" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Roles">
                                <i className="icon-dark ri-user-settings-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Roles</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Notificaciones)}
                    >
                        <li id="AT_notificaciones" className="nav-item">
                            <NavLink to={"/admin/configuracion"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Notificaciones">
                                <i className="icon-dark bi bi-bell"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Notificaciones</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.TablasSecundarias),
                        ValidatePermissionById(2, SubModule.Catalogos),
                        ValidatePermissionById(2, SubModule.ColoniasSepomex)
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "CDC" : "Configuración de Catálogos"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.TablasSecundarias)}
                    >
                        <li id="AT_tablasSecundarias" className="nav-item">
                            <NavLink to={"/admin/tablasSecundarias"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Tablas Secundarias">
                                <i className="icon-dark bi bi-layout-text-window-reverse"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Tablas Secundarias</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Catalogos)}
                    >
                        <li id="AT_catalogos" className="nav-item">
                            <NavLink to="/admin/catalogo" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Catálogos">
                                <i className="icon-dark bi bi-book"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Catálogos</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ColoniasSepomex)}
                    >
                        <li id="AT_catalogosepomex" className="nav-item">
                            <NavLink to="/admin/coloniasepomex" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Colonias Sepomex">
                                <i className="icon-dark bi bi-journal-bookmark"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Colonias Sepomex</span>
                            </NavLink >
                        </li>
                    </ValidatePermission>

                    <ValidatePermission isActive={validateManySubModule([
                        ValidatePermissionById(2, SubModule.ConfiguracionDeEtapas),
                        ValidatePermissionById(2, SubModule.ConfiguracionDeInventario),
                        ValidatePermissionById(2, SubModule.DataDocs),
                        ValidatePermissionById(2, SubModule.DataDocsConfig),
                        ValidatePermissionById(2, SubModule.Logs),
                        ValidatePermissionById(2, SubModule.Parametros),
                        ValidatePermissionById(2, SubModule.ParametrosCartera),
                        ValidatePermissionById(2, SubModule.CambioEtapaDW),                        
                    ])}>
                        <li className="nav-heading"><strong>{visible ? "CDS" : "Configuración de Sistema"}</strong></li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ConfiguracionDeEtapas)}
                    >
                        <li id="AT_configEtapas" className="nav-item">
                            <NavLink to={"/admin/configuracionetapa"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Configuración de etapas">
                                <i className="bi bi-arrow-repeat"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Configuración de etapas</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ConfiguracionDeInventario)}
                    >
                        <li id="AT_configInventario" className="nav-item">
                            <NavLink to={"/admin/inventario"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Configuración inventario">
                                <i className="ri-file-edit-line"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Configuración inventario</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.DataDocs)}

                    >
                        <li id="AT_datadocs" className="nav-item">
                            <NavLink to={"/admin/datadocs"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="DataDocs">
                                <i className="icon-dark bi bi-folder"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>DataDocs</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.DataDocsConfig)}

                    >
                        <li id="AT_datadocs" className="nav-item">
                            <NavLink to={"/admin/datadocsconfig"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="DataDocs Configuración">
                                <i className="icon-dark bi bi-folder"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>DataDocs Configuración</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Logs)}
                    >
                        <li id="AT_logs" className="nav-item">
                            <NavLink to={"/admin/logs"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Logs">
                                <i className="icon-dark bi bi-file-earmark"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Logs</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                        <li id="AT_logs" className="nav-item">
                            <NavLink to={"/admin/servicios-datamovil"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Servicios Datamovil">
                                <i className="icon-dark bi bi-calendar"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Servicios Datamovil</span>
                            </NavLink>
                        </li>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.Parametros)}
                    >
                        <li id="AT_parametros" className="nav-item">
                            <NavLink to={"/admin/parametros"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Parámetros">
                                <i className="icon-dark bi bi-list-nested"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Parámetros</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.DiasFestivos)}
                    >
                        <li id="AT_parametros" className="nav-item">
                            <NavLink to={"/admin/diasfestivos"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Parámetros">
                                <i className='icon-dark bx bx-calendar'></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Días Festivos</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ProgramacionDeJobs)}
                    >
                        <li id="AT_parametros" className="nav-item">
                            <NavLink to={"/admin/jobs"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Parámetros">
                                <i className=' glyphicon bx bx-cog'></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Programación de jobs</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>

                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ParametrosCartera)}>
                        <li id="AT_parametros_Cartera" className="nav-item">
                            <NavLink to={"/admin/parametros-cartera"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Parámetros cartera">
                                <i className=' glyphicon bx bx-cog'></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Parámetros cartera</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.CambioEtapaDW)}
                    >
                        <li id="AT_usuarios" className="nav-item">
                            <NavLink to="/admin/cambioetapadw" className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Usuarios">
                                <i className="icon-dark ri-map-pin-2-fill"></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Cambio Etapa DW</span>
                            </NavLink>
                        </li>
                    </ValidatePermission>
                    {/* <ValidatePermission
                        isActive={ValidatePermissionById(2, SubModule.ParametrosHistorialCartera)}>
                        <li id="AT_parametros_Cartera" className="nav-item">
                            <NavLink to={"/admin/parametros-cartera-historial"} className="nav-link collapsed text-dark" data-toggle="tooltip" data-placement="top" title="Parámetros cartera historial">
                                <i className=' glyphicon bx bx-cog'></i>
                                <span className={`${visible ? "d-none" : "d-block"}`}>Parámetros cartera historial</span>
                            </NavLink>
                        </li>
                    </ValidatePermission> */}

                </ul>
            </aside>
        </>
    )
}

export default Sidebar