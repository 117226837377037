import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import toast from "react-hot-toast";
import { FormatFecha, message } from "../../../../Utils/functions";

const GeolocalizacionDetalle = () => {
   const navigate = useNavigate();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [loading, setLoading] = useState(false);
   const [dataUbicaciones, setDataUbicaciones] = useState([])

   const GetAllUbicaciones = async() => {
      let NewData = []
      setLoading(true);
      const data = {
         "verb": "string",
         "id": datosConsulta?.sol_id,
         "field": datosConsulta?.vin,
         "value": datosConsulta?.contrato,
         "flag": true,
         "anyObject": {}
      }

      await CobranzaService.GetAllUbicacionesCliente(data)
         .then(res => {
            res?.data?.responseData?.data?.map(item=>{
               NewData.push(item)
            })
            setLoading(false)
         })
         .catch(e => {
            setLoading(false)
            if (e.response !== undefined)
               toast.error(message("¡Error!", e.response.data.message));
            else
               toast.error(message("¡Error!", e.message));
         })

         setDataUbicaciones(NewData)
   }

   useEffect(()=>{
      GetAllUbicaciones();
   },[]);

   const handleConsultarUbicacion = (values) => {
      const googleMapsUrl = `https://www.google.com/maps?q=${values.latitud},${values.longitud}`;
      window.open(googleMapsUrl, '_blank');
      console.log(values);
   }

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem"}}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-12 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                    <h2 className="fw-bold ">Consulta de geolocalización</h2>
                              </div>
                           </div>
                           <div className="row gap-2">
                              <div className="col col-12">
                                    <div className="row mt-4">
                                       <div className="col col-sm-4 col-xl-3">
                                          <div className="row justify-content-start">
                                                <div className="col col-12">
                                                   <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.item.tipo}</span>
                                                </div>
                                          </div>
                                       </div>
                                       <div className="col col-sm-5 col-xl-4">
                                          <div className="row justify-content-start">
                                                <div className="col col-12">
                                                   <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                                </div>
                                          </div>
                                       </div>
                                    </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                  <div className="px-4">
                     {
                        dataUbicaciones.length > 0 
                        ?
                           <>
                              {
                                 dataUbicaciones?.map((item, idx)=>{
                                    return <div className="row mt-2 px-4 d-flex align-items-center">
                                       <div className="col-4 mt-3">
                                          <p><i className="icon-blue ri-map-pin-add-line" style={{fontSize: '20px'}}></i><span style={{fontSize: '20px', fontWeight: 'bold'}}> ID {item.clave_ubicacion}</span>
                                          {item.punto_interes&&(<><span style={{color:'#6fcb00'}}> <i className="icon-green ri-focus-fill" style={{fontSize: '10px'}}></i> Lugar de interés</span></>)}</p>
                                          <p><span className="text-sm">Fecha de último movimiento: {FormatFecha(item.fecha_ultima_actualizacion)}</span></p>
                                       </div>
                                       <div className="col-2 mt-3">
                                          <div className="row justify-content-end ">
                                             <div>
                                                <button className='btn col-12 ' type='button' onClick={() => handleConsultarUbicacion(item)}>Ver ubicación</button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 })
                              }
                           </>
                        :  <div style={{textAlign: "center", width: "auto"}} >
                              <p><span style={{fontSize: "25px", fontWeight: "bold"}}>No se encontro información para mostrar</span></p>
                           </div>
                     }
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default GeolocalizacionDetalle;