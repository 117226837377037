import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
// import { tabActiveLog } from '../../../Redux/Slice/NavigateSliceLogs';
import { tabActiveLog } from '../../Redux/Slice/NavigateSliceLogs';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section } from '../../Auth/Authorization';
import { tabActiveTblSecundaria } from '../../Redux/Slice/navigateSliceTblSecundaria';
const Submenu = () => {
  const { value } = useSelector((state) => state.tabNavigateLogs);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    handleVigate(value);
  }, [value])


  const handleSetStateTab = (value) => {
    dispatch(tabActiveLog(value));
  }
  const handleVigate = (value) => {
    if (ValidatePermissionById(3, Section.TablasSecundariasOriginacion) && value === 1) {
      navigate(`/admin/tablasSecundarias/originacion`);
    }
    else if (ValidatePermissionById(3, Section.TablasSecundariasCartera) && value === 2) {
      navigate(`/admin/tablasSecundarias/cartera`);
    }
  }

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <ValidatePermission isActive={ValidatePermissionById(3, Section.TablasSecundariasOriginacion)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value == 1 && "tab-active"}`} value={1} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Tablas Originación</button>
          </li>
        </ValidatePermission>
        <ValidatePermission isActive={ValidatePermissionById(3, Section.TablasSecundariasCartera)}>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${value === 2 && "tab-active"}`} value={2} type="button" role="tab" onClick={(e) => { handleSetStateTab(e.target.value) }}>Tablas Cartera</button>
          </li>
        </ValidatePermission>
      </ul>
    </>
  )
}

export default Submenu