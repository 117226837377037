import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormatNumberMoney2, getDateFormat, message } from '../../../../Utils/functions'
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import DataTable from '../../../../Components/datatable/DataTable'
import ModalCostos from './ModalCostos'
import DSystemsServicios from '../../../../Services/DatamovilSystems/ServiciosDatamovil';

const CostosAdicionalesTable = ({ submitButton }) => {
    const { id } = useParams();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [loading, setLoading] = useState(false);
    const [totalPagos, setTotalPagos] = useState(0);
    const [initialValue, setInitialValue] = useState();
    const [data, setData] = useState([]);

    const columnsPagos = [
        { field: 'id', headerName: '#' },
        { field: 'fechaAlta', headerName: 'Fecha y hora' },
        { field: 'usuarioAltaStr', headerName: 'Ejecutivo' },
        { field: 'tipoCosto', headerName: 'Tipo de costo' },
        { field: 'concepto', headerName: 'Concepto' },
        { field: 'subtotal', headerName: 'Subtotal' },
        { field: 'iva', headerName: 'IVA' },
        { field: 'total', headerName: 'Total' },
    ];

    useEffect(() => {
        getAll()
    }, [])

    const getAll = async () => {
        setLoading(true);
        DSystemsServicios.getServicioCostosAdicionalesCosto(id)
            .then(res => {
                const updatedData = res.data.map((item, index) => ({
                    ...item,
                    id: index + 1
                }));
                
                const totalPagos = updatedData.reduce((sum, item) => sum + (item.total || 0), 0);

            setData(updatedData);
            setTotalPagos(totalPagos);

            })
            .catch(error => {
                console.error("Error al obtener los datos:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    

    const handleEdit = (item) => {
        console.log(item)
        setSelectedItem({ ...item });
        setIsOpenModal(true)
    }

    const handleDelete = (item) => {
        setLoading(true);
        DSystemsServicios.deleteServicioCostosAdicionalesCosto(item.servicioCostosAdicionalesCostoId)
            .then(res => {
                getAll()
            })
            .catch(error => {

            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className="row mt-2 mb-2 pt-4">
                <div className="col-auto">
                    <small><strong>costos adicionales</strong></small>
                </div>
                <div className="col-auto">
                    <fieldset
                        className='btn'
                        onClick={() => { setIsOpenModal(true); setSelectedItem({}) }}>
                        Registrar costo
                    </fieldset>
                </div>
                <div className="col-auto ms-auto ">
                    {
                        // initialValue.concluido ?
                        //     <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                        //     : 
                        <button
                            className='btn'
                            onClick={() => {
                                submitButton()
                            }}
                            //disabled={initialValue.concluido}
                            type='submit'>
                            Guardar
                        </button>
                        
                    }
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="col-12">
                    <DataTable
                        column={columnsPagos}
                        data={data}
                        editable={true}
                        handleEdit={handleEdit}
                        deleteable={true}
                        handleDelete={handleDelete}
                    />
                </div>
            </div>
            <div className="row mt-2 px-4 mb-2 justify-content-end">
                <div className="col-auto">
                    <small><strong>Total: ${FormatNumberMoney2(totalPagos)}</strong></small>
                </div>
            </div>
            {
                isOpenModal &&
                <ModalCostos
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    item={selectedItem}
                    valuesInit={initialValue}
                    handleGetAll={getAll}
                    toast={toast}
                />
            }
        </>
    );
};

export default CostosAdicionalesTable;
