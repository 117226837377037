import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Modal from './Modal';
import Spinner from '../Loadig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../TextField';
import { toast, Toaster } from 'react-hot-toast';
import { AddDays_CurrentDate, message, pageRows } from '../../Utils/functions';
import { useDispatch, useSelector } from 'react-redux';

import ClavesDemoService from '../../Services/Inventario/ClavesDemoService';

const AgregarClaveDemoModal = ({ modalMessage, setModalMessage, getClaves, claves }) => {
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const todayStr = AddDays_CurrentDate(1);

    const initial = {
        claveDemo: "",
        fechaVencimiento: "",
    };
    
    const [initialValue, setInitialValue] = useState(initial);
 
    const validationSchema = Yup.object().shape({
        claveDemo: Yup.string()
            .typeError('Debe ser un texto de 6 dígitos')
            .required('Campo requerido')
            .matches(/^\d{6}$/, 'Debe ser un texto de 6 dígitos numéricos')
            .test('is-unique-clave', 'La clave ya existe en el sistema', function(value) {
                if (!Array.isArray(claves) || claves.length === 0) {
                    return true;
                }
                return !claves.some(clave => clave.clave === value);
            }),
        fechaVencimiento: Yup.string()
            .typeError('Debe ser una fecha válida')
            .required('Campo requerido')
            .test('is-greater', 'La fecha de vencimiento no puede ser anterior a la fecha actual', function(value) {
                if (!value) return false;
                const today = new Date();
                const [year, month, day] = value.split('-').map(Number);
                const inputDate = new Date(year, month - 1, day);
                return inputDate >= today;
            })

            .test('is-not-duplicate', 'Ya existe una clave registrada con esta fecha de vencimiento', function (value) {
                if (!value) return false; // Si no hay valor, no es válido.
    
                // Función para convertir DD/MM/YYYY o YYYY-MM-DD a YYYY-MM-DD
                const convertToISO = (date) => {
                    if (!date) return null;
                    
                    // Si el formato ya es YYYY-MM-DD, simplemente lo retornamos
                    if (date.includes('-')) {
                        return date.split('T')[0]; // Solo tomamos la parte de la fecha antes de la "T"
                    }
                    
                    // Si el formato es DD/MM/YYYY, lo convertimos
                    const [day, month, year] = date.split('-');
                    return `${year}-${month}-${day}`;
                };
            
                try {
                    // Convertimos la fecha ingresada a YYYY-MM-DD
                    const inputDateISO = convertToISO(value);
                    if (!inputDateISO) return false; // Si la conversión falla, no es válido.
                    
                    // Verificamos si alguna de las claves tiene una fecha de vencimiento duplicada
                    return !claves.some(clave => {
                        if (!clave.fechavencimiento) {
                            console.warn('Fecha de vencimiento inválida o indefinida:', clave);
                            return false; // Ignorar claves sin fecha válida.
                        }
            
                        // Convertir la fecha de vencimiento de la clave a YYYY-MM-DD
                        const existingDateISO = new Date(clave.fechavencimiento).toISOString().split('T')[0];
                        console.log('Fecha de vencimiento existente:', existingDateISO);
                        return inputDateISO === existingDateISO;
                    });
                } catch (error) {
                    console.error('Error al procesar las fechas:', error);
                    return false;
                }
            }),
    });

    const handlerGuardar = async (values) => {
        try {
            setLoading(true);
            const resp = await ClavesDemoService.saveClaveDemo(values);
            if (resp.data.data.ban === 1) {
                getClaves();
                refFormik.current.resetForm();
                setModalMessage({ isOpen: false });
                toast.success(message("¡Corrrecto!", resp.data.data.mensaje));
            } else {
                toast.error(message("¡Error!", resp.data.data.mensaje));
                setModalMessage({ isOpen: false });
            }
        } catch (err) {
            toast.error(message("¡Error!", err?.message));
            console.error(err);
            setModalMessage({ isOpen: false });
        } finally {
            setLoading(false);
        }
    };

    const handleRegresar = () => {
        refFormik.current.resetForm();
        setModalMessage({ ...modalMessage, isOpen: false });
    };

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />

            <Formik
                enableReinitialize
                innerRef={refFormik}
                initialValues={initialValue}
                validationSchema={validationSchema}
            >
                {({ isValid, values, setFieldValue, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <section className='d-flex justify-content-end'>
                            <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                onClick={handleRegresar}><i className="ri-close-fill"></i></button>
                        </section>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-12">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <small id="AT_TxtBasicModalMenssage">
                                        A continuación ingresa la clave de 6 dígitos y su vigencia
                                    </small>
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="claveDemo"
                                                label="Clave"
                                                name="claveDemo"
                                                type="text"
                                                holder="Escribe"
                                                onChange={(event) => setFieldValue("claveDemo", event.target.value)}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField 
                                                id="fechaVencimiento" 
                                                label="Vigencia de la clave" 
                                                name="fechaVencimiento" 
                                                type="date" 
                                                min={todayStr}
                                                holder="Escribe" 
                                                onChange={(event) => setFieldValue("fechaVencimiento", event.target.value) }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={handleRegresar}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!isValid}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handlerGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default AgregarClaveDemoModal;
