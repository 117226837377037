import { Formik } from "formik"
import { useEffect, useRef, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom"
import { TextField } from "../../Components/TextField";
import { SelectField } from "../../Components/SelectField";
import Catalogo from "../../Services/Catalogos/Catalogo";
import toast, { Toaster } from "react-hot-toast";
import HorariosCalendarioService from "../../Services/ConfigCalendar/HorariosCalendarioService";
import { message } from "../../Utils/functions";

const TipoServicioModal = ({ modalMessage, setModalMessage, edit = false, getData, servicio }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const refFormik = useRef();
    console.log(servicio)
    const initial = {
        calendarioTipoId: id !== "0" ? id : "",
        tipo: servicio?.tipo || "",
        duracionAtencionServicioId: servicio?.duracionAtencionServicioId || "",
    };

    console.log(initial)
    const [initialValue, setInitialValue] = useState(initial);
    const [duraciones, setDuraciones] = useState([]);

    useEffect(() => {
        getTiempos();
    }, []);

    const getTiempos = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.DuracionAtencionServicio')
            .then(resp => {
                const items = resp.data.map(item => {
                    return {
                        value: item.DuracionAtencionServicioId,
                        label: item.DuracionAtencion
                    }
                });
                setDuraciones(items);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleSubmit = async (values) => {
        if(!edit) handlePost(values);
        else handlePut(values);
    }

    const handlePost = async (values) => {
        await HorariosCalendarioService.postServicio(values)
        .then(resp => {
            if(resp.data.ban === 1){
                toast.success(message(resp.data.message));
                setTimeout(() => {
                    navigate(`/admin/servicios-datamovil/${resp.data.calendarioTipoId}`)
                }, 2000);
            }else{
                toast.error(message(resp.data.message));
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(message('¡Oops..., ocurrió un error al intentar crear el servicio!'));
        })
        .finally(() => {
            setModalMessage({ isOpen: false });
        });
    }

    const handlePut = async (values) => {
        
        await HorariosCalendarioService.putServicio(values)
        .then(resp => {
            console.log(resp.data)
            if(resp.data.ban === 1){
                toast.success(message(resp.data.message));
                getData();
                // setTimeout(() => {
                //     navigate(`/admin/servicios-datamovil/${resp.data.calendarioTipoId}`)
                // }, 2000);
            }else{
                toast.error(message(resp.data.message));
            }
        })
        .catch(err => {
            console.error(err);
            toast.error(message('¡Oops..., ocurrió un error al intentar crear el servicio!'));
        })
        .finally(() => {
            setModalMessage({ isOpen: false });
        });
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Formik
                innerRef={refFormik}
                enableReinitialize={true}
                initialValues={initialValue}
            >
                {({ isValid, values, resetForm, setFieldValue, errors }) => (
                    <>
                        <Form>
                            <div className='p-2'>
                                <div className='justify-content-start align-items-center'>
                                    <div className="row">
                                        <div className="col col-12">
                                            <h5><strong>{!edit ? 'Agregar' : 'Editar'} servicio</strong></h5>
                                        </div>
                                    </div>
                                    {/* <pre>{ JSON.stringify(servicio, null, 2) }</pre> */}
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <TextField
                                                id="tipo"
                                                label="Nombre del servicio"
                                                name="tipo"
                                                type="text"
                                                holder="Nombre del servicio"
                                                onChange={(event) => {
                                                    setFieldValue("tipo", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-2">
                                        <div className="col col-12">
                                            <span className="fw-bold">Indica la duración del servicio</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col col-12">
                                            <SelectField id="duracionAtencionServicioId"
                                                label="Duración de atención"
                                                name="duracionAtencionServicioId"
                                                items={duraciones}
                                                onChange={(event) => {
                                                    setFieldValue("duracionAtencionServicioId", event.value);
                                                }} />
                                        </div>
                                    </div>
                                    <section className='d-flex mt-4 justify-content-end gap-2'>
                                        <button id="AT_BtnCloseModal" className='btn btn-outline d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={(e) => {
                                            e.preventDefault();
                                            resetForm();
                                            setModalMessage({ ...modalMessage, isOpen: false });
                                        }}>Cancelar</button>
                                        <button id="AT_BtnCloseModal" className='btn btn-blue d-flex justify-content-center align-items-center' style={{ paddingLeft: '25px', paddingRight: '25px' }} onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit(values);
                                            resetForm();
                                        }}>Continuar</button>
                                    </section>
                                </div>
                            </div>

                        </Form>
                    </>
                )
                }
            </Formik>
        </>
    );
}

export default TipoServicioModal;