import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Spinner from '../Loadig';
// import { SelectField } from '../../../Components/SelectField';
import { TextAreaField } from '../TextAreaField';
import Modal from './Modal';
import { SelectField } from '../SelectField';
import { TextField } from '../TextField';
import Catalogo from '../../Services/Catalogos/Catalogo';
import ClavesServices from '../../Services/Inventario/ClavesService';
import toast, { Toaster } from 'react-hot-toast';
import { message } from '../../Utils/functions';
import Garantias from '../../Services/Cartera/Garantias';

const SolicitarClaveModal = ({ data, isOpen, setIsOpen }) => {
   const refbtn = useRef();
   const refFormik = useRef();

   let initial = {
      vin: data?.vin,
      contrato: data?.numContrato,
      sol_id: data?.numSolicitud,
      motivo_clave: "",
      comentario_clave: "",
      interno: false,
      fecha_solicitud: ""
   }

   const [initialValue, setInitialValue] = useState(initial);
   const [motivosClave, setMotivosClave] = useState([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      getMotivos();
   }, []);

   const today = new Date();
   const minDate = today.toISOString().split('T')[0]; // Fecha mínima (hoy)
   const maxDate = new Date(today.setFullYear(today.getFullYear() + 1)).toISOString().split('T')[0]; // Fecha máxima (un año a partir de hoy)

   const isWeekend = (date) => {
      // const selectedDate = new Date(date.split("-").reverse().join("/"));
      console.log(date)
      const day = date.getDay();
      return day === 0 || day === 6;
   };

   // const generateDateRange = () => {
   //    const today = new Date();
   //    const minDate = today.toISOString().split('T')[0];
   //    return minDate;
   // };

   // const generateDateRange = () => {
   //    const today = new Date();
   //    today.setDate(today.getDate());
   //    return today.toISOString().split('T')[0];
   // };

   // const generateValidDateRange = () => {
   //    const today = new Date();
   //    const validDates = [];

   //    // Generar una fecha mínima que es hoy y la próxima fecha válida que no sea fin de semana
   //    while (isWeekend(today)) {
   //       today.setDate(today.getDate() + 1); // Saltar los fines de semana
   //    }

   //    validDates.push(today.toISOString().split('T')[0]); // Formato YYYY-MM-DD

   //    // Devolver solo la fecha válida
   //    return validDates[0];
   // };

   const handleDateChange = (field, value, fn) => {
      const date = value;
      const selectedDay = new Date(date).getDay();
      if (selectedDay === 5 || selectedDay === 6) {
         toast.error('No se puede seleccionar los días sábado o domingo')
         return;
      }
      fn(field, date);
   };

   const getMotivos = async () => {
      await Catalogo.getDropdownsByTable('Catalogos.MotivoClave')
         .then(resp => {
            const tmpList = resp.data.map(item => {
               return {
                  value: item.MotivoClave,
                  label: item.MotivoClave
               }
            });
            setMotivosClave(tmpList);
         })
         .catch(err => {
            console.error(err);
         });
   }

   const validate = Yup.object({
      motivo_clave: Yup.string()
         .required("El campo es requerido."),
      fecha_solicitud: Yup.string()
         .required("El campo es requerido.")
   });

   const handleSend = async (values) => {
      setLoading(true)
      await ClavesServices.sendClaveManual(values)
         .then(resp => {
            if (resp.data.ban === 1) {
               toast.success(message(resp.data.message));
               sendMail(values);
            } else {
               toast.error(message(resp.data.message));
            }
         })
         .catch(err => {
            toast.error(message('Ocurrió un error al intentar solicitar la clave'));
            console.error(err);
         })
         .finally(() => {
            setLoading(false)
            setIsOpen(false);
        });
   }

   const sendMail = (values) => {
      const contenido = {
         VARIABLE_EJECUTIVO: sessionStorage.getItem('usuario'),
         VARIABLE_CONTRATO: values.contrato,
         VARIABLE_FECHA: getOnlyDate(),
         VARIABLE_MOTIVO: values.motivo_clave,
         VARIABLE_COMENTARIO: values.comentario_clave,
      }

      Garantias.creardatosenviar({
         modulo: 'Solicitud de Clave Manual',
         tipoCorreoId: 102,
         contenido: JSON.stringify(contenido)
      }).catch(err => {
         console.log("Error al enviar correo de Solicitud de Clave Manual: ", err);
      })
   }

   const getOnlyDate = () => {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
   }


   return (
      <>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: '#47a066',
                     color: '#FFFF',
                     borderLeft: '10px solid #2f7246'
                  },
               },
               error: {
                  style: {
                     background: '#d53f3f',
                     color: '#FFFF',
                     borderLeft: '10px solid #ac241a'
                  },
               },
            }}
         />
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            ref={refFormik}
            validationSchema={validate}
            onSubmit={(values) => {
               handleSend(values);
            }}
         >{
               ({ isValid, values, setFieldValue }) => (
                  <>
                     <section className='my-2 mx-2' >
                        <Form>
                           <span className='fs-6' style={{ fontWeight: 'bold', color: "#000" }}><strong>Solicitar clave</strong></span>
                           <hr />
                           <span className="fs-6 fw-bold" style={{ color: "#1451A5" }}>
                              <i className='bx bx-info-circle'></i> Verifica que los datos estén activos para que solicitud proceda.
                           </span>
                           <p className='mt-3'><small style={{ color: "#000", fontWeight: "bold" }}>Completa la siguiente información para solicitar clave del vehículo</small></p>
                           <div className="mb-3 row" style={{ marginTop: 30 }}>
                              <div className="col-sm-6">
                                 <SelectField label="Motivo" name="motivo_clave" items={motivosClave} onChange={(e) => {
                                    setFieldValue("motivo_clave", e.value != 0 ? e.value : "");
                                 }} />
                              </div>
                              <div className="col-sm-6">
                                 <TextField
                                    label="Vigencia Clave"
                                    type="date"
                                    name="fecha_solicitud"
                                    min={minDate}
                                    max={maxDate}
                                    onChange={(e) => {
                                       handleDateChange("fecha_solicitud", e.target.value, setFieldValue)
                                       // setFieldValue("fecha_solicitud", e.target.value);
                                    }} />
                              </div>
                           </div>
                           <div className="mt-4 row">
                              <div className="col-sm-12 mb-2">
                                 <TextAreaField
                                    id="comentario_clave"
                                    className="form-control"
                                    rows="3"
                                    label="Comentarios"
                                    name="comentario_clave"
                                    type="text"
                                    holder="Escribe"
                                 />
                              </div>
                           </div>
                           <div className="mb-3">
                              <div className="form-check form-switch">
                                 <Field className="form-check-input" type="checkbox" name="interno" />
                                 <label className="form-check-label">Uso interno</label>
                              </div>
                           </div>
                           <section className='d-flex justify-content-end mt-5'>
                              <button id="AT_btnCancelar" className='btn btn-outline col-4 mw-100' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                              <button id="AT_btnGuardar" className='btn mx-2 col-4 mw-100' ref={refbtn} type="submit" disabled={!(isValid)}>Enviar</button>
                           </section>
                        </Form>
                     </section>
                  </>
               )
            }
         </Formik>
      </>
   )
}

export default SolicitarClaveModal
