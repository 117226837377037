import { createBrowserRouter } from "react-router-dom";
import LayoutMenu from "../Layout/LayoutMenu";
import NotFound from "../Pages/NotFound";
import Login from "../Pages/Login/Login";
import CatalogoLST from "../Pages/Catalogos/CatalogoLST";
import Home from "../Pages/Home";
import IntelimotorDetail from "../Pages/Catalogos/IntelimotorDetail";
import Intelimotor from "../Pages/Catalogos/Intelimotor";
import SocioComercial from "../Pages/Catalogos/SocioComercial";
import Datamovil from "../Pages/Catalogos/Datamovil";
import TablasSecundarias from "../Pages/TablasSecundarias/TablasSecundarias";
import Parametros from "../Pages/Parametros/SubmenuParam";
import Dataware from "../Pages/Parametros/Dataware";
import Acendes from "../Pages/Parametros/Acendes";
import IntelimotorParam from "../Pages/Parametros/Intelimotor";
import DataDocs from "../Pages/Parametros/Datadocs";
import SubmenuLogs from "../Pages/Logs/SubMenuLogs";
import Interfaces from "../Pages/Logs/Interfaces";
import Usuario from "../Pages/Logs/Usuario";
import Sistema from "../Pages/Logs/Sistema";
import UsuarioLst from "../Pages/Usuario/Usuario";
import InterfazDetail from "../Pages/Logs/InterfazDetail";
import UsuarioDetail from "../Pages/Logs/UsuarioDetail";
import SistemaDetail from "../Pages/Logs/SistemaDetail";
import UsuarioAddEdit from "../Pages/Usuario/UsuarioAddEdit";
import ConfCorreos from "../Pages/Notificaciones/confCorreos";
import CambioEtapa from "../Pages/CambioEtapa/CambioEtapa";
import HistorialEtapa from "../Pages/CambioEtapa/historialEtapa";
import InvIntelimotor from "../Pages/SyncInventario/InveIntelimotor";
import LayoutMenuSales from "../Layout/LayoutMenuSales";
import PreAltaHome from "../Pages/Ventas/Prealta/PreAlta";
import VehiculoPrealta from "../Pages/Ventas/Prealta/VehiculoVenta";
import InvIntelimitorDetail from "../Pages/SyncInventario/InvIntelimitorDetail";
import AddEditVehiculo from "../Pages/Ventas/Prealta/AddEditVehiculo";
import VehiculoAlta from "../Pages/Ventas/Alta/Vehiculoalta";
import VehiculoValoracion from "../Pages/Ventas/Valoracion/VehiculoValoracion";
import VehiculoNumSerie from "../Pages/Ventas/Alta/VehiculoNumSerie";
import VehiculoCambioUbicacion from "../Pages/Ventas/Alta/VahiculoCambioUbicacion";
import SubmenuDatadocs from "../Pages/AdminDatadocs/SubMenuDatadocs";
import DataFolder from "../Pages/AdminDatadocs/dataFolder";
import TipoDocumentoEtapa from "../Pages/AdminDatadocs/TipoDocumentoEtapa";
import TipoDocumentoUsuario from "../Pages/AdminDatadocs/TipoDocumentoUsuario";
import AltaDataDocs from "../Pages/Ventas/Docs/AltaDataDocs";
import DocsEliminados from "../Pages/AdminDatadocs/DocsEliminados";
import LayoutMenuInventario from "../Layout/LayoutMenuInventario";
import InventarioHome from "../Pages/Inventario/Inventario";
import SeguroHome from "../Pages/Seguro/Seguro";
import LayoutMenuSeguro from "../Layout/LayoutMenuSeguro";
import InvDatamovil from "../Pages/SyncInventario/IvnDatamovil";
import InvClavesDemo from "../Pages/Inventario/ClavesDemo/InvClavesDemo";
import SeguroCotizado from "../Pages/Seguro/Cotizado/SeguroCotizado";
import SeguroSinCotizado from "../Pages/Seguro/SinCotizar/SeguroSincotizar";
import SeguroSinCotizar from "../Pages/Seguro/SinCotizar/SeguroSincotizar";
import InvSocioComercial from "../Pages/Inventario/SocioComercial/InvSocioComercial";
import SeguroAddEdit from "../Pages/Seguro/SinCotizar/SeguroAddEdit";
import Password from "../Pages/Login/Password";
import ChangePassword from "../Pages/Login/ChangePassword";
import Clave from "../Pages/Login/Clave";
import SubmenuSync from "../Pages/SyncInventario/SubmenuSync";
import InvEliminarIntelimotor from "../Pages/SyncInventario/InvEliminarIntelimotor";
import CotizacionMensual from "../Pages/Seguro/Cotizado/CotizacionMensual";
import Responsiva from "../Pages/Inventario/Responsiva/Responsiva";
import SubMenuResponsiva from "../Pages/Inventario/Responsiva/SubMenuResponsiva";
import ResponsivaHistorial from "../Pages/Inventario/Responsiva/ResponsivaHistorial";
import VehiculoUtilitario from "../Pages/Inventario/utilitario/VehiculoUtilitario";
import SubmenuRenovacionSeguro from "../Pages/Seguro/RenovacionSeguro/SubmenuRenovacionSeguro";
import VehiculosEnContrato from '../Pages/Seguro/RenovacionSeguro/VehiculosEncontrato'
import RenovacionUtilitario from "../Pages/Seguro/RenovacionSeguro/RenovacionUtilitario";
import RenovacionSeguroAddEdit from "../Pages/Seguro/RenovacionSeguro/RenovacionSeguroAddEdit";
import CambioEtapaInventario from "../Pages/Inventario/CambioEtapa/CambioEtapaInventario";
import PruebaManejo from "../Pages/Inventario/PruebaManejo/PruebaManejo";
import VehiculoSeparado from "../Pages/Inventario/VehiculoSeparado/vehiculoSeparado";
import SubmenuSeguroAutorizado from "../Pages/Seguro/Autorizado/submenuSeguroAutorizado";
import PendienteAutorizar from "../Pages/Seguro/Autorizado/PendienteAutorizar";
import SegurosProporcionales from "../Pages/Seguro/Autorizado/SegurosProporcionales";
import SeguroAutorizado from "../Pages/Seguro/Autorizado/SeguroAutorizado";
import SeguroFinalAddEdit from "../Pages/Seguro/Autorizado/SeguroFinalAddEdit";
import SubMenuPoliza from "../Pages/Seguro/EmisionPoliza/SubMenuPoliza";
import SeguroAutorizadoPoliza from "../Pages/Seguro/EmisionPoliza/SeguroAutorizadoPoliza";
import VehiculoUtilitarioPoliza from "../Pages/Seguro/EmisionPoliza/VehiculoUtilitarioPoliza";
import SeguroRenovadoPoliza from "../Pages/Seguro/EmisionPoliza/SeguroRenovadoPoliza";
import VehiculoEnEnganche from "../Pages/Inventario/VehiculosEnganche/VehiculosEnEnganche";
import SubMenuInstalacion from "../Pages/Inventario/InstalacionDatamovil/SubmenuInstalacion";
import VehiculosSocioComercial from "../Pages/Inventario/InstalacionDatamovil/VehiculosSocioComercial";
import ProgramadoEnContrato from "../Pages/Inventario/InstalacionDatamovil/ProgramadoEncontrato";
import VehiculosDeInventario from "../Pages/Inventario/InstalacionDatamovil/VehiculosEnInventario";
import FirmaContrato from "../Pages/Inventario/FirmaContrato/FirmaContrato";
import SubMenuGestoria from "../Pages/Inventario/GestoriaVehiculos/SubMenuGestoria";
import VehiculoPendientePlaca from "../Pages/Inventario/GestoriaVehiculos/VehiculoPendientePlaca";
import PlacasAddEdit from "../Pages/Inventario/GestoriaVehiculos/PlacasAddEdit";
import LayoutMenuGarantias from "../Layout/LayoutMenuGarantias";
import GarantiasHome from "../Pages/Garantias/Garantias";
// import Llamada from "../Pages/Garantias/Llamadas/Llamadas";
// import GarantiasTab from "../Pages/Garantias/Contratos/Garantias";
// import LlamadasTab from "../Pages/Garantias/Contratos/Llamadas";
// import MantenimientosTab from "../Pages/Garantias/Contratos/Mantenimientos";
import IncidenciasLlamada from "../Pages/Garantias/Incidencias/IncidenciasLlamada";
import IncidenciasLlamadaInfo from "../Pages/Garantias/IncidenciasInfo";
import IncidenciasHome from "../Pages/Garantias/Incidencias";
import VehiculoEnGestoria from "../Pages/Inventario/GestoriaVehiculos/VehiculoEnGestoria";
import CancelacionContratoGestoria from "../Pages/Inventario/GestoriaVehiculos/CancelacionContratoGestoria";
import GestoriaRealizada from "../Pages/Inventario/GestoriaVehiculos/GestoriaRealizada";
import Citas from "../Pages/Garantias/Citas/Citas";
import SubMenuEndoso from "../Pages/Inventario/Endoso/SubMenuEndoso";
import PendienteEndoso from "../Pages/Inventario/Endoso/PendienteEndoso";
import EndosoValidado from "../Pages/Inventario/Endoso/EndosoValidado";
import VehiculoConvertido from "../Pages/Inventario/VehiculoConvertido/VehiculoConvertido";
import CitasPorAutorizar from "../Pages/Garantias/Citas/PorAutorizar";
import CitasPorAutorizarGarantias from "../Pages/Garantias/Citas/PorAutorizarGarantias";
import CitasPorAutorizarGruas from "../Pages/Garantias/Citas/PorAutorizarGruas";
import VehiculoEntrega from "../Pages/Inventario/Entrega/VehiculosEnEntrega";
import AgendaCitas from "../Pages/Garantias/Citas/Agenda/AgendaCitas";
import CitasAgendaGarantias from "../Pages/Garantias/Citas/Agenda/AgendaGarantias";
import CitasAgendaGruas from "../Pages/Garantias/Citas/Agenda/AgendaGruas";
import CitasCanceladas from "../Pages/Garantias/Citas/Canceladas/CitasCanceladas";
import CitasGarantiasCanceladas from "../Pages/Garantias/Citas/Canceladas/GarantiasCanceladas";
import CitasGruasCanceladas from "../Pages/Garantias/Citas/Canceladas/GruasCanceladas";
import ExpedienteEnProceso from "../Pages/Inventario/Expediente/ExpedienteEnProceso";
import HistorialContratos from "../Pages/Inventario/HistorialContratos/HistorialContratos";
import CostosInventario from "../Pages/Inventario/Costos/CostosInventario";
import CambioProducto from "../Pages/Inventario/CambioAProducto/CambioProducto";
import InspeccionesRapidas from "../Pages/Garantias/Inspecciones/Inspecciones";
import VehiculosEnCredito from "../Pages/Inventario/VehiculosCredito/VehiculosEnCredito";
import VehiculosSinRenovar from "../Pages/Seguro/VehiculoSinRenovar/VehiculoSinRenovar";
import SegurosRenovados from "../Pages/Seguro/RenovacionSeguro/SegurosRenovados";
import TablasSecundariasDinamicas from "../Pages/TablasSecundarias/TablasSecundariasDinamicas";
import ColoniasSepomex from "../Pages/Catalogos/ColoniasSepomex";
import GestoriaCanceladaCaptura from "../Pages/Inventario/GestoriaVehiculos/GestoriaCanceladaCaptura";
import EntregaCanceladaCaptura from "../Pages/Inventario/Entrega/EntregaCanceladaCaptura";
import VehiculoEnCancelacionPendiente from "../Pages/Inventario/Entrega/VehiculosEnCancelacionPendiente";
import SubMenuEnganche from "../Pages/Inventario/VehiculosEnganche/SubMenuEnganche";
import VehiculoPendienteEnganche from "../Pages/Inventario/VehiculosEnganche/VehiculosPendienteEnganche";
import SubMenuVentacontado from "../Pages/Inventario/VentaContado/SubMenuVentaContado";
import VehiculoVentaContado from "../Pages/Inventario/VentaContado/VentaContado";
import Devoluciones from "../Pages/Garantias/devoluciones/devoluciones";
import DevolucionesInfo from "../Pages/Garantias/devoluciones/devolucioninfo";
import AddEditRevisionDeUnidad from "../Pages/Inventario/RevisionDeUnidad/AddEditRevisionDeUnidad";
import VehiculoVentaContadoSeguimiento from "../Pages/Inventario/VentaContado/VentaContadoSeguimiento";
import AutorizadoAddEdit from "../Pages/Inventario/VentaContado/AutorizadoAddEdit";
import SubMenuEntrega from "../Pages/Inventario/Entrega/SubMenuEntrega";
import IndexTablas from "../Pages/TablasSecundarias/IndexTablas";
import ProcesosHome from "../Pages/ProcesosCierre/Procesos";
import LayoutMenuProcesos from "../Layout/LayourMenuProcesos";
import Resguardos from "../Pages/ProcesosCierre/resguardos/resguardos";
import Prealta from "../Pages/ProcesosCierre/resguardos/prealta";
import ResguardoCard from "../Pages/ProcesosCierre/resguardos/resguardoCard";
import SeguimientoDeCierre from "../Pages/ProcesosCierre/SeguimientoCierre/seguimientoCierre";
import CierreCard from "../Pages/ProcesosCierre/cierres/CierreCard";
import VehiculoVentaContadoConcluido from "../Pages/Inventario/VentaContado/VentaContadoConcluido";
import SubMenuCancelacionSeguro from "../Pages/Seguro/CancelacionSeguro/SubMenuCancelacionSeguro";
import SeguroSinPago from "../Pages/Seguro/CancelacionSeguro/SeguroSinPago";
import SeguroPagado from "../Pages/Seguro/CancelacionSeguro/SeguroPagado";
import SubMenuPolizasCanceladas from "../Pages/Seguro/PolizasCancelada/SubMenuPolizasCanceladas";
import PolizasNoPagadas from "../Pages/Seguro/PolizasCancelada/PolizasNoPagadas";
import PolizasPagadas from "../Pages/Seguro/PolizasCancelada/PolizasPagadas";
import SubMenuPagoDesembolso from "../Pages/Seguro/Pago_Desembolso/SuMenuPagoDesembolso";
import PendientesPago from "../Pages/Seguro/Pago_Desembolso/PendientesPago";
import PendientesDesembolsoPago from "../Pages/Seguro/CancelacionSeguro/PendientesDesembolsoPago";
import ConfigDataDocs from "../Pages/ConfigDataDocs/ConfigDataDocs";
import UploadDataDocs from "../Pages/ConfigDataDocs/UploadDataDocs";
import DatamovilSeguimiento from "../Pages/ProcesosCierre/datamovil/datamovilSeguimiento";
import DatamovilCard from "../Pages/ProcesosCierre/datamovil/datamovilCard";
import EliminarExterno from "../Pages/Inventario/EliminarExterno/EliminarExterno";
import DatosGenerales from "../Pages/Inventario/DatosGenerales/DatosGenerales";
import CobranzaHome from "../Pages/Cobranza/Cobranza";
import LayoutMenuCobranza from "../Layout/LayoutMenuCobranza";
import AccesoriosSeguimiento from "../Pages/Cobranza/FinanciamientoAccesorios/accesoriosSeguimiento";
import ValuacionesSeguimiento from "../Pages/ProcesosCierre/valuaciones/ValuacionesSeguimiento";
import ValuacionesCard from "../Pages/ProcesosCierre/valuaciones/valuacionesCard";
import PolizasCanceladas from "../Pages/Seguro/PolizasVigentes/PolizasVigentes";
import PolizasVigentes from "../Pages/Seguro/PolizasVigentes/PolizasVigentes";
import ProcesoDePagoPD from "../Pages/Seguro/Pago_Desembolso/ProcesoDePagoPD";
import SubMenuComisiones from "../Pages/Seguro/Comisiones/SubMenuComisiones";
import PendientesPagoComision from "../Pages/Seguro/Comisiones/PendientesPagoComision";
import ProcesoPagoComision from "../Pages/Seguro/Comisiones/ProcesoPagoComision";
import SeguimientoPolizasPorCancelar from "../Pages/Garantias/polizasPorCancelar/PolizasPorCancelar";
import ComisionDeEmpresasPendientePago from "../Pages/Inventario/Comisiones/ComisionEmpresa/ComisionDeEmpresasPendientePago";
import ComisionDeEmpresasProcesoPago from "../Pages/Inventario/Comisiones/ComisionEmpresa/ComisionDeEmpresasProcesoPago";
import ComisionesEmpresaHome from "../Pages/Inventario/Comisiones/ComisionEmpresa/ComisionesEmpresaHome";
import ComisionDeEmpresasComisionesPagadas from "../Pages/Inventario/Comisiones/ComisionEmpresa/ComisionDeEmpresaComisionesPagadas";
import SubMenuComisionesInventario from "../Pages/Inventario/Comisiones/SubMenuComisionesInventario";
import ComisionesExternosaHome from "../Pages/Inventario/Comisiones/ComisionExterno/ComisionExternoHome";
import ComisionExternoPendientePago from "../Pages/Inventario/Comisiones/ComisionExterno/ComisionExternoPendientePago";
import ComisionExternoProcesoPago from "../Pages/Inventario/Comisiones/ComisionExterno/ComisionExternoProcesoPago";
import ComisionExternoComisionesPagadas from '../Pages/Inventario/Comisiones/ComisionExterno/ComisionExternoComisionesPagadas'
import CobroComisionExternoHome from "../Pages/Inventario/Comisiones/CobroComisionExterno/CobroComisionExternoHome";
import CobroComisionExternoPendientePago from "../Pages/Inventario/Comisiones/CobroComisionExterno/CobroComisionExternoPendientePago";
import CobroComisionExternoProcesoPago from "../Pages/Inventario/Comisiones/CobroComisionExterno/CobroComisionExternoprocesoPago";
import ComisionesExternasCobradas from "../Pages/Inventario/Comisiones/CobroComisionExterno/ComisionesExternasCobradas";
import PagoUnidadesSeguimiento from "../Pages/Cobranza/PagoUnidad/PagoUnidadesSeguimiento";
import EncabezadoPendientesPago from "../Pages/Cobranza/PagoUnidad/EncabezadoPendientesPago";
import SeguimientoProcesoPago from "../Pages/Cobranza/PagoUnidad/SeguimientoProcesoPago";
import SeguimientoProcesoPagados from "../Pages/Cobranza/PagoUnidad/SeguimientoProcesoPagados";
import SeguimientoSiniestros from "../Pages/Seguro/Siniestros/seguimientoSiniestros";
import SiniestroCard from "../Pages/Seguro/Siniestros/siniestroCard";
import GestoriaHome from "../Pages/Gestoria/GestoriaHome";
import LayoutMenuGestoria from "../Layout/LayoutMenuGestoria";
import PagoRefrendoPlacas from "../Pages/Gestoria/PagoRefrendoYPlacas/PagoRefrendoYPlacas";
import PendienteEnviarAcendes from "../Pages/Gestoria/PendienteEnviarAcendes/PendienteEnviarAcendes";
import GestoriaCard from "../Pages/Gestoria/Seguimiento/GestoriaCard";
import SeguimientoGestoria from "../Pages/Gestoria/Seguimiento/SeguimientoGestoria";
import SeguimientoProcesoPagoPR from "../Pages/Gestoria/PagoRefrendoYPlacas/SeguimientoProcesoPago";
import Roles from "../Pages/Roles/Roles";
import RolAddEditPrivilegios from "../Pages/Roles/RolAddEdditPrivilegios";
import RevisionUnidadRechazada from "../Pages/Inventario/RevisionUnidadRechazada/RevisionUnidadRechazada";
import ReenviarPoliza from "../Pages/Seguro/EmisionPoliza/ReenviarPoliza";
import DiasFestivos from "../Pages/DiasFestivos/DiasFestivos";
import SubMenuJobs from "../Pages/ProgramacionJobs/SubMenuJobs";
import ProgramacionJobsCartera from "../Pages/ProgramacionJobs/ProgramacionJobsCartera";
import ProgramacionJobsOriginacion from "../Pages/ProgramacionJobs/ProgramacionJobsOriginacion";
import ProgramacionJobsCarteraDetalle from "../Pages/ProgramacionJobs/ProgramacionJobsCarteraDetalle";
import AreasCobradores from "../Pages/Cobranza/AreasDeCobradores/AreasCobradores";
import CobradoresDetalle from "../Pages/Cobranza/Cobradores/CobradoresDetalle";
import CobradoresNivel1 from "../Pages/Cobranza/Cobradores/CobradoresNivel1";
import CobradoresNivel2 from "../Pages/Cobranza/Cobradores/CobradoresNivel2";
import SupervisionDeCargaNivel1 from "../Pages/Cobranza/SupervisionDeCarga/SupervisionDeCargaNivel1";
import SupervisionDeCargaNivel2 from "../Pages/Cobranza/SupervisionDeCarga/SupervisionDeCargaNivel2";
import ReasignarCargaNivel1 from "../Pages/Cobranza/SupervisionDeCarga/ReasignarCargaNivel1";
import ReasignarCargaNivel2 from "../Pages/Cobranza/SupervisionDeCarga/ReasignarCargaNivel2";
import ValuacionDetalle from "../Pages/Inventario/SeguimientoDeValuacion/ValuacionDetalle";
import Valuacion from "../Pages/Inventario/SeguimientoDeValuacion/Valuacion";
//import RegistroDeGastosCard from "../Pages/Inventario/SeguimientoDeValuacion/RegistroDeGastos/RegistroDeCostosCard";
import RegistroDeCostos from "../Pages/Inventario/SeguimientoDeValuacion/RegistroDeGastos/RegistroDeGastos";
import InicioDeValuacion from "../Pages/Inventario/SeguimientoDeValuacion/InicioValuacion/InicioDeValuacion";

import SubMenuSeguimiento from "../Pages/Cobranza/SeguimientoTelefonico/SubMenuSeguimiento";
import SeguimientoCobranza from "../Pages/Cobranza/SeguimientoTelefonico/SeguimientoCobranza";
import PromesasPago from "../Pages/Cobranza/SeguimientoTelefonico/PromesasPago";
import SeguimientoVisita2 from "../Pages/Cobranza/SeguimientoTelefonico/SeguimientoVisita2";
import SeguimientoCobranzaJuridico3 from "../Pages/Cobranza/SeguimientoTelefonico/SeguimientoCobranzaJuridico3";
import SeguimientoCobranzaJuridico4 from "../Pages/Cobranza/SeguimientoTelefonico/SeguimientoCobranzaJuridico4";
import SeguimientoCobranzaDetalle from "../Pages/Cobranza/SeguimientoTelefonico/SeguimientoTelefonicoDetalle";
import ValoracionDeUnidad from "../Pages/Inventario/SeguimientoDeValuacion/ValoracionUnidad/ValoracionDeUnidad";
import InspeccionRapidaValuacion from "../Pages/Inventario/SeguimientoDeValuacion/InspeccionRapida/InspeccionRapidaValuacion";
import AutorizacionSolicitudes from "../Pages/Cobranza/AutorizaciondeSolicitudes/autorizaciondesolicitudes";
import DetalleRegistroSiniestro from "../Pages/Seguro/Siniestros/Detalle/DetalleRegistroSiniestro";
import CarteraGeneral from "../Pages/Cobranza/SeguimientoVisita/CarteraGeneral";
import SeguimientoVisita from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/SeguimientoVisita";
import RutasCobranza from "../Pages/Cobranza/SeguimientoVisita/RutaCobranza/RutasCobranza";
import HistorialVisitas from "../Pages/Cobranza/SeguimientoVisita/HistorialVisitas";
import RutaDetalle from "../Pages/Cobranza/SeguimientoVisita/RutaCobranza/RutaDetalle";
import HistorialVisitasDetalle from "../Pages/Cobranza/SeguimientoVisita/HistorialVisitasDetalle";
import SeguimientoVisitaDetalle from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/SeguimientoVisitaDetalle";
import BoletinarDetalle from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/BoletinarDetalle";
import ContactoDetalle from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/ContactoDetalle";
import BoletinarAgregar from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/BoletinadoAgregar";
import DireccionesDetalle from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/DireccionesDetalle";
import ReferenciasDetalle from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/ReferenciasDetalle";
import ReferenciasAgregar from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/ReferenciasAgregar";
import GeolocalizacionDetalle from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/GeolocalizacionDetalle";
import ContactoAgregar from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/ContactoAgregar";
import DireccionesAgregar from "../Pages/Cobranza/SeguimientoVisita/SeguimientoVisita/DireccionesAgregar";
import CalendarDinamical from "../Components/Calendar/CalendarDynamical";
import CitasDinamicas from "../Pages/Garantias/Citas/CitasDinamicas";
import SiniestroValuacion from "../Pages/Seguro/Siniestros/Valuacion/SiniestroValuacion";
import SiniestroDetalle from "../Pages/Seguro/Siniestros/Detalle/SiniestroDetalle";
import LiberacionDeUnidad from "../Pages/Seguro/Siniestros/LiberacionDeUnidad/LiberacionDeUnidad";
import CierreSiniestro from "../Pages/Seguro/Siniestros/CierreDeSiniestro/CierreSiniestro";
import SubMenuAutorizacionDeSolicitudes from "../Pages/Seguro/AutorizacionDeSolicitudes/SubMenuAutorizacionDeSolicitudes";
import AutorizacionDictamenListado from "../Pages/Seguro/AutorizacionDeSolicitudes/AutorizacionDictamen/AutorizacionDictamenListado";
import ValucionEnRoboListado from "../Pages/Seguro/AutorizacionDeSolicitudes/ValuacionEnRobo/ValucionEnRoboListado";
import SolicitudDeCitasListado from "../Pages/Seguro/AutorizacionDeSolicitudes/SolicitudDeCitas/SolicitudDeCitasListado";
import UnidadesPendientesPago from "../Pages/Inventario/PagodeUnidad/UnidadesPendientes";
import SeguimientoPagoUnidades from "../Pages/Inventario/PagodeUnidad/SeguimientoUnidades";
import SeguimientoBoletinado from "../Pages/Cobranza/SeguimientoBoletinado/SeguimientoBoletinado";
import SeguimientoSiniestrosList from "../Pages/Seguro/Siniestros/SeguimientoSiniestrosList";
import UnidadesRecuperadas from "../Pages/Cobranza/UnidadesRecuperadas/UnidadesRecuperadas";
import UnidadesRecuperadasDetalle from "../Pages/Cobranza/UnidadesRecuperadas/UnidadesRecuperadasDetalle";
import SubMenuSeguimientoVisita from "../Pages/Cobranza/SeguimientoVisita/SubMenuSeguimientoVisita";
import ParametrosCartera from "../Pages/ParametrosCartera/ParametrosCartera";
import ParametrosCarteraHistorial from "../Pages/ParametrosCartera/ParametrosCarteraHistorial";
import PagodeUnidadDetalle from "../Pages/Inventario/PagodeUnidad/PagodeUnidadDetalle";
import ListadoSeguimientoGestoria from "../Pages/Cobranza/SeguimientoGestoria/ListadoSeguimientoGestoria";
import ResguardoUnidad from "../Pages/Inventario/ResguardoUnidad/ResguardoUnidad";
import ResguardoUnidadDetalle from "../Pages/Inventario/ResguardoUnidad/Cards/ResguardoUnidadDetalle";
import UnidadesEnRemateInventario from "../Pages/Inventario/UnidadesEnRemate/UnidadesEnRemate";
import UnidadesEnRemateDetalleInventario from "../Pages/Inventario/UnidadesEnRemate/UnidadesEnRemateDetalle";
import UnidadesEnRemateProcesos from "../Pages/ProcesosCierre/GestionDeRemate/UnidadesEnRemate";
import UnidadesEnRemateDetalleProcesos from "../Pages/ProcesosCierre/GestionDeRemate/UnidadesEnRemateDetalle";
import UnidadesEnRemate from "../Pages/Cobranza/GestionDeRemate/UnidadesEnRemate/UnidadesEnRemate";
import UnidadesEnRemateDetalle from "../Pages/Cobranza/GestionDeRemate/UnidadesEnRemate/UnidadesEnRemateDetalle";
import Test from "../Pages/Garantias/Test";
import ProcesoFirma from "../Pages/ProcesosCierre/SeguimientoCierre/ProcesoFirma";
import AgendarFirma from "../Pages/ProcesosCierre/SeguimientoCierre/AgendarFirma";
import ListadoPrincipalSolicitudes from "../Pages/ProcesosCierre/Autorizaciones/ListadoPrincipal";
import LayoutMenuDatamovil from "../Layout/LayoutMenuDatamovil";
import SolicitudesDatamovilSystems from "../Pages/DatamovilSystems/Autorizaciones/solicitudes";
import SeguimientoServiciosXTipo from "../Pages/DatamovilSystems/Listados/SeguimientoServiciosXTipo";
import AsignacionesDatamovilSystems from "../Pages/DatamovilSystems/Asignaciones/Asignaciones";
import AsignacionServicios from "../Pages/DatamovilSystems/Asignaciones/AsignacionServicios";
import DatamovilSystems from "../Pages/DatamovilSystems/DatamovilSystems";
import AgendarCita from "../Pages/DatamovilSystems/Revision/AgendarCita";
import InicioServicio from "../Pages/DatamovilSystems/Revision/InicioServicio";
import RevisionDatamovil from "../Pages/DatamovilSystems/Revision/RevisionDatamovil";
import OrdenServicio from "../Pages/DatamovilSystems/Revision/OrdenServicio";
import DatamovilPrealta from "../Pages/DatamovilSystems/Prealta/DatamovilPrealta";
import CitaEntregaUnidad from "../Pages/Cobranza/CitaEntregaUnidad/CitaEntregaUnidad";
import InicioTramite from "../Pages/Gestoria/PasosSeguimiento/InicioTramite";
import SeguimientoAgendarCita from "../Pages/Gestoria/PasosSeguimiento/AgendarCita";
import GestoriaProcesoDeTramite from "../Pages/Gestoria/Seguimiento/ProcesoDeTramite/GestoriaProcesoDeTramite";
import GestoriaConcluirTramite from "../Pages/Gestoria/Seguimiento/ConcluirTramite/GestoriaConcluirTramite";
import GeneracionExpedientes from "../Pages/Cobranza/GeneracionExpedientes/generacionexpedientes";
import SeguimientoExpedientes from "../Pages/ProcesosCierre/Expedientes/SeguimientoExpedientes";
import DetalleExpediente from "../Pages/ProcesosCierre/Expedientes/DetalleExpediente";
import AutorizacionDeSolicitidesListado from "../Pages/Gestoria/GestionDeSolicitudes/AutorizacionDeSolicitidesListado";
import SeguimientoBoletinadoList from "../Pages/Seguro/SeguimientoBoletinado/SeguimientoBoletinadoList";
import SeguimientoLecturaClaves from "../Pages/Cobranza/LecturaClaves/SeguimientoLecturaClaves";
import ServiciosDatamovil from "../Pages/ServiciosDatamovil/ServiciosDatamovil";
import DetalleServicio from "../Pages/ServiciosDatamovil/DetalleServicio";
import ConfiguracionUbicacion from "../Pages/ServiciosDatamovil/ConfiguracionUbicacion";
import RegresionManual from "../Pages/RegresionManual/RegresionManual";
//const auth = new  AuthLogin();
export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Login />,
            }
        ]
    },
    {
        path: "/password",
        element: <Password />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Login />,
            }
        ]
    },
    {
        path: "/changePassword/:id",
        element: <ChangePassword />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Login />,
            }
        ]
    },
    {
        path: "/clave",
        element: <Clave />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Clave />,
            }
        ]
    },
    {
        path: "/",
        element: <LayoutMenu />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "/admin",
                element: <Home />,
            },
            {
                path: "/datadocs",
                element: <UploadDataDocs />,
            },
            {
                path: "/admin/catalogo",
                element: <CatalogoLST />,
            },
            //Catalogos
            {
                path: "/admin/catalogo/datamovil",
                element: <Datamovil />,
            },
            {
                path: "/admin/catalogo/intelimotor",
                element: <Intelimotor />,
            },
            {
                path: "/admin/catalogo/sociocomercial",
                element: <SocioComercial />
            },
            {
                path: "/admin/catalogo/intelimotor/:id",
                element: <IntelimotorDetail />
            },
            // Tablas Secundarias
            {
                path: "/admin/tablasSecundarias",
                element: <IndexTablas />
            },
            {
                path: "/admin/tablasSecundarias/originacion",
                element: <TablasSecundarias />
            },
            {
                path: "/admin/tablasSecundarias/cartera",
                element: <TablasSecundariasDinamicas />
            },
            //Colonias Sepomex
            {
                path: "/admin/coloniasepomex",
                element: <ColoniasSepomex />
            },
            //Parametros
            {
                path: "/admin/parametros",
                element: <Parametros />
            },
            {
                path: "/admin/parametros/dataware",
                element: <Dataware />
            },
            {
                path: "/admin/parametros/acendes",
                element: <Acendes />
            },
            {
                path: "/admin/parametros/intelimotor",
                element: <IntelimotorParam />
            },
            {
                path: "/admin/parametros/datadocs",
                element: <DataDocs />
            },
            {
                path: "/admin/parametros-cartera",
                element: <ParametrosCartera />
            },
            {
                path: "/admin/parametros-cartera-historial",
                element: <ParametrosCarteraHistorial />
            },
            //Logs
            {
                path: "/admin/logs",
                element: <SubmenuLogs />
            },
            {
                path: "/admin/logs/interfaces",
                element: <Interfaces />
            },
            {
                path: "/admin/logs/interfaces/eliminado",
                element: <InterfazDetail />
            },
            {
                path: "/admin/logs/usuario",
                element: <Usuario />
            },
            {
                path: "/admin/logs/usuario/eliminado",
                element: <UsuarioDetail />
            },
            {
                path: "/admin/logs/sistema",
                element: <Sistema />
            },
            {
                path: "/admin/logs/sistema/eliminado",
                element: <SistemaDetail />
            },
            // Servicios Datamovil
            {
                path: "/admin/servicios-datamovil",
                element: <ServiciosDatamovil />
            },
            {
                path: "/admin/servicios-datamovil/:id",
                element: <DetalleServicio />
            },
            {
                path: "/admin/servicios-datamovil/:id/:ubicacionId",
                element: <ConfiguracionUbicacion />
            },
            // Usuarios
            {
                path: "/admin/usuario",
                element: <UsuarioLst />
            },
            {
                path: "/admin/usuario/:id",
                element: <UsuarioAddEdit />
            },
            //Roles
            {
                path: "/admin/rol",
                element: <Roles />
            },
            {
                path: "/admin/rol/:id",
                element: <RolAddEditPrivilegios />
            },
            //Correos
            {
                path: "/admin/configuracion",
                element: <ConfCorreos />
            },
            //cambio de etapa
            {
                path: "/admin/configuracionetapa",
                element: <CambioEtapa />
            },
            {
                path: "/admin/configuracionetapa/historial",
                element: <HistorialEtapa />
            },
            //SyncInventario
            {
                path: "/admin/inventario",
                element: <SubmenuSync />
            },
            {
                path: "/admin/inventario/intelimotor",
                element: <InvIntelimotor />
            },
            {
                path: "/admin/inventario/eliminarintelimotor",
                element: <InvEliminarIntelimotor />
            },
            {
                path: "/admin/inventario/dataware",
                element: <InvDatamovil />
            },
            {
                path: "/admin/inventario/intelimotor/:id",
                element: <InvIntelimitorDetail />
            },

            //Docs
            {
                path: "/admin/datadocs",
                element: <SubmenuDatadocs />
            },
            {
                path: "/admin/datadocsconfig",
                element: <ConfigDataDocs />
            },
            {
                path: "/admin/datadocs/folder",
                element: <DataFolder />
            },
            {
                path: "/admin/datadocs/etapa",
                element: <TipoDocumentoEtapa />
            },
            {
                path: "/admin/datadocs/usuario",
                element: <TipoDocumentoUsuario />
            },
            {
                path: "/admin/datadocs/eliminado",
                element: <DocsEliminados />
            },
            //Programación de Jobs
            {
                path: "/admin/jobs",
                element: < SubMenuJobs />
            },
            {
                path: "/admin/jobs/originacion",
                element: < ProgramacionJobsOriginacion />
            },
            {
                path: "/admin/jobs/cartera",
                element: < ProgramacionJobsCartera />
            },
            {
                path: "/admin/jobs/cartera/:id",
                element: < ProgramacionJobsCarteraDetalle />
            },
            //Dias Festivos
            {
                path: "/admin/diasfestivos",
                element: < DiasFestivos />
            },
            //Regresion manual
            {
                path: "/admin/cambioetapadw",
                element: <RegresionManual />
            },
        ]
    },
    // {
    //     path: "/venta",
    //     element: <LayoutMenuSales />,
    //     errorElement: <NotFound />,
    //     children: [
    //         {
    //             index: true,
    //             element: <PreAltaHome />,
    //         },
    //         {
    //             path: "/venta/vehiculo",
    //             element: <VehiculoPrealta />,
    //         },
    //         {
    //             path: "/venta/vehiculo/prealta/:id",
    //             element: <AddEditVehiculo />,
    //         },
    //         {
    //             path: "/venta/vehiculo/alta/:id",
    //             element: <VehiculoAlta />,
    //         },
    //         {
    //             path: "/venta/vehiculo/numeroserie/:id",
    //             element: <VehiculoNumSerie />,
    //         },
    //         {
    //             path: "/venta/vehiculo/valoracion/:id",
    //             element: <VehiculoValoracion />,
    //         },
    //         {
    //             path: "/venta/vehiculo/cambioubicacion/:id",
    //             element: <VehiculoCambioUbicacion />,
    //         },
    //         {
    //             path: "/venta/vehiculo/datadocs/:id",
    //             element: <AltaDataDocs />,
    //         },
    //     ]
    // }
    {
        path: "/inventario",
        element: <LayoutMenuInventario />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <InventarioHome />,
            },
            {
                path: "/inventario/clavesdemo",
                element: <InvClavesDemo />,
            },
            {
                path: "/inventario/vehiculo",
                element: <VehiculoPrealta />,
            },
            {
                path: "/inventario/vehiculo/prealta/:id",
                element: <AddEditVehiculo />,
            },
            {
                path: "/inventario/vehiculo/datosgenerales/:id",
                element: <DatosGenerales />,
            },
            {
                path: "/inventario/vehiculo/alta/:id",
                element: <VehiculoAlta />,
            },
            {
                path: "/inventario/numeroserie/:id",
                element: <VehiculoNumSerie />,
            },
            {
                path: "/inventario/vehiculo/valoracion/:id",
                element: <VehiculoValoracion />,
            },
            {
                path: "/inventario/cambioubicacion/:id",
                element: <VehiculoCambioUbicacion />,
            },
            {
                path: "/inventario/vehiculo/datadocs/:id",
                element: <AltaDataDocs />,
            },
            {
                path: "/inventario/vehiculo/revisiondeunidad/:id",
                element: <AddEditRevisionDeUnidad />,
            },
            {
                path: "/inventario/sociocomercial",
                element: <InvSocioComercial />,
            },
            {
                path: "/inventario/sociocomercial/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            {
                path: "/inventario/responsiva",
                element: <SubMenuResponsiva />,
            },
            {
                path: "/inventario/responsiva/:id",
                element: <Responsiva />,
            },
            {
                path: "/inventario/responsiva/historial/:id",
                element: <ResponsivaHistorial />,
            },
            {
                path: "/inventario/utilitario",
                element: <VehiculoUtilitario />,
            },
            {
                path: "/inventario/cambioetapa/:id",
                element: <CambioEtapaInventario />,
            },
            {
                path: "/inventario/cambioproducto/:id",
                element: <CambioProducto />,
            },
            {
                path: "/inventario/pruebamanejo",
                element: <PruebaManejo />,
            },
            {
                path: "/inventario/vehiculoseparado",
                element: <VehiculoSeparado />,
            },
            {
                path: "/inventario/enganche",
                element: <SubMenuEnganche />,
            },
            {
                path: "/inventario/enganche/enganchecompleto",
                element: <VehiculoEnEnganche />,
            },
            {
                path: "/inventario/enganche/pendienteenganche",
                element: <VehiculoPendienteEnganche />,
            },
            //Instalaciones
            {
                path: "/inventario/instalaciones",
                element: <SubMenuInstalacion />,
            },
            {
                path: "/inventario/instalaciones/vehículoinventario",
                element: <VehiculosDeInventario />,
            },
            {
                path: "/inventario/instalaciones/vehículosociocomercial",
                element: <VehiculosSocioComercial />,
            },
            {
                path: "/inventario/instalaciones/programadocontrato",
                element: <ProgramadoEnContrato />,
            },
            //
            {
                path: "/inventario/firmacontrato",
                element: <FirmaContrato />,
            },
            //Gestoria
            {
                path: "/inventario/gestoria",
                element: <SubMenuGestoria />,
            },
            {
                path: "/inventario/gestoria/vehiculopendiente",
                element: <VehiculoPendientePlaca />,
            },
            {
                path: "/inventario/gestoria/placas/:id",
                element: <PlacasAddEdit />,
            },
            {
                path: "/inventario/gestoria/vehiculoengestoria",
                element: <VehiculoEnGestoria />,
            },
            {
                path: "/inventario/gestoria/cancelaciongestoria",
                element: <CancelacionContratoGestoria />,
            },
            {
                path: "/inventario/gestoria/gestoriarealizada",
                element: <GestoriaRealizada />,
            },
            {
                path: "/inventario/gestoria/capturagestoriacancelada/:id",
                element: <GestoriaCanceladaCaptura />,
            },
            //Endoso
            {
                path: "/inventario/endoso",
                element: <SubMenuEndoso />,
            },
            {
                path: "/inventario/endoso/pendienteendoso",
                element: <PendienteEndoso />,
            },
            {
                path: "/inventario/endoso/endosovalido",
                element: <EndosoValidado />,
            },
            //Vehiculos Convertidos
            {
                path: "/inventario/vehiculosconvertidos",
                element: <VehiculoConvertido />,
            },
            //Vehiculos en entrega
            {
                path: "/inventario/entrega",
                element: <SubMenuEntrega />,
            },
            {
                path: "/inventario/entrega/vehiculosentrega",
                element: <VehiculoEntrega />,
            },
            {
                path: "/inventario/entrega/cancelacionpendiente",
                element: <VehiculoEnCancelacionPendiente />
            },
            {
                path: "/inventario/entrega/entregacancelada/:id",
                element: <EntregaCanceladaCaptura />,
            },
            //Expediente
            {
                path: "/inventario/expediente",
                element: <ExpedienteEnProceso />,
            },
            //HistorialContratos
            {
                path: "/inventario/historialcontratos",
                element: <HistorialContratos />,
            },
            //Costos
            {
                path: "/inventario/costos/:id",
                element: <CostosInventario />,
            },
            {
                path: "/inventario/vehiculosencredito",
                element: <VehiculosEnCredito />,
            },
            //VentaContado
            {
                path: "/inventario/venta/revisiondeunidad/:id",
                element: <AddEditRevisionDeUnidad />,
            },
            {
                path: "/inventario/venta",
                element: <SubMenuVentacontado />
            },
            {
                path: "/inventario/venta/ventacontado",
                element: <VehiculoVentaContado />
            },
            {
                path: "/inventario/venta/ventacontadoconcluido",
                element: <VehiculoVentaContadoConcluido />
            },
            {
                path: "/inventario/venta/seguimientoventa",
                element: <VehiculoVentaContadoSeguimiento />
            },
            {
                path: "/inventario/venta/prealta/:id",
                element: <AddEditVehiculo />,
            },
            {
                path: "/inventario/venta/alta/:id",
                element: <VehiculoAlta />,
            },
            {
                path: "/inventario/venta/valoracion/:id",
                element: <VehiculoValoracion />,
            },
            {
                path: "/inventario/venta/autorizacion/:id",
                element: <AutorizadoAddEdit />,
            },
            //eliminar Externo
            {
                path: "/inventario/eliminarexterno",
                element: <EliminarExterno />,
            },
            //ComisionesEmpresa Inventario
            {
                path: "/inventario/comisiones",
                element: <SubMenuComisionesInventario />
            },
            {
                path: "/inventario/comisiones/comisiondeempresa",
                element: <ComisionesEmpresaHome />,
            },
            {
                path: "/inventario/comisiones/comisiondeempresa/pendientespago",
                element: <ComisionDeEmpresasPendientePago />
            },
            {
                path: "/inventario/comisiones/comisiondeempresa/procesopago",
                element: <ComisionDeEmpresasProcesoPago />
            },
            {
                path: "/inventario/comisiones/comisiondeempresa/comisionespagadas",
                element: <ComisionDeEmpresasComisionesPagadas />
            },
            //ComisionesExterno Inventario
            {
                path: "/inventario/comisiones/comisionexterno",
                element: <ComisionesExternosaHome />
            },
            {
                path: "/inventario/comisiones/comisionexterno/comisionespendientes",
                element: <ComisionExternoPendientePago />
            },
            {
                path: "/inventario/comisiones/comisionexterno/comisionesprocesopago",
                element: <ComisionExternoProcesoPago />
            },
            {
                path: "/inventario/comisiones/comisionexterno/comisionespagadas",
                element: <ComisionExternoComisionesPagadas />
            },
            //cobroComisionesExterno Inventario
            {
                path: "/inventario/comisiones/cobrocomisionexterno",
                element: <CobroComisionExternoHome />
            },
            {
                path: "/inventario/comisiones/cobrocomisionexterno/cobrocomisionespendientes",
                element: <CobroComisionExternoPendientePago />
            },
            {
                path: "/inventario/comisiones/cobrocomisionexterno/cobrocomisionesprocesopago",
                element: <CobroComisionExternoProcesoPago />
            },
            {
                path: "/inventario/comisiones/cobrocomisionexterno/comisionesexternascobradas",
                element: <ComisionesExternasCobradas />
            },
            //DataDocsInventarioComisiones
            {
                path: "/inventario/comisiones/datadocs/:id",
                element: <AltaDataDocs />,
            },
            {
                path: "/inventario/pagoUnidad",
                element: <UnidadesPendientesPago />,
            },
            {
                path: "/inventario/pagoUnidad/pendientes",
                element: <EncabezadoPendientesPago />,
            },
            {
                path: "/inventario/pagoUnidad/proceso",
                element: <SeguimientoProcesoPago />,
            },
            {
                path: "/inventario/pagoUnidad/pagados",
                element: <SeguimientoProcesoPagados />,
            },
            //Nuevos
            {
                path: "/inventario/pagoUnidad/seguimiento-pago-unidades",
                element: <SeguimientoPagoUnidades />

            },
            {
                path: "/inventario/pagoUnidad/seguimiento-pago-unidades/:id",
                element: <PagodeUnidadDetalle />
            },
            {
                path: "/inventario/pagoUnidad/datadocs/:id",
                element: <AltaDataDocs />,
            },
            {
                path: "/inventario/pagoUnidad/unidades-pendientes",
                element: <UnidadesPendientesPago />

            },
            //Resguardo de Unidad
            {
                path: "/inventario/resguardo-unidad",
                element: <ResguardoUnidad />,
            },
            {
                path: "/inventario/resguardo-unidad/detalles",
                element: < ResguardoUnidadDetalle />,
            },
            //datadocs
            {
                path: "/inventario/resguardo-unidad/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            //RevisionUnidadRechazada
            {
                path: "/inventario/revisionunidadrechazada",
                element: <RevisionUnidadRechazada />,

            },
            //Seguimiento de Valuacion
            {
                path: "/inventario/valuacion",
                element: <Valuacion />,
            },
            // InspeccionRapida            
            {
                path: "/inventario/valuacion/:id/2",
                element: <InspeccionRapidaValuacion />,
            },
            //REgsitro de costos
            {
                path: "/inventario/valuacion/:id/3",
                element: <RegistroDeCostos />,
            },
            {
                path: "/inventario/valuacion/:id/1",
                element: <InicioDeValuacion />,
            },
            //Valoracion de unidad  
            {
                path: "/inventario/valuacion/:id/4",
                element: <ValoracionDeUnidad />,
            },
            //datadocs
            {
                path: "/inventario/valuacion/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            //Unidades en remate
            {
                path: "/inventario/unidades-en-remate",
                element: <UnidadesEnRemateInventario />
            },
            {
                path: "/inventario/unidades-en-remate/detalle",
                element: <UnidadesEnRemateDetalleInventario />
            }
        ]
    },
    {
        path: "/seguro",
        element: <LayoutMenuSeguro />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <SeguroHome />,
            },
            {
                path: "/seguro/vehiculo/datadocs/:id",
                element: <AltaDataDocs />,
            },
            {
                path: "/seguro/polizasvigentes",
                element: <PolizasVigentes />,
            },
            //Siniestro
            {
                path: "/seguro/siniestros",
                element: <SeguimientoSiniestrosList />,
            },
            {
                path: "/seguro/siniestros/:id/1",
                element: <DetalleRegistroSiniestro />
            },
            {
                path: "/seguro/siniestros/:id/2",
                element: <LiberacionDeUnidad />
            },
            {
                path: "/seguro/siniestros/:id/:generalid/:siniestroid",
                element: <SiniestroCard />,
            },
            {
                path: "/seguro/siniestros/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            //Siniestro - Valuacion
            {
                path: "/seguro/siniestros/:id/3",
                element: <SiniestroValuacion />
            },
            // cierre de siniestro
            {
                path: "/seguro/siniestros/:id/4",
                element: <CierreSiniestro />
            },
            {
                path: "/seguro/cotizado",
                element: <SeguroCotizado />,
            },
            {
                path: "/seguro/sincotizar",
                element: <SeguroSinCotizar />,
            },
            {
                path: "/seguro/sincotizar/seguro/:id",
                element: <SeguroAddEdit />,
            },
            {
                path: "/seguro/cotizado/seguro/:id",
                element: <SeguroAddEdit />,
            },
            //Cotizcion mensual
            {
                path: "/seguro/cotizacionmensual",
                element: <CotizacionMensual />,
            },
            //Renovacion de seguro
            {
                path: "/seguro/renovacionseguro",
                element: <SubmenuRenovacionSeguro />,
            },
            {
                path: "/seguro/renovacionseguro/contrato",
                element: <VehiculosEnContrato />,
            },
            {
                path: "/seguro/renovacionseguro/utilitario",
                element: <RenovacionUtilitario />,
            },
            {
                path: "/seguro/renovacionseguro/segurosrenovados",
                element: <SegurosRenovados />,
            },
            {
                path: "/seguro/renovacionseguro/AddEdit/:id",
                element: <RenovacionSeguroAddEdit />,
            },
            {
                path: "/seguro/renovacionseguro/segurofinal/:id",
                element: <SeguroFinalAddEdit />,
            },
            //Seguro autorizado
            {
                path: "/seguro/autorizado",
                element: <SubmenuSeguroAutorizado />,
            },
            {
                path: "/seguro/autorizado/pendiente",
                element: <PendienteAutorizar />,
            },
            {
                path: "/seguro/autorizado/proporcional",
                element: <SegurosProporcionales />,
            },
            {
                path: "/seguro/autorizado/autorizado",
                element: <SeguroAutorizado />,
            },
            {
                path: "/seguro/seguro/segurofinal/:id",
                element: <SeguroFinalAddEdit />,
            },
            //EmisionPoliza
            {
                path: "/seguro/poliza",
                element: <SubMenuPoliza />
            },
            {
                path: "/seguro/poliza/seguroautorizado",
                element: <SeguroAutorizadoPoliza />
            },
            {
                path: "/seguro/poliza/segurorenovado",
                element: <SeguroRenovadoPoliza />
            },
            {
                path: "/seguro/poliza/vehiculoutilitario",
                element: <VehiculoUtilitarioPoliza />
            },
            {
                path: "/seguro/poliza/reenviopolizas",
                element: <ReenviarPoliza />
            },
            {
                path: "/seguro/vehiculosinrenovar",
                element: <VehiculosSinRenovar />
            },
            //Cancelacion de seguro
            {
                path: "/seguro/cancelacion",
                element: <SubMenuCancelacionSeguro />
            },
            {
                path: "/seguro/cancelacion/polizaemitida",
                element: <SeguroSinPago />
            },
            {
                path: "/seguro/cancelacion/polizapagada",
                element: <SeguroPagado />
            },
            {
                path: "/seguro/cancelacion/pendientedesembolsopago",
                element: <PendientesDesembolsoPago />
            },
            //PolizaCancelada
            {
                path: "/seguro/polizacancelada",
                element: <SubMenuPolizasCanceladas />
            },
            {
                path: "/seguro/polizacancelada/polizasnopagadas",
                element: <PolizasNoPagadas />
            },
            {
                path: "/seguro/polizacancelada/polizaspagadas",
                element: <PolizasPagadas />
            },
            //Pago_Desembolso
            {
                path: "/seguro/pagodesembolso",
                element: <SubMenuPagoDesembolso />
            },
            {
                path: "/seguro/pagodesembolso/pendientepago",
                element: <PendientesPago />
            },
            {
                path: "/seguro/pagodesembolso/procesodepago",
                element: <ProcesoDePagoPD />
            },
            //Comisiones
            {
                path: "/seguro/comisiones",
                element: <SubMenuComisiones />
            },
            {
                path: "/seguro/comisiones/pendientepago",
                element: <PendientesPagoComision />
            },
            {
                path: "/seguro/comisiones/procesodepago",
                element: <ProcesoPagoComision />
            },
            //Siniestros
            //Autorización de solicitudes
            {
                path: "/seguro/autorizacion",
                element: <SubMenuAutorizacionDeSolicitudes />
            },
            {
                path: "/seguro/autorizacion/solicituddecitas",
                element: <SolicitudDeCitasListado />
            },
            {
                path: "/seguro/autorizacion/autorizaciondictamen",
                element: <AutorizacionDictamenListado />
            },
            {
                path: "/seguro/autorizacion/veluacionenrobo",
                element: <ValucionEnRoboListado />
            },
            {
                path: "/seguro/siniestros/Citas/v2/:id",
                element: <CitasDinamicas />,
            },
            {
                path:"/seguro/seguimientoboletinado",
                element:<SeguimientoBoletinadoList/>
            }
        ]
    },
    {
        path: "/garantias",
        element: <LayoutMenuGarantias />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <GarantiasHome />,
            },
            {
                path: "/garantias/incidencias",
                element: <IncidenciasHome />,
            },
            {
                path: "/garantias/incidencias/:id",
                element: <IncidenciasHome />,
            },
            {
                path: "/garantias/incidencias/:id/:idllamada",
                element: <IncidenciasLlamada />,
            },
            {
                path: "/garantias/incidenciasinfo/:id/:idllamada",
                element: <IncidenciasLlamadaInfo />,
            },
            {
                path: "/garantias/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            {
                path: "/garantias/inspeccionrapida/:id/:idllamada",
                element: <IncidenciasLlamadaInfo inInspeccionRapida={true} />,
            },
            {
                path: "/garantias/incidencias/datadocs/:id",
                element: <AltaDataDocs />,
            },
            /*
            {
                path: "/garantias/llamada/:id",
                element: <Llamada />,
            },
            {
                path: "/garantias/contrato/garantias",
                element: <GarantiasTab />,
            },
            {
                path: "/garantias/contrato/llamadas",
                element: <LlamadasTab />,
            },
            {
                path: "/garantias/contrato/mantenimientos",
                element: <MantenimientosTab />,
            },
            */
            {
                path: "/garantias/Citas/:id",
                element: <Citas />,
            },
            {
                path: "/garantias/test",
                element: <Test />,
            },
            {
                path: "/garantias/Citas/v2/:id",
                element: <CitasDinamicas />,
            },
            {
                path: "/garantias/citas/autorizar",
                element: <CitasPorAutorizar />,
            },
            {
                path: "/garantias/citas/autorizar/garantias",
                element: <CitasPorAutorizarGarantias />,
            },
            {
                path: "/garantias/citas/autorizar/gruas",
                element: <CitasPorAutorizarGruas />,
            },
            {
                path: "/garantias/citas/agenda",
                element: <AgendaCitas />,
            },
            {
                path: "/garantias/citas/agenda/garantias",
                element: <CitasAgendaGarantias />,
            },
            {
                path: "/garantias/citas/agenda/gruas",
                element: <CitasAgendaGruas />,
            },
            {
                path: "/garantias/citas/canceladas",
                element: <CitasCanceladas />,
            },
            {
                path: "/garantias/citas/canceladas/garantias",
                element: <CitasGarantiasCanceladas />,
            },
            {
                path: "/garantias/citas/canceladas/gruas",
                element: <CitasGruasCanceladas />,
            },
            {
                path: "/garantias/inspeccionrapida",
                element: <InspeccionesRapidas />,
            },
            {
                path: "/garantias/devoluciones",
                element: <Devoluciones />,
            },
            {
                path: "/garantias/devolucioninfo/:id/:idllamada",
                element: <DevolucionesInfo />,
            },
            {
                path: "/garantias/polizas-por-cancelar",
                element: <SeguimientoPolizasPorCancelar />,
            },
        ]
    },
    { // Datamovil Systems
        path: "/datamovil",
        element: <LayoutMenuDatamovil />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <DatamovilSystems />,
            },
            {
                path: "/datamovil/solicitudes",
                element: <SolicitudesDatamovilSystems />,
            },
            {
                path: "/datamovil/servicio/:id",
                element: <SeguimientoServiciosXTipo />,
            },
            {
                path: "/datamovil/asignaciones",
                element: <AsignacionesDatamovilSystems />
            },
            {
                path: "/datamovil/asignaciones/:folioId",
                element: <AsignacionServicios />
            },
            //Revision y Desinstalacion
            {
                path: "/datamovil/5/:tipoServicio/:id/1",
                element: <AgendarCita />,
            },
            {
                path: "/datamovil/5/:tipoServicio/:id/2",
                element: <InicioServicio />,
            },
            {
                path: "/datamovil/5/:tipoServicio/:id/3",
                element: <RevisionDatamovil />,
            },
            {
                path: "/datamovil/6/:tipoServicio/:id/1",
                element: <OrdenServicio />, //Componente para la orden
            },
            {
                path: "/datamovil/6/:tipoServicio/:id/2",
                element: <AgendarCita />,
            },
            {
                path: "/datamovil/6/:tipoServicio/:id/3",
                element: <InicioServicio />,
            },
            {
                path: "/datamovil/6/:tipoServicio/:id/4",
                element: <RevisionDatamovil />,
            },
            {
                path: "/datamovil/prealta/:tipoServicio/:vin",
                element: <DatamovilPrealta />,
            },
            {
                path: "/datamovil/Citas/v2/:id",
                element: <CitasDinamicas />,
            },
        ]
    },
    {
        path: "/procesocierre",
        element: <LayoutMenuProcesos />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <ProcesosHome />,
            },
            {
                path: "/procesocierre/resguardos",
                element: <Resguardos />,
            },
            {
                path: "/procesocierre/prealta/:vin/:destino",
                element: <Prealta />,
            },
            {
                path: "/procesocierre/resguardos/resguardo/:id",
                element: <ResguardoCard />,
            },
            {
                path: "/procesocierre/resguardos/:id/:resguardo/:resguardoid",
                element: <ResguardoCard />,
            },
            {
                path: "/procesocierre/seguimientocierre",
                element: <SeguimientoDeCierre />,
            },
            {
                path: "/procesocierre/seguimientocierre/:id/1",
                element: <AgendarFirma />,
            },
            {
                path: "/procesocierre/seguimientocierre/:id/2",
                element: <ProcesoFirma />,
            },
            {
                path: "/procesocierre/cierres/:id/:generalid/:cierreid",
                element: <CierreCard />,
            },
            {
                path: "/procesocierre/datamovil",
                element: <DatamovilSeguimiento />,
            },
            {
                path: "/procesocierre/datamovil/:id/:generalid/:datamovilid",
                element: <DatamovilCard />,
            },
            {
                path: "/procesocierre/valuaciones",
                element: <ValuacionesSeguimiento />,
            },
            {
                path: "/procesocierre/valuaciones/:id/:generalid/:valuacionid",
                element: <ValuacionesCard />,
            },
            //Unidades en remate
            {
                path: "/procesocierre/unidades-en-remate",
                element: <UnidadesEnRemateProcesos />
            },
            {
                path: "/procesocierre/unidades-en-remate/detalle",
                element: <UnidadesEnRemateDetalleProcesos />
            },
            {
                path: "/procesocierre/unidades-en-remate/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            {
                path: "/procesocierre/solicitudes",
                element: <ListadoPrincipalSolicitudes />
            },
            {
                path: "/procesocierre/expedientes",
                element: <SeguimientoExpedientes />
            },
            {
                path: "/procesocierre/expediente/:id",
                element: <DetalleExpediente />
            },
        ]
    },
    {
        path: "/cobranza",
        element: <LayoutMenuCobranza />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <CobranzaHome />,
            },
            {
                path: "/cobranza/seguimientogestoria",
                element: <ListadoSeguimientoGestoria />,
            },
            {
                path: "/cobranza/accesorios",
                element: <AccesoriosSeguimiento />,
            },
            {
                path: "/cobranza/areascobradores",
                element: <AreasCobradores />,
            },
            {
                path: "/cobranza/cobradoresniveluno",
                element: <CobradoresNivel1 />,
            },
            {
                path: "/cobranza/cobradoresniveldos",
                element: <CobradoresNivel2 />,
            },
            {
                path: "/cobranza/cobradoresdetalle",
                element: <CobradoresDetalle />,
            },
            {
                path: "/cobranza/supervisioncarganiveluno",
                element: <SupervisionDeCargaNivel1 />,
            },
            {
                path: "/cobranza/supervisioncarganiveldos",
                element: <SupervisionDeCargaNivel2 />,
            },
            {
                path: "/cobranza/reasignarcargatrabajoniveluno",
                element: <ReasignarCargaNivel1 />
            },
            {
                path: "/cobranza/reasignarcargatrabajoniveldos",
                element: <ReasignarCargaNivel2 />
            },
            {
                path: "/cobranza/seguimiento-telefonico",
                element: < SubMenuSeguimiento />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/seguimiento-cobranza",
                element: < SeguimientoCobranza />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/promesas-de-pago",
                element: < PromesasPago />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/seguimiento-visita-nivel-2",
                element: < SeguimientoVisita2 />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/seguimiento-cobranza-juridica-nivel-3",
                element: < SeguimientoCobranzaJuridico3 />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/seguimiento-cobranza-juridica-nivel-4",
                element: < SeguimientoCobranzaJuridico4 />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/detalles",
                element: < SeguimientoCobranzaDetalle />,
            },
            {
                path: "/cobranza/seguimiento-telefonico/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            {
                path: "/cobranza/seguimiento-visita",
                element: < SubMenuSeguimientoVisita />,
            },
            {
                path: "/cobranza/seguimiento-visita/cartera-general",
                element: < CarteraGeneral />,
            },
            {
                path: "/cobranza/seguimiento-visita/rutas-cobranza",
                element: < RutasCobranza />,
            },
            {
                path: "/cobranza/seguimiento-visita/rutas-cobranza/ruta-detalle",
                element: < RutaDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/seguimiento-visita",
                element: < SeguimientoVisita />,
            },
            {
                path: "/cobranza/seguimiento-visita/detalle",
                element: < SeguimientoVisitaDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/boletinar-detalle",
                element: <BoletinarDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/boletinar-agregar",
                element: <BoletinarAgregar />,
            },
            {
                path: "/cobranza/seguimiento-visita/contacto-detalle",
                element: <ContactoDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/contacto-agregar",
                element: <ContactoAgregar />,
            },
            {
                path: "/cobranza/seguimiento-visita/direccion-detalle",
                element: <DireccionesDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/direccion-agregar",
                element: <DireccionesAgregar />,
            },
            {
                path: "/cobranza/seguimiento-visita/referencias-detalle",
                element: <ReferenciasDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/referencias-agregar",
                element: <ReferenciasAgregar />,
            },
            {
                path: "/cobranza/seguimiento-visita/geolocalizacion-detalle",
                element: <GeolocalizacionDetalle />,
            },
            {
                path: "/cobranza/seguimiento-visita/historial-visitas",
                element: < HistorialVisitas />,
            },
            {
                path: "/cobranza/seguimiento-visita/historial-visitas-detalle",
                element: < HistorialVisitasDetalle />,
            },
            {
                path: "/cobranza/autorizacion-solicitudes/",
                element: < AutorizacionSolicitudes />,
            },

            //Agregar componente de autorizacion de boletinado
            {
                path: "/cobranza/seguimiento-boletinado/",
                element: < SeguimientoBoletinado />,
            },
            {
                path: "/cobranza/seguimiento-visita/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            //Unidades recuperadas
            {
                path: "/cobranza/unidades-recuperadas",
                element: < UnidadesRecuperadas />,
            },
            {
                path: "/cobranza/unidades-recuperadas/detalle",
                element: < UnidadesRecuperadasDetalle />,
            },
            {
                path: "/cobranza/unidades-recuperadas/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            {
                path: "/cobranza/generacion-expedientes",
                element: < GeneracionExpedientes/>,
            }, 
            {
                path: "/cobranza/cita-entrega-unidad",
                element: < CitaEntregaUnidad />,
            },
            {
                path: "/cobranza/lectura-claves",
                element: <SeguimientoLecturaClaves />
            },
            {
                path: "/cobranza/Citas/v2/:id",
                element: <CitasDinamicas />,
            },
            {
                path: "/cobranza/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
        ]
    },
    {
        path: "/gestoria",
        element: <LayoutMenuGestoria />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <GestoriaHome />,
            },
            {
                path: "/gestoria/pagorefrendoyplacas",
                element: <PagoRefrendoPlacas />,
            },
            {
                path: "/gestoria/pagorefrendoyplacas/seguimiento/:id",
                element: <SeguimientoProcesoPagoPR />
            },
            {
                path: "/gestoria/pendienteenviaracendes",
                element: <PendienteEnviarAcendes />,
            },
            {
                path: "/gestoria/seguimiento/",
                element: <SeguimientoGestoria />,
            },
            {
                path: "/gestoria/seguimiento/:vin/:id",
                element: <GestoriaCard />,
            },
            {
                path: "/gestoria/pagorefrendoyplacas/datadocs/:_fichero/:_etapa/:_foliogarantia/:_idllamada",
                element: <UploadDataDocs />,
            },
            //Seguimiento paso 1 paso 2
            {
                path: "/gestoria/seguimiento/:id/1",
                element: <InicioTramite />,
            },
            {
                path: "/gestoria/seguimiento/:id/2",
                element: <SeguimientoAgendarCita />,
            },
            {
                path: "/gestoria/seguimiento/:id/3",
                element: <GestoriaProcesoDeTramite />,
            },
            {
                path: "/gestoria/seguimiento/:id/4",
                element: <GestoriaConcluirTramite />,
            },
            //Calendario
            {
                path: "/gestoria/seguimiento/Citas/v2/:id",
                element: <CitasDinamicas />,
            },
            //Autorizacion de Solicitudes
            {
                path: "/gestoria/autorizaciondesolicitudes",
                element: <AutorizacionDeSolicitidesListado />,
            },
        ]
    }
]);
