import axios from '../../Utils/ApiService';
import { IsNullOrEmpty } from '../../Utils/functions';

const DSAsignaciones = {
    getEstadisticas: () => {
        return axios.get(`/functions/serviciosEstadistica`);
    },
    getSeguimientoSinAsignar: ({buscar, proceso, tipoSolicitud, tipoUbicacion, page, rows}) => {
        let query = '';
        if(!IsNullOrEmpty(buscar)) query += `Buscar=${buscar}&`;
        if(!IsNullOrEmpty(proceso)) query += `ProcesoId=${proceso}&`;
        if(!IsNullOrEmpty(tipoSolicitud)) query += `TipoSolicitudId=${tipoSolicitud}&`;
        if(!IsNullOrEmpty(tipoUbicacion)) query += `TipoUbicacion=${tipoUbicacion}&`;

        query += `Page=${page}&Rows=${rows}`;
        return axios.get(`/functions/SeguimientoServiciosSinAsignar?${query}`);
    },
    getSeguimientoAsignadosTecnico: ({ buscar, folioId, tecnicoId, tipoServicioId, tipoUbicacion, page, rows }) => {
        let query = '';
        if(!IsNullOrEmpty(buscar)) query += `Buscar=${buscar}&`;
        if(!IsNullOrEmpty(tipoServicioId)) query += `TipoServicioId=${tipoServicioId}&`;
        if(!IsNullOrEmpty(tipoUbicacion)) query += `TipoUbicacion=${tipoUbicacion}&`;
        if(!IsNullOrEmpty(tecnicoId)) query += `TecnicoId=${tecnicoId}&`;

        query += `FolioId=${folioId}&Page=${page}&Rows=${rows}`;
        return axios.get(`/functions/SeguimientoServiciosAsignadosTecnico?${query}`);
    },
    getSeguimientoServiciosAsignados: ({buscar, tecnicoId, tipoServicioId, tipoUbicacion, concluido, fechaInicio, fechaFin, page, rows }) => {
        let query = '';
        if(buscar.trim().length > 0) query += `Buscar=${buscar}&`;
        if(tecnicoId.trim().length > 0) query += `TecnicoId=${tecnicoId}&`;
        if(tipoServicioId.trim().length > 0) query += `TipoServicioId=${tipoServicioId}&`;
        if(tipoUbicacion.trim().length > 0) query += `TipoUbicacion=${tipoUbicacion}&`;
        // if(concluido === true) query += `Concluido=1&`;
        // if(fechaInicio.trim().length > 0) query += `FechaInicio=${fechaInicio}&`;
        // if(fechaFin.trim().length > 0) query += `FechaFin=${fechaFin}&`;

        query += `Page=${page}&Rows=${rows}`;
        return axios.get(`/functions/ServicioAsignados?${query}`);
    },
    getTecnicos: () => {
        return axios.get(`/functions/Tecnicos`);
    },
    postAsignacion: (data) => {
        return axios.post(`functions/ServicioAsignacion`, data);
    },
    putAsignacion: (data) => {
        return axios.put(`/functions/ServicioAsignacion`, data);
    },
    getAsignacion: (id) => {
        return axios.get(`/functions/ServicioAsignacion?FolioId=${id}`)
    },
    postAsignarServicios: (data) => {
        return axios.post(`/functions/AsignarServicio`, data);
    },
    postDesasignarServicios: (data) => {
        return axios.post(`/functions/DesasignarServicio`, data);
    }
}

export default DSAsignaciones;