import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import Spinner from "../Loadig";
import * as Yup from 'yup';
import { TextField } from "../TextField";
import DSAsignaciones from "../../Services/DatamovilSystems/Asignaciones";
import { message } from "../../Utils/functions";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import MultiSelect from "../DropDown";

const AsignarServiciosModal = ({ modalMessage, setModalMessage, edit = false, servicioAsignacion, getAsignacion }) => {
    const { folioId } = useParams();
    const navigate = useNavigate();
    const refFormik = useRef();
    const [loading, setLoading] = useState(false);
    let initial = {
        servicioAsignacion: null,
        tecnicoAsignado: '',
        fechaAsignacion: '',
    }

    const [initialValue, setInitialValue] = useState(initial);

    const resultados = [
        { value: '0', label: 'Rechazar' },
        { value: '1', label: 'Aprobar' },
    ]
    const [tecnicos, setTecnicos] = useState([]);
    const [state, setState] = useState([]);

    useEffect(() => {
        getTecnicos();
        getCurrentDate();
    }, []);

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
        const year = today.getFullYear();

        const currentDate = `${day}-${month}-${year}`;
        setInitialValue({
            ...initialValue,
            fechaAsignacion: currentDate
        })

    }

    const getTecnicos = async () => {
        await DSAsignaciones.getTecnicos()
            .then(resp => {
                let items = resp.data.map(item => {
                    return {
                        value: item.userId,
                        label: item.tecnico
                    }
                });

                setTecnicos(items);
            })
            .catch(err => {

            })
    }

    const handleRegresar = () => {
        setModalMessage({ isOpen: false });
    }
    // const validate = Yup.object({
    //     tecnicoAsignado: Yup.string()
    //         .required("El campo es requerido.")
    // });
    const handleGuardar = async (values) => {        
        setLoading(false);
        let req = values;
        req.fechaAsignacion = new Date(req.fechaAsignacion.split("-").reverse().join("/"));

        if(!edit){
            await DSAsignaciones.postAsignacion(req)
                .then(resp => {
                    toast.success(message('Asignación creada correctamente, en breve será redirigido a la asignación'));
                    navigate(`/datamovil/asignaciones/${resp.data.folioId}`);
                })
                .catch(err => {
                    toast.error(message('Ocurrió un error al intentar agregar la asignación'));
                    setModalMessage({ isOpen: false });
                })
                .finally(() => {
                    setLoading(false);
                });
        }else{
            req.servicioAsignacion = servicioAsignacion;
            await DSAsignaciones.putAsignacion(req)
            .then(resp => {
                toast.success(message('Asignación actualizada correctamente'));
                getAsignacion();
                setModalMessage({ isOpen: false });
            })
            .catch(err => {
                toast.error(message('Ocurrió un error al intentar agregar la asignación'));
                setModalMessage({ isOpen: false });
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={refFormik}
                initialValues={initialValue}
                // validationSchema={validate}
                isInitialValid={false}
                initialTouched={{
                    tecnicoAsignado: true,
                }}
            >
                {({ isValid, values, setFieldValue,meta, errors, resetForm }) => (
                    <div>
                        <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                        <div className='justify-content-start p-2 align-items-center'>
                            <div className="row">
                                <div className="col col-10">
                                    <h5><strong>{modalMessage.title}</strong></h5>
                                </div>
                                <div className="col col-2 d-flex justify-content-end">
                                    <button className='btn btn-outline d-flex justify-content-center align-items-center' style={{ width: '23px', height: '23px' }}
                                        onClick={() => handleRegresar()}>
                                        <i className="ri-close-fill"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3 mb-2">
                                <div className="col col-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6 col-12">
                                            <label className='text'>{"Técnico"}</label>
                                            <MultiSelect
                                                id="tecnicoAsignado"
                                                holder={"Seleccione"}
                                                className={'col-12 mx-1'}
                                                name="tecnicoAsignado"
                                                value={state}
                                                options={tecnicos}
                                                handleChange={(values) => { 
                                                    setState(values);
                                                    let newDataId = [];
                                                    values?.map(item=>{
                                                        newDataId.push(item.value);
                                                    })
                                                    setFieldValue("tecnicoAsignado", newDataId.join()); 
                                                }}
                                            />                                         
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <TextField
                                                id="fechaAsignacion"
                                                disabled={true}
                                                label="Fecha"
                                                name="fechaAsignacion"
                                                type="text"
                                                onChange={(event) => {
                                                    setFieldValue("fechaAsignacion", event.target.value);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section className='d-flex justify-content-end'>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            type='reset'
                                            id="AT_BtnCloseModal"
                                            className='btn btn-outline d-flex justify-content-center align-items-center'
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => handleRegresar()}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            id="AT_BtnCloseModal"
                                            className='btn btn-blue d-flex justify-content-center align-items-center'
                                            disabled={!(state.length > 0)}
                                            style={{ paddingLeft: '25px', paddingRight: '25px' }}
                                            onClick={() => {
                                                handleGuardar(values);
                                                resetForm();
                                            }}
                                        >
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default AsignarServiciosModal;