import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import RowPages from '../../../Components/RowPages'
import DataTable from '../../../Components/datatable/DataTable'
import AutorizacionBoletinadoModal from '../../../Components/Modal/AutorizacionBoletinadoModal'
import useGeneracionExpedientes from '../../../Hooks/Cobranza/useGeneracionExpedientes'
import MultiSelect from '../../../Components/DropDown'
import GenerarExpedienteModal from './modalGenerarExpedientes'

const GeneracionExpedientes = () => {
  const { columns,
    data,
    handlePageClick,
    currenPage,
    pageCount,
    setStr,
    loading,
    loadingModal,
    setpageRows,
    setOpenModal,
    openModal,
    handleGetAll,
    setItemSelected,
    itemSelected,
    selectRef,
    TipoPersona,
    tipoDocumento,
    nivelCobranza,
    cobrador,
    handleGetCobradorById,
    setNivelCobradorValue,
    setNombreCobradorValue,
    setNombreCobrador,
    setTelefonoCobrador,
    setTipoDocumentoValue,
    setTipoPersonaValue,
    stateTD, stateTP, setstateTD, setstateTP,
    handlechekedItem,
    downloadPDF, //este es els ervicio?
    setIdsDescargar,
    limpiarFiltro,
    refbtnDownload,
    generarModal,
    setGenerarModal,
    handleGenerarExpedienteModal,
  } = useGeneracionExpedientes(toast);
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      {
        generarModal &&
        <GenerarExpedienteModal
          isOpen={generarModal}
          loading={loadingModal}
          setIsOpen={setGenerarModal}
          downloadPDF={downloadPDF}
        />
      }
      <ValidatePermission isActive={ValidatePermissionById(4, Section.ExpedienteVisitaSection, SubModule.GeneracionExpedientes)?.Ver}>
        <section className='mx-3 py-4 d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <span className='fs-5'><strong>Expediente de visitas</strong></span>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ExpedienteVisitaSection, SubModule.GeneracionExpedientes)?.Descarga}>
              <button ref={refbtnDownload} className='btn btn-blue me-2' onClick={() => handleGenerarExpedienteModal()}>Generar PDF</button>

            </ValidatePermission>
          </div>
          <span className='mt-2'>A continuación filtra los datos para generar el expediente de visitas.</span>
        </section>

        <section className='pb-4'>
          <div className='row col-12 px-4'>
            <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
              <Select ref={(el) => (selectRef.current.selectNivelCobranza = el)} options={nivelCobranza} className='col-12' placeholder="Nivel de cobranza" onChange={(e) => { setNivelCobradorValue(e.value); handleGetCobradorById(e.value) }} />
            </div>
            <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
              <Select ref={(el) => (selectRef.current.selectCobrador = el)} options={cobrador} className='col-12' placeholder="Nombre cobrador"
                onChange={(e) => {
                  setNombreCobradorValue(e.value)
                  setNombreCobrador(e.label)
                  setTelefonoCobrador(e.phone)
                }} />
            </div>
            <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
              {/* <Select ref={(el) => (selectRef.current.selectTipoPersona = el)} options={TipoPersona} className='col-12' placeholder="Tipo de persona" onChange={(e) => { setTipoPersonaValue(e.value) }} /> */}
              <MultiSelect
                holder={"Tipo de persona"}
                className={'col-12'}
                value={stateTP}
                options={TipoPersona}
                handleChange={(e) => {
                  setstateTP(e);
                  let stringValue = [];
                  e?.map(x => {
                    stringValue.push(x.value);
                  })
                  setTipoPersonaValue(stringValue.join());
                }
                } />
            </div>
            <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
              {/* <Select ref={(el) => (selectRef.current.selectTipoDoc = el)} options={tipoDocumento} className='col-12' placeholder="Tipo de documento" onChange={(e) => { setTipoDocumentoValue(e.value) }} /> */}
              <MultiSelect
                holder={"Tipo de documento"}
                className={'col-12'}
                value={stateTD}
                options={tipoDocumento}
                handleChange={(e) => {
                  setstateTD(e);
                  let stringValue = [];
                  e?.map(x => {
                    stringValue.push(x.value);
                  })
                  setTipoDocumentoValue(stringValue.join());
                }
                } />
            </div>
            <div className="col-12 col-md-auto my-1 px-1">
              <button className='btn btn-blue me-2' onClick={() => { handleGetAll(currenPage) }}>
                Buscar
              </button>
              <button className='btn btn-blue' onClick={limpiarFiltro}>
                Limpiar filtro
              </button>
            </div>
          </div>
        </section>
        <RowPages
          setpageRows={setpageRows}
        />
        <div className='mx-4 tbl-scroll' style={{ overflowX: "scroll" }}>
          <DataTable
            column={columns}
            data={data}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={currenPage - 1}
            checkbox={ValidatePermissionById(4, Section.ExpedienteVisitaSection, SubModule.GeneracionExpedientes)?.Descarga}
            iswidth={true}
            handleCheck={handlechekedItem}
          />
        </div>
      </ValidatePermission>
      {
        openModal &&
        <AutorizacionBoletinadoModal
          data={itemSelected}  // Aquí debes pasar el objeto `selectedData`
          setIsOpen={setOpenModal}
          isOpen={openModal}
          toast={toast}
        />
      }
    </>
  )
}

export default GeneracionExpedientes