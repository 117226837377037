import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cobranza from '../../Services/Cobranza/Cobranza';
import { useDispatch } from 'react-redux';
import { setUsuario } from '../../Redux/Slice/Cobranza/ReasignarCargaNivelDosSlice';
import { Exception } from 'sass';
import CobranzaService from '../../Services/Cobranza/Cobranza';
import { formatearMonto, getDateFormat, FormatFecha, getHourFormat } from '../../Utils/functions';


const useSupervisionCargaNivel2 = (toast) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectRefStatus = useRef({
    select1: "",
    searchInput: ""
  });
  //Columnas de vista principal
  const columns = [
    { field: 'nombrecobrador', headerName: 'Cobrador', width: 200 },
    { field: 'cantidadcontratos', headerName: 'Cantidad contratos', width: 200 },
    { field: 'fechayhora', headerName: 'Fecha y hora', width: 170 },
  ]

  //Columnas del reporte de contratos N2 sin asignar
  const columnsContratosSinAsignar = [
    { field: 'contador', headerName: '#', width: 100 },
    { field: 'contrato_id', headerName: 'Contrato', width: 200 },
    { field: 'municipio', headerName: 'Municipio', width: 200 },
    { field: 'codigo', headerName: 'Código Postal', width: 200 },
    { field: 'colonia', headerName: 'Colonia', width: 200 },
    { field: 'calleNumero', headerName: 'Calle y Número', width: 200 }
  ]

  const columnsDetail = [
    { field: 'zona', headerName: 'Zona', width: 300 },
    { field: 'contrato', headerName: 'Contrato', width: 150 },
    { field: 'tipo_persona', headerName: 'Tipo persona', width: 180 },
    { field: 'nombre', headerName: 'Nombre', width: 300 },
    { field: 'direcciontooltip', headerName: 'Dirección', width: 110 },
    { field: 'diasvencidos', headerName: 'Días vencidos', width: 130 },
    { field: 'diassinconsultarclave', headerName: 'Días sin consultar clave', width: 200 },
    { field: 'montovencido', headerName: 'Monto vencido', width: 150 },
    { field: 'visitas', headerName: 'Visitas', width: 120 },
  ];

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [dataCobradores, setDataCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState("");
  const [str, setStr] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [pageRows, setpageRows] = useState(10)
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(data)
  const [openModalAvanzado, setOpenModalAvanzado] = useState(false);
  const [limpiarCampos, setLimpiarCampos] = useState(false)
  const [dataContratosSinAsignar, setDataContratosSinAsignar] = useState([]);
  const [contratosSinAsignar, setContratosSinAsignar] = useState([]);
  const [pageCountSinAsignar, setPageCountSinAsignar] = useState(1);
  const [pageSinAsignar, setPageSinAsignar] = useState(1);
  const [pageRowsSinAsignar, setPageRowsSinAsignar] = useState(10)
  const [textoBusqueda, setTextoBusqueda] = useState("")

  const [dataFiltro, setDataFiltro] = useState({
    estatus: "",
    strAvanzado: "",
    fechaInicio: "",
    fechaFin: "",
    concluido: false,
    busquedaAvanzada: false
  });

  useEffect(() => {
    // handleGetAllData();


    setLoading(true);
    handleCargaInicial()
  }, []);


  const handleCargaInicial = async () => {
    const [] = await Promise.all([handleGetAllData(), GetAllCobranzaEventoItem()]).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    const copydata = structuredClone(data)
    let filteredData = copydata.filter(item => {
      let counter = 0;
      let count = 1;
      let totalDias = 0;
      let totalSuma = 0;
      const nameMatches = str === '' || (item.nombrecobrador && item.nombrecobrador.toLowerCase().includes(str.toLowerCase()));

      let filteredContrats = item?.detail?.length === 0 ? item.detail : item?.detail?.filter(contrato => {
        const contratoMatches = str === '' || (contrato.contrato && contrato.contrato.toLowerCase().includes(str.toLowerCase())) || nameMatches;
        if (contratoMatches) {
          contrato.numero = count
          totalDias = totalDias + contrato?.cantidad_promediar;
          totalSuma = totalSuma + contrato?.cantidad_a_sumar;
          count++;
          counter++
        }
        return (contratoMatches)
      })

      if (item.detail.length === 0 && nameMatches) {
        counter++
      }

      item.detail = filteredContrats
      item.cantidadcontratos = filteredContrats.length
      item.promedio_dias = item.detail.length === 0 ? 0 : totalDias / item.detail.length;
      item.total_mostrar = totalSuma

      return (
        (nameMatches || counter > 0)
        && (cobradorSeleccionado === "" || item.nombrecobrador === cobradorSeleccionado || cobradorSeleccionado === "Seleccionar")
      )
    })

    const indexOfLastItem = currentPage * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [str, pageRows, data, cobradorSeleccionado, currentPage, limpiarCampos])

  useEffect(() => {
    const copydata = structuredClone(data)
    if (dataFiltro.busquedaAvanzada) {
      setCurrentData(data)
      // Filtra los datos según los filtros seleccionados
      let filteredData = copydata.filter(item => {
        let counter = 0;
        let count = 1;
        let totalDias = 0;
        let totalSuma = 0;
        let fechaFiltrada = true

        const nameMatches = dataFiltro.strAvanzado === '' || (item?.nombrecobrador && item?.nombrecobrador?.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))

        let filteredContrats = item?.detail?.length === 0 ? item?.detail : item?.detail?.filter(contrato => {
          let fechaFiltradaContrato = true
          if (dataFiltro.busquedaAvanzada && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {
            const FechaVencimiento = new Date(dataFiltro.fechaFin)
            const FechaInicio = new Date(dataFiltro.fechaInicio)
            const fechaItem = new Date(contrato.fecha_ultimo_pago)
            fechaFiltradaContrato = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
          }
          const contratoMatches = dataFiltro.strAvanzado === ''
            || (contrato?.contrato && contrato.contrato.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase())) || nameMatches || fechaFiltradaContrato
            || (contrato?.zona && contrato.zona.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
            || (contrato?.tipo_persona && contrato.tipo_persona.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
            || (contrato?.nombre && contrato.nombre.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()))
            || (contrato?.direccion && contrato.direccion.toLowerCase().includes(dataFiltro.strAvanzado.toLowerCase()));

          if (contratoMatches) {
            totalDias = totalDias + contrato?.dias_sin_consultar_clave
            contrato.numero = count
            totalDias = totalDias + contrato?.cantidad_promediar;
            totalSuma = totalSuma + contrato?.cantidad_a_sumar;
            count++;
            counter++
          }
          return (contratoMatches)
        })
        if (item.detail.length === 0 && nameMatches) {
          counter++
        }
        item.detail = filteredContrats
        item.cantidadcontratos = filteredContrats.length
        item.promedio_dias = item.detail.length === 0 ? 0 : totalDias / item.detail.length;
        item.total_mostrar = totalSuma

        if (dataFiltro.strAvanzado === '' && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {
          return (dataFiltro.busquedaAvanzada === false || counter > 0)
        } else if (dataFiltro.strAvanzado !== '' && dataFiltro.fechaFin === "" && dataFiltro.fechaInicio === "") {
          return (nameMatches)
        } else {
          return (nameMatches || counter > 0)
        }
      })

      const indexOfLastItem = currentPage * pageRows
      const indexOfFirstItem = indexOfLastItem - pageRows
      const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
      setCurrentData(newData)

      const newTotalPages = Math.ceil(filteredData.length / pageRows)
      setPageCount(newTotalPages)
      setDataFiltro({
        estatus: "",
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
      })
    }
  }, [dataFiltro])

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    if (dataContratosSinAsignar.length > 0) {
      //  let filteredData = data?.filter(item => {
      //     const nameMatches = str === '' || (item?.nombrecobrador && item?.nombrecobrador.toLowerCase().includes(str.toLowerCase()));

      //     return (
      //        nameMatches &&
      //        (filtroEstadoLaboral === "" || item?.estatulaboral === filtroEstadoLaboral || filtroEstadoLaboral === "Estado laboral") &&
      //        (filtroEstatus === "" || item?.statuscobrador === filtroEstatus || filtroEstatus=== "Estatus") &&
      //        (filtroZonas === "" || item?.zona.toLowerCase().includes(filtroZonas.toLowerCase()) || filtroZonas === "Zonas")
      //     )
      //  })

      // let filteredData = dataContratosSinAsignar;
      let filteredData = dataContratosSinAsignar.filter(item => {
        const nameMatches = textoBusqueda === '' || (item.contrato_id && item.contrato_id.toLowerCase().includes(textoBusqueda.toLowerCase()));
        return nameMatches;
    });

      const indexOfLastItem = pageSinAsignar * pageRowsSinAsignar
      const indexOfFirstItem = indexOfLastItem - pageRowsSinAsignar
      const newData = filteredData?.slice(indexOfFirstItem, indexOfLastItem)
      setContratosSinAsignar(newData)

      const newTotalPages = Math.ceil(filteredData.length / pageRowsSinAsignar)
      setPageCountSinAsignar(newTotalPages)
      // if (currentPage > newTotalPages) {
      //   setCurrentPage(newTotalPages)
      // } else if (currentPage <= 0) {
      //   setCurrentPage(1)
      // }
    }

  }, [pageSinAsignar, pageRowsSinAsignar, dataContratosSinAsignar, textoBusqueda])

  const formatMoney = value => new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value ?? 0);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
    // handleGetAllCobradores(event.selected + 1);
  };

  const handlePageClickSinAsignar = (event) => {
    setPageSinAsignar(event.selected + 1);
    // handleGetAllCobradores(event.selected + 1);
  };

  const handleGetAllData = async () => {
    let NewData = [{ value: "", label: "Seleccionar" }];
    let dataUsuarios = [];

    await Cobranza.getEjecutivosFullDetail()
      .then(res => {
        if (res?.data?.callSucceded) {
          res?.data?.responseData?.map(item => {
            if (item?.nivel_cobrador === "2" && item?.status?.toLowerCase() !== "inactivo") {
              NewData.push({ value: item?.user_detail?.userId, label: item?.user_detail?.names });
              let count = 1;
              let dataContratos = [];
              let totalDias = 0;
              let totalSuma = 0;
              item?.contratos?.map(cont => {
                if (cont?.cartera_vencida === 1 && cont?.nivel_cobranza === '2') {
                  console.log(cont)
                  let fechaRegistro = new Date(cont?.fecha_hora)
                  let hour = getHourFormat(`${String(fechaRegistro.getHours()).padStart(2, '0')}:${String(fechaRegistro.getMinutes()).padStart(2, '0')}`)

                  dataContratos.push({
                    id: cont?.id,
                    numero: count,
                    zona: cont?.zona,
                    contrato: cont?.contrato_id,
                    tipo_persona: cont?.tipo_persona,
                    nombre: `${cont?.nombre} ${cont?.apellido_paterno} ${cont?.apellido_materno}`,
                    direcciontooltip: `${cont?.calle} ${cont?.no_ext}, ${cont?.colonia} ${cont?.cp}, ${cont?.estado} - ${cont?.municipio}`,
                    direccion: `${cont?.calle} ${cont?.no_ext}, ${cont?.colonia} ${cont?.cp}, ${cont?.estado} - ${cont?.municipio}`,
                    diasvencidos: cont?.dias_vencido,
                    dias_sin_consultar_clave: cont?.dias_sin_consultar_clave,
                    montovencido: formatearMonto(cont?.monto_adeudo),
                    visitas: cont?.visitas,
                    cantidad_promediar: isNaN(cont?.dias_vencido) ? 0 : cont?.dias_vencido,
                    cantidad_a_sumar: isNaN(cont?.monto_adeudo) ? 0 : cont?.monto_adeudo,
                    fecha_ultimo_pago: cont?.fecha_ultimo_pago,
                    municipio: cont?.municipio,
                    cp: cont?.cp,
                    colonia: cont?.colonia,
                    calle: cont?.calle,
                    no_ext: cont?.no_ext,
                    dias_vencido: cont?.dias_vencido,
                    diassinconsultarclave: cont?.dias_sin_consultar_clave,
                    fecha_hora: FormatFecha(cont?.fecha_hora) + ' ' + hour,
                  });
                  totalDias = totalDias + (isNaN(cont?.dias_vencido) ? 0 : cont?.dias_vencido);
                  totalSuma = totalSuma + (isNaN(cont?.monto_adeudo) ? 0 : cont?.monto_adeudo);
                  count++;
                }
              });
              dataUsuarios.push({
                userid: item?.userid,
                nombrecobrador: item?.user_detail?.names,
                cantidadcontratos: count - 1,
                fechayhora: dataContratos[0]?.fecha_hora,
                detail: dataContratos,
                disponible: item?.disponible,
                nivel_cobrador: item?.nivel_cobrador,
                numTelefono: item?.numTelefono,
                status: item?.status,
                rangoFechas: item?.rangoFechas,
                email: item?.user_detail?.email,
                numeroEmpleado: item?.user_detail?.numeroEmpleado,
                sucursalId: item?.user_detail?.sucursalId,
                zona: item?.zona,
                promedio_dias: dataContratos.length === 0 ? 0 : totalDias / dataContratos.length,
                total_mostrar: totalSuma,
              });
            }
          })
        }
      })
      .catch(e => {
        // setLoading(false);
        console.log(`ERROR -- ${e}`)
      })
    setData(dataUsuarios);
    // setLoading(false);
    setDataCobradores(NewData);
  }

  const GetAllCobranzaEventoItem = async () => {
    // let NewData = [{ value: "", label: "Seleccionar" }];
    let dataContratosSinAsignar = [];

    let data = {
      "verb": "string",
      "id": "string",
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": {},
      "kvp": {}
    };
    
    await Cobranza.GetAllCobranzaEventoItem(data)
      .then(res => {
        if (res?.data?.callSucceded) {
          dataContratosSinAsignar = res?.data?.responseData?.filter(item =>
            item.cartera_vencida == 1 &&
            item.nivel_cobranza == "2"
            &&
            item.zonaid == ""
          ).map((item, index) => ({
            contador: index + 1,
            contrato_id: item.contrato_id,
            municipio: item.municipio,
            codigo: item.cp,
            colonia: item.colonia,
            calleNumero: `${item.calle} ${item.no_ext}`,
          }));
        }
      })
      .catch(e => {
        console.log(`ERROR -- ${e}`)
      });
    setDataContratosSinAsignar(dataContratosSinAsignar);
  }

  const distribucionDeCarga = async () => {
    setLoading(true);
    await Cobranza.distribucionDeCarga({})
      .then(res => {
        if (res?.data?.callSucceded) {
          // setLoading(false);
          handleCargaInicial();
        } else {
          throw Exception(res.data.message);
        }
      })
      .catch(e => {
        console.log(`ERROR -- ${e}`)
        setLoading(false);
      });
  }

  const handleDetails = (item) => {
    dispatch(setUsuario({
      userid: item?.userid,
      nombrecobrador: item?.nombrecobrador,
      numerocontratos: item?.cantidadcontratos,
      contratos: item?.detail
    }));

    navigate(`/cobranza/reasignarcargatrabajoniveldos`, {
      state: {
        page: 'Detalle',
        title: "Editar usuario"
      },
    });
  }

  const handleDownloadFile = async () => {
    let datosAExportar = []
    let nombreArchivo = `SupervisionCargaNivelDos`

    data.map(item => {
      item.detail?.map(element => {
        datosAExportar.push({
          "Zona": element?.zona === null ? "-" : element?.zona,
          "Contrato": element?.contrato === null ? "-" : element?.contrato,
          "Tipo de Persona": element?.tipo_persona === null ? "-" : element?.tipo_persona,
          "Titular": element?.nombre,
          "Municipio": element?.municipio === null ? "-" : element?.municipio,
          "Codigo Postal": element?.cp === null ? "-" : element?.cp,
          "Colonia": element?.colonia === null ? "-" : element?.colonia,
          "Calle y numero": `${element?.calle} ${element?.no_ext}`,
          "Dias venciidos": element?.dias_vencido === null ? "-" : element?.dias_vencido,
          "Dias sin consultar clave": element?.dias_sin_consultar_clave === null ? "-" : element?.dias_sin_consultar_clave,
          "Monto vencido": element?.montovencido,
          "Cant. Visita": element?.visitas === null ? "-" : element?.visitas,
          "Cobrador": item?.nombrecobrador === null ? "-" : item?.nombrecobrador,
          "Nivel de cobranza": item?.nivel_cobrador === null ? "-" : item?.nivel_cobrador,
        })
      })
    })

    let reporte = {
      "verb": "string",
      "id": nombreArchivo,
      "field": "string",
      "value": "string",
      "level": 0,
      "flag": true,
      "anyObject": datosAExportar,
      "kvp": {}
    }

    console.log(reporte)

    setLoading(true);
    await CobranzaService.ExportReport(reporte)
      .then(res => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

        setLoading(false);
      }).catch(e => {
        setLoading(false);
      })
  }

  const handleDownloadFileContratosSinAsignar = async () => {
    if (dataContratosSinAsignar?.length > 0) {
      let datosAExportar = []
      let nombreArchivo = `ContratosN2SinAsignar`
      let counter = 1

      dataContratosSinAsignar.map(item => {
        datosAExportar.push({
          "#": counter,
          "Contrato": item?.contrato_id === null ? "-" : item?.contrato_id,
          "Municipio": item?.municipio === null ? "-" : item?.municipio,
          "Código Postal": item?.codigo === null ? "-" : item?.codigo,
          "Colonia": item?.colonia === null ? "-" : item?.colonia,
          "Calle y número": item?.calleNumero === null ? "-" : item?.calleNumero
        })
        counter++
      })

      let reporte = {
        "verb": "string",
        "id": nombreArchivo,
        "field": "string",
        "value": "string",
        "level": 0,
        "flag": true,
        "anyObject": datosAExportar,
        "kvp": {}
      }

      console.log(reporte)

      setLoading(true);
      await CobranzaService.ExportReport(reporte)
        .then(res => {
          var FileSaver = require('file-saver');
          FileSaver.saveAs(res.data, `${nombreArchivo}_${new Date(Date.now()).toLocaleString().split(',')[0]}.xlsx`);

          setLoading(false);
        }).catch(e => {
          setLoading(false);
        })
    }
    else {
      toast.error("No hay datos a exportar");
    }
  }

  const limpiarFiltros = () => {
    Object.values(selectRefStatus.current).forEach(select => {
      if (select) {
        select?.setValue(""); // Limpiar el valor solo si clearValue está disponible
      }
    });
    setStr("")
    setCobradorSeleccionado("")
    setDataFiltro({
      estatus: "",
      strAvanzado: "",
      fechaInicio: "",
      fechaFin: "",
      concluido: false,
      busquedaAvanzada: false
    })
    setLimpiarCampos(!limpiarCampos)
  }

  return {
    columns,
    columnsContratosSinAsignar,
    contratosSinAsignar,
    columnsDetail,
    currentData,
    dataCobradores,
    setCobradorSeleccionado,
    setStr,
    str,
    loading,
    dataFiltro,
    setDataFiltro,
    handleDetails,
    distribucionDeCarga,
    openModalAvanzado,
    setOpenModalAvanzado,
    handleDownloadFile,
    pageCount,
    handlePageClick,
    page,
    limpiarFiltros,
    selectRefStatus,
    setpageRows,
    pageCountSinAsignar,
    handlePageClickSinAsignar,
    pageSinAsignar,
    setPageRowsSinAsignar,
    handleDownloadFileContratosSinAsignar,
    setTextoBusqueda,
  }
}

export default useSupervisionCargaNivel2