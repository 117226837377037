
const ColumsModal = () => {
    const columnsMarca = [
        // { field: 'claveMarca', headerName: 'Id Marca', width: 230 },claveMarca
        { field: 'nombreMarca', headerName: 'Marca', width: 230 },
        { field: 'interfaz', headerName: 'Interfaz', width: 230 },
        { field: 'claveMarca', headerName: 'Clave', width: 200 },
        // { field: 'status', headerName: 'Estatus', width: 100 },
    ];
    const columnsModelo = [
        // { field: 'modeloId', headerName: 'Id Modelo', width: 230 },
        { field: 'nombreModelo', headerName: 'Modelo', width: 230 },
        { field: 'interfaz', headerName: 'Interfaz', width: 230 },
        { field: 'claveModelo', headerName: 'Clave', width: 200 },
        // { field: 'status', headerName: 'Estatus', width: 100 },
    ];
    const columnsVersion = [
        // { field: 'versionId', headerName: 'Id Version', width: 230 },
        { field: 'nombreVersion', headerName: 'Version', width: 230 },
        { field: 'interfaz', headerName: 'Interfaz', width: 230 },
        { field: 'claveVersion', headerName: 'Clave', width: 200 },
        // { field: 'status', headerName: 'Estatus', width: 100 },
        // {field:"year",headerName:'Año',with:80}
    ];
    const columnsYears = [
        // { field: 'yearId', headerName: 'Id Año', width: 230 },
        { field: 'nombreYear', headerName: 'Año', width: 230 },
        { field: 'interfaz', headerName: 'Interfaz', width: 230 },
        { field: 'claveYear', headerName: 'Clave', width: 200 },
        // { field: 'status', headerName: 'Estatus', width: 130 },
    ];
    const columnsAseguradora = [
        { field: 'aseguradoraId', headerName: 'Id Aseguradora', width: 230 },
        { field: 'aseguradora', headerName: 'Aseguradora', width: 230 },
        { field: 'telefono', headerName: 'Telefono', width: 230 },        
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columnsBanco = [
        { field: 'bancoId', headerName: 'Id Banco', width: 230 },
        { field: 'banco', headerName: 'Banco', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columnsCatCosto = [
        { field: 'categoriaCostoId', headerName: 'Id Categoria Costo', width: 230 },
        { field: 'categoriaCosto', headerName: 'Categoria Costo', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columnsCobertura = [
        { field: 'coberturaId', headerName: 'Id Cobertura', width: 230 },
        { field: 'cobertura', headerName: 'Cobertura', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columnsEtapa = [
        { field: 'etapaId', headerName: 'Id Etapa', width: 100 },
        { field: 'id', headerName: 'Id', width: 50 },
        { field: 'etapa', headerName: 'Etapa', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columnsExtension = [
        { field: 'extensionId', headerName: 'Id Extensión', width: 230 },
        { field: 'extension', headerName: 'Extensión', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoCS = [
        { field: 'motivoCancelacionSeguroId', headerName: 'Id Motivo Cancelacion', width: 230 },
        { field: 'motivoCancelacionSeguro', headerName: 'Motivo Cancelación', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoUb = [
        { field: 'motivoUbicacionId', headerName: 'Id Motivo Ubicación', width: 230 },
        { field: 'motivoUbicacion', headerName: 'Motivo Ubicación', width: 230 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsProducto = [
        { field: 'productoId', headerName: 'Id Producto', width: 110 },
        { field: 'producto', headerName: 'Producto', width: 180 },
        { field: 'descripcion', headerName: 'Descripción', width: 180 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsStatusSeguro = [
        { field: 'statusSeguroId', headerName: 'Id Status Seguro', width: 180 },
        { field: 'statusSeguro', headerName: 'Status Seguro', width: 180 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoAd = [
        { field: 'tipoAdquisicionId', headerName: 'Id Tipo Adquisición', width: 180 },
        { field: 'tipoAdquisicion', headerName: 'Tipo Adquisición', width: 100 },
        { field: 'clave', headerName: 'Clave', width: 100 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoCompra = [
        { field: 'tipoCompraId', headerName: 'Id Tipo compra', width: 180 },
        { field: 'customField', headerName: 'Custome Field', width: 100 },
        { field: 'tipoCompra', headerName: 'Tipo Compra', width: 100 },
        { field: 'interfaz', headerName: 'Enviar a Intelimotor', width: 100 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoExterno = [
        { field: 'tipoExternoId', headerName: 'Id Externo Id', width: 180 },
        { field: 'tipoExterno', headerName: 'Externo', width: 100 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoPago = [
        { field: 'tipoPagoId', headerName: 'Id Tipo Pago', width: 180 },
        { field: 'customField', headerName: 'Custome Field', width: 100 },
        { field: 'tipoPago', headerName: 'Tipo Pago', width: 100 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsResponsiva = [
        { field: 'tipoResponsivaId', headerName: 'Id Tipo Responsiva', width: 180 },
        { field: 'tipoResponsiva', headerName: 'Tipo Responsiva', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoUb = [
        { field: 'tipoUbicacionId', headerName: 'Id Tipo Ubicacion', width: 180 },
        { field: 'tipoUbicacion', headerName: 'Tipo Ubicacion', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTransmision = [
        { field: 'transmisionId', headerName: 'Id Transmisión', width: 180 },
        { field: 'transmision', headerName: 'Transmisión', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsUsoUnidad = [
        { field: 'usoUnidadId', headerName: 'Id Uso Unidad', width: 180 },
        { field: 'usoUnidad', headerName: 'Uso Unidad', width: 150 },
        { field: 'diaCancelacion', headerName: 'Dia Cancelación', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsEdoMexico = [
        { field: 'edoMexicoId', headerName: 'Id Estado México', width: 180 },
        { field: 'estado', headerName: 'Estado', width: 150 },
        { field: 'costo', headerName: 'Costo', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsCondicion = [
        { field: 'condicionId', headerName: 'Id Condición', width: 180 },
        { field: 'condicion', headerName: 'condicion', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsFrecuenciaP = [
        { field: 'frecuenciaPagoId', headerName: 'Id Frecuencia', width: 180 },
        { field: 'frecuencia', headerName: 'Frecuencia Pago', width: 150 },
        { field: 'meses', headerName: 'meses', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsInterfaz = [
        { field: 'interfazId', headerName: 'Id Interfaz', width: 180 },
        { field: 'interfaz', headerName: 'Interfaz', width: 150 },        
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoFactura = [
        { field: 'tipoFacturaId', headerName: 'Id Tipo Factura', width: 180 },
        { field: 'tipoFactura', headerName: 'Tipo Factura', width: 150 },        
        { field: 'customField', headerName: 'Custom Field', width: 150 },   
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsStatusGarantia = [
        { field: 'statusGarantiaId', headerName: 'Id Status Garantia', width: 180 },
        { field: 'statusGarantia', headerName: 'Status Garantia', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoGarantia = [
        { field: 'motivoGarantiaId', headerName: 'Id Motivo Garantia', width: 180 },
        { field: 'motivoGarantia', headerName: 'Status Garantia', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];

    const columsMotivoContrato = [
        { field: 'motivoContratoId', headerName: 'Id Motivo Contrato', width: 180 },
        { field: 'motivoContrato', headerName: 'Status Contrato', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];

    const columsTipoComision = [
        { field: 'tipoComisionId', headerName: 'Id Tipo Comisión', width: 180 },
        // { field: 'clave', headerName: 'Clave', width: 150 },      
        { field: 'tipoComision', headerName: 'Tipo Comisión', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];

    const columsMotivoCambio = [
        { field: 'motivoCambioId', headerName: 'Id Motivo Cambio', width: 180 },        
        { field: 'motivoCambio', headerName: 'Motivo Cambio', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsStatusDatamovil = [
        { field: 'statusDatamovilId', headerName: 'Id Datamovil', width: 180 },        
        { field: 'statusDatamovil', headerName: 'Status Datamovil', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoCosto = [
        { field: 'tipoCostoId', headerName: 'Id Tipo Costo', width: 180 },        
        { field: 'nombre', headerName: 'Nombre', width: 150 },      
        { field: 'tiempo', headerName: 'Tiempo', width: 150 },      
        { field: 'costo', headerName: 'Costo', width: 150 },   
        { field: 'categoriaCosto', headerName: 'Categoria Costo', width: 150 },      
        { field: 'requerido', headerName: 'Requerido', width: 150 },      
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsParametros = [
        { field: 'parametrosId', headerName: 'Id Parametro', width: 180 },        
        { field: 'parametro', headerName: 'Parametro', width: 150 },      
        { field: 'valorAnterior', headerName: 'valor Anterior', width: 150 },      
        { field: 'valorNuevo', headerName: 'Valor Nuevo', width: 150 }
    ];
    const columsColonia = [
        { field: 'coloniaId', headerName: 'Id Colonia', width: 70 },                           
        { field: 'colonia', headerName: 'Colonia', width: 150 },     
        { field: 'estado', headerName: 'Estado', width: 150 },   
        { field: 'municipio', headerName: 'Municipio', width: 150 },   
        { field: 'codigoPostal', headerName: 'Codigo Postal', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsInvExterno = [
        { field: 'inventarioExternoId', headerName: 'Id Inventario', width: 100 },                           
        { field: 'tipoCompra', headerName: 'Tipo Compra', width: 150 },      
        { field: 'externo', headerName: 'Externo', width: 150 },       
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];

    const columsTipoDocumento = [
        { field: 'tipoDocumentoId', headerName: 'Id Tipo Documento', width: 150 },                                   
        { field: 'dataDoctoId', headerName: 'Data Doc', width: 150 },      
        { field: 'nombre', headerName: 'Nombre', width: 150 },       
        { field: 'acendes', headerName: 'Id_Acendes', width: 150 },               
        { field: 'requerido', headerName: 'Requerido', width: 70 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoDocumentoUsuario = [
        { field: 'tipoDocumentoUsuarioId', headerName: 'Id', width: 150 },   
        { field: 'tipoDocumentoId', headerName: 'Id Tipo Doc', width: 150 },                           
        { field: 'idUsuario', headerName: 'Usuario', width: 150 },            
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoArchivo = [
        { field: 'tipoArchivoId', headerName: 'Id Archivo', width: 150 },   
        { field: 'nombre', headerName: 'Tipo Doc', width: 150 },                           
        { field: 'extension', headerName: 'Extensión', width: 150 },            
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTribunal = [
        { field: 'tribunalId', headerName: 'Id Tribunal', width: 150 },   
        { field: 'tribunal', headerName: 'Tribunal', width: 150 },                           
        { field: 'estado', headerName: 'Estado', width: 150 },            
        { field: 'municipio', headerName: 'Municipio', width: 150 },     
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsReglaNegocio = [
        { field: 'reglasNegocioId', headerName: 'Id Regla N', width: 150 },   
        { field: 'regla', headerName: 'Regla', width: 150 },                           
        { field: 'producto', headerName: 'Producto', width: 150 },            
        { field: 'tipoCompra', headerName: 'Tipo Compra', width: 150 },     
        { field: 'etapa', headerName: 'Etapa', width: 150 },    
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsEmpresa = [
        { field: 'empresaId', headerName: 'Id Empresa', width: 150 },   
        { field: 'empresa', headerName: 'Empresa', width: 150 },                           
        { field: 'calle', headerName: 'Calle', width: 150 },   
        { field: 'numExterior', headerName: 'Num Exterior', width: 150 },   
        { field: 'numInterior', headerName: 'Num Interior', width: 150 }, 
        { field: 'codigoPostalId', headerName: 'CP', width: 150 },            
        { field: 'colonia', headerName: 'colonia', width: 150 },    
        { field: 'municipio', headerName: 'Municipio', width: 150 },   
        { field: 'estado', headerName: 'Estado', width: 150 },   
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsRLegal = [
        { field: 'representanteLegalId', headerName: 'Id Empresa', width: 150 },    
        // { field: 'empresa', headerName: 'Empresa', width: 150 },                           
        { field: 'primerNombre', headerName: 'Primer Nombre', width: 150 },   
        { field: 'segundoNombre', headerName: 'Segundo Nombre', width: 150 },   
        { field: 'apellidoPaterno', headerName: 'Ap. Paterno', width: 150 }, 
        { field: 'apellidoMaterno', headerName: 'Ap. Materno', width: 150 },        
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsConfigFichero= [
        { field: 'configuracionFicheroId', headerName: 'Id', width: 150 },   
        { field: 'nombreTipoDocumento', headerName: 'Documento', width: 150 },                           
        { field: 'peso', headerName: 'Peso', width: 150 },   
        { field: 'required', headerName: 'Required', width: 150 },   
        { field: 'unico', headerName: 'Unico', width: 150 }, 
        { field: 'extensiones', headerName: 'Extensiones', width: 150 },        
        { field: 'orden', headerName: 'Orden', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsSocioComercial= [
        { field: 'socioComercialId', headerName: 'Id', width: 150 },   
        { field: 'nombre', headerName: 'Nombre', width: 150 },                           
        { field: 'telefono', headerName: 'Telefono', width: 150 },   
        { field: 'contactoPrincipal', headerName: 'Contacto Principal', width: 150 },   
        { field: 'correo', headerName: 'Correo', width: 150 }, 
        { field: 'calle', headerName: 'Calle', width: 150 },    
        { field: 'clasificacionVendedor', headerName: 'clasificación vendedor', width: 250 },        
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsCambioEtapa= [
        { field: 'cambioEtapaId', headerName: 'Id', width: 150 },   
        { field: 'motivoCambio', headerName: 'Motivo Cambio', width: 150 },                           
        { field: 'etapa', headerName: 'Etapa', width: 150 },   
        { field: 'inventarioId', headerName: 'Inventario', width: 150 },   
        { field: 'nombreUsuarioCambio', headerName: 'Usuario Cambio', width: 150 }, 
        { field: 'nombreUsuarioSolicito', headerName: 'Usuario Solicito', width: 150 },       
        { field: 'fechaCambio', headerName: 'Fecha Cambio', width: 150 }, 
        { field: 'hora', headerName: 'Hora', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsUbicacion= [
        // { field: 'ubicacionId', headerName: 'Id', width: 150 },   
        { field: 'nombre', headerName: 'Nombre', width: 150 },                           
        { field: 'tipoUbicacion', headerName: 'Tipo Ubicacion', width: 150 },   
        { field: 'responsable', headerName: 'Responsable'},   
        { field: 'calle', headerName: 'Calle', width: 150 },   
        { field: 'numExterior', headerName: 'Num Exterior', width: 150 }, 
        { field: 'numInterior', headerName: 'Num Interior', width: 150 },       
        { field: 'codigoPostalId', headerName: 'CP', width: 150 }, 
        { field: 'colonia', headerName: 'Colonia', width: 150 }, 
        { field: 'municipio', headerName: 'Municipio', width: 70 },
        { field: 'estado', headerName: 'Estado', width: 70 },
        { field: 'latitud', headerName: 'Latitud', width: 70 },
        { field: 'longitud', headerName: 'Longitud', width: 70 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMunicipio= [
        { field: 'municipioId', headerName: 'Id', width: 150 },   
        { field: 'municipio', headerName: 'Nombre', width: 150 },                           
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsVehiculo= [
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },   
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 }, 
        { field: 'nombreYear', headerName: 'Año', width: 150 },                           
        { field: 'nombreVersion', headerName: 'Versión', width: 150 },                           
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsIntelimotor= [
        { field: 'nombreMarca', headerName: 'Marca', width: 150 },   
        { field: 'nombreModelo', headerName: 'Modelo', width: 150 }, 
        { field: 'nombreYear', headerName: 'Año', width: 150 },                           
        { field: 'nombreVersion', headerName: 'Versión', width: 150 },                           
        { field: 'interfaz', headerName: 'Interfaz', width: 100 },
        { field: 'fechaString', headerName: 'Fecha Alta', width: 280 },
    ];
    const columsAgenteSeguro= [
        { field: 'agenteSeguroId', headerName: 'Id Agente seguro', width: 350 },   
        { field: 'agenteSeguro', headerName: 'Agente Seguro', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsSucursal= [
        { field: 'sucursalId', headerName: 'Id Sucursal', width: 350 },   
        { field: 'sucursal', headerName: 'Sucursal', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsVendedor= [
        { field: 'vendedorId', headerName: 'Id Vendedor', width: 350 },   
        { field: 'nombre', headerName: 'Nombre', width: 250 }, 
        { field: 'paterno', headerName: 'Apellido Paterno', width: 250 }, 
        { field: 'materno', headerName: 'Apellido Materno', width: 250 }, 
        { field: 'sucursal', headerName: 'Sucursal', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoMantener= [
        { field: 'motivoMantenerId', headerName: 'Id Motivo', width: 350 },   
        { field: 'motivo', headerName: 'Motivo', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoLiberacionSeparado= [
        { field: 'motivoLiberacionSeparadoId', headerName: 'Id Motivo liberación', width: 350 },   
        { field: 'motivoSeparado', headerName: 'Motivo Separado', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoEjecutivo= [
        { field: 'tipo_EjecutivoId', headerName: 'Id Tipo ejecutivo', width: 350 },   
        { field: 'tipo_Ejecutivo', headerName: 'Tipo ejecutivo', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoPromotor= [
        { field: 'tipo_PromotorId', headerName: 'Id Tipo promotor', width: 350 },   
        { field: 'tipo_Promotor', headerName: 'Tipo promotor', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsProcedenciaCliente= [
        { field: 'procedencia_ClienteId', headerName: 'Id Procedencia cliente', width: 350 },   
        { field: 'procedencia_Cliente', headerName: 'Procedencia cliente', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsEjecutivo= [
        { field: 'ejecutivoId', headerName: 'Id Ejecutivo', width: 350 },   
        { field: 'nombre_Ejecutivo', headerName: 'Nombre Ejecutivo', width: 250 }, 
        { field: 'tipo_Ejecutivo', headerName: 'Tipo Ejecutivo', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsLiberacionEnganche= [
        { field: 'motivoLiberacionEngancheId', headerName: 'Id', width: 350 },   
        { field: 'motivo', headerName: 'Motivo', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsOperacionPlacas= [
        { field: 'operacion_PlacasId', headerName: 'Id', width: 350 },   
        { field: 'descripcion', headerName: 'Descripción', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTecnico= [
        { field: 'tecnicoId', headerName: 'Id Técnico', width: 350 },   
        { field: 'nombre_Tecnico', headerName: 'Nombre', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoBaja= [
        { field: 'motivoBajaPlacasId', headerName: 'Id Motivo', width: 350 },   
        { field: 'motivo', headerName: 'Motivo', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoPM= [
        { field: 'motivoLiberacionPruebaManejoId', headerName: 'Id Motivo', width: 350 },   
        { field: 'motivo', headerName: 'Motivo', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsANombreDe= [
        { field: 'nombreId', headerName: 'Id', width: 250 },   
        { field: 'nombre', headerName: 'Nombre', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoCancelacionGestoria= [
        { field: 'motivoCancelacionGestoriaId', headerName: 'Id', width: 250 },   
        { field: 'motivo', headerName: 'Motivo', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoCancelacionEntrega= [
        { field: 'motivoCancelacionEntregaId', headerName: 'Id', width: 250 },   
        { field: 'motivo', headerName: 'Motivo', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoExpediente= [
        { field: 'tipoExpedienteId', headerName: 'Expediente Id', width: 250 },   
        { field: 'tipoExpediente', headerName: 'Expediente', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoCambioProd= [
        { field: 'motivoCambioProductoId', headerName: 'Motivo Id', width: 250 },   
        { field: 'motivo', headerName: 'Motivo', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMotivoNoRenovacionSeguro= [
        { field: 'motivoNoRenovacionSeguroId', headerName: 'Motivo Id', width: 250 },   
        { field: 'motivo', headerName: 'Motivo', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsRevisionUnidad= [
        { field: 'revisionUnidadId', headerName: 'Id', width: 250 },   
        { field: 'year', headerName: 'Año', width: 150 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsMecanicos= [
        { field: 'mecanicoId', headerName: 'ID Mecanico', width: 350 },   
        { field: 'nombre', headerName: 'Nombre', width: 250 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsUsoCFDI= [
        { field: 'usoCFDIId', headerName: 'ID', width: 350 },   
        { field: 'clave', headerName: 'Clave', width: 100 }, 
        { field: 'descripcion', headerName: 'Descipción', width: 250 }, 
        { field: 'cadRegimenFiscal', headerName: 'Regimen Fiscal', width: 350 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsRegimenFiscal= [
        { field: 'regimenFiscalId', headerName: 'ID', width: 350 },   
        { field: 'clave', headerName: 'Clave', width: 100 }, 
        { field: 'descripcion', headerName: 'Descipción', width: 250 }, 
        { field: 'fisicaStr', headerName: 'Fiscal', width: 100 }, 
        { field: 'moralStr', headerName: 'Moral', width: 100 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsTipoCalculo= [
        // { field: 'tipoCalculoId', headerName: 'ID', width: 350 },   
        { field: 'nombre', headerName: 'Nombre', width: 100 }, 
        { field: 'aseguradora', headerName: 'Aseguradora', width: 250 }, 
        { field: 'usoUnidad', headerName: 'Uso de unidad', width: 100 }, 
        { field: 'porcentaje', headerName: 'Porcentaje', width: 100 }, 
        { field: 'strMonto', headerName: 'Monto', width: 100 }, 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsClasificacionVendedor= [
        // { field: 'tipoCalculoId', headerName: 'ID', width: 350 },   
        { field: 'clasificacion', headerName: 'Clasificación', width: 100 }, 
        { field: 'porcentaje', headerName: 'Porcentaje', width: 100 }, 
        { field: 'cuotaPorLeadStr', headerName: 'Cuota por Lead', width: 200 },                 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsSucursalVendedor= [        
        { field: 'externo', headerName: 'Socio Comercial', width: 100 }, 
        { field: 'nombre', headerName: 'Nombre', width: 100 }, 
        { field: 'calle', headerName: 'Calle', width: 200 },
        { field: 'numeroExterior', headerName: 'Numero Exterior', width: 100 },                 
        { field: 'numeroInterior', headerName: 'Numero Interior', width: 100 }, 
        { field: 'codigoPostal', headerName: 'CP', width: 100 }, 
        { field: 'telefono', headerName: 'Telefono', width: 150 },  
        { field: 'matrizStr', headerName: 'Matriz', width: 70 },                 
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    const columsUbicacionDestino= [        
        { field: 'ubicacionDestinoId', headerName: 'Id Ubicación Destino', width: 180 },
        { field: 'ubicacion', headerName: 'Ubicación', width: 150 },
        { field: 'status', headerName: 'Estatus', width: 70 },
    ];
    return {
        columnsMarca,
        columnsModelo,
        columnsVersion,
        columnsYears,
        columnsAseguradora,
        columnsBanco,
        columnsCatCosto,
        columnsCobertura,
        columnsEtapa,
        columnsExtension,
        columsMotivoCS,
        columsMotivoUb,
        columsProducto,
        columsStatusSeguro,
        columsTipoAd,
        columsTipoCompra,
        columsTipoExterno,
        columsTipoPago,
        columsResponsiva,
        columsTipoUb,
        columsTransmision,
        columsUsoUnidad,
        columsEdoMexico,
        columsCondicion,
        columsFrecuenciaP,
        columsInterfaz,
        columsTipoFactura,
        columsStatusGarantia,
        columsMotivoGarantia,
        columsMotivoContrato,
        columsTipoComision,
        columsMotivoCambio,
        columsStatusDatamovil,
        columsTipoCosto,
        columsParametros,
        columsColonia,
        columsInvExterno,
        columsTipoDocumento,
        columsTipoDocumentoUsuario,
        columsTipoArchivo,
        columsTribunal,
        columsReglaNegocio,
        columsEmpresa,        
        columsConfigFichero,
        columsSocioComercial,
        columsCambioEtapa,
        columsUbicacion,
        columsRLegal,
        columsMunicipio,
        columsVehiculo,
        columsIntelimotor,
        columsAgenteSeguro,
        columsSucursal,
        columsVendedor,
        columsMotivoMantener,
        columsMotivoLiberacionSeparado,
        columsTipoEjecutivo,
        columsTipoPromotor,
        columsProcedenciaCliente,
        columsEjecutivo,
        columsLiberacionEnganche,
        columsOperacionPlacas,
        columsTecnico,
        columsMotivoBaja,
        columsMotivoPM,
        columsANombreDe,
        columsMotivoCancelacionGestoria,
        columsMotivoCancelacionEntrega,
        columsTipoExpediente,
        columsMotivoCambioProd,
        columsMotivoNoRenovacionSeguro,
        columsRevisionUnidad,
        columsMecanicos,
        columsUsoCFDI,
        columsRegimenFiscal,
        columsTipoCalculo,
        columsClasificacionVendedor,
        columsSucursalVendedor,
        columsUbicacionDestino
    }
}

export default ColumsModal