import { Form, Formik, Field } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from '../../../../Components/TextField';
import Modal from '../../../../Components/Modal/Modal';
import Spinner from '../../../../Components/Loadig';
import { SelectField } from '../../../../Components/SelectField';
import useInicioServicio from '../../../../Hooks/DatamovilSystems/RevisionPasos/useInicioServicio';
import { getDateFormat } from '../../../../Utils/functions';
import { TextAreaField } from '../../../../Components/TextAreaField';
import { useNavigate } from 'react-router-dom';

const CardInicioServicio = ({ handleConcluido }) => {
    const refFormik = useRef();
    const [collapsible, setCollapsible] = useState(false);

    const validationSchema = Yup.object().shape({

        vehiculoEnUbicacion: Yup.string()
            .when('concluirRegistroInicioServicio', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('Selecciona una opción')
            }),
        // accederUbicacion: Yup.string()
        //     .nullable()
        //     .when(["vehiculoEnUbicacion", "concluirRegistroInicioServicio"], {
        //         is: (vehiculoEnUbicacion, concluirRegistroInicioServicio) => vehiculoEnUbicacion && concluirRegistroInicioServicio,
        //         then: Yup.string()
        //             .nullable()
        //             .required("Selecciona una opción"),
        //     }),
        // accederVehiculo: Yup.string()
        //     .nullable()
        //     .when(["vehiculoEnUbicacion", "concluirRegistroInicioServicio"], {
        //         is: (vehiculoEnUbicacion, concluirRegistroInicioServicio) => vehiculoEnUbicacion && concluirRegistroInicioServicio,
        //         then: Yup.string()
        //             .nullable()
        //             .required("Selecciona una opción"),
        //     }),
        comentariosInicioServicio: Yup.string()
            .nullable()
            .when('concluirRegistroInicioServicio', {
                is: true,
                then: Yup.string()
                    .nullable()
                    .required('Escribe un comentario')
            }),
    });
    const paso = 2
    const {
        initialValue,
        loading,
        setLoading,
        submitFormUbicacionVehiculo,
        opcionesBool,
        tipoUbicaciones
    } = useInicioServicio({ handleConcluido, paso });

    const navigate = useNavigate();
    const handleCalendario = () => {
        navigate('/datamovil/citas/v2/19', { state: { title: 'Servicios Datamovil', tipoCalendario: 19 } });
    }

    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <div className='row mx-md-4 my-4'>
                <div className="col-12 px-md-3">
                    <div className="p-4 white-wrapper mb-2">
                        <div
                            className="row mt-2"
                            data-bs-toggle="collapse"
                            href="#collapseUbicacion"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseUbicacion"
                            onClick={() => setCollapsible(!collapsible)}
                        >
                            <div className="col-11">
                                <h6><span className={`semaforo semaforo-${initialValue?.concluirRegistroInicioServicio ? "green" : "default"}`}></span> <strong>
                                    Ubicación de Vehículo
                                </strong></h6>
                            </div>
                            <div className="col-11">
                                <small>{initialValue.concluirRegistroInicioServicio ? " Concluido" : " En Proceso"}</small>
                            </div>
                            <div className="col-1 text-end">
                                <i className={!collapsible ? "icon-blue bx bx-chevron-down x2" : "icon-blue bx bx-chevron-up x2"}></i>
                            </div>
                        </div>
                        <div className="collapse" id="collapseUbicacion">
                            <div className="divider"></div>
                            {/* <span className='my-4'>
                                <b>Detalle de ubicación</b>
                            </span> */}
                            <Formik
                                innerRef={refFormik}
                                enableReinitialize={true}
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={(values) => submitFormUbicacionVehiculo(values)}
                            >
                                {({ isValid, values, setFieldValue, errors }) => (
                                    <Form id="agendarCitaForm">
                                        <div className="row mt-2 mb-2">
                                            <div className='col-4'>
                                                <h6><strong>Detalle de ubicación</strong></h6>
                                            </div>
                                            <div className="col-8">
                                                <div className="row justify-content-end">
                                                    <div className="col-auto">
                                                        {
                                                            initialValue.concluirRegistroInicioServicio ?
                                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'>
                                                                    <i className="icon-dark bx bx-hide mx-1" ></i>
                                                                    Modo lectura
                                                                </fieldset>
                                                                : <button
                                                                    className='btn'
                                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                                    type='submit'>
                                                                    Guardar
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Select para la ubicación del vehículo */}
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <SelectField
                                                    id="vehiculoEnUbicacion"
                                                    label="¿Vehículo estaba en la ubicación designada?"
                                                    name="vehiculoEnUbicacion"
                                                    items={opcionesBool}
                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                    onChange={(event) => setFieldValue("vehiculoEnUbicacion", event.value)}
                                                />
                                            </div>
                                        </div>
                                        {values.vehiculoEnUbicacion && (
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <SelectField
                                                            id="accederUbicacion"
                                                            label="¿Puedes acceder a la ubicación?"
                                                            name="accederUbicacion"
                                                            items={opcionesBool}
                                                            disabled={initialValue.concluirRegistroInicioServicio}
                                                            onChange={(event) => setFieldValue("accederUbicacion", event.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <SelectField
                                                            id="accederVehiculo"
                                                            label="¿Puedes acceder al vehiculo?"
                                                            name="accederVehiculo"
                                                            items={opcionesBool}
                                                            disabled={initialValue.concluirRegistroInicioServicio}
                                                            onChange={(event) => setFieldValue("accederVehiculo", event.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-8">
                                                        <TextAreaField id="comentariosInicioServicio"
                                                            label="Comentarios"
                                                            disabled={initialValue.concluirRegistroInicioServicio}
                                                            className="form-control col-8"
                                                            rows="3"
                                                            name="comentariosInicioServicio"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => setFieldValue("comentariosInicioServicio", event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className='col col-md-2 col-sm-12'>
                                                        <TextField label="Fecha inicio"
                                                            name="fechaInicioUbicacion"
                                                            type="text"
                                                            disabled={true} />
                                                    </div>
                                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                                        <TextField
                                                            disabled={true}
                                                            label="Hora inicio"
                                                            name="horaInicioUbicacion"
                                                            type="text" />
                                                    </div>

                                                    <div className='col-lg-2 col-md-2 col-sm-12'>
                                                        <TextField
                                                            label="Usuario"
                                                            name="usuarioSolicita"
                                                            id="usuarioSolicita"
                                                            holder=''
                                                            type="text"
                                                            disabled={true}
                                                        />
                                                    </div>

                                                </div>
                                            </>
                                        )}
                                        {/* Mostrar estos campos solo si vehiculoDesignado es "No" */}
                                        {values.vehiculoEnUbicacion === false && (
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-md-8">
                                                        <TextAreaField id="comentariosInicioServicio"
                                                            label="Comentarios"
                                                            disabled={initialValue.concluirRegistroInicioServicio}
                                                            className="form-control col-8"
                                                            rows="3"
                                                            name="comentariosInicioServicio"
                                                            type="text"
                                                            holder="Escribe"
                                                            onChange={(event) => setFieldValue("comentariosInicioServicio", event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <h6><strong>Tipo de Ubicación</strong></h6>
                                                    <div className="col-md-4">
                                                        <SelectField
                                                            label="Tipo de Ubicación"
                                                            name="tipoUbicacion"
                                                            id="tipoUbicacion"
                                                            items={tipoUbicaciones}
                                                            disabled={true}
                                                            onChange={(event) => setFieldValue("tipoUbicacion", event.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <TextField
                                                            label="Ubicación"
                                                            name="nombreUbicacion"
                                                            holder=''
                                                            type="text"
                                                            disabled={true}
                                                        //onChange={(event) => setFieldValue("nombreUbicacion", event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <h6><strong>Fecha para Reagendar cita</strong></h6>
                                                    <div className='col-lg-32 col-md-3 col-sm-12'>
                                                        <TextField label="Fecha y hora de cita"
                                                            name="fechaHoraCita"
                                                            type="text"
                                                            disabled={true}
                                                            holder="No hay cita seleccionada" />
                                                    </div>
                                                    <div className='col-lg-32 col-md-3 col-sm-12'>
                                                        <TextField label="Estatus cita"
                                                            name="estatusUbicacion"
                                                            type="text"
                                                            disabled={true}
                                                            holder="Estatus cita" />
                                                    </div>
                                                    <div className="col-2" style={{ alignSelf: "flex-end", paddingBottom: "0.5rem" }}>
                                                        <button className='btn'
                                                            disabled={initialValue.concluirRegistroInicioServicio}
                                                            onClick={() => {
                                                                handleCalendario();
                                                            }}>Ver calendario</button>
                                                    </div>
                                                    {/* <label className='d-flex align-items-center mb-4' style={{ fontSize: '13px', color: 'red' }}><i className='icon-red ri-error-warning-fill' style={{ fontSize: "15px" }}></i> Es importante concluir el registro para inicar el proceso de autorización.</label> */}
                                                </div>
                                            </>
                                        )}

                                        <div className="row mb-3">
                                            <div className="col-md-3 d-flex align-items-center">
                                                {/* ConcluirRegistroInicioServicio */}
                                                <Field
                                                    type="checkbox"
                                                    name="concluirRegistroInicioServicio"
                                                    className="form-check-input me-2"
                                                    disabled={initialValue.concluirRegistroInicioServicio}
                                                    onClick={(event) => {
                                                        setFieldValue("concluirRegistroInicioServicio", event.target.value);
                                                    }} />
                                                <label className="form-check-label">Concluir registro</label>
                                            </div>


                                        </div>

                                        <div className="text-end">
                                            <small className="text-muted">
                                                Inicio de proceso: {initialValue?.fechaInicioServicio} | Fin de proceso: {initialValue?.fechaFinalServicio}
                                            </small>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardInicioServicio;
