import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../Components/Loadig';
import Modal from '../../../Components/Modal/Modal';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import DSystemsServicios from '../../../Services/DatamovilSystems/ServiciosDatamovil';


const ModalBusquedaAvanzada = ({ isOpen, setIsOpen, busquedaAvanzada,handleBusqueda }) => {
    const refbtn = useRef();
    const { id } = useParams();
    const navigate = useNavigate();
    
    let initial = {
        buscarAvanzado: busquedaAvanzada.buscarAvanzado ?? "",
        fechaInicio: busquedaAvanzada.fechaInicio ?? "",
        fechaFin: busquedaAvanzada.fechaFin ??"",
        concluido: busquedaAvanzada.concluido ?? ""
    };
    const [initialValue, setInitialValue] = useState(initial);
    const [loading, setLoading] = useState([]);

    useEffect(() => {

    }, [])

    const validate = Yup.object({

    });

    const buscarVIN = (values) => {
        handleBusqueda(values)
        setIsOpen(false)
    }
    return (
        <>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={validate}
                onSubmit={(values) => {
                    buscarVIN(values);
                }}
            >
                {({ isValid, setFieldValue, values }) => (
                    <Modal isOpen={isOpen} color='#fff' style={{ width: '10px' }}>
                        <section className='d-flex justify-content-between mx-4'>
                            <section className='py-2 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <span className=''><strong>Búsqueda Avanzada</strong></span>
                                </div>
                            </section>
                        </section>
                        <section className='my-4 mx-4'>
                            <Form>
                                <>
                                    <div className='mb-3 row'>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <TextField
                                                label=""
                                                name="buscarAvanzado"
                                                type="text"
                                                holder="Buscar"
                                            // onChange={(event) => {
                                            //     setFieldValue("buscar", event.target.value)
                                            // }}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-3 row'>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <TextField
                                                label="Fecha Inicio"
                                                name="fechaInicio"
                                                type="date"
                                                holder=""
                                            // onChange={(event) => {
                                            //     setFieldValue("fechaInicio", event.target.value)
                                            // }}
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-3 row'>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <TextField
                                                label="Fecha fin"
                                                name="fechaFin"
                                                type="date"
                                                holder=""
                                            // onChange={(event) => {
                                            //     setFieldValue("fechaFin", event.target.value)
                                            // }}
                                            />
                                        </div>
                                    </div>

                                    <div className='mb-3 row'>
                                        <div className="col-md-12 d-flex align-items-center">
                                            <Field type="checkbox" name="concluido" className="form-check-input me-2"
                                            // onClick={(event) => {
                                            //     setFieldValue("concluido", event.target.value);
                                            // }}
                                            />
                                            <label className="form-check-label">Concluido</label>
                                        </div>
                                    </div>
                                </>
                                <section className='my-3'>
                                    <button
                                        id="AT_btnCancelar"
                                        className='btn btn-outline'
                                        type='reset'
                                        onClick={() => { setIsOpen(false) }}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className='btn mx-2'
                                        type="submit"
                                        disabled={!(isValid)}>
                                        Continuar
                                    </button>
                                </section>
                            </Form>
                        </section>
                    </Modal>
                )
                }
            </Formik >
        </>
    )
}

export default ModalBusquedaAvanzada