import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { SelectField } from "../../../../Components/SelectField";
import { TextAreaField } from "../../../../Components/TextAreaField";
import { TextField } from "../../../../Components/TextField";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";
import handlePhonNumberFormatter, { FormatFecha, getHourFormat, message } from "../../../../Utils/functions";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import useScreenSize from "../../../../Hooks/SeguimientoVisita/useScreenSize";

const ContactoAgregar = () => {
   const navigate = useNavigate();
   const { datosConsulta } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [fechaInicio, setFechaInicio] = useState("")
   const [fechaFin, setFechaFin] = useState("")
   const [dataTipoContacto, setDataTipoContacto] = useState([]);
   const [dataSubTipoContacto, setDataSubTipoContacto] = useState([]);
   const [loading, setLoading] = useState(false);

   let initial = {
      tipoContacto: "",
      subtipoContacto: "",
      valor: "",
      comentarios: ""
   }

   const HandleGetTipoContacto = async () => {
      await CobranzaService.getAllTipoContacto()
         .then(res => {
            let tipos = []
            res?.data?.responseData?.map(item => {
               if (item?.activo) {
                  tipos.push({
                     value: item?.id,
                     label: item?.nombre
                  });
               }
            });
            setDataTipoContacto(tipos);
         })
   }

   const HandleGetSubtiposContacto = async () => {
      await CobranzaService.getAllSubTipoContacto()
         .then(res => {
            let subtipo = []
            res?.data?.map(item => {
               if (item?.activo) {
                  subtipo.push({
                     value: item?.id,
                     label: item?.nombre
                  });
               }
            });
            setDataSubTipoContacto(subtipo);
         })
   }

   const handleAgregarContacto = async (values) => {
      setLoading(true);

      let today = new Date();


      let datoValor = values.valor;
      // if ((values.tipoContacto == "Télefono fijo" || values.tipoContacto == "Celular") && values.subtipoContacto == "Particular")
      if ((values.tipoContacto == "Celular" && values.subtipoContacto == "Particular") || (values.tipoContacto == "Télefono fijo"))
         datoValor = datoValor.replace(/\D/g, '');

      let data = {
         sol_id: parseInt(datosConsulta.sol_id),
         contrato: datosConsulta?.contrato,
         vin: datosConsulta?.vin,
         idpersona_acendes: parseInt(datosConsulta.persona.value),
         tipo_persona: datosConsulta?.persona?.item.tipo,
         curp: datosConsulta?.persona?.item?.curp_ref,
         tipo_contacto: values.tipoContacto,
         subtipo_contacto: values.subtipoContacto,
         dato: datoValor,
         valor: datoValor,
         comentario: values.comentarios,
         fecha_operacion: today
      }

      console.log(data)

      await CobranzaService.createNuevoDatoContacto(data)
         .then(res => {
            toast.success(message("¡Operación exitosa!", res.data.mensaje));
            setLoading(false)
            navigate(-1)
         }).catch(e => {
            setLoading(false)
            if (e.response !== undefined) {
               if (e.response.data == null || e.response.data == "")
                  toast.error(message("¡Error!", e.response.statusText));
               else
                  toast.error(message("¡Error!", e.response.data.message));
            }
            else
               toast.error(message("¡Error!", e.message));
         })
   }

   const [initialValue, setInitialValue] = useState(initial);
   const validate = Yup.object({
      tipoContacto: Yup.string()
         .required("El campo es requerido."),
      subtipoContacto: Yup.string()
         .required("El campo es requerido."),
      valor: Yup.string()
         .required("El campo es requerido")
         .when("tipoContacto", {
            is: "Télefono fijo",
            then: Yup.string().length(14, "Introduce un número teléfonico valido a 10 dígitos")
         })
         .when("tipoContacto", {
            is: "Celular",
            then: Yup.string().length(14, "Introduce un número teléfonico valido a 10 dígitos")
         })
         .when("tipoContacto", {
            is: "Correo",
            then: Yup.string().email("Ingresa un correo valido")
         }),
      comentarios: Yup.string()
         .required("El campo es requerido.")
         .min(20, "El comentario debe contener al menos 20 caracteres")
   });

   useEffect(() => {
      let diaActual = new Date()
      let hour = getHourFormat(`${String(diaActual.getHours()).padStart(2, '0')}:${String(diaActual.getMinutes()).padStart(2, '0')}`)
      setFechaInicio(FormatFecha(diaActual) + ' ' + hour)
      setFechaFin(FormatFecha(diaActual) + ' ' + hour)
      HandleGetTipoContacto();
      HandleGetSubtiposContacto();
   }, [])

   return (
      <>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto' }}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-12 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                 <h2 className="fw-bold ">Agregar contacto</h2>
                              </div>
                           </div>
                           <div className="row gap-2">
                              <div className="col col-12">
                                 <div className="row mt-4">
                                    <div className="col-sm-4 col-xl-3">
                                       <div className="row justify-content-start">
                                          <div className="col col-12">
                                             <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.item.tipo}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-5 col-xl-4">
                                       <div className="row justify-content-start">
                                          <div className="col col-12">
                                             <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-11 mx-3 wrapper-vehiculo " style={{ background: "#FFF", height: "auto" }}>
                  <Formik
                     enableReinitialize={true}
                     validationSchema={validate}
                     initialValues={initialValue}
                     onSubmit={(values) => {
                        handleAgregarContacto(values);
                     }}>
                     {({ isValid, values, setFieldValue, errors }) => (
                        <>
                           <Form>
                              <div className="px-4">
                                 <div className="row mt-2 px-4">
                                    <div className="col-sm-5 col-xl-2 mt-3">
                                       <p><span style={{ fontSize: '20px', fontWeight: 'bold' }}>Detalle de contacto</span></p>
                                    </div>
                                    <div className="col-sm-7 col-xl-6 mt-3">
                                       <div className="row justify-content-end">
                                          <div className="col-auto">
                                             <button className='btn col-12' type='submit' disabled={!(isValid)}>Continuar</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                    <div className="col-sm-3 col-xl-2 mt-3">
                                       <SelectField label="Tipo contacto" name="tipoContacto" items={dataTipoContacto} onChange={(e) => {
                                          setFieldValue("tipoContacto", e.value !== "" ? e.label : "");
                                          setFieldValue("valor", "");
                                       }} />
                                    </div>
                                    <div className="col-sm-3 col-xl-2 mt-3">
                                       <SelectField label="Subtipo" name="subtipoContacto" items={dataSubTipoContacto} onChange={(e) => {
                                          setFieldValue("subtipoContacto", e.value !== "" ? e.label : "");
                                       }} />
                                    </div>
                                    {
                                       values.tipoContacto === "Correo"
                                          ? <div className="col-sm-3 col-xl-2 mt-3">
                                             <TextField label="Valor" name="valor" type="text" holder="Escribe"
                                                onChange={(event) => { setFieldValue("valor", event.target.value); }} />
                                          </div>
                                          : <div className="col-sm-3 col-xl-2 mt-3">
                                             <TextField label="Valor" min={0} name="valor" type="text" holder="Escribe"
                                                onChange={(event) => { setFieldValue("valor", handlePhonNumberFormatter(event.target.value)); }} />
                                          </div>
                                    }
                                 </div>
                                 <div className="mb-3 row px-4" style={{ marginTop: 30 }}>
                                    <div className="col-6 mb-3">
                                       <TextAreaField label="Comentarios:" className="col-sm-12 col-xl-8 mt-2 form-control" rows="4" name="comentarios" type="text" holder="Escribe" />
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        </>)}
                  </Formik>
               </div>
            </div>
         </div>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <Toaster
            position="top-right"
            toastOptions={{
               success: {
                  style: {
                     background: "#47a066",
                     color: "#FFFF",
                     borderLeft: "10px solid #2f7246",
                  },
               },
               error: {
                  style: {
                     background: "#d53f3f",
                     color: "#FFFF",
                     borderLeft: "10px solid #ac241a",
                  },
               },
            }}
         />
      </>
   );
}

export default ContactoAgregar;