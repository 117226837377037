import { Formik, Form } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import Modal from '../../../../Components/Modal/Modal';
import { TextField } from '../../../../Components/TextField';
import { formatearMontoMx, message } from '../../../../Utils/functions';

const ModalCostosResguardoUnidad = ({ isOpen, setIsOpen, item, addUpdateCostosAdicionalesTbl, toast }) => {
    const refbtn = useRef();

    let initial = {
        idCostoAdicional: item?.id ?? "",
        valuacionRegistroGastosId: item.valuacionRegistroGastosId ?? "",
        concepto: item?.concepto ?? "",
        subtotal: item?.subtotal ?? "",
        iva: item?.iva ?? 0,
        total: item?.total ?? "",
        concluido: item?.concluido ?? false,
        fechaInicio: item?.fechaInicio ?? "",
        fechaFin: item?.fechaFin ?? ""
    };
    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        concepto: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .matches(
                "^[a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+(?: [a-zA-Z0-9-ZÀ-ÿ\u00f1\u00d1.,]+)*$",
                "La información ingresada es incorrecta."
            )
            .matches(
                "^[a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+(?: [a-zA-Z0-9-ZáéíóúÁÉÍÓÚñÑ.,]+)*$",
                "solo debe existir un espacio entre cada palabra."
            )
            .min(3, 'minimo 3 caracteres'),
        subtotal: Yup.number()
            .required("El campo es requerido.")
            .positive("Ingrese un valor mayor a cero."),
        iva: Yup.number().test({
            name: 'is-Iva',
            skipAbsent: true,
            test(value, ctx) {
                if (value < 0)
                    return ctx.createError({ message: 'Ingrese un valor mayor o igual a 0.' })
                if (value >= 100)
                    return ctx.createError({ message: 'ingrese un valor entre 0 y 99' })
                return true
            }
        }),
        total: Yup.number()
            .required("El campo es requerido.")
    });

    const handleAddEdit = async (item) => {
        refbtn.current.disabled = true;
        if (initialValue.idCostoAdicional !== "") {
            setIsOpen(false);
            toast.success(message("¡correcto!", 'registro agregado a tabla temporal'));
            addUpdateCostosAdicionalesTbl(item);
        }
        else {
            setIsOpen(false);
            toast.success(message("¡correcto!", 'registro actualizado en tabla temporal'));
            addUpdateCostosAdicionalesTbl(item);
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
            }}
        >
            {({ isValid, setFieldValue, values }) => (
                <Modal isOpen={isOpen} color='#fff'>
                    <section className='d-flex justify-content-between mx-4'>
                        <section className='py-2 d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <span className=''><strong>Registrar costo</strong></span>
                            </div>
                            <span className='mt-2 fs-6'>A continuación completa todos los campos para el registro del costo.</span>
                        </section>
                    </section>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <Form>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="Concepto" name="concepto" type="text" holder="Escribe" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField icon={"bi bi-currency-dollar"} label="Subtotal" name="subtotal" type="text" holder="Escribe" isMoney={true} onChange={(event) => {
                                        if (event.target.value < 0 || isNaN(event.target.value)) {
                                            setFieldValue("subtotal", 0);
                                            setFieldValue("total", 0);
                                        }
                                        else {
                                            setFieldValue("subtotal", event.target.value)
                                            if (event.target.value === "" || isNaN(event.target.value)) {
                                                setFieldValue("total", 0);
                                            }
                                            else {
                                                let total = (parseFloat(event.target.value) * parseInt(values.iva) / 100) + parseFloat(event.target.value);
                                                setFieldValue("total", Number(total.toFixed(2)));
                                            }
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="IVA (%)" name="iva" type="number" min="0" max="99" decimalScale={0} isMoney={true} holder="Escribe" onChange={(event) => {
                                        if (event.target.value === "" || event.target.value < 0 || isNaN(event.target.value)) {
                                            setFieldValue("iva", 0);
                                            setFieldValue("total", 0);
                                        }
                                        else {
                                            setFieldValue("iva", event.target.value);
                                            if (isNaN(values.subtotal) || values.subtotal === "") {
                                                setFieldValue("total", 0);
                                            }
                                            else {
                                                let total = (parseFloat(values.subtotal) * parseInt(event.target.value) / 100) + parseFloat(values.subtotal);
                                                setFieldValue("total", Number(total.toFixed(2)));
                                            }
                                        }
                                    }} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <TextField label="Total" disabled name="total" type="text" holder="Escribe" value={formatearMontoMx(values.total)} />
                                </div>
                            </div>
                            <section className='my-3'>
                                <button id="AT_btnCancelar" className='btn btn-outline' type='reset' onClick={() => { setIsOpen(false) }}>Cancelar</button>
                                <button id="AT_btnGuardar" className='btn mx-2' ref={refbtn} type="submit" disabled={!(isValid)}>{initialValue.idCostoAdicional === "" ? "Guardar" : "Editar"}</button>
                            </section>
                        </Form>
                    </section>
                </Modal>
            )
            }
        </Formik>
    )
}

export default ModalCostosResguardoUnidad