import { useEffect, useState } from "react";
import { FormatFecha, message } from "../../Utils/functions";
import CobranzaService from "../../Services/Cobranza/Cobranza";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setItemGeneralProperty } from '../../Redux/Slice/Cobranza/SeguimientoVisita/SeguimientoVisitaDetalleSlice';

const useSeguimientoVisitaDetalle = (toast) => {
    const { contratoActual, itemGeneral } = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
    const userId = sessionStorage.getItem('userId').trim();
    const [currentDataSeguimiento, setCurrentDataSeguimiento] = useState([{}]);
    const [userDetail, setUserDetail] = useState([{}]);
    const [promesasRotas, setPromesasRotas] = useState(0);
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const navigate = useNavigate();
    const [allContracts, setAllContracts] = useState([]);
    const [modalConfirm, setModalConfirm] = useState(false);
    // const [cierreVisita, setCierreVisita] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        // handleGetSeguimientoContrato();
        // GetUserStats();

        handleGetAllData();
    }, [])

    const handleGetAllData = async () => {
        setLoading(true)
        const [] = await Promise.all([handleGetSeguimientoContrato(), GetUserStats()]).finally(() => {
            setLoading(false)
        });
    }

    const handleGetSeguimientoContrato = async () => {
        setLoading(true);
        const data = {
            "verb": null,
            "id": null,
            "field": null,
            "value": null
        }
        await CobranzaService.getAllEjecutivosWithFullDetail(data)
            .then(res => {
                setAllContracts(res?.data?.responseData);
                const userContracts = res?.data?.responseData?.find(user => user?.userid?.toLowerCase() === userId?.toLowerCase());

                let datosFormateados = userContracts?.contratos?.map((item) => {
                    const fechaPago = FormatFecha(item?.fecha_ultimo_pago)
                    const fechaClave = FormatFecha(item?.fecha_ultima_clave)
                    return {
                        ...item,
                        fecha_ultimo_pago: fechaPago,
                        fecha_ultima_clave: fechaClave,
                    }
                })

                const contractDetail = datosFormateados.find(contract => contract?.contrato_id === contratoActual?.contrato_id) || [{}]

                const userDetail = userContracts.user_detail

                setCurrentDataSeguimiento(contractDetail)
                setUserDetail(userDetail)
                setLoading(false);

            }).catch(e => {
                setLoading(false);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e?.response?.data?.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e?.message })
            })
    }

    const handleReturnPage = () => {
        navigate(`/cobranza/seguimiento-visita/seguimiento-visita`);
    }

    const handleCerrarVisita = async () => {
        // setCierreVisita(true);
        UpsertCobranzaRutaItem();
        setModalConfirm(false);
    };

    const UpsertCobranzaRutaItem = async () => {
        setLoading(true);
        let data = {
            "verb": "string",
            "id": "string",
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": false,
            "anyObject": {
                "id": itemGeneral.id,
                "ruta_head_id": itemGeneral.ruta_head_id,
                "evento_item_id": itemGeneral.evento_item_id,
                "evento_head_id": itemGeneral.evento_head_id,
                "fecha": itemGeneral.fecha,
                "tipo_visita": itemGeneral.tipo_visita,
                // "estatus": "Concluido",
                "estatus": itemGeneral?.visitaDoms[0].resultado != null ? (itemGeneral?.visitaDoms[0].encontraste_cliente == 1 ? itemGeneral?.visitaDoms[0].resultado : itemGeneral?.visitaDoms[0].motivo) : "Concluido",
                "resultado": itemGeneral.resultado,
                "userid": itemGeneral.userid,
                "fecha_inicio": itemGeneral.fecha_inicio != null ? itemGeneral.fecha_inicio : getSqlServerDateTime(),
                "fecha_fin": itemGeneral.fecha_fin != null ? itemGeneral.fecha_fin : getSqlServerDateTime(),
                "cierre_visita": true
            }
        }

        await CobranzaService.UpsertCobranzaRutaItem(data)
            .then(res => {
                setLoading(false)
                toast.success(message("¡Operación exitosa!", "Visita cerrada"));
                dispatch(setItemGeneralProperty({ key: 'cierre_visita', value: true }));
                handleReturnPage();
                // handleGetSeguimientoContrato();
            }).catch(e => {
                setLoading(false)
                if (e.response !== undefined)
                    toast.error(message("¡Error!", e.response.data.message));
                else
                    toast.error(message("¡Error!", e.message));
            })
    }

    const GetUserStats = async () => {
        let data = {
            "verb": "string",
            "id": userId,
            "field": "string",
            "value": "string",
            "level": 0,
            "flag": true,
            "anyObject": {},
            "kvp": {}
        }
        await CobranzaService.GetUserStats(data)
            .then(res => {
                const filteredCount = res?.data?.responseData?.filter(item =>
                    item.status === "rota" && item.contrato_id === contratoActual?.contrato_id
                ).length;
                setPromesasRotas(filteredCount);
            }).catch(e => {
                setPromesasRotas(0);
                if (e.response !== undefined)
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
                else
                    setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
            })
    }

    function getSqlServerDateTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return {
        currentDataSeguimiento,
        userDetail,
        promesasRotas,
        loading,
        handleReturnPage,
        allContracts,
        handleCerrarVisita,
        modalConfirm,
        setModalConfirm
    }

}

export default useSeguimientoVisitaDetalle;