import { createSlice } from '@reduxjs/toolkit';

const initialState = JSON.parse(sessionStorage.getItem('SeguimientoVisitaDetalleSlice')) || {
   contratoActual: {},
   itemGeneral: {},
   datosConsulta: {},
   visitadoliciliariaId: "",
   archivos: [],
};

export const SeguimientoVisitaDetalleSlice = createSlice({
   name: 'SeguimientoVisitaDetalleSlice',
   initialState,
   reducers: {
      setDatosConsulta: (state, action) => {
         state.datosConsulta = action.payload;
         sessionStorage.setItem('SeguimientoVisitaDetalleSlice', JSON.stringify(state)); // Guardar en sessionStorage
      },
      setContrato: (state, action) => {
         state.contratoActual = action.payload;
         sessionStorage.setItem('SeguimientoVisitaDetalleSlice', JSON.stringify(state));
      },
      setItemGeneral: (state, action) => {
         state.itemGeneral = action.payload;
         sessionStorage.setItem('SeguimientoVisitaDetalleSlice', JSON.stringify(state));
      },
      setVisitaDomiciliariaId: (state, action) => {
         state.visitadoliciliariaId = action.payload;
         sessionStorage.setItem('SeguimientoVisitaDetalleSlice', JSON.stringify(state));
      },
      setArchivos: (state, action) => {
         state.archivos = action.payload;
         sessionStorage.setItem('SeguimientoVisitaDetalleSlice', JSON.stringify(state));
      },
      resetState: (state) => {
         Object.assign(state, initialState);
         sessionStorage.removeItem('SeguimientoVisitaDetalleSlice'); // Eliminar datos al restablecer
      },
      setItemGeneralProperty: (state, action) => {
         const { key, value } = action.payload;
         state.itemGeneral[key] = value;
         sessionStorage.setItem('SeguimientoVisitaDetalleSlice', JSON.stringify(state));
      },
   },
});

export const {
   setDatosConsulta,
   setContrato,
   setItemGeneral,
   setVisitaDomiciliariaId,
   setArchivos,
   resetState,
   setItemGeneralProperty,
} = SeguimientoVisitaDetalleSlice.actions;

export default SeguimientoVisitaDetalleSlice.reducer;
