import React, { useState } from 'react';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import { useRef } from 'react';
import DataTable from '../../../Components/datatable/DataTable'
import { Field, Formik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField } from '../../../Components/TextField';
import { useDispatch } from 'react-redux';
import useSeguimientoValuaciones from '../../../Hooks/Inventario/useSeguimientoValuaciones';
import { SelectField } from '../../../Components/SelectField';
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission';
import { Section, SubModule } from '../../../Auth/Authorization';
import Select from 'react-select'

const Valuacion = () => {
  const refFormik = useRef();

  const {
    initialValue,
    columns,
    buscar,
    setBuscar,
    selectRef,
    estatus,
    setEstatus,
    getListado,
    loading,
    setLoading,
    modalMessage,
    setModalMessage,
    data,
    totalPages,
    currentPage,
    setCurrentPage,
    handleEdit,
    handlePageClick,
    submitSearch,
    listEstatus,
    cleanSearch,
    clearAdvanced
  } = useSeguimientoValuaciones();

  return (
    <Formik
      innerRef={refFormik}
      enableReinitialize={true}
      initialValues={initialValue}
    >
      {({ isValid, values, resetForm, setFieldValue, errors }) => (
        <>
          <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
          <Modal isOpen={modalMessage.isOpen} color='#fff' width={360}>
            <div className='row mx-2'>
              <div>
                <h5 className='p-2 fw-bold'>Búsqueda avanzada</h5>
              </div>
              <div style={{ 'border': 'solid', 'borderBottomWidth': '1px', 'borderTopWidth': '0px', 'marginBottom': '10px', 'borderBottomColor': '#CFCFCF' }}></div>
              <div className='mt-4'>
                <div className='inner-addon right-addon'>
                  <i className="glyphicon fas fa-search"></i>
                  <TextField id="buscarAvanzada" name="buscarAvanzada" type="text" holder="Buscar..." onChange={(event) => {
                    setFieldValue("buscarAvanzada", event.target.value);
                  }} />
                </div>
                <div className='mt-4'>
                  <TextField id="fechaInicio" label="Fecha de inicio" name="fechaInicio" type="date" onChange={(event) => {
                    setFieldValue("fechaInicio", event.target.value);
                  }} />
                </div>
                <div className='mt-4'>
                  <TextField id="fechaFin" label="Fecha de fin" name="fechaFin" type="date" onChange={(event) => {
                    setFieldValue("fechaFin", event.target.value);
                  }} />
                </div>
                <div className='mt-4'>
                  <Field className="form-check-input" type="checkbox" name="concluido" onClick={(event) => {
                    setFieldValue("concluido", event.target.value);
                  }} />
                  <label className="form-check-label" style={{ 'marginLeft': '10px' }}>Concluido</label>
                </div>
              </div>
              <div className='p-3 mt-2 mb-4'>
                <div className="d-flex gap-2">
                  <button className='btn btn-outline-secondary col cols-6' onClick={(e) => {
                    setModalMessage({ isOpen: false });
                    clearAdvanced(setFieldValue)
                  }}>
                    Cancelar
                  </button>
                  <button className='btn btn-outline-primary col cols-6' onClick={(e) => {
                    submitSearch(values, true, setFieldValue);
                    setModalMessage({ isOpen: false });
                    resetForm();
                  }}>
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoValuacionSection, SubModule.SeguimientoValuacion)?.Ver}>
            <section>
              <div className='pb-4'>
                <section className='mx-4 my-4 d-flex flex-column'>
                  <strong className='mt-2'>Valuación de unidad</strong>
                  <div className='col-12'>
                    <div className="row">
                      <div className="col-12 col-md-3 mt-2 mr-1 inner-addon right-addon">
                        <i className="glyphicon fas fa-search"></i>
                        <TextField id="buscar" name="buscar" type="text" holder="Buscar..." onChange={(event) => {
                          setFieldValue("buscar", event.target.value);
                          setBuscar(event.target.value);
                          // getListado();
                        }} />
                      </div>
                      <div className="col-12 col-md-3 mt-2 mr-1">
                        <Select ref={selectRef} options={listEstatus} className='col-12' onChange={(e) => { 
                            setFieldValue("estatus", e.value);
                            setEstatus(e.value);
                            getListado(); 
                          }} placeholder="Estatus" />
                        {/* <SelectField id="estatus"
                          name="estatus"
                          items={listEstatus}
                          onChange={(event) => {
                            setFieldValue("estatus", event.value);
                            setEstatus(event.value);
                            getListado();
                          }} /> */}
                      </div>
                      <div className="col-12 col-md-auto mt-2 mr-1">
                        <button className='btn btn-blue' onClick={(e) => {
                          setModalMessage({ isOpen: true, type: 1, title: 'Busqueda Avanzada' })
                        }
                        }>
                          Búsqueda Avanzada
                        </button>
                        <button className='btn btn-blue mx-1' onClick={(e) => {
                          setFieldValue("buscar", '');
                          setFieldValue("estatus", '');
                          setBuscar('');
                          setEstatus('');
                          cleanSearch(setFieldValue);
                        }}>
                          {/* <i className="glyphicon fas fa-broom fa-1x"></i>  */}
                          Limpiar Búsqueda
                        </button>
                      </div>

                    </div>
                  </div>

                  <div className="row table-responsive">
                    <div className='col-12'>
                      <DataTable
                        column={columns}
                        data={data}
                        detailable={ValidatePermissionById(4, Section.SeguimientoValuacionSection, SubModule.SeguimientoValuacion)?.Actualizar}
                        handleDetail={handleEdit}
                        pageCount={totalPages}
                        currentPage={currentPage - 1}
                        handlePageClick={handlePageClick}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </ValidatePermission>
        </>
      )
      }
    </Formik>
  )
}

export default Valuacion