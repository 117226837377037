import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router';
import UnidadesRecuperadasService from '../../Services/Cartera/UnidadesRecuperadas';
import { FormatFecha, FormatNumberMoney } from '../../Utils/functions'

const useUnidadesRecuperadas = () => {
    const navigate = useNavigate();

    const columns = [
        { field: 'vin', headerName: 'VIN', width: 150 },
        { field: 'contrato', headerName: 'Contrato', width: 150 },
        { field: 'resultado', headerName: 'Tipo de recuperación', width: 200 },
        { field: 'montoVencido', headerName: 'Monto vencido', width: 150 },
        { field: 'montoRecuperado', headerName: 'Monto recuperado', width: 200 },
        { field: 'fechaVencimiento', headerName: 'Fecha vencimiento', width: 200 },
        { field: 'vigencia', headerName: 'Vigencia', width: 150 },
        { field: 'promesa_status', headerName: 'Estatus', width: 150 }
    ];

    const tipoRecuperacionSelect = [
        // { value: 1, label: "No dan acceso" },
        // { value: 2, label: "Carta visita" },
        // { value: 3, label: "Carta jurídica" },
        // { value: 4, label: "Cita despacho externo", },
        // { value: 5, label: "Promesa de pago" },
        // { value: "", label: "Tipo de recuperación", },
        { value: 6, label: "Promesa de auto garantía", },
        { value: 7, label: "Promesa de auto definitiva", }, //ESTATUS en valuacion  MONTO RECUPERADO -, MONTO VENCIMIENTO - VIFENCIA -
    ]

    //Cambiar el select de estatus. 
    // Vigencia es de dia actual a promesa.fecha_compromiso


    const estatusSelect = [
        // { value: "", label: "Estatus" },
        { value: 1, label: "En Valuación" },
        { value: 2, label: "En Proceso de Venta" },
        { value: 3, label: "Pago de la Unidad" },
        { value: 4, label: "Proceso de Entrega", },
        { value: 5, label: "Vigente", },
    ]
    const [pageRows, setpageRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [zonas, setzonas] = useState([]);
    const [tipoRecuperacion, setTipoRecuperacion] = useState("")
    const [estatus, setEstatus] = useState("")

    //Filtro
    const [data, setData] = useState([{}]);
    const [currentData, setCurrentData] = useState([]);
    const [textoBusqueda, setTextoBusqueda] = useState("")

    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });
    const [openModalAvanzado, setOpenModalAvanzado] = useState(false);

    //Filtro Avanzado
    const [dataFiltro, setDataFiltro] = useState({
        //Filtor vanzado
        estatus: "",
        tipoRecuperacion: "",
        strAvanzado: "",
        fechaInicio: "",
        fechaFin: "",
        concluido: false,
        busquedaAvanzada: false
    });

    useEffect(() => {
        handleGetAll();
    }, [])

    useEffect(() => {
        // Filtra los datos según los filtros seleccionados
        const filteredData = data.filter(item => {
            let fechaFiltrada = true

            if (dataFiltro.busquedaAvanzada && dataFiltro.fechaFin !== "" && dataFiltro.fechaInicio !== "") {

                const FechaVencimiento = new Date(dataFiltro.fechaFin)
                const FechaInicio = new Date(dataFiltro.fechaInicio)

                // const fechaItemFormat = getDateNow(item.fechaVencimiento)
                const fechaItem = new Date(item.ruta_item.promesa.fecha_vencimiento)
                fechaFiltrada = fechaItem >= FechaInicio && fechaItem <= FechaVencimiento
            }
            const nameMatches = textoBusqueda === '' || (item.vin && item.vin.toLowerCase().includes(textoBusqueda.toLowerCase()));
            const contratoMatches = textoBusqueda === '' || (item.contrato && item.contrato.toLowerCase().includes(textoBusqueda.toLowerCase()));


            return (
                (nameMatches || contratoMatches) &&
                (tipoRecuperacion === '' || tipoRecuperacion === 'Tipo de recuperación' || item.resultado === tipoRecuperacion) &&
                (estatus === '' || estatus === 'Estatus' || item.promesa_status === estatus) &&
                (dataFiltro.busquedaAvanzada === false || fechaFiltrada)
            )
        })

        const indexOfLastItem = (currentPage + 1) * pageRows
        const indexOfFirstItem = indexOfLastItem - pageRows
        const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
        setCurrentData(newData)

        const newTotalPages = Math.ceil(filteredData.length / pageRows)
        setPageCount(newTotalPages)
    }, [currentPage, textoBusqueda, tipoRecuperacion, estatus, pageRows, data, dataFiltro])

    useEffect(() => {
        setCurrentPage(0)
    }, [pageRows, textoBusqueda, tipoRecuperacion, estatus])

    useEffect(() => {
        setTextoBusqueda(dataFiltro.strAvanzado)
        setOpenModalAvanzado(false)
    }, [dataFiltro])

    const handleGetAll = async () => {
        setLoading(true);
        await UnidadesRecuperadasService.getAllVisitaDom()
            .then(res => {
                const filteredData = res.data.responseData.filter(item => item.ruta_item !== null && item.ruta_item.contrato && item.ruta_item.promesa);

                const resultadosFiltrados = filteredData.filter(item =>
                    item.resultado === "Promesa de auto definitiva" || item.resultado === "Promesa de auto garantía"
                );

                const response = resultadosFiltrados.map((item) => {
                    let estatus = "En Valuación"
                    let montoVencido
                    let montoRecuperado = FormatNumberMoney(item.ruta_item.promesa.monto_recuperado)
                    // let vigencia = item.vigencia
                    item.ruta_item.promesa.monto_vencido = FormatNumberMoney(item.ruta_item.promesa.monto_vencido)
                    item.ruta_item.promesa.monto_recuperado = FormatNumberMoney(item.ruta_item.promesa.monto_recuperado)
                    //
                    if (item.resultado === "Promesa de auto definitiva") {
                        montoVencido = FormatNumberMoney(item.ruta_item.promesa.monto_vencido)
                        montoRecuperado = "-"
                    }
                    if (item.resultado === "Promesa de auto garantía") {
                        montoVencido = FormatNumberMoney(item.ruta_item.promesa.monto_prometido)
                        estatus = "Vigente"

                    }

                    let colorFondo
                    let colorTexto

                    switch (estatus) {
                        case "Cumplida":
                            colorFondo = "#D8F2CD"
                            colorTexto = "#47A066"
                            break;
                        case "Rota":
                            colorFondo = "#F5CECE"
                            colorTexto = "#CE2222"
                            break;
                        case "En Valuación":
                            colorFondo = "#E8EAF6"
                            colorTexto = "#1A3D7E"
                            break;
                        case "Por vencer":
                            colorFondo = "#FFE5CB"
                            colorTexto = "#D57200"
                            break;
                        case "Vigente":
                            colorFondo = "#FAE7BC";
                            colorTexto = "#B46B16";
                        default:
                            break;
                    }

                    const today = new Date()
                    let fechaVencimiento = "-"
                    let vigenciaDias = 0
                    if (item.ruta_item.promesa?.fecha_vencimiento) {
                        fechaVencimiento = FormatFecha(item.ruta_item.promesa.fecha_vencimiento)
                        const fechaVencimientoDay = new Date(item.ruta_item.promesa.fecha_vencimiento)
                        const diferenciaDias = fechaVencimientoDay - today
                        vigenciaDias = diferenciaDias / (1000 * 60 * 60 * 24)
                    }
                    item.ruta_item.contrato.fecha_ultima_act = FormatFecha(item.ruta_item.contrato.fecha_ultima_interaccion)
                    item.ruta_item.contrato.fecha_ultima_act = FormatFecha(item.ruta_item.contrato.fecha_ultima_interaccion)
                    return {
                        ...item,
                        vin: item.ruta_item.contrato.vin,
                        contrato: item.ruta_item.contrato.contrato_id,
                        montoVencido: `$${montoVencido}`,
                        montoRecuperado: montoRecuperado,
                        fechaVencimiento: fechaVencimiento,
                        vigencia: vigenciaDias >= 0 ? Math.ceil(vigenciaDias) : 0,
                        promesa_status: estatus,
                        colorFondo: colorFondo,
                        colorTexto: colorTexto,
                        sol_id: item.ruta_item.contrato.sol_id,

                    }
                })

                setData(response)
            })
        setLoading(false);
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    const handleDetails = (value) => {
        //Movimiento a Detalles del Job
        navigate(`/cobranza/unidades-recuperadas/detalle`, {
            state: value
        })
    }

    return {
        columns,
        currentData,
        handleDetails,
        handlePageClick,
        currentPage,
        pageCount,
        setTextoBusqueda,
        loading,
        zonas,
        setpageRows,
        tipoRecuperacionSelect,
        setTipoRecuperacion,
        estatusSelect,
        setEstatus,
        dataFiltro,
        setDataFiltro,
        openModalAvanzado,
        setOpenModalAvanzado,
        textoBusqueda,
    }
}

export default useUnidadesRecuperadas