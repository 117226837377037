import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import SubmenuDatadocs from './SubMenuDatadocs'
import Modal from '../../Components/Modal/Modal'
import Spinner from '../../Components/Loadig'
import DataTable from '../../Components/datatable/DataTable'
import CrearFolderModal from '../Ventas/ModalCreateFolder'
import useDataDocs from '../../Hooks/DataDocs/useDataDocs'
import { pageRows } from '../../Utils/functions'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'
const DataFolder = () => {
  const { columns,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    dataTipoAdquisicion,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    DataFiltroFolder,
    setfiltroDocs,
    habdleGetAll,
    handleSort,
    setpageRows } = useDataDocs();
  const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
  return (
    <>
      <SubmenuDatadocs />
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <section className='mx-3 py-4 d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Folder DataDocs</strong></span>
        </div>
        <span className='mt-2'>El siguiente listado corresponde a los vehículos que cuentan con su carpeta dada de alta en Datadocs.</span>
      </section>

      <ValidatePermission isActive={ValidatePermissionById(4, Section.Folder, SubModule.DataDocs).Ver}>
        <section className='headerTab pb-4'>
          <div className='mx-2'>
            <div className='row col-12'>
              <div className="col-lg-3 col-md-6 col-sm-12 my-1 inner-addon right-addon">
                <i className="glyphicon fas fa-search"></i>
                <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStr(e.target.value) }} />
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12 my-1'>
                <Select options={dataTipoAdquisicion} className='col-12' onChange={(e) => { setTipoAd(e.value) }} placeholder="Tipo Compra" />
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12 my-1'>
                <Select options={DataFiltroFolder} className='col-12' onChange={(e) => { setfiltroDocs(e.value) }} placeholder="Folder" />
              </div>
            </div>
          </div>

        </section>
        <RowPages
          setpageRows={setpageRows}
        />
        <div className='mx-4 table-responsive' style={{overflowX : "scroll"}}>
          <DataTable
            column={columns}
            data={data}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={currenPage - 1}
            actions={ValidatePermissionById(4, Section.Folder, SubModule.DataDocs).Actualizar}
            type={1}
            handleAction={handleCreateFolder}
            handleSort={handleSort}
          />
        </div>
      </ValidatePermission>

      {
        isOpenDocs &&
        <CrearFolderModal
          isOpen={isOpenDocs}
          setIsOpen={setIsOpenDocs}
          item={dataVehiculo}
          handleGetAll={habdleGetAll}
          message={message}
          toast={toast}
        />
      }
    </>
  )
}

export default DataFolder