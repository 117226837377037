import React, { useEffect, useState } from "react";
import CobranzaService from '../Services/Cobranza/Cobranza'
import { FormatFecha, message, formatearMontoMx, handleParseDate, FormatPhoneNumber } from "../Utils/functions";


const CarteraInfoCard = ({ datos, user, promesasRotas, updateCurrentData = false, setCurrentDataSeguimiento }) => {
    const [contrato, setContrato] = useState({});
    const [validacion, setValidacion] = useState();
    const [loading, setLoading] = useState(false); // Estado de carga.

    useEffect(() => {
        if (datos.contrato_id) {
            getContrato(datos.contrato_id);
        }
    }, [datos.contrato_id]);

    const getContrato = async () => {
        let data = {
            FIELD: "contrato_id",
            VALUE: datos.contrato_id,
        };
        try {
            const resp = await CobranzaService.GetAllCobranzaEventoItemByField(data);
            setContrato(resp.data.responseData[0]);
            if (updateCurrentData) {
                let montoVencido = formatearMontoMx(resp.data.responseData[0]?.monto_adeudo)
                setCurrentDataSeguimiento((prevData) => ({
                    ...prevData,
                    monto_adeudo: montoVencido
                }));
            }

            let data2 = {
                contrato: datos.contrato_id
            }
            const actualización = await CobranzaService.getValidacionUltimaAct(data2)
            const validation = actualización.data.validacion ? "Exito" : "Fallido"
            setValidacion(validation)
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <div className="row p-4 wrapper-vehiculo">
                <div className="col col-12">
                    <div className="row align-items-center justify-content-between">
                        <div className="col col-auto">
                            <h1 className="fw-bold text-sm">Información de cartera</h1>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row gap-2">
                        <div className="col col-12">
                            <div className="row mt-4">
                                <div className="col col-3">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Días vencidos:</strong> {contrato.dias_vencido}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-3">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Monto vencido:</strong> {contrato?.monto_adeudo ? formatearMontoMx(contrato.monto_adeudo): ""}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-3">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Fecha última clave:</strong> {contrato?.vigencia_clave ? FormatFecha(contrato.vigencia_clave): ""}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-3">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Días sin consultar clave:</strong> {contrato.dias_sin_consultar_clave}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col col-4">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Última actualización: </strong>
                                            {contrato.fecha_ultima_interaccion? `${FormatFecha(contrato.fecha_ultima_interaccion)} - ${validacion}`: "-"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-4">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Cobrador:</strong>{user.names}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-4">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Promesas rotas del período:</strong><span className="error"><i className='bx bxs-error'> </i> {promesasRotas}</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col col-12">
                                    <div className="row justify-content-start">
                                        <div className="col col-12 text-xs">
                                            <span><strong className="mr-2">Dirección:</strong>{contrato.calle} {contrato.no_ext} {contrato.no_int} {contrato.colonia} {contrato.municipio} {contrato.estado} {contrato.pais}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CarteraInfoCard;