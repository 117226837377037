import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import DataTable from '../../../../Components/datatable/DataTable';
import ConfirmarRutasModal from './ConfirmarRutasModal';
import useRutaDetalle from '../../../../Hooks/SeguimientoVisita/useRutaDetalle';
import CambioRutaModal from './CambioRutaModal';
import RutaConfiguradaModal from './RutaConfiguradaModal';
import Modal from '../../../../Components/Modal/Modal';
import ErrorModal from '../../../../Components/Modal/ErrorModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import Spinner from '../../../../Components/Loadig';
import { FormatFecha, FormatFechaSplit } from '../../../../Utils/functions';

const RutaDetalle = () => {
   const { state } = useLocation();
   const navigate = useNavigate();

   let {
      columns,
      columnsContratos,
      dataContratos,
      dataContratosNoAsignados,
      ruta,
      setStrAsignado,
      setStrNoAsignado,
      dataTipoPersonas,
      dataZonas,
      btnEditConfirm,
      setBtnEditConfirm,
      rutaConfigurada,
      setRutaConfigurada,
      handleCheckAsignar,
      handleCheckSinAsignar,
      openModal,
      setOpenModal,
      openModalConfirmacion,
      setOpenModalConfirmacion,
      openModalCambioRuta,
      setOpenModalCambioRuta,
      handleConfirmacion,
      handleDesasignarContratos,
      contratosSeleccionados,
      dataAsignarContratos,
      dataContratosSinAsignar,
      contratosAsignadosAruta,
      handleAceptar,
      loading,
      setFiltroTipoNoAsignado,
      setFiltroZonaNoAsignado,
      setFiltroTipoAsignado,
      setFiltroMunicipio,
      dataMunicipios,
      modalMessage,
      setModalMessage,
   } = useRutaDetalle(state);

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <Modal isOpen={modalMessage.isOpen} color='#fff'>
            {
               modalMessage.type === 1 &&
               <ErrorModal
                  modalMessage={modalMessage}
                  setModalMessage={setModalMessage}
               />
            }
            {
               modalMessage.type === 2 &&
               <SuccessModal
                  modalMessage={modalMessage}
                  setModalMessage={setModalMessage}
               />
            }

         </Modal>
         <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-3'>
            <i className="bx bxs-chevron-left mx-2" onClick={() => { navigate(-1) }}></i>
            Regresar a listado de rutas {state.page}
         </header>
         <section className='mx-5'>
            <section className='py-4'>
               <div className='row'>
                  <span className='col-sm-6 col-xl-3' style={{ fontSize: "20px", color: "#000", fontWeight: "bold" }}>
                     <strong>Ruta - {ruta.ruta}</strong>
                  </span>
                  {
                     ruta.editar && (
                        <button
                           id="btn_ruta_configurada"
                           disabled={dataContratos.length === 0 || !rutaConfigurada}
                           className='btn col-sm-4 col-xl-2'
                           type="button"
                           onClick={() => {
                              setOpenModalConfirmacion(true);
                           }}>Guardar Ruta</button>
                     )
                  }

               </div>
               <p><span style={{ fontWeight: "bold" }}>Datos de ruta:</span></p>
               <div className='row'>
                  <span className='col-sm-6 col-xl-3' style={{ fontWeight: "bold" }}>Cobrador</span>
                  <span className='col-sm-6 col-xl-3' style={{ fontWeight: "bold" }}>Fecha de ruta</span>
               </div>
               <div className='row'>
                  <span className='col-sm-6 col-xl-3' >{ruta?.nombrecobrador}</span>
                  <span className='col-sm-6 col-xl-3' >{FormatFechaSplit(ruta?.fechainicio)} al {FormatFechaSplit(ruta?.fechafin)}</span>
               </div>
            </section>
            {
               ruta.editar && (
                  <section>
                     <section className=' py-4'>
                        <div className='d-flex row'>
                           <span className='fs-5 col-6'>
                              <span style={{ fontSize: "20px", color: "#000", fontWeight: "bold" }}>
                                 <strong>Contratos no asignados: </strong>
                              </span>
                           </span>
                        </div>
                     </section>
                     <div className="mb-3 row">
                        <div className="col-sm-4 col-xl-2 inner-addon right-addon">
                           <i className="glyphicon fas fa-search"></i>
                           <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStrNoAsignado(e.target.value) }} />
                        </div>
                        <div className="col-sm-4 col-xl-2">
                           <Select
                              name='tipo_persona'
                              options={dataTipoPersonas}
                              className='col-12 mx-1'
                              onChange={(e) => {
                                 setFiltroTipoNoAsignado(e.label)
                              }}
                              placeholder="Tipo de persona" />
                        </div>
                        <div className="col-sm-4 col-xl-2">
                           <Select name='zona' options={dataZonas} className='col-12 mx-1' onChange={(e) => { setFiltroZonaNoAsignado(e.label) }} placeholder="Zona" />
                        </div>
                        <section className='d-flex justify-content-end row col-xl-6'>
                           <div className='row mt-2'>
                              <div className='d-flex justify-content-end row col-12'>
                                 {
                                    btnEditConfirm === 1 && (
                                       <button id="AT_btnReasignar" className='btn btn-outline col-sm-5 col-xl-5' type='button' onClick={() => { setBtnEditConfirm(2) }}>Editar ruta</button>
                                    )
                                 }
                                 {
                                    btnEditConfirm === 2 && (
                                       <>
                                          <div className='d-flex justify-content-end col-sm-5 col-xl-5'>
                                             <span>
                                                <input
                                                   className="form-check-input"
                                                   type="checkbox"
                                                   checked={rutaConfigurada}
                                                   value={"rutaconfigurada"}
                                                   onChange={(e) => {
                                                      setRutaConfigurada(e.target.checked)
                                                   }} /><span style={rutaConfigurada ? { fontWeight: 'bold', color: '#000' } : null} 
                                                      // onClick={() => {
                                                      // if (rutaConfigurada) {
                                                      //    setOpenModalConfirmacion(true);
                                                      // }}}
                                                      > Ruta configurada</span>
                                             </span>
                                          </div>
                                          <button
                                             id="AT_btnAgregar"
                                             disabled={dataAsignarContratos.length === 0}
                                             className='btn col-sm-5 col-xl-3'
                                             type="button"
                                             onClick={() => {
                                                setOpenModal(true);
                                             }}>Confirmar</button>
                                       </>
                                    )
                                 }
                              </div>
                           </div>
                           <div className='row'>
                              <span className='col-12 pt-3' style={{ textAlign: "end" }}>Contratos asignados a ruta: {dataContratos.length}</span>
                           </div>
                        </section>
                     </div>
                     {/* <ValidatePermission
                     isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                     > */}
                     {
                        btnEditConfirm === 2 && (
                           <div className='mx-4 table-responsive' style={{ height: '25rem', overflowY: "scroll" }}>
                              <DataTable
                                 column={columns}
                                 data={dataContratosNoAsignados}
                                 checkbox={true}
                                 handleCheck={handleCheckAsignar}
                              />
                           </div>
                        )
                     }
                     {
                        btnEditConfirm === 1 && (
                           <div className='mx-4 table-responsive' style={{ height: '25rem', overflowY: "scroll" }} >
                              <DataTable
                                 column={columns}
                                 data={dataContratosNoAsignados}
                              />
                           </div>
                        )
                     }
                     {/* </ValidatePermission> */}
                  </section>
               )
            }

            {
               btnEditConfirm === 2 && (
                  <>
                     <div className='d-flex justify-content-center'>
                        <i className="icon-blue ri-arrow-up-circle-fill" style={{ fontSize: "30px" }} ></i>
                     </div>
                     <div className="divider"></div>
                     <div className='d-flex justify-content-center'>
                        <i className="icon-blue ri-arrow-down-circle-fill" style={{ fontSize: "30px" }}></i>
                     </div>
                  </>)
            }
            <section>
               <section className=' py-4'>
                  <div className='d-flex row'>
                     <span className='fs-5 col-6'>
                        <span style={{ fontSize: "20px", color: "#000", fontWeight: "bold" }}>
                           <strong>Contratos asignados: </strong>
                        </span>
                     </span>
                  </div>
               </section>
               <div className="mb-3 row">
                  <div className="col-sm-4 col-xl-2 inner-addon right-addon">
                     <i className="glyphicon fas fa-search"></i>
                     <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { setStrAsignado(e.target.value) }} />
                  </div>
                  <div className="col-sm-4 col-xl-2">
                     <Select name='tipo_persona' options={dataTipoPersonas} className='col-12 mx-1' onChange={(e) => { setFiltroTipoAsignado(e.label) }} placeholder="Tipo de persona" />
                  </div>
                  <div className="col-sm-4 col-xl-2">
                     <Select name='municipio' options={dataMunicipios} className='col-12 mx-1' onChange={(e) => { setFiltroMunicipio(e.label) }} placeholder="Municipios" />
                  </div>
                  <section className='d-flex justify-content-end row col-xl-6'>
                     <div className='d-flex justify-content-end row col-12'>
                        {
                           btnEditConfirm === 2 && (
                              <button
                                 id="AT_btnReasignar"
                                 disabled={dataContratosSinAsignar.length === 0}
                                 className='btn btn-outline col-sm-5 col-xl-5'
                                 type='button'
                                 onClick={() => {
                                    setOpenModalCambioRuta(true);
                                 }}
                              >Eliminar seleccionados</button>
                           )
                        }
                     </div>
                  </section>
               </div>

               {/* <ValidatePermission
                  isActive={ValidatePermissionById(4, Section., SubModule.Usuarios).Ver}
                  > */}
               {
                  btnEditConfirm === 2 && (
                     <div className='mx-4 table-responsive' style={{ height: '25rem', overflowY: "scroll" }} >
                        <DataTable
                           column={columnsContratos}
                           data={dataContratos}
                           checkbox_ruta={true}
                           handleCheck={handleCheckSinAsignar}
                        />
                     </div>
                  )
               }
               {
                  btnEditConfirm === 1 && (
                     <div className='mx-4 table-responsive' style={{ height: '25rem', overflowY: "scroll" }} >
                        <DataTable
                           column={columnsContratos}
                           data={dataContratos}
                        />
                     </div>
                  )
               }
               {/* </ValidatePermission> */}
               {
                  <ConfirmarRutasModal
                     isOpen={openModal}
                     setIsOpen={setOpenModal}
                     setConfirmar={handleConfirmacion}
                     contratos={contratosSeleccionados}
                     fechaInicio={ruta.fechainicio}
                     fechaFin={ruta.fechafin}
                  />
               }
            </section>
         </section>
         {
            <RutaConfiguradaModal
               isOpen={openModalConfirmacion}
               setIsOpen={setOpenModalConfirmacion}
               nombreCobrador={ruta.nombrecobrador}
               handleAceptar={handleAceptar}
            />
         }
         {
            <CambioRutaModal
               isOpen={openModalCambioRuta}
               setIsOpen={setOpenModalCambioRuta}
               setConfirmar={handleDesasignarContratos}
               tipoUsuario={2}
            />
         }
      </>
   )
}

export default RutaDetalle