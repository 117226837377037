import { createSlice } from '@reduxjs/toolkit';

// Cargar el estado inicial desde sessionStorage
const initialState = JSON.parse(sessionStorage.getItem('DetalleRutaSlice')) || {
   datosruta: {
      ruta: "",
      nombrecobrador: "",
      fechainicio: '',
      fechafin: '',
      items: [],
      zonas: [],
      editar: false
   }
};

export const DetalleRutaSlice = createSlice({
   name: 'DetalleRutaSlice',
   initialState,
   reducers: {
      setDatosRuta: (state, action) => {
         state.datosruta = action.payload;
         // Guardar en sessionStorage
         sessionStorage.setItem('DetalleRutaSlice', JSON.stringify(state));
      },
      setLimpiarData: (state) => {
         state.datosruta = {
            ruta: "",
            nombrecobrador: "",
            fechainicio: '',
            fechafin: '',
            items: [],
            zonas: [],
            editar:false
         };
         // Limpiar sessionStorage
         sessionStorage.removeItem('DetalleRutaSlice');
      },
   },
});

export const { setDatosRuta, setLimpiarData } = DetalleRutaSlice.actions;

export default DetalleRutaSlice.reducer;
