import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  finalizarTramite:false,
  clienteSiniestro:"",
  contratoAcendes:"",
  countFetching:0
}

export const reloadSlice = createSlice({
  name: 'reloadSlice',
  initialState,
  reducers: {
    concluirTramiteGestria: (state, action) => {
      return { ...state, finalizarTramite: action.payload.finalizarTramite }
    },
    increment: (state,action) => {      
      // return {...state,count:state.count += 1}      
      return { ...state, count: action.payload.count += 1}
    },
    clearStateProcesoTramite : () =>{
      return {count: 0,finalizarTramite:false}
    },
    SaveClienteSiniestro : (state, action) =>{
      return { ...state, clienteSiniestro: action.payload.clienteSiniestro,contratoAcendes: action.payload.contrato}
    },
    IncrementFetching: (state,action) => {              
      return { ...state, countFetching: action.payload.countFetching += 1}
    },
  },
})

export const { concluirTramiteGestria,increment,clearStateProcesoTramite,SaveClienteSiniestro,IncrementFetching } = reloadSlice.actions

export default reloadSlice.reducer