import axios from '../../Utils/ApiService';

const ClavesDemoService = {
    getClaveDemo:(page,rows,tipoCompraId,externoId,string)=> {        
        return axios.get(`/cartera/ClaveDemoGet`);        
    },    
    
    saveClaveDemo:(data)=>{
        return axios.post(`/cartera/ClaveDemoCrear`, data);
    },
    GetAllClavesDemo:()=>{
        return axios.post(`/cartera/GetAllClavesDemo`);
    }
}
export default ClavesDemoService;