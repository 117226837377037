import { useEffect, useState } from 'react'
// import { useNavigate, } from 'react-router';
import CobranzaService from '../../Services/Cobranza/Cobranza'
import { FormatFecha , getDateFormat} from '../../Utils/functions'
import toast, { Toaster } from 'react-hot-toast'


const BoletinadoHooks = () => {

  const columns = [
    { field: 'contrato', headerName: 'Contrato' },
    { field: 'tipo_Persona', headerName: 'Tipo de persona' },
    { field: 'nombreCliente', headerName: 'Nombre' },
    { field: 'siniestroClienteBoletinarMotivo', headerName: 'Motivo Boletinado' },
    { field: 'comentariosUI', headerName: 'Comentarios' },
    { field: 'usuarioAltaNombre', headerName: 'Cobrador solicito' },
    { field: 'fechaAltaStr', headerName: 'Fecha y Hora de solicitud' },
  ];

  const [name, setName] = useState("")
  const [pageRows, setpageRows] = useState(10)
  const [data, setData] = useState([])

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentData, setCurrentData] = useState(data)
  //Modal
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '', data: [], authorizationType: 2, Origin: "Listado" });

  const [tiposPersona, setTiposPersona] = useState([]);
  const [cobradores, setCobradores] = useState([]);
  const [cobradorSeleccionado, setCobradorSeleccionado] = useState(''); // Estado para el cobrador seleccionado
  const [tipoPersonaSeleccionado, setTipoPersonaSeleccionado] = useState(''); // Estado para el tipo de persona

  //#Tipos de persona
  useEffect(() => {
    const fetchTipoPersona = async () => {
      try {

        const data = [{ key: 1, value: "Cliente" }, { key: 2, value: "Aval" }];

        setTiposPersona(data);
      } catch (error) {
        toast.error('Error al obtener los cobradores');
      }
    };

    fetchTipoPersona();
  }, []);
  //#End tipos de persona

  // Traer cobradores desde el servicio
  useEffect(() => {
    const fetchCobradores = async () => {
      try {
        const res = await CobranzaService.GetSeguimientoBoletinado();
        const cobradoresData = res.data;
        // Eliminar duplicados por nombre
        const cobradoresUnicos = cobradoresData.filter(
          (cobrador, index, self) =>
            index === self.findIndex((t) => t.usuarioAltaNombre === cobrador.usuarioAltaNombre)
        );

        setCobradores(cobradoresUnicos);
      } catch (error) {
        toast.error('Error al obtener los cobradores');
      }
    };

    fetchCobradores();
  }, []);

  useEffect(() => {
    // Filtra los datos según los filtros seleccionados
    const filteredData = data
      .filter(item => {
        const contratoMatches = name === '' || (item.contrato && item.contrato.toLowerCase().includes(name.toLowerCase()));
        const nameMatches = name === '' || (item.nombreCliente && item.nombreCliente.toLowerCase().includes(name.toLowerCase()))
        return (
          (nameMatches || contratoMatches) &&
          (cobradorSeleccionado === '' || item.usuarioAltaNombre === cobradorSeleccionado) &&
          (tipoPersonaSeleccionado === '' || item.tipo_Persona === tipoPersonaSeleccionado)
        )
      })

    const indexOfLastItem = (currentPage + 1) * pageRows
    const indexOfFirstItem = indexOfLastItem - pageRows
    const newData = filteredData.slice(indexOfFirstItem, indexOfLastItem)
    setCurrentData(newData)

    const newTotalPages = Math.ceil(filteredData.length / pageRows)
    setPageCount(newTotalPages)
  }, [currentPage, name, cobradorSeleccionado, tipoPersonaSeleccionado, pageRows, data])

  useEffect(() => {
    setCurrentPage(0)
  }, [pageRows, name, cobradorSeleccionado, tipoPersonaSeleccionado])

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  };

  useEffect(() => {
    handleGetSeguimientoContrato()
  }, [])


  const handleGetSeguimientoContrato = async () => {
    setLoading(true);
    const data = {
      "verb": null,
      "id": null,
      "field": null,
      "value": null
    }
    await CobranzaService.GetSeguimientoBoletinado()
      .then(res => {
        const filteredData = res.data;//.responseData;//.filter(item => item.aplica_visita === 1);

        const update = filteredData.map(item => {

          //Confirmar que sea Cliente
          const tipoPersona = (item.tipo_Persona === "Fisica" || item.tipo_Persona === "Moral" ? "Cliente" : item.tipo_Persona)

          return {
            ...item,
            comentariosUI: item.comentariosEjecutivo,
            tipo_Persona: tipoPersona
          }
        })
        setData(update)
        setLoading(false);

      }).catch(e => {
        setLoading(false);
        if (e.response !== undefined)
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.response.data.message })
        else
          setModalMessage({ ...modalMessage, isOpen: true, type: 1, message: e.message })
      })
  }

  return {
    tiposPersona,
    cobradores,
    setCobradorSeleccionado,
    setTipoPersonaSeleccionado,
    setName,
    setpageRows,
    columns,
    currentData,
    pageCount,
    currentPage,
    handlePageClick,
    handleGetSeguimientoContrato,
    loading, 
    setLoading
  }
}
export default BoletinadoHooks