import { useEffect, useRef, useState } from "react";
import Catalogo from "../../../Services/Catalogos/Catalogo";
import DSAsignaciones from "../../../Services/DatamovilSystems/Asignaciones";
import { message } from "../../../Utils/functions";

const DSAsignacionesHook = (toast)  => {
    const refFormik = useRef();
    const selectRef = useRef();
    const selectServicioRef = useRef();
    const selectUbicacionRef = useRef();
    let initial = {
        buscar: '',
        tecnicoId: '',
        tipoServicioId: '',
        tipoUbicacion: '',
        page: 1,
        rows: 10
    }
    const [initialValue, setInitialValue] = useState(initial);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [tecnicoId, setTecnicoId] = useState('');
    const [tipoServicio, setTipoServicio] = useState('');
    const [tipoUbicacion, setTipoUbicacion] = useState('');
    const [tecnicos, setTecnicos] = useState([]);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [modalMessage, setModalMessage] = useState({ isOpen: false, type: 1, title: '', message: '' });
    const [tipoServicios, setTipoServicios] = useState([]);

    //MultiSelect
    const [state, setState] = useState([]);
    //
    const [totalServiciosSinAsignar, setTotalServiciosSinAsignar] = useState(0);

    const columns = [
        { field: 'folioId', headerName: 'Folio', widht: '150px' },
        { field: 'tecnico', headerName: 'Técnico', widht: '150px' },
        { field: 'cantidadServicios', headerName: 'Cantidad de servicios', widht: '150px' },
        { field: 'fechaCita', headerName: 'Fecha de servicio', widht: '150px' }
    ];

    const tipoUbicaciones = [
        { value: '1', label: 'Interna' },
        { value: '2', label: 'Externa' }
    ];

    useEffect(() => {
        getTecnicos();
        getTipoServicios();
        getListado();
        getServiciosSinAsignar()
    }, []);

    useEffect(() => {
        getListado();
    }, [buscar, tecnicoId, tipoServicio, tipoUbicacion, page, rows]);

    const getTecnicos = async () => {
        await DSAsignaciones.getTecnicos()
        .then(resp => {
            let items = resp.data.map(item => {
                return {
                    value: item.userId,
                    label: item.tecnico
                }
            });

            setTecnicos(items);
        })
        .catch(err => {

        })
    }

    const getTipoServicios = async () => {
        await Catalogo.getDropdownsByTable('Catalogos.Servicios')
        .then(resp => {
            let items = resp.data.map(item => {
                return {
                    value: item.TipoServicioId.toString(),
                    label: item.TipoServicio
                }
            });
            setTipoServicios(items); 
        })
        .catch(err => {
            console.error(err);
        });
    }

    const getListado = async () => {
        setLoading(true);
        const params = { 
            buscar: buscar,  
            tecnicoId: tecnicoId,  
            tipoServicioId: tipoServicio, 
            tipoUbicacion: tipoUbicacion, 
            page, 
            rows
        };
        
        await DSAsignaciones.getSeguimientoServiciosAsignados(params)
        .then(resp => {
            let items = [];
            resp.data.data.forEach(item => {
                item.fechaHoraCita = item.fechaCita ?? "-";
                let detalles = item.detalle.map(subItem => {
                    subItem.fechaHoraCita = subItem.fechaCita ? `${getFormatDateTime(subItem.fechaCita, subItem.horaCita)}` : ' - ';
                    return subItem;
                });
                item.detalle = detalles;
                items.push(item);
            });
            setData(items);
            setTotalPages(resp?.data.totalPages);
            setTotalRows(resp.data.totalRows);
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getServiciosSinAsignar = async () => {
        const params = {
            buscar,
            proceso: '',
            tipoSolicitud: tipoServicio,
            tipoUbicacion,
            page,
            rows,
        }
        DSAsignaciones.getSeguimientoSinAsignar(params)
            .then(resp => {
               let total = resp?.data?.data?.length;
                setTotalServiciosSinAsignar(total);
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const getFormatDate = (fecha) => {
        if(fecha === undefined || fecha === null) return false;
        return fecha.split("T")[0].split("-").reverse().join("-");
    }

    const getFormatDateTime = (fecha, hora) => {
        if(fecha === undefined || fecha === null) return false;
        return fecha.split("T")[0].split("-").reverse().join("-") + " " + getHourFormat(hora);
    }

    
    const getHourFormat = (hours) => {
        let [hour, minutes] = hours.split(":");
        
        let newHour = '';
        let meridiam = 'AM'
        if(Number(hour) > 12){
            newHour = ("0"+(Number(hour) - 12)).slice(-2);
            meridiam = 'PM';
        }else{
            if(Number(hour) === 12){
                meridiam = 'PM';
            }
            newHour = ("0"+Number(hour)).slice(-2);
        }
        return `${newHour}:${minutes}:00 ${meridiam}`;
    }
    
    const handleAsignar = () => {
        //Validar que exista un servicio sin asignar
        if(totalServiciosSinAsignar <= 0){
            toast.custom(<div className='warnin-toast'>{message("¡Alerta.!", "No hay servicios sin asignar.")}</div>);
            setTimeout(() => {
                toast.remove();
            }, 3000);
            return;
        }
        setModalMessage({ isOpen: true, title: 'Asignar servicios', type: 1 });
    }

    const limpiarFiltros = (values) => {
        setInitialValue({
            buscar: '',
            tecnicoId: [],
            tipoSolicitudId: '',
            tipoUbicacion: '',
            page: 1,
            rows: 10
        });
        
        // selectRef.current.clearValue();
        selectServicioRef.current.clearValue();
        selectUbicacionRef.current.clearValue();

        setBuscar('');
        setTecnicoId('');
        setTipoServicio('');
        setTipoUbicacion('');
        setState();

        values.buscar = '';
        values.tecnicoId = '';
        values.tipoServicioId = '';
        values.tipoUbicacion = '';
        values.page = 1;
        values.rows = 10;
    }

    const handlePageClick = (page) => {
        setPage(page.selected+1);
    };

    return {
        refFormik,
        selectRef,
        selectServicioRef,
        selectUbicacionRef,
        initialValue,
        data,
        loading,
        setBuscar,
        buscar,
        columns,
        setModalMessage,
        modalMessage,
        setTipoServicio,
        setTipoUbicacion,
        setTecnicoId,
        tipoServicios,
        tipoUbicaciones,
        getListado,
        handleAsignar,
        tecnicos,
        limpiarFiltros,
        handlePageClick,
        page,
        totalPages,
        setRows,
        state,
        setState
    }
}

export default DSAsignacionesHook;