import Tab from "../../../Components/Tabs/Tab";
import Tabs from "../../../Components/Tabs/Tabs";
import SeguimientoAsignaciones from "./SeguimientoAsignaciones";
import SeguimientoSinAsignar from "./SeguimientoSinAsignar";

const AsignacionesDatamovilSystems = () => {

    return (
        <>
            <Tabs>
                <Tab title={'Servicios sin asignar'}>
                    <SeguimientoSinAsignar />
                </Tab>
                <Tab title={'Asignación de servicios'}>
                    <SeguimientoAsignaciones />
                </Tab>
            </Tabs>
        </>
    );

}

export default AsignacionesDatamovilSystems;