import axios from '../../Utils/ApiService';

const HorariosCalendarioService = {
    getSeguimiento: ({ buscar, page, rows }) => {
        let query = '';
        if(buscar.trim().length > 0) query += `buscar=${buscar}&`; 
        query += `page=${page}&rows=${rows}`;
        return axios.get(`/functions/seguimientoServiciosDatamovilHorarios?${query}`);
    },
    getSeguimientoUbicaciones: ({ buscar, calendarioTipoId, page, rows }) => {
        let query = '';
        if(buscar.trim().length > 0) query += `buscar=${buscar}&`; 
        query += `calendarioTipoId=${calendarioTipoId}&page=${page}&rows=${rows}`;
        return axios.get(`/functions/seguimientoServiciosUbicacionDetalle?${query}`);
    },
    postServicio: (data) => {
        return axios.post(`/functions/ServicioDatamovil`, data);
    },
    putServicio: (data) => {
        return axios.put(`/functions/ServicioDatamovil`, data);
    },
    getServicio: (id) => {
        return axios.get(`/functions/ServicioDatamovil?CalendarioTipoId=${id}`);
    },
    crearHorarios: (data) => {
        return axios.post(`/functions/CalendarioHorario`, data);
    },
    actualizarHorarios: (data) => {
        return axios.put(`/functions/CalendarioHorario`, data);
    },
    getHorarios: (id, ubicacionId) => {
        return axios.get(`/functions/CalendarioHorario?UbicacionId=${ubicacionId}&CalendarioTipoId=${id}`);
    }
}

export default HorariosCalendarioService;