import React, { useEffect, useState } from "react";
import DataTable from "../../../../Components/datatable/DataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CobranzaService from "../../../../Services/Cobranza/Cobranza";
import { FormatFecha } from "../../../../Utils/functions";
import Modal from "../../../../Components/Modal/Modal";
import Spinner from "../../../../Components/Loadig";

const DireccionesDetalle = () => {
   const navigate = useNavigate();
   const {datosConsulta} = useSelector((state) => state.SeguimientoVisitaDetalleSlice);
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(false);

   const columns = [
      { field: 'usuario', headerName: 'Usuario', width: 210 },
      { field: 'fecha_creacion', headerName: 'Fecha de registro', width: 200 },
      { field: 'direccion', headerName: 'Dirección', width: 550 },
      { field: 'visitas', headerName: 'Visitas', width: 100 },
      { field: 'comentariosTooltip', headerName: 'Comentarios', width: 150 }
   ]

   useEffect(()=>{
      handleGetDirecciones()
   },[])

   const handleGetDirecciones = async () => {
      setLoading(true)
      let value = {
         field: "referencia_ascendes_id",
         value: datosConsulta?.persona?.value
      }

      await CobranzaService.getAllContactoReferenciaDireccionByField(value)
         .then(res => {
            let datosFormateados = res?.data?.responseData?.map(item => {
               if (item) {
                  let domicilio = `Calle: ${item?.calle} No.Ext:${item?.numero_exterior || ""} No.Int:${item?.numero_interior || ""} C.P:${item?.codigo_posta}, Col. ${item?.colonia_id} Mun. ${item?.municipio_id} Estado: ${item?.estado_id}`
                  let fechaRegistro = FormatFecha(item?.fecha_creacion);
                  let comentarios = item?.comentarios
                  let usuario = item?.names?.names
                  let num_visitas = item?.visitas ? item?.visitas : 0
                  return {
                     ...item,
                     direccion: domicilio,
                     comentariosTooltip: comentarios,
                     fecha_creacion:fechaRegistro,
                     usuario: usuario,
                     visitas: num_visitas
                  }
               }
            });
            setData(datosFormateados);
         })
      setLoading(false)
   }

   return (
      <>
         <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
         <div style={{ background: "#f4f4f4", height: '100vh', width: 'auto'}}>
            <div style={{ background: "#F5F5F5", paddingBottom: "1rem" }}>
               <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2' style={{ background: "#FFF" }}>
                  <div className='cursor-pointer' onClick={() => { navigate(-1) }}>
                     <i className="bx bxs-chevron-left mx-2" ></i>
                     Regresar al detalle de seguimiento de visita
                  </div>
               </header>
               <div className="col col-12 d-flex">
                  <div className="col col-11 px-4 py-2">
                     <div className="row p-4 wrapper-vehiculo">
                        <div className="col col-12">
                           <div className="row align-items-center justify-content-between">
                              <div className="col col-auto ">
                                 <h2 className="fw-bold ">Consulta de direcciones</h2>
                              </div>
                           </div>
                           <div className="row gap-2">
                              <div className="col col-12">
                                 <div className="row mt-4">
                                    <div className="col-sm-4 col-xl-3">
                                       <div className="row justify-content-start">
                                          <div className="col col-12">
                                             <span><strong className="mr-2">Tipo de persona:</strong> {datosConsulta.persona.item.tipo}</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-sm-5 col-xl-4">
                                       <div className="row justify-content-start">
                                             <div className="col col-12">
                                                <span><strong className="mr-2"> Nombre:</strong> {datosConsulta.persona.label}</span>
                                             </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col col-11 mx-3 my-2  wrapper-vehiculo " style={{ background: "#FFF" }}>
                  <div className="px-4">
                     <p className="pt-2" >Detalle de Direcciones</p>
                     <DataTable
                        column={columns}
                        data={data}
                     />
                  </div>
                  <div className="mt-3 mb-3 row"><span> </span></div>
               </div>
            </div>
         </div>
      </>
   );
}

export default DireccionesDetalle;